import React from "react";

import Layout from "./src/shared/layout";

/*
 * Custom fonts
 * Uncomment below if using custom fonts
 */
import "./static/fonts/fonts.css";
import "react-toastify/dist/ReactToastify.css";

if (process.env.GATSBY_SANITY_DATASET === "production") {
  console.log = () => {};
  console.error = () => {};
}

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}
