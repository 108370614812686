import React from "react";

import { SanityDrawer } from "@graphql-types";
import { Container } from "@util/standard";
import { Link } from "@global";
import { assets, colors, fontFamilies, fontSizes, fontWeights, mediaQuery } from "@util/constants";
import styled from "styled-components";
import { useDrawer } from "@accessible/drawer";

interface Props {
  data: SanityDrawer;
}

function LinkDrawerContent({ data }: Props) {
  if (data == null) return null;

  const { title, description, drawerLinks } = data;
  const context = useDrawer();

  return (
    <ContentWrapper>
      <p className="drawer-text drawer-title">{title}</p>
      <p className="drawer-text drawer-subtitle">{description}</p>

      {drawerLinks &&
        drawerLinks.map((link, index) => {
          if (link == null || link.linkText == null) return null;

          return (
            <MenuLink
              {...link}
              width="100%"
              className="drawer-text"
              key={link._key ?? link.linkText + index}
              drawerId={context}
            />
          );
        })}
    </ContentWrapper>
  );
}

export default LinkDrawerContent;

const ContentWrapper = styled(Container)`
  flex-direction: column;
  width: 100%;

  .drawer-title {
    font-family: ${fontFamilies.tobias};
    font-size: ${fontSizes.h3.mobile}px;
    line-height: ${fontSizes.h3.mobile + 6}px;
    margin: 15px 0 20px 0;
  }

  .drawer-subtitle {
    font-size: ${fontSizes.h4.mobile}px;
    font-weight: ${fontWeights.bold};
    line-height: 1.2;
    margin: 0px;
    margin-bottom: 30px;
  }
  .drawer-text {
    color: ${colors.navy};
  }

  ${mediaQuery.tabletDown} {
    .drawer-title {
      margin-top: 40px;
    }
  }
`;
const MenuLink = styled(Link)`
  padding: 30px 28px;
  display: block;

  margin-bottom: 10px;
  border: 1px solid ${colors.navy};
  height: max-content;
  width: 100%;

  background: url(${assets.chevronRight});
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: calc(100% - 30px) 50%;
`;
