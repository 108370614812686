import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

export default function useFooterQuery() {
  const { sanityFooter }: Query = useStaticQuery(graphql`
    {
      sanityFooter {
        newsletterTitle
        newsletterCta {
          ...sanityLink
        }
        socials {
          _key
          externalLink
          linkText
          icon {
            provider
            name
            _type
            _key
          }
        }
        groupedLinks {
          title
          _key
          links {
            ...sanityLink
          }
        }
      }
    }
  `);

  return sanityFooter;
}
