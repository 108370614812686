import "swiper/css/pagination";
import "swiper/css/navigation";

import { AbsoluteWrapper, Container } from "@util/standard";
import { Maybe, SanityHeroSlider, SanityLink, SanityVariation } from "@graphql-types";
import React, { ReactNode, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { assets, colors, mediaQuery, mobileOSQueries } from "@util/constants";

import { ErrorMsg } from "@shared";
import styled from "styled-components";
import HeroSlide from "@components/heroSlide";
import { usePersonalisationHook } from "@util/personalisationHooks";
import { VariationModal } from "@components/variationSelect";

interface Props {
  data: Maybe<SanityHeroSlider> | undefined;
  childContent?: any;
  extraContent?: ReactNode;
  careNavigation?: Maybe<Maybe<SanityLink>[]> | undefined;
  variationSelector?: Maybe<SanityVariation> | undefined;
}

const ariaLabel = "Hero Heading";

export default function HeroSlider({
  data,
  childContent = null,
  careNavigation,
  variationSelector,
  extraContent,
}: Props) {
  const [controller, setController] = useState<any>();
  if (data == null || data.slides == null || data.slides.length === 0)
    return <ErrorMsg data={data} msg="Error fetching data for hero" />;
  const { slides, slideInterval } = data;

  const isOneSlide = slides.length === 1;
  const variationSelectorRef = useRef<HTMLDivElement | null>(null);

  const {
    selectedAnswer,
    hasSubmitted,
    pageColor,
    handleAnswerSelect,
    handleCustomModalClose,
    currentPersonalisation,
  } = usePersonalisationHook(variationSelector);

  const shouldShowPersonalisation = () => {
    if (!variationSelector) return false;
    if (!currentPersonalisation) return true;
    if (currentPersonalisation.selectedState == "unselected") return true;
    if (currentPersonalisation.modalOpen) return true;
    return false;
  };

  return (
    <HeroSection aria-labelledby={ariaLabel}>
      <HeroContainer className={extraContent ? "hasExtraContent" : ""}>
        {shouldShowPersonalisation() && (
          <VariationModal
            hasSubmitted={hasSubmitted}
            data={variationSelector}
            pageColor={pageColor}
            color="navy"
            handleAnswerSelect={handleAnswerSelect}
            selectedAnswer={selectedAnswer}
            isHero
            handleCustomClose={handleCustomModalClose}
            containerRef={variationSelectorRef}
          />
        )}
        <SliderWrapper>
          <Swiper
            modules={[Autoplay, Pagination, Navigation]}
            pagination={{
              clickable: true,
            }}
            autoplay={
              slideInterval
                ? {
                    delay: slideInterval,
                  }
                : false
            }
            onSwiper={setController}
            passiveListeners
          >
            {slides?.map((slide, index) => {
              if (slide == null) return null;
              return (
                <SwiperSlide key={slide._key}>
                  <HeroSlide
                    data={slide}
                    childContent={childContent}
                    careNavigation={careNavigation}
                    slideIndex={index}
                    hasVariantSelector={shouldShowPersonalisation()}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          {controller && !isOneSlide && !shouldShowPersonalisation() && (
            <NavArrow src={assets.circleArrowFill} onClick={() => controller.slidePrev()} />
          )}
          {controller && !isOneSlide && !shouldShowPersonalisation() && (
            <NavArrow right src={assets.circleArrowFill} onClick={() => controller.slideNext()} />
          )}
        </SliderWrapper>
        {extraContent && <div className="col-2">{extraContent}</div>}
      </HeroContainer>
      {extraContent && <div className="heroExtraContentRow">{extraContent}</div>}
    </HeroSection>
  );
}

const HeroSection = styled.section`
  position: relative;
  .heroExtraContentRow {
    width: 100%;
    display: none;
  }

  ${mediaQuery.tabletLandScapeDown} {
    .heroExtraContentRow {
      display: block;
    }
  }
`;

const HeroContainer = styled(Container)<{ hasImage?: boolean }>`
  position: relative;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background-color: ${props => (props.hasImage ? colors.white : colors.navy)};
  align-items: ${props => (props.hasImage ? "initial" : "center")};

  div {
    color: white;
  }
  p {
    max-width: 565px;
  }

  .content {
    position: relative;
    width: 100%;
    margin: 0 40px;

    ${mediaQuery.mobileDown} {
      margin: 20px;
      .scroll-down {
        bottom: 35px;
      }
    }
  }

  .col-2 {
    z-index: 1;
    margin: auto 15% auto auto;
    width: 440px;

    ${mediaQuery.tabletLandScapeDown} {
      display: none;
    }
  }

  #hero-links,
  .scroll-down {
    pointer-events: visible;
  }
  ${mobileOSQueries.iosMobile} {
    height: 500px;
    min-height: fill-available;
  }

  ${mediaQuery.tabletDown} {
    height: 92vh;
    p {
      width: 70%;
    }
    ${mobileOSQueries.androidMobile} {
      height: 92vh;
      p {
        width: 100%;
      }
    }
  }
`;

const SliderWrapper = styled(AbsoluteWrapper)`
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  .swiper-horizontal > .swiper-pagination {
    position: absolute;
    z-index: 2;
    text-align: left;
    left: 9%;
    bottom: 11%;
    width: max-content;
  }
  .swiper-horizontal {
    width: 100%;
    height: 100%;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    opacity: 0.5;
    background: ${colors.white};
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
  }

  ${mediaQuery.tabletDown} {
    .swiper-horizontal > .swiper-pagination {
      left: 14%;
      bottom: 9%;
    }
  }
`;

const NavArrow = styled.img<{ right?: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 50%;
  z-index: 2;
  pointer-events: visible;
  transform: translateY(-50%);
  left: 4%;
  ${({ right }) => right && `transform: rotateZ(180deg) translateY(50%); right: 4%; left: unset;`};

  ${mediaQuery.ipadProDown} {
    left: 5%;
    ${({ right }) =>
      right && `transform: rotateZ(180deg) translateY(50%); right: 5%; left: unset;`};
  }
  ${mediaQuery.tabletDown} {
    left: 7%;
    ${({ right }) =>
      right && `transform: rotateZ(180deg) translateY(50%); right: 7%; left: unset;`};
  }

  ${mediaQuery.mobileDown} {
    display: none;
  }
`;
