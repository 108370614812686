import {
  Maybe,
  SanityEvent,
  SanityForm,
  SanityGuideCategory,
  SanityNews,
  SanityRegion,
  SanitySeo,
  SanityVillage,
  SanityVillageLivingOptionsRef,
} from "@graphql-types";

import { LEAD_CAPTURE_SET } from "@util/constants";

export interface EventsInRegions {
  events: SanityEvent[];
  region: SanityRegion;
}

export interface VillageContactsInRegions {
  contacts: SanityVillage[];
  region: SanityRegion;
}

export interface GuidesInCategories {
  forms: SanityForm[];
  category: SanityGuideCategory;
}

export interface InfoPacksInRegion {
  forms: SanityVillage[];
  region: SanityRegion;
}

export interface InfoPacksLandingPageContext {
  infoPacksInRegions: InfoPacksInRegion[];
}
export interface EventsLandingPageContext {
  eventsInRegions: EventsInRegions[];
}

export interface ContactVillageLandingPageContext {
  villageContactsInRegions: VillageContactsInRegions[];
}

export interface GuidesLandingPageContext {
  guidesInCategories: GuidesInCategories[];
}
export interface VillagePageContext {
  pageType?: string;
  region?: string;
  regionId?: string;
  regionName?: string;
  slug?: string;
  parentRegion?: string;
  villageCode?: string;
  isDevelopment?: boolean;
  title?: string;
  lat?: number;
  lng?: number;
  seo?: Maybe<SanitySeo> | undefined;
  pagePath?: string;
  displayCareOptionsInForm?: boolean;
  contactFormOptions?: string;
  livingOptions?: SanityVillageLivingOptionsRef[];
  villageContact?: any;
  regionDynamicsValue?: string;
  dynamicsVillageCode?: string;
}
export interface Village extends VillagePageContext {
  news?: SanityNews[];
  events?: SanityEvent[];
}

export interface SelectedVillageInfoPack {
  villageTitle?: string;
  villageCode?: string;
}
export interface SelectedMetguides {
  guideName?: string;
  guidePDFName?: string;
  guideCategory?: string;
}

type MetlifeEnquiry =
  | "prospect_only"
  | "partner"
  | "enquirer_only"
  | "881990000"
  | "881990001"
  | "881990002";
type MetlifeInfoResearchStage = "starting" | "active" | "ready";
type MetlifeCareLevel = "ILU" | "SA" | "CARE";

export type MetlifeUnion = MetlifeEnquiry | MetlifeInfoResearchStage | MetlifeCareLevel;
export interface LeadCapture {
  FirstName?: string;
  LastName?: string;
  Email?: string;
  metlifeEnquiry?: MetlifeEnquiry;
  metlife_info_research_stage?: MetlifeInfoResearchStage;
  metlife_care_level?: MetlifeCareLevel;
  metlife_financially_capable?: boolean;
  [key: string]: any;
}

export interface Facet {
  count: number;
  isRefined: boolean;
  label: string;
}

export type FacetGroup = { [key: string]: { [key: string]: number } };

export type MarketoQuestionValue =
  | "metlife_care_level"
  | "metlife_info_research_stage"
  | "metlife_financially_capable"
  | "metlifeEnquiry";

export function isMarketoQuestionValue(value: string): value is MarketoQuestionValue {
  return (
    value === "metlife_care_level" ||
    value === "metlife_info_research_stage" ||
    value === "metlife_financially_capable" ||
    value === "metlifeEnquiry"
  );
}

export function isMetlifeUnion(value: Maybe<string> | undefined): value is MetlifeUnion {
  if (value == null) return false;
  return LEAD_CAPTURE_SET.includes(value);
}

export type DrawerId =
  | "infoPack"
  | "bookATour"
  | "registerInfoForm"
  | "guides-form"
  | "info-packs-form"
  | string
  | undefined;
