import { SanityVillageLivingOptionsRef } from "@graphql-types";
import { colors } from "@util/constants";
import { darkOrLight } from "@util/helper";
import React from "react";
import styled from "styled-components";
import Link from "./link";

interface Props {
  data: SanityVillageLivingOptionsRef[] | undefined;
}

const VillageLivingOptions = ({ data }: Props) => {
  if (!data) return null;

  return (
    <StyledVillageLivingOptions>
      {data &&
        data?.map((option: SanityVillageLivingOptionsRef) => {
          if (!option) return null;

          const Tag = () => (
            <StyledLivingOptionTag
              className="living-option"
              colour={option.livingOption?.filterColour?.colour?.hex ?? colors.cream}
            >
              <p className="option-title">{option.livingOption?.title}</p>
            </StyledLivingOptionTag>
          );

          if (option.link) {
            return (
              <Link {...option.link} key={option._key}>
                <Tag />
              </Link>
            );
          }

          return <Tag key={option._key} />;
        })}
    </StyledVillageLivingOptions>
  );
};

export default VillageLivingOptions;

const StyledLivingOptionTag = styled.span<{ colour: string }>`
  padding: 10px 20px;

  background-color: ${props => props.colour};
  color: ${props => darkOrLight(props.colour)};
  width: fit-content;
  display: flex;
  flex-direction: column;

  .option-title {
    font-size: 16px;
    margin: 0;
  }
`;

const StyledVillageLivingOptions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0;
`;
