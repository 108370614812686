import React from "react";
import { SwiperSlide } from "swiper/react";
import styled from "styled-components";

import { BlocksContent, Carousel, Image } from "@global";
import { SanityGallery } from "@graphql-types";
import { Container, PageWidth, Section } from "@util/standard";
import { mediaQuery } from "@util/constants";

interface Props {
  data: SanityGallery;
}

function Gallery({ data }: Props) {
  if (data == null) return null;

  const { text, images } = data;

  const ariaLabel = "Image gallery";

  return (
    <Section aria-labelledby={ariaLabel}>
      <Wrapper>
        {text && (
          <TextWrapper width="61%">
            <BlocksContent data={text} />
          </TextWrapper>
        )}
        {images && (
          <Container position="relative">
            <Carousel>
              {images?.map(image => {
                if (image == null) return null;

                return (
                  <SwiperSlide key={image._key}>
                    <ImageWrapper width="100%" height="100%">
                      <Image data={image} width="100%" objectFit="cover" />
                    </ImageWrapper>
                  </SwiperSlide>
                );
              })}
            </Carousel>
          </Container>
        )}
      </Wrapper>
    </Section>
  );
}

export default Gallery;

const TextWrapper = styled(Container)`
  p {
    font-size: 20px;
    line-height: 24px;
  }

  margin-bottom: 50px;
  ${mediaQuery.tabletDown} {
    width: 80%;
    padding: 0 20px;
    margin: auto;
    margin-bottom: 30px;
  }
`;

const Wrapper = styled(PageWidth)`
  ${mediaQuery.tabletDown} {
    width: 100%;
    padding: 0;
  }
`;

const ImageWrapper = styled(Container)`
  width: 100%;
  height: 100%;
  max-height: 70vh;

  img {
    -webkit-user-drag: none;
  }

  ${mediaQuery.mobileDown} {
    height: 350px;
  }
`;
