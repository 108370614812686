import React, { Dispatch, ReactNode, SetStateAction } from "react";
import styled from "styled-components";

import { useEventListener, useScrollFreeze } from "@util/hooks";
import { AbsoluteWrapper, Overlay, PageWidth } from "@util/standard";
import { assets, mediaQuery } from "@util/constants";

interface Props {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>> | null;
  hideClose?: boolean;
  height?: string;
  maxWidth?: string;
  handleCustomClose?: () => void;
}

function ModalBox({
  children,
  isOpen,
  setIsOpen,
  hideClose,
  height,
  maxWidth,
  handleCustomClose,
}: Props) {
  if (children == null) return null;

  const handleClose = () => {
    handleCustomClose ? handleCustomClose() : setIsOpen && setIsOpen(false);
  };
  const handleKeyUp = (e: KeyboardEvent) => {
    if (e && e.code === "Escape") handleClose();
  };

  useEventListener("keyup", handleKeyUp);
  useScrollFreeze(isOpen);

  if (isOpen) {
    return (
      <ModalWrapper>
        <Overlay zIndex={10} onClick={handleClose} />
        <ModalChildWrapper
          height={height}
          maxWidth={maxWidth}
          className="modal-child-wrapper-absolute"
        >
          <PageWidth
            maxWidth={maxWidth ?? "100vw"}
            fullWidth={maxWidth == null ? false : true}
            mobileFullWidth
            className="modal-pagewidth"
          >
            {children}
          </PageWidth>
        </ModalChildWrapper>
        {!hideClose && (
          <CloseWrapper
            top="130px"
            right="60px"
            onClick={handleClose}
            className="modal-child-close-button"
          >
            <img src={assets.close} />
          </CloseWrapper>
        )}
      </ModalWrapper>
    );
  }
  return null;
}

export default ModalBox;

const ModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  position: fixed;
  z-index: 11;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &:-webkit-scrollbar {
    display: none;
  }
`;

const ModalChildWrapper = styled(AbsoluteWrapper)<{ height?: string; maxWidth?: string }>`
  z-index: 11;
  height: ${props => props.height ?? "50vh"};

  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  ${props => props.maxWidth && `max-width: ${props.maxWidth};`}
`;

export const CloseWrapper = styled(AbsoluteWrapper)`
  filter: invert(1);
  cursor: pointer;
  z-index: 12;
  ${mediaQuery.tabletDown} {
    top: 170px;
    right: 60px;
  }
  ${mediaQuery.mobileDown} {
    top: 150px;
    right: 50px;
  }
`;
