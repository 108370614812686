import React, { ReactNode } from "react";
import styled from "styled-components";

import { ButtonTheme } from "@util/types";
import { mediaQuery } from "@util/constants";
import { Container, defaultButtonStyle, buttonThemeStyle } from "@util/standard";
import { Loading } from "@global";
import { Maybe } from "@graphql-types";

const Loader = () => (
  <Container justifyContent="center" alignItems="center">
    <Loading />
    <p style={{ margin: "0 0 0 5px" }}>Loading..</p>
  </Container>
);

export interface Props {
  theme?: ButtonTheme;
  disabled?: boolean;
  children?: ReactNode;
  onClick?: (args?: any) => void;
  margin?: string;
  mobileMargin?: string;
  type?: "submit" | "reset" | "button";
  width?: string;
  loading?: boolean;
  colorvalue?: Maybe<string>;
  className?: string;
}

const Button = ({
  theme = "base",
  onClick,
  children,
  loading,
  type,
  className,
  ...rest
}: Props) => {
  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }
  };

  return (
    <StyledButton
      type={type ?? "button"}
      buttontheme={theme}
      onClick={handleOnClick}
      {...rest}
      className={className}
    >
      {loading ? <Loader /> : children}
    </StyledButton>
  );
};

export default Button;

export const StyledButton = styled.button<{
  buttontheme: ButtonTheme;
  mobileMargin?: string;
  margin?: string;
  width?: string;
  colorvalue?: Maybe<string>;
}>`
  ${defaultButtonStyle}
  ${buttonThemeStyle}

  ${({ margin }) => margin && `margin: ${margin};`}

  ${mediaQuery.mobileDown} {
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}
  }
`;
