import { Button, Frame, Image, Link, SearchInput } from "@global";
import { Container, DesktopContainer, P, TabletContainer } from "@util/standard";
import { Drawer, Target, Trigger } from "@accessible/drawer";
import { DrawerTarget, ErrorMsg } from "@shared";
import {
  HEADER_HEIGHT,
  HEADER_MOBILE_OFFSET,
  HEADER_OFFSET,
  HEADER_OFFSET_TOP,
  assets,
  colors,
  fontWeights,
  mediaQuery,
  villageSubPagePaths,
} from "@util/constants";
import { Maybe, SanityHeader, SanityVillage } from "@graphql-types";
import React, { memo, useEffect, useRef, useState } from "react";
import { useHasScrolled, useOnClickOutside } from "@util/hooks";

import BookATourForm from "@components/forms/bookATourForm";
import { Color } from "@util/types";
import { ContactForm } from "@components/forms/contactForm";
import { Link as GatsbyLink, navigate } from "gatsby";
import InfoPackForm from "@components/forms/infoPackForm";
import MegaMenu from "./megaMenu";
import MobileMenu from "./mobileMenu";
import VillageMobileMenu from "@components/village/villageMobileMenu";
import styled from "styled-components";
import { useStore } from "@state/store";

interface Props {
  data: Maybe<SanityHeader> | undefined;
  village?: SanityVillage;
  isHomePage?: boolean;
}

function Header({ data, village, isHomePage }: Props) {
  const [showSearchInput, setShowSearchInput] = useState(false);
  const { navColor, pageColor } = useNavColor(village);
  const { hasScrolled } = useHasScrolled();
  const searchRef = useRef<HTMLDivElement>(null);
  const handleShowSearch = () => setShowSearchInput(true);
  const handleHideSearch = () => setShowSearchInput(false);
  useOnClickOutside(searchRef, handleHideSearch);

  if (data == null) return <ErrorMsg data={data} msg="Error fetching data for header" />;
  const { logo, navigationItems, cta, phoneNumber, showPreferences } = data;

  const hasHomepageTopLink = Boolean(isHomePage && data.homePageTopLink != null);

  const returnHeaderOffset = () => {
    if (!hasScrolled) return false;
    if (hasHomepageTopLink) return HEADER_OFFSET_TOP;
    return HEADER_OFFSET;
  };

  return (
    <>
      <ul>
        <SkipToContent href="#hero-content">Skip to main content</SkipToContent>
        <SkipToContent href="#footer">Go to footer</SkipToContent>
      </ul>
      <Drawer>
        <DesktopContainer breakPointOverride={mediaQuery.tabletLandScapeDown}>
          <Frame />
          <StyledHeader
            aria-label="Go to home page"
            useTopBarOffset={isHomePage && !hasScrolled}
            hasBanner={hasHomepageTopLink}
          >
            <NavWrapper scrollOffset={returnHeaderOffset()} color={navColor}>
              {hasHomepageTopLink && (
                <ImportantUpdates aria-label="Metlifecare - Important updates">
                  <Link {...data.homePageTopLink} />
                </ImportantUpdates>
              )}
              {logo && (
                <LogoLinkWrapper to="/" aria-label="Metlifecare - Go to home page">
                  <Image data={logo} width="100%" />
                </LogoLinkWrapper>
              )}

              <MegaMenu data={navigationItems} pageColor={navColor} />
              {!showSearchInput && (
                <RightContainer>
                  <a
                    aria-label={`Contact phone number - ${phoneNumber}`}
                    className="header-phone-number"
                    href={`tel:${phoneNumber}`}
                  >
                    {phoneNumber}
                  </a>

                  <Trigger>
                    <div>
                      <Link {...cta} className="button-base" removeDrawerTransform />
                    </div>
                  </Trigger>

                  <button id="search" aria-label="Search Metlifecare" onClick={handleShowSearch}>
                    <img alt="search" src={assets.search} width="16px" height="16px" />
                  </button>

                  {showPreferences && (
                    <button
                      id="preferences"
                      aria-label="Change preferences"
                      onClick={() => navigate("/preferences")}
                    >
                      <img alt="search" src={assets.preferencesIcon} width="30px" height="30px" />
                    </button>
                  )}
                </RightContainer>
              )}
              {showSearchInput && (
                <RightContainer ref={searchRef}>
                  <SearchInput buttonColor="navy" width="300px" placeholder="Search" />
                </RightContainer>
              )}
            </NavWrapper>

            <VillageNav village={village} villageNavColor={pageColor} hasScrolled={hasScrolled} />
          </StyledHeader>
        </DesktopContainer>
      </Drawer>

      {isHomePage && data.homePageTopLink && (
        <ImportantUpdates aria-label="Metlifecare - Important updates">
          <Link {...data.homePageTopLink} />
        </ImportantUpdates>
      )}

      {/* mobile */}
      <Drawer>
        <TabletContainer breakPointOverride={mediaQuery.tabletLandScapeUp}>
          <StyledHeader aria-label="Go to home page" useTopBarOffset={isHomePage && !hasScrolled}>
            <NavWrapper scrollOffset={hasScrolled && HEADER_OFFSET} color={navColor}>
              {logo && (
                <LogoLinkWrapper to="/">
                  <Image data={logo} width="100%" aria-label="Go to home page" />
                </LogoLinkWrapper>
              )}
              <Trigger>
                <Container flexDirection="column">
                  <img src={assets.hamburger} />
                  <P margin="0" fontSize={12} fontWeight="bold">
                    Menu
                  </P>
                </Container>
              </Trigger>
            </NavWrapper>
            <VillageNav village={village} villageNavColor={pageColor} hasScrolled={hasScrolled} />
            <Frame />
          </StyledHeader>
          <MobileMenu data={data} />
        </TabletContainer>
      </Drawer>
    </>
  );
}

export default memo(Header);

interface VillageNavProps {
  villageNavColor: string;
  village?: SanityVillage;
  hasScrolled: boolean;
}

const VillageNav = ({ village, villageNavColor, hasScrolled }: VillageNavProps) => {
  if (village == null) return null;

  const {
    villageLogo,
    villageNavigationLinksColour,
    villageNavigationButtonsColour,
    publishIndependentLiving,
    publishLocation,
    publishLifeHere,
    publishCare,
    publishContact,
    publishHomeOptions,
    secondaryVillageColour,
    slug,
    region,
    disabledInfoPack,
    canBookATour,
    canRegisterInterest,
  } = village;

  const villageSlug = `${region?.slug?.current}/${slug?.current}`;

  return (
    <>
      <DesktopContainer>
        <NavWrapper
          color={villageNavColor}
          scrollOffset={hasScrolled && HEADER_HEIGHT + HEADER_OFFSET}
          linkColor={villageNavigationLinksColour}
          buttonColor={villageNavigationButtonsColour}
        >
          <Container>
            {villageLogo && <Image data={villageLogo} height="25px" width="inherit" />}
            {villageSlug && (
              <Container margin="0 0 0 40px" columnGap="30px" alignItems="center">
                <Link
                  linkText="Overview"
                  internalLink={villageSubPagePaths(villageSlug).overview}
                />
                {publishLocation && (
                  <Link
                    linkText="Location"
                    internalLink={villageSubPagePaths(villageSlug).location}
                  />
                )}
                {publishLifeHere && (
                  <Link
                    linkText="Life here"
                    internalLink={villageSubPagePaths(villageSlug).lifeHere}
                  />
                )}
                {publishCare && (
                  <Link linkText="Care" internalLink={villageSubPagePaths(villageSlug).care} />
                )}
                {publishHomeOptions && (
                  <Link
                    linkText="Home options"
                    internalLink={villageSubPagePaths(villageSlug).homeOptions}
                  />
                )}
                {publishIndependentLiving && (
                  <Link
                    linkText="Independent Living"
                    internalLink={villageSubPagePaths(villageSlug).independentLiving}
                  />
                )}
                {publishContact && (
                  <Link
                    linkText="Contact village"
                    internalLink={villageSubPagePaths(villageSlug).contact}
                  />
                )}
              </Container>
            )}
          </Container>

          <Container>
            {canBookATour && (
              <Drawer>
                <Trigger>
                  <div>
                    <Button
                      margin="0 20px 0 0"
                      theme={secondaryVillageColour == null ? "base" : "colored"}
                      colorvalue={secondaryVillageColour}
                    >
                      Book a tour
                    </Button>
                  </div>
                </Trigger>
                <DrawerTarget title="Book a tour" drawerId="bookATour">
                  <BookATourForm
                    isDrawerStyling
                    secondaryVillageColour={secondaryVillageColour}
                    selectedVillage={village}
                  />
                </DrawerTarget>
              </Drawer>
            )}
            {!disabledInfoPack && (
              <Drawer>
                <Trigger>
                  <div>
                    <Button
                      theme={secondaryVillageColour == null ? "base" : "colored"}
                      colorvalue={secondaryVillageColour}
                    >
                      Get info pack
                    </Button>
                  </div>
                </Trigger>
                <DrawerTarget title="Get your village info pack." drawerId="infoPack">
                  <InfoPackForm
                    secondaryVillageColour={secondaryVillageColour}
                    selectedVillage={village}
                    isGeneralInfoPack={false}
                  />
                </DrawerTarget>
              </Drawer>
            )}
            {canRegisterInterest && (
              <Drawer>
                <div>
                  <Trigger>
                    <div style={{ display: "block" }}>
                      <Button
                        theme={secondaryVillageColour == null ? "base" : "colored"}
                        colorvalue={secondaryVillageColour}
                      >
                        Register interest
                      </Button>
                    </div>
                  </Trigger>
                  <DrawerTarget title="Register Interest" drawerId="registerInfoForm">
                    <ContactForm
                      secondaryVillageColour={secondaryVillageColour}
                      isDevelopmentVillage
                      canRegisterInterest
                      isDrawer
                    />
                  </DrawerTarget>
                </div>
              </Drawer>
            )}
          </Container>
        </NavWrapper>
      </DesktopContainer>

      <Drawer>
        <StyledVillageMobileHeader>
          <StyledMobileVillageNav
            color={villageNavColor}
            scrollOffset={hasScrolled && HEADER_HEIGHT + HEADER_OFFSET}
            linkColor={villageNavigationLinksColour}
          >
            <Container height="50px" justifyContent="center" padding="10px 0">
              {villageLogo && <Image data={villageLogo} width="inherit" />}
            </Container>
            <StyledSecondaryVillageMobileHeader secondaryColor={secondaryVillageColour}>
              {villageSlug && (
                <Link
                  linkText="Overview"
                  internalLink={villageSubPagePaths(villageSlug).overview}
                  className="village-link-desktop"
                />
              )}
              <p>|</p>
              {publishCare && villageSlug && (
                <>
                  <Link
                    linkText="Care"
                    internalLink={villageSubPagePaths(villageSlug).care}
                    className="village-link-desktop"
                  />
                  <p>|</p>
                </>
              )}
              {publishLocation && villageSlug && !publishCare && (
                <>
                  <Link
                    linkText="Location"
                    internalLink={villageSubPagePaths(villageSlug).location}
                    className="village-link-desktop"
                  />
                  <p>|</p>
                </>
              )}
              {/* TODO: add menu functionality */}
              <Trigger>
                <p>More</p>
              </Trigger>
            </StyledSecondaryVillageMobileHeader>
          </StyledMobileVillageNav>
        </StyledVillageMobileHeader>
        {/* MOBILE ACTION FORMS & MENUS*/}
        <TabletContainer>
          <Target>
            <VillageMobileMenu data={village} />
          </Target>
          <Drawer>
            <DrawerTarget title="Get your village info pack" drawerId="infoPack">
              <InfoPackForm
                secondaryVillageColour={secondaryVillageColour}
                selectedVillage={village}
                isGeneralInfoPack={false}
              />
            </DrawerTarget>
          </Drawer>
          <Drawer>
            <DrawerTarget title="Book a tour" drawerId="bookATour">
              <BookATourForm
                isDrawerStyling
                secondaryVillageColour={secondaryVillageColour}
                selectedVillage={village}
              />
            </DrawerTarget>
          </Drawer>
          {canRegisterInterest && (
            <Drawer>
              <div>
                <Trigger>
                  <div style={{ display: "none" }}>
                    <Button></Button>
                  </div>
                </Trigger>
                <DrawerTarget title="Register Interest" drawerId="registerInfoForm">
                  <ContactForm
                    secondaryVillageColour={secondaryVillageColour}
                    isDevelopmentVillage
                    isDrawer
                  />
                </DrawerTarget>
              </div>
            </Drawer>
          )}
        </TabletContainer>
      </Drawer>
    </>
  );
};

/**
 * Determines the color or the nav and village nav
 *
 * @param village
 * @returns `navColor` for main nav & `pageColor` for village nav
 */
function useNavColor(village?: SanityVillage) {
  const { pageColor } = useStore();
  const [navColor, setNavColor] = useState<string>(pageColor);

  useEffect(() => {
    if (village) {
      setNavColor(colors.cream);

      return;
    }
    setNavColor(pageColor);
  }, [pageColor]);

  return { navColor, pageColor };
}

// styles
const StyledHeader = styled.header<{
  scrollOffset?: boolean;
  useTopBarOffset?: boolean;
  hasBanner?: boolean;
}>`
  position: fixed;
  z-index: 3;
  pointer-events: none;

  top: ${props => (props.hasBanner ? `${HEADER_OFFSET_TOP}px` : `${HEADER_OFFSET}px`)};
  left: ${HEADER_OFFSET}px;
  right: ${HEADER_OFFSET}px;

  ${mediaQuery.tabletDown} {
    pointer-events: all;
    ${({ useTopBarOffset }) => useTopBarOffset && `top: ${HEADER_OFFSET + 40}px;`};
  }

  ${mediaQuery.mobileDown} {
    pointer-events: all;
    top: ${HEADER_MOBILE_OFFSET}px;
    left: ${HEADER_MOBILE_OFFSET}px;
    right: ${HEADER_MOBILE_OFFSET}px;
    ${({ useTopBarOffset }) => useTopBarOffset && `top: ${HEADER_MOBILE_OFFSET + 40}px;`};
  }
`;

const StyledVillageMobileHeader = styled(TabletContainer)`
  width: 100%;
  justify-content: center;
  flex-direction: column;
  pointer-events: all;
`;

const StyledSecondaryVillageMobileHeader = styled(Container)<{
  secondaryColor?: Maybe<string> | undefined;
}>`
  ${({ secondaryColor }) => secondaryColor && `background-color: ${secondaryColor};`}
  width: auto;
  pointer-events: all;
  margin-left: 10px;
  margin-right: 10px;
  height: 44px;
  justify-content: center;

  .village-link-desktop {
    height: 20px;
    margin: auto 0px;
  }
  p {
    height: 20px;
    margin: auto 10px;
  }
`;

const StyledMobileVillageNav = styled.nav<{
  color: string;
  scrollOffset: number | boolean;
  linkColor?: Maybe<string> | undefined;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: all;
  background-color: ${props => props.color ?? colors.cyan};
  min-height: ${HEADER_HEIGHT}px;
  justify-content: space-between;
  ${({ scrollOffset }) =>
    scrollOffset && `transform: translateY(-${Number(scrollOffset) - HEADER_MOBILE_OFFSET}px);`}
  transition: transform 0.5s;
`;

const NavWrapper = styled.div<{
  color: string;
  scrollOffset: number | boolean;
  linkColor?: Maybe<string> | undefined;
  buttonColor?: Maybe<string> | undefined;
}>`
  display: flex;
  flex-direction: row;
  pointer-events: all;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 15px 30px;
  transition: transform 0.5s;
  background-color: ${props => props.color ?? colors.cyan};
  min-height: ${HEADER_HEIGHT}px;

  ${({ buttonColor }) => buttonColor && `button { color: ${colors[buttonColor as Color]}; }`}
  ${({ scrollOffset }) => scrollOffset && `transform: translateY(-${scrollOffset}px);`}

  a {
    color: ${props => (props.linkColor ? colors[props.linkColor as Color] : "unset")};
  }

  #text {
    display: none;
  }

  button {
    padding: 15px 25px;
    line-height: 20px;
  }

  ${mediaQuery.mobileDown} {
    ${({ scrollOffset }) =>
      scrollOffset && `transform: translateY(-${Number(scrollOffset) - HEADER_MOBILE_OFFSET}px);`}
  }
`;

const ImportantUpdates = styled.div`
  width: 100%;
  position: absolute;
  pointer-events: all;
  top: -35px;
  left: 0px;
  height: 35px;
  background: ${colors.navy};
  color: ${colors.white};

  a {
    background: ${colors.navy};
    display: block;
    cursor: pointer;
    margin-top: 5px;
    padding-left: 30px;
  }

  ${mediaQuery.ipadProDown} {
    z-index: 5;
    top: 0px;
    left: 0px;
  }
  ${mediaQuery.tabletDown} {
    a {
      padding-left: ${HEADER_OFFSET}px;
    }
  }
  ${mediaQuery.mobileDown} {
    a {
      padding-left: ${HEADER_MOBILE_OFFSET}px;
    }
  }
`;

const RightContainer = styled(Container)`
  align-items: center;
  pointer-events: all;
  a {
    font-weight: ${fontWeights.bold};
    margin-right: 26px;
  }

  .header-phone-number {
    pointer-events: none;
  }

  #preferences {
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    background-color: transparent;
    margin-left: 0px;
    padding-left: 15px;
    padding-right: 0px;
  }

  #search {
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    background-color: transparent;
    // margin-left: 20px;
  }

  ${mediaQuery.ipadProDown} {
    .header-phone-number {
      pointer-events: visible;
    }
  }
`;

const LogoLinkWrapper = styled(GatsbyLink)`
  width: 160px;
  pointer-events: all;
  ${mediaQuery.tabletDown} {
    width: 132px;
  }
`;

const SkipToContent = styled.a`
  padding: 6px;
  position: absolute;
  pointer-events: all;
  top: -40px;
  left: 0;
  color: white;
  border: 1px solid white;
  border-radius: 8px;
  background: ${colors.navy};
  transition: top 0.5s ease-out;
  z-index: 2;

  :focus {
    top: 0;
  }
`;
