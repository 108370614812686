import React, { useState } from "react";
import { Drawer, Trigger } from "@accessible/drawer";
import moment from "moment-timezone";
import styled from "styled-components";

import { BlocksContent, Button, Link, Image } from "@global";
import { Container, CreamCardContainer, SpanBefore } from "@util/standard";
import { DrawerTarget } from "@shared";
import EventForm from "./forms/eventForm";
import { SanityEvent } from "@graphql-types";
import { mediaQuery } from "@util/constants";
import { useCheckScreenSize } from "@util/hooks";

const EventCard = ({ data, invertColors }: { data: SanityEvent; invertColors?: boolean }) => {
  const [displayDate] = useState<string | undefined>(() => {
    if (!data) return undefined;
    const { dateTime, endTime } = data;
    const formattedDate = dateTime && moment(dateTime).format("dddd Do MMMM, h:mma");
    let displayEndDate = endTime && moment(endTime).format("h:mma");

    if (dateTime && endTime && !moment(dateTime).isSame(moment(endTime), "day")) {
      displayEndDate = endTime && moment(endTime).format("dddd Do MMMM, h:mma");
    }

    return formattedDate && displayEndDate ? `${formattedDate} - ${displayEndDate}` : formattedDate;
  });

  const { tabletDown } = useCheckScreenSize();

  if (data == null) return null;

  const {
    title,
    description,
    location,
    contactName,
    contactNumber,
    munchkinId,
    formId,
    publishInnerPage,
    featuredImage,
  } = data;

  return (
    <Wrapper invert={invertColors} hasImage={Boolean(featuredImage)}>
      {featuredImage && (
        <div className="col-1">
          <Image data={featuredImage} isBackground={!tabletDown} objectFit="cover" />
        </div>
      )}

      <div className="col-2">
        <div className="card-content-row-1">
          <h4>{title}</h4>
        </div>
        <div className="card-content-row-2">
          <BlocksContent data={description} />
          <div className="card-details">
            {displayDate && <SpanBefore before="Date and time">{displayDate}</SpanBefore>}
            {location && <SpanBefore before="Location">{location}</SpanBefore>}
            {contactNumber && (
              <SpanBefore before="Contact details">
                {contactName} | <a href={`tel:${contactNumber}`}>{contactNumber}</a>
              </SpanBefore>
            )}
          </div>
        </div>
        {formId && munchkinId && (
          <div className="card-content-row-3">
            <Drawer>
              <Trigger>
                <Container width="max-content">
                  <Button mobileMargin="25px 0 0 0">Register for this event</Button>
                </Container>
              </Trigger>
              <DrawerTarget>
                <EventForm formID={formId} munchkinID={munchkinId} />
              </DrawerTarget>
            </Drawer>
          </div>
        )}
        {publishInnerPage && (
          <div className="card-content-row-4">
            <Link internalLink={data} className="button-base" linkText="Find out more" />
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default EventCard;

const Wrapper = styled(CreamCardContainer)<{ hasImage: boolean }>`
  padding: 0;
  display: ${props => (props.hasImage ? "grid" : "flex")};
  grid-template-columns: 1fr 3fr;
  ${mediaQuery.tabletLandScapeDown} {
    grid-template-columns: 1fr 2fr;
  }
  ${mediaQuery.tabletDown} {
    grid-template-columns: 1fr;
  }
  .gatsby-image-wrapper {
    height: webkit-fill-available;
    width: 100%;
  }

  .col-1 {
    position: relative;
  }
  .col-2 {
    .card-content-row-1 {
      margin-bottom: 20px;
    }
    padding: 60px;
    .rte :first-child {
      margin-top: 0;
    }

    ${mediaQuery.tabletDown} {
      padding: 50px;
    }
    ${mediaQuery.mobileDown} {
      padding: 50px 30px;
    }
  }

  .card-content-row-4 {
    margin-top: 30px;
  }
  .card-content-row-2 {
    display: grid;
    grid-template-columns: 3fr 2fr;
    column-gap: 40px;

    ${mediaQuery.tabletLandScapeDown} {
      grid-template-columns: 1fr;
    }
    .card-details {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
`;
