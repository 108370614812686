import { SanityPopup } from "@graphql-types";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BlocksContent from "./blocksContent";
import { HEADER_MOBILE_OFFSET, HEADER_OFFSET, assets, colors, mediaQuery } from "@util/constants";
import { useIsOnScreen } from "@util/hooks";

interface Props {
  data: SanityPopup;
}

const FramePopup = ({ data }: Props) => {
  if (data == null) return null;
  const [isExpanded, setIsExpanded] = useState(true);
  const [isAvailable, setIsAvailable] = useState(false);
  const frameRef = useRef<HTMLDivElement | null>(null);
  const isOnScreen = useIsOnScreen(frameRef, "10px");

  useEffect(() => {
    if (!isAvailable && isOnScreen) setIsAvailable(true);
  }, [isOnScreen]);

  const Popup = () => {
    return (
      <StyledFramePopup
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
        colour={data.popupColour?.colour?.hex ?? "navy"}
      >
        {!isExpanded && (
          <>
            <span className="chevron">
              <img src={assets.chevronWhite} />
            </span>
            <p className="popup-title">{data.popupTitle}</p>
          </>
        )}
        {isExpanded && (
          <span className="close">
            <img src={assets.chevronWhite} />
          </span>
        )}

        <span className="block-wrapper">
          <BlocksContent data={data.popupContent} />
        </span>
      </StyledFramePopup>
    );
  };

  return <div ref={frameRef}>{isAvailable && <Popup />}</div>;
};

export default FramePopup;

const StyledFramePopup = styled.div<{ colour?: string; isExpanded: boolean }>`
  width: 380px;
  padding: ${props => (props.isExpanded ? "30px" : "18px 30px 28px 30px")};
  background: ${colors.navy};
  color: ${colors.white};
  position: fixed;
  bottom: ${HEADER_OFFSET}px;
  right: 80px;
  z-index: 2;
  border-radius: 13px 13px 0px 0px;

  cursor: pointer;

  transition: ease-in-out 0.3s;

  .popup-title {
    max-width: 90%;
  }

  .close {
    position: absolute;
    top: 28px;
    right: 20px;

    img {
      width: 14px;
      height: 8px;
    }
  }

  .chevron {
    position: absolute;
    display: block;
    right: 20px;
    top: 25%;

    img {
      transform: rotate(180deg);
      width: 14px;
      height: 8px;
    }
  }

  .block-wrapper {
    display: ${props => (props.isExpanded ? "block" : "none")};
    .button-base {
      border: 1px solid ${colors.white};
      display: inline-block;
    }
  }

  p {
    ${({ isExpanded }) => !isExpanded && `margin: 0; font-weight: bold;`}
    min-height: 16px;
  }

  p:first-child {
    ${({ isExpanded }) => isExpanded && `margin-top: 0;`}
  }

  ${mediaQuery.mobileDown} {
    padding: ${props => (props.isExpanded ? "30px 33px 30px 43px" : "20px 30px 30px 30px")};
    max-width: calc(100vw - 40px);

    bottom: ${HEADER_MOBILE_OFFSET}px;
    right: ${HEADER_MOBILE_OFFSET}px;
    border-radius: 13px 13px 40px 13px;

    .chevron,
    .close {
      right: 30px;
    }

    .block-wrapper {
      .button-base {
        margin-top: 20px;
      }
    }
  }

  @media screen and (max-width: 430px) {
    border-radius: 13px 13px 40px 40px;
  }
`;
