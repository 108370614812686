import { Container, GridContainer, PageWidth, Section } from "@util/standard";
import {
  useAllVillagesData,
  useCareVillagePreviews,
  useRelatedVillagesData,
  useVillagesInRegions,
} from "@util/logicHooks";

import { Carousel, Link } from "@global";
import React, { useRef } from "react";
import { SanityOtherVillages } from "@graphql-types";
import VillagePreview from "./preview";
import { isArrayString } from "@util/types";
import { assets, mediaQuery } from "@util/constants";
import styled from "styled-components";
import { useStore } from "@state/store";
import { SwiperSlide } from "swiper/react";

interface Props {
  data: SanityOtherVillages;
  defaultTitle?: string;
}

export default function OtherVillages({ data, defaultTitle }: Props) {
  const { pagePaths } = useStore();

  const {
    title,
    blockExcerpt,
    blockLink,
    defaultVillages,
    regionalVillages,
    layout,
    _key,
    addCareOptions,
    showAllVillages,
  } = data;

  const relatedVillages = regionalVillages
    ? useVillagesInRegions(regionalVillages, false)
    : useRelatedVillagesData();

  const allVillages = useAllVillagesData(showAllVillages);
  const {
    regionState,
    careState,
    allRegions,
    allCareOptions,
    filteredVillages,
    setCareState,
    setRegionState,
  } = useCareVillagePreviews(allVillages);

  const villages = defaultVillages?.length
    ? defaultVillages
    : relatedVillages?.length
    ? relatedVillages
    : allVillages?.length
    ? allVillages
    : null;

  if (!Boolean(villages?.length) && regionalVillages == null) return null;

  const gridLength = villages ? villages.length : 0;
  const swiperRef = useRef(null);

  const handleChangeNav = (next: boolean) => () => {
    if (next) {
      swiperRef.current?.swiper?.slideNext();
    } else {
      swiperRef.current?.swiper?.slidePrev();
    }
  };

  return (
    <Section>
      <PageWidth mobileFullWidth>
        <StyledContainer justifyContent="space-between">
          <ContentContainer>
            <p className="h3">{title ?? defaultTitle}</p>
            {blockExcerpt && <p>{blockExcerpt}</p>}
          </ContentContainer>
          {addCareOptions ? (
            <Container className="buttons-wrapper column">
              <Container
                margin="0 0 50px 0"
                isMobileColumn
                mobileWidth="100%"
                mobileMargin="0 0 20px 0"
              >
                <select
                  id="region-select"
                  value={regionState}
                  className="select"
                  onChange={e => {
                    console.log(e.target.value);
                    setRegionState(e.target.value);
                  }}
                >
                  <option value="all">Select your region</option>
                  {allRegions?.map((region, index) => {
                    if (region == null) return null;
                    return <option key={index}>{region.marketoValue}</option>;
                  })}
                </select>
                <select
                  id="care-select"
                  className="select"
                  value={careState}
                  onChange={e => setCareState(e.target.value)}
                >
                  <option value="all">Select your care option</option>
                  {allCareOptions?.map((careOption, index) => {
                    if (careOption == null) return null;
                    return <option key={index}>{careOption.title}</option>;
                  })}
                </select>
              </Container>
              {Number(filteredVillages?.length) > 1 && (
                <ArrowNav handleChangeNav={handleChangeNav} />
              )}
            </Container>
          ) : (
            <Container className="buttons-wrapper">
              {layout === "carousel" && gridLength > 2 && (
                <ArrowNav handleChangeNav={handleChangeNav} />
              )}
              {blockLink ? (
                <Link {...blockLink} className="button-base" />
              ) : (
                <Link
                  linkText="Find a village"
                  className="button-base"
                  internalLink={pagePaths?.villagesLanding}
                />
              )}
            </Container>
          )}
        </StyledContainer>

        {filteredVillages && filteredVillages.length === 1 ? (
          <GridContainer repeat={2} tabletRepeat={1} rowGap={20} columnGap={20}>
            {filteredVillages &&
              filteredVillages.length > 0 &&
              filteredVillages.map((village, index) => {
                if (village == null) return null;

                return (
                  <VillagePreview
                    isGroqData={isArrayString(village.previewImages)}
                    key={index}
                    data={village}
                  />
                );
              })}
            {filteredVillages?.length === 0 && <p>No villages match your search</p>}
            {!filteredVillages &&
              villages?.map((village, index) => {
                if (village == null) return null;

                return (
                  <VillagePreview
                    isGroqData={isArrayString(village.previewImages)}
                    key={index}
                    data={village}
                  />
                );
              })}
          </GridContainer>
        ) : layout === "carousel" && villages && villages.length > 0 ? (
          <>
            <Carousel
              swiperRef={swiperRef}
              swiperProps={{
                navigation: {
                  prevEl: `#other-villages-prev`,
                  nextEl: `#other-villages-next`,
                },
                breakpoints: {
                  800: { slidesPerView: 2, spaceBetween: 20, pagination: false },
                  300: { slidesPerView: 1, spaceBetween: 0, pagination: false },
                },
              }}
              hideArrows
            >
              {filteredVillages &&
                filteredVillages.length > 0 &&
                filteredVillages.map((village, index) => {
                  if (village == null) return null;

                  return (
                    <SwiperSlide key={index}>
                      <VillagePreview
                        isGroqData={isArrayString(village.previewImages)}
                        data={village}
                      />
                    </SwiperSlide>
                  );
                })}

              {!filteredVillages &&
                villages.map((village, index) => {
                  if (village == null) return null;

                  return (
                    <SwiperSlide key={index}>
                      <VillagePreview
                        isGroqData={isArrayString(village.previewImages)}
                        data={village}
                      />
                    </SwiperSlide>
                  );
                })}
            </Carousel>
            {filteredVillages && filteredVillages.length === 0 && (
              <p>No villages match your search</p>
            )}
          </>
        ) : villages && villages.length > 0 ? (
          <GridContainer repeat={2} tabletRepeat={1} rowGap={20} columnGap={20}>
            {filteredVillages &&
              filteredVillages.length > 0 &&
              filteredVillages.map((village, index) => {
                if (village == null) return null;

                return (
                  <VillagePreview
                    isGroqData={isArrayString(village.previewImages)}
                    key={index}
                    data={village}
                  />
                );
              })}
            {filteredVillages && filteredVillages.length === 0 && (
              <p>No villages match your search</p>
            )}
            {!filteredVillages &&
              villages.map((village, index) => {
                if (village == null) return null;

                return (
                  <VillagePreview
                    isGroqData={isArrayString(village.previewImages)}
                    key={index}
                    data={village}
                  />
                );
              })}
          </GridContainer>
        ) : null}
      </PageWidth>
    </Section>
  );
}

const ArrowNav = ({ handleChangeNav }: { handleChangeNav: (next: boolean) => () => void }) => {
  return (
    <NavWrapper>
      <img className="left" onClick={handleChangeNav(false)} src={assets.circleArrowFill} />
      <img className="right" onClick={handleChangeNav(true)} src={assets.circleArrowFill} />
    </NavWrapper>
  );
};

const StyledContainer = styled(Container)`
  p.block-title {
    margin-bottom: 16px;
  }
  p {
    margin-top: 0px;
  }

  .column {
    flex-direction: column;
  }

  .buttons-wrapper {
    .select {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border-radius: 60px;
      border: 1px solid var(--Navy, #262746);
      background: #fff;
      padding: 15px 25px;
      align-items: center;

      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 98%;
      background-position-y: 15px;

      &:last-child {
        margin-left: 30px;
      }
      ${mediaQuery.mobileDown} {
        margin-left: 0px;
        width: 100%;

        &:last-child {
          margin-top: 15px;
          margin-left: 0px;
        }
      }
    }
    ${mediaQuery.mobileDown} {
      flex-direction: column;
      margin-left: 0px;
    }
  }

  ${mediaQuery.tabletDown} {
    width: 80%;
    flex-direction: column;
    margin: auto;
    a {
      margin: 0 0 10% 0;
    }

    .buttons-wrapper {
      margin-top: 30px;
    }
  }
`;

const ContentContainer = styled(Container)`
  flex-direction: column;
  margin-bottom: 60px;
  width: 45%;

  ${mediaQuery.tabletDown} {
    flex-direction: column;
    margin: auto;
    align-items: initial;
    width: 100%;
  }
`;

const NavWrapper = styled.div`
  margin-top: 5px;
  margin-right: 20px;
  margin-left: auto;
  margin-bottom: 30px;
  img {
    transition: all 0.2s;
    cursor: pointer;
  }
  .left {
    margin-right: 20px;
    &:hover {
      // transform: scale(1.2);
    }
  }
  .right {
    transform: rotate(180deg);
    &:hover {
      // transform: rotate(180deg) scale(1.2);
    }
  }

  ${mediaQuery.mobileDown} {
    margin: 5px auto 15px auto;
  }
`;
