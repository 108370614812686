import React from "react";
import { Target, CloseButton, Trigger, Drawer } from "@accessible/drawer";

import { SanityVillage } from "@graphql-types";
import { Container } from "@util/standard";
import styled, { CSSProperties } from "styled-components";
import { assets, colors, fontWeights, villageSubPagePaths } from "@util/constants";
import { Link } from "@global";
import { DrawerTarget } from "@shared";
import { ContactForm } from "@components/forms/contactForm";

interface Props {
  data: SanityVillage;
}

function VillageMobileMenu({ data }: Props) {
  if (data == null) return null;

  const { slug, title, region } = data;
  const villageSlug = `${region?.slug?.current}/${slug?.current}`;

  return (
    <Target placement="right" openStyle={targetStyle as CSSProperties}>
      <MenuWrapper>
        <Container
          flexDirection="column"
          width="88%"
          margin="40px auto 0 auto"
          height="calc(100% - 80px)"
          justifyContent="space-between"
        >
          <div>
            <Container width="100%" justifyContent="space-between" margin="0 0 40px 0px">
              <p className="village-title">{title}</p>
              <CloseButton>
                <Container flexDirection="column">
                  <Container justifyContent="flex-end">
                    <img src={assets.close} />
                  </Container>
                  <p className="close-text">Close menu</p>
                </Container>
              </CloseButton>
            </Container>

            {villageSlug && (
              <div>
                <VillageLink
                  linkText="Overview"
                  internalLink={villageSubPagePaths(villageSlug).overview}
                  className="village-link"
                />
                {data.publishLocation && (
                  <VillageLink
                    linkText="Location"
                    internalLink={villageSubPagePaths(villageSlug).location}
                    className="village-link"
                  />
                )}
                {data.publishLifeHere && (
                  <VillageLink
                    linkText="Life here"
                    internalLink={villageSubPagePaths(villageSlug).lifeHere}
                    className="village-link"
                  />
                )}
                {data.publishCare && (
                  <VillageLink
                    linkText="Care"
                    internalLink={villageSubPagePaths(villageSlug).care}
                    className="village-link"
                  />
                )}
                {data.publishHomeOptions && (
                  <VillageLink
                    linkText="Home Options"
                    internalLink={villageSubPagePaths(villageSlug).homeOptions}
                    className="village-link"
                  />
                )}
                {data.publishContact && (
                  <VillageLink
                    linkText="Contact village"
                    internalLink={villageSubPagePaths(villageSlug).contact}
                    className="village-link"
                  />
                )}
              </div>
            )}
          </div>

          <Container justifyContent="space-between">
            {!data.disabledInfoPack && (
              <Drawer>
                <Trigger>
                  <div>
                    <Link actions="infoPack" linkText="Get info pack" />
                  </div>
                </Trigger>
              </Drawer>
            )}
            {data.canBookATour && (
              <Drawer>
                <Trigger>
                  <div>
                    <Link actions="bookATour" linkText="Book a tour" />
                  </div>
                </Trigger>
              </Drawer>
            )}
            {data.canRegisterInterest && (
              <Drawer>
                <div>
                  <Trigger>
                    <div>
                      <Link actions="registerInfoForm" linkText="Register interest" />
                    </div>
                  </Trigger>
                  <DrawerTarget title="Register Interest" drawerId="registerInfoForm">
                    <ContactForm
                      secondaryVillageColour={data.secondaryVillageColour}
                      isDevelopmentVillage
                      isDrawer
                    />
                  </DrawerTarget>
                </div>
              </Drawer>
            )}
          </Container>
        </Container>
      </MenuWrapper>
    </Target>
  );
}

export default VillageMobileMenu;

const MenuWrapper = styled.nav`
  width: 100vw;
  height: 100vh;

  background: white;
  transition: all 0.3s;
  z-index: 5;
  .village-title {
    font-weight: ${fontWeights.bold};
    margin: 0;
  }
  .close-text {
    margin: 0;
  }
`;

const VillageLink = styled(Link)`
  padding: 30px 28px;
  display: block;
  margin-bottom: 10px;
  border: 1px solid ${colors.navy};
  height: max-content;
  width: 100%;
`;

const targetStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 5,
};
