import React from "react";
import styled from "styled-components";

import { Maybe, SanityBlockContent, SanityImageWithMeta, SanityLink } from "@graphql-types";
import { BlocksContent, Image, Link } from "@global";
import { colors, mediaQuery } from "@util/constants";
import { Container, P } from "@util/standard";

interface Props {
  index: number;
  title?: Maybe<string> | undefined;
  description?: Maybe<string> | undefined;
  image?: Maybe<SanityImageWithMeta> | undefined;
  cta?: Maybe<SanityLink> | undefined;
  slideCount?: number;
  overrideBackgroundColour?: boolean;
  richDescription?: Maybe<SanityBlockContent> | undefined;
  onFocus?: () => void;
  imageStyle?: string;
}

const SliderPreviewCard = (props: Props) => {
  if (props == null) return null;

  const {
    title,
    description,
    richDescription,
    image,
    cta,
    index,
    slideCount,
    overrideBackgroundColour,
    onFocus,
    imageStyle,
  } = props;

  return (
    <Container flexDirection="column" height="100%" width="100%" aria-labelledby="title">
      <PreviewWrapper overrideBackgroundColour={overrideBackgroundColour}>
        {image && (
          <ImageWrapper imageStyle={imageStyle}>
            <Image data={image} objectFit="cover" height="100%" width="100%" />
          </ImageWrapper>
        )}
        <TextWrapper>
          <Container flexDirection="column">
            {slideCount && index && (
              <P
                fontSize={20}
                margin="0 0 20px 0"
                aria-label={`${index} of ${slideCount}`}
                onFocus={onFocus}
              >
                {index}/{slideCount}
              </P>
            )}

            <strong id="title">{title}</strong>
            {richDescription?._rawBlockContent?.length ? (
              <BlocksContent data={richDescription} />
            ) : (
              <p>{description}</p>
            )}
          </Container>
          {cta && (
            <LinkWrapper>
              <Link {...cta} className="button-base" />
            </LinkWrapper>
          )}
        </TextWrapper>
      </PreviewWrapper>
    </Container>
  );
};

export default SliderPreviewCard;

const PreviewWrapper = styled(Container)<{ overrideBackgroundColour?: boolean }>`
  flex-direction: column;
  background-color: ${props => (props.overrideBackgroundColour ? colors.white : colors.cream)};
  margin-bottom: 2%;
  width: 100%;
  height: 100%;

  strong {
    font-size: 20px;
  }

  ${mediaQuery.tabletDown} {
    width: 100%;
    height: 100%;
  }
`;

const ImageWrapper = styled.div<{ imageStyle?: string }>`
  width: 100%;
  height: 250px;
  // padding-top: 20px;
  img {
    width: 100%;
    ${props =>
      props.imageStyle ? `object-fit: ${props.imageStyle}` : `object-fit: contain !important;`}
  }
  ${mediaQuery.mobileDown} {
    min-height: 200px;
    height: 250px;
  }
`;

const TextWrapper = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  ${mediaQuery.tabletDown} {
    width: 75%;
    padding: 0;
    margin: 30px auto;
  }
  ${mediaQuery.mobileDown} {
    margin: 15px auto;
  }
`;

const LinkWrapper = styled.div`
  margin: 20px 0 0 0;
  width: 100%;
  a {
    display: block;
  }
  ${mediaQuery.tabletDown} {
    margin: 45px 0 0 0;
  }
  ${mediaQuery.mobileDown} {
    margin: 0;
  }
`;
