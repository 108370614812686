import { Container, DynamicsFormContainer, MobileContainer, P, PageWidth } from "@util/standard";
import { DataLayerContactForm, isContactForm } from "@util/types";
import {
  DropdownContainer,
  Form,
  LabelInput,
  RadioGroup,
  StyledSubmitButton,
  SubmitWrapper,
} from "@styles/formStyles";
import { Maybe } from "@graphql-types";
import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from "react";
import { bindCareForm, bindContactForm, isBrowser } from "@util/helper";
import { sendFormFill, useFormData } from "@util/datalayer";
import { useContactOptions, useDynamicsFormLoad, useRegionAndVillages } from "@util/hooks";

import CoreFormFields from "./coreFormFields";
import FormSuccessText from "./formSuccessText";
import {
  CARE_FORM_ID,
  CONTACT_US_FORM_ID,
  REGISTER_INTEREST_FORM,
  VILLAGE_CONTACT_FORM_ID,
  colors,
} from "@util/constants";
import { useStore } from "@state/store";

interface Props {
  generalContact?: Maybe<boolean> | undefined;
  secondaryVillageColour?: Maybe<string> | undefined;
  isDevelopmentVillage?: Maybe<boolean> | undefined;
  isDrawer?: Maybe<boolean> | undefined;
  canRegisterInterest?: Maybe<boolean> | undefined;
  fullWidth?: Maybe<boolean> | undefined;
}

/**
 * Form that is added to the village and general contact pages
 *
 * @param param0
 * @returns Contact form with hidden marketo form
 */
export function ContactForm({
  generalContact = false,
  secondaryVillageColour,
  isDevelopmentVillage = false,
  isDrawer = false,
  canRegisterInterest = false,
  fullWidth = false,
}: Props) {
  const { village, pageColor, lead, setLead } = useStore();

  const [enquiry, setEnquiry] = useState("881990000");
  const [opt, setOpt] = useState<string | null>();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);
  const [reloadForm, setReloadForm] = useState(false);

  const formType = () => {
    if (generalContact) return "contact";
    return "villageContact";
  };

  const formState = useFormData(generalContact ? "contact" : "villageContact");
  if (!isContactForm(formType(), formState.formData)) return null;

  const { formData, setFormData } = formState;
  const [nasc, setNsac] = useState("");
  const [levelOfCare, setLevelOfCare] = useState("");

  useEffect(() => {
    if (lead && lead.metlifeEnquiry) {
      setFormData({ ...formData, metlifeEnquiry: lead.metlifeEnquiry });
      setEnquiry(lead.metlifeEnquiry);
    }
  }, [lead]);

  useEffect(() => {
    // console.log(formData);
    if (formData == undefined) return;
    if (formData?.metlife_contact_us_reason == "881990000C") {
      setReloadForm(true);

      //set form data for current village
      if (
        village &&
        formData.metlife_villages_of_interest_brochure !== village?.villageCode &&
        !generalContact
      ) {
        setFormData({
          ...formData,
          metlife_stated_region_of_interest: village?.regionDynamicsValue,
          metlife_villages_of_interest_brochure: village?.dynamicsVillageCode,
        });
      }
    } else {
      setReloadForm(false);
    }
  }, [formData]);

  const handleEnquiryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEnquiry(event.target.value);
    setFormData({ ...formData, metlifeEnquiry: event.target.value });
    setLead({ ...lead, metlifeEnquiry: event.target.value });
  };

  const handleOptChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOpt(event.target.value);
    setFormData({ ...formData, optInMetlifecare: event.target.value });
  };

  const handleNascChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNsac(event.target.value);
    setFormData({ ...formData, needs_assessment: event.target.value });
  };
  const handleLevelOfCareChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setLevelOfCare(event.target.value);
    setFormData({ ...formData, level_of_care_known: event.target.value });
  };

  // DYNAMICS WORK

  useDynamicsFormLoad(reloadForm);
  const { villages, regions, updateRegion } = useRegionAndVillages();
  const { contactOptions } = useContactOptions();

  const dynamicsFormRef = useRef(null);

  const handleFormSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    const returnFormType = () => {
      if (formData?.metlife_contact_us_reason === "881990000C") return "Care Form";
      if (generalContact) return "Contact Form";
      return "Village Contact Form";
    };

    if (formData?.metlife_contact_us_reason === "881990000C") {
      await bindCareForm(dynamicsFormRef, formData, village);
    } else {
      await bindContactForm(dynamicsFormRef, formData, village);
    }
    try {
      setFormSubmitted(true);
      const comsConsent = Boolean(formData?.optInMetlifecare == "true");
      const formRegion =
        village?.region && !generalContact
          ? village?.region
          : formData?.metlife_stated_region_of_interest;

      const dataLayer: DataLayerContactForm = {
        village_id: generalContact ? undefined : village?.villageCode,
        village_name: generalContact ? undefined : village?.title,
        region: formRegion,
        form_step: "success",
        form_name: returnFormType(),
        document_type: undefined,
        success_step: true,
        comms_consent: comsConsent,
        lead_generated: true,
        enquiry_type: formData?.metlife_contact_us_reason,
        requesting_for: formData?.metlifeEnquiry,
      };

      sendFormFill(dataLayer);
    } catch (err) {
      console.log(err);
    }
  };

  // @ts-ignore
  if (isBrowser() && window.MsCrmMkt?.MsCrmFormLoader) {
    // @ts-ignore
    window.MsCrmMkt.MsCrmFormLoader.on("afterFormLoad", function () {
      setFormLoaded(true);
    });
  }

  const contactFormOptions = () => {
    if (village?.contactFormOptions == null && !generalContact)
      return (
        <>
          <option value="881990000">Sales</option>
          <option value="881990001">Feedback</option>
          <option value="881990001">Other</option>
        </>
      );

    if (
      (!generalContact && village?.contactFormOptions == "care") ||
      (generalContact && contactOptions?.contactFormOptions == "care")
    )
      return (
        <>
          <option value="881990000">
            {contactOptions?.feedbackText ?? "I have an Independent living enquiry"}
          </option>
          <option value="881990000C">
            {contactOptions?.careText ?? "I have a care home enquiry"}
          </option>
          <option value="881990001">
            {contactOptions?.otherText ?? "I have a general enquiry"}
          </option>
        </>
      );

    if (
      (!generalContact && village?.contactFormOptions == "other") ||
      (generalContact && contactOptions?.contactFormOptions == "other")
    )
      return (
        <>
          <option value="881990000">
            {contactOptions?.feedbackText ?? "I have an Independent living enquiry"}
          </option>
          <option value="881990001">
            {contactOptions?.otherText ?? "I have a general enquiry"}
          </option>
        </>
      );

    return (
      <>
        <option value="881990000">Sales</option>
        <option value="881990001">Feedback</option>
        <option value="881990001">Other</option>
      </>
    );
  };

  const returnFormType = () => {
    if (formData?.metlife_contact_us_reason === "881990000C")
      return (
        <DynamicsFormContainer
          ref={dynamicsFormRef}
          data-form-block-id={CARE_FORM_ID}
          key="care_form"
        />
      );
    if (generalContact)
      return (
        <DynamicsFormContainer
          ref={dynamicsFormRef}
          data-form-block-id={CONTACT_US_FORM_ID}
          key="general_form"
        />
      );
    if (canRegisterInterest)
      return (
        <DynamicsFormContainer
          ref={dynamicsFormRef}
          data-form-block-id={REGISTER_INTEREST_FORM}
          key="register_form"
        />
      );
    return (
      <DynamicsFormContainer
        ref={dynamicsFormRef}
        data-form-block-id={VILLAGE_CONTACT_FORM_ID}
        key="default_form"
      />
    );
  };

  return (
    <Container margin="50px 0px" className="contact-form-wrapper">
      {returnFormType()}

      <PageWidth fullWidth={isDrawer || fullWidth} mobileFullWidth={isDrawer}>
        {!generalContact && !formSubmitted && village.isDevelopment && isDrawer && (
          <P>Register your interest to find out more about {village.title}.</P>
        )}
        {!formSubmitted && (
          <Form id="contact-us-form" onSubmit={handleFormSubmit}>
            <Container width="100%" isMobileColumn columnGap="20px">
              <Container
                width={isDevelopmentVillage ? "100%" : "50%"}
                flexDirection="column"
                margin="0px 15px 0px 0px"
                isMobileColumn
                mobileMargin="0px"
                className="group-1"
              >
                <CoreFormFields
                  formData={formData}
                  setFormData={setFormData}
                  isVillageContactForm={!generalContact}
                />
              </Container>

              {!isDevelopmentVillage && (
                <Container
                  width="50%"
                  flexDirection="column"
                  margin="0px 0px 0px 15px"
                  mobileWidth="100%"
                  mobileMargin="0px"
                  className="group-2"
                >
                  <LabelInput minWidth="150px">
                    <label htmlFor="enquiryType">Type of enquiry</label>

                    <DropdownContainer
                      id="enquiryType"
                      aria-required="true"
                      required
                      onChange={e => {
                        setFormData({ ...formData, metlife_contact_us_reason: e.target.value });
                      }}
                      defaultValue="881990000"
                    >
                      <>{contactFormOptions()}</>
                    </DropdownContainer>
                  </LabelInput>
                  <MobileContainer>
                    <label htmlFor="message">Message*</label>
                  </MobileContainer>

                  <textarea
                    id="message"
                    placeholder="Message"
                    onChange={e => setFormData({ ...formData, MktoPersonNotes: e.target.value })}
                    aria-required="true"
                    required
                  />
                </Container>
              )}
            </Container>
            {formData?.metlife_contact_us_reason === "881990000C" && generalContact && (
              <>
                <DropdownContainer
                  id="enquiryType"
                  aria-required="true"
                  required
                  onChange={e => {
                    const selectedRegion = e.target.value;
                    setFormData({
                      ...formData,
                      metlife_stated_region_of_interest: e.target.value,
                    });
                    updateRegion(e);
                  }}
                  defaultValue="default"
                  noLabel
                >
                  <option value="default" disabled>
                    Select region*
                  </option>
                  {regions?.map(region => {
                    console.log({ region });

                    if (region == null) return null;
                    const regionValue =
                      Number(region?.dynamicsValue?.length) > 0
                        ? region?.dynamicsValue
                        : region?.title;

                    return (
                      <option key={region?._id} value={regionValue!}>
                        {region.title}
                      </option>
                    );
                  })}
                </DropdownContainer>
                <DropdownContainer
                  id="village"
                  aria-required="true"
                  required
                  // onChange={handleVillageChange}
                  defaultValue="default"
                  noLabel
                >
                  <option value="default" disabled>
                    Select village*
                  </option>
                  {villages &&
                    villages.map(village => {
                      if (village == null) return null;
                      const villageCode = village.dynamicsVillageCode ?? village.villageCode;
                      return <option value={villageCode!}>{village.title}</option>;
                    })}
                </DropdownContainer>
              </>
            )}

            <Container flexDirection="column" isMobileColumn>
              <p>Who are you enquiring for?*</p>
              <Container isMobileColumn flexWrap="wrap" rowGap="15px">
                <RadioGroup isSelected={enquiry == "881990000"}>
                  <input
                    id="myself"
                    type="radio"
                    name="enquire"
                    value="881990000"
                    checked={enquiry == "881990000"}
                    onChange={handleEnquiryChange}
                    aria-required="true"
                    required
                  />
                  <label htmlFor="myself">Myself</label>
                </RadioGroup>
                <RadioGroup isSelected={enquiry == "881990001"}>
                  <input
                    id="myPartner"
                    type="radio"
                    name="enquire"
                    value="881990001"
                    checked={enquiry == "881990001"}
                    onChange={handleEnquiryChange}
                    aria-required="true"
                    required
                  />
                  <label htmlFor="myPartner">My partner & I</label>
                </RadioGroup>
                <RadioGroup isSelected={enquiry == "881990002"}>
                  <input
                    id="family"
                    type="radio"
                    name="enquire"
                    value="881990002"
                    checked={enquiry == "881990002"}
                    onChange={handleEnquiryChange}
                    aria-required="true"
                    required
                  />
                  <label htmlFor="family">A family member / A friend</label>
                </RadioGroup>
              </Container>
            </Container>

            {formData?.metlife_contact_us_reason === "881990000C" && (
              <>
                {/* NASC */}
                <Container flexDirection="column" isMobileColumn>
                  <p>Needs assessment (NASC) completed?</p>
                  <Container isMobileColumn flexWrap="wrap" rowGap="15px">
                    <RadioGroup isSelected={nasc == "881990000"}>
                      <input
                        id="nasc1"
                        type="radio"
                        name="nasc"
                        value="881990000"
                        checked={nasc == "881990000"}
                        onChange={handleNascChange}
                        aria-required="true"
                        required
                      />
                      <label htmlFor="nasc1">Not started</label>
                    </RadioGroup>
                    <RadioGroup isSelected={nasc == "881990001"}>
                      <input
                        id="nasc2"
                        type="radio"
                        name="nasc"
                        value="881990001"
                        checked={nasc == "881990001"}
                        onChange={handleNascChange}
                        aria-required="true"
                        required
                      />
                      <label htmlFor="nasc2">In progress</label>
                    </RadioGroup>
                    <RadioGroup isSelected={nasc == "881990002"}>
                      <input
                        id="nasc3"
                        type="radio"
                        name="nasc"
                        value="881990002"
                        checked={nasc == "881990002"}
                        onChange={handleNascChange}
                        aria-required="true"
                        required
                      />
                      <label htmlFor="nasc3">Approved</label>
                    </RadioGroup>
                  </Container>
                </Container>

                {/* Level */}
                <Container flexDirection="column" isMobileColumn>
                  <p>Level of care if known</p>
                  <Container isMobileColumn flexWrap="wrap" rowGap="15px">
                    <RadioGroup isSelected={levelOfCare == "881990000"}>
                      <input
                        id="care_level1"
                        type="radio"
                        name="care_level"
                        value="881990000"
                        checked={levelOfCare == "881990000"}
                        onChange={handleLevelOfCareChanged}
                        aria-required="true"
                        required
                      />
                      <label htmlFor="care_level1">Rest home</label>
                    </RadioGroup>
                    <RadioGroup isSelected={levelOfCare == "881990001"}>
                      <input
                        id="care_level2"
                        type="radio"
                        name="care_level"
                        value="881990001"
                        checked={levelOfCare == "881990001"}
                        onChange={handleLevelOfCareChanged}
                        aria-required="true"
                        required
                      />
                      <label htmlFor="care_level2">Hospital</label>
                    </RadioGroup>
                    <RadioGroup isSelected={levelOfCare == "881990003"}>
                      <input
                        id="care_level3"
                        type="radio"
                        name="care_level"
                        value="881990003"
                        checked={levelOfCare == "881990003"}
                        onChange={handleLevelOfCareChanged}
                        aria-required="true"
                        required
                      />
                      <label htmlFor="care_level3">Dementia</label>
                    </RadioGroup>
                  </Container>
                </Container>
              </>
            )}
            <SubmitWrapper generalContact={generalContact}>
              <Container
                flexDirection="column"
                width={generalContact ? "47%" : "97%"}
                tabletWidth="60%"
                mobileWidth="100%"
              >
                <p className="update">
                  Would you like to keep updated with Metlifecare news and events?*
                </p>
                <Container isMobileColumn flexWrap="wrap" rowGap="15px">
                  <RadioGroup isSelected={opt == "true"}>
                    <input
                      id="yes"
                      type="radio"
                      name="opt"
                      value="true"
                      checked={opt == "true"}
                      onChange={handleOptChange}
                      aria-required="true"
                      required
                    />
                    <label htmlFor="yes">Yes, please</label>
                  </RadioGroup>
                  <RadioGroup isSelected={opt == "false"}>
                    <input
                      id="no"
                      type="radio"
                      name="opt"
                      value="false"
                      checked={opt == "false"}
                      onChange={handleOptChange}
                      aria-required="true"
                      required
                    />
                    <label htmlFor="no">No, thanks</label>
                  </RadioGroup>
                </Container>
              </Container>

              <Container
                margin="20px 0 0 10px"
                flexDirection="column"
                width={isDrawer ? "100%" : "50%"}
                isTabletColumn
              >
                <P fontWeight="normal">
                  Your personal information will be used for the purpose of providing you with
                  information about Metlifecare and will not be given to any third parties or used
                  for any other purposes.
                </P>
                <a
                  href="/privacy-policy"
                  style={{ textDecoration: "underline", marginBottom: "20px", color: colors.navy }}
                  target="_blank"
                >
                  View our Privacy Policy
                </a>
                <strong>
                  <p>* are required fields</p>
                </strong>

                <StyledSubmitButton
                  pageColour={secondaryVillageColour ?? pageColor}
                  type="submit"
                  value={"Submit form"}
                  overrideMargin={!generalContact ? "20px 0 0 0 " : undefined}
                  enabled
                />
              </Container>
            </SubmitWrapper>
          </Form>
        )}
        {formSubmitted && <FormSuccessText formType="contact" />}
      </PageWidth>
    </Container>
  );
}
