import React, { ReactNode, useState } from "react";
import { Link as GatsbyLink, navigate } from "gatsby";
import { Drawer, Trigger } from "@accessible/drawer";

import {
  Maybe,
  SanityDrawer,
  SanityFileDownload,
  SanityForm,
  SanityRegion,
  SanityVariation,
  SanityVideo,
  Scalars,
} from "@graphql-types";
import { colors } from "@util/constants";
import Button, { Props as ButtonProps } from "./button";
import { DrawerTarget } from "@shared";
import { LinkDrawerContent, ModalBox, Video } from "@global";
import { useStore } from "@state/store";
import MetguideForm from "@components/forms/metguideForm";

interface Props extends ButtonProps {
  children?: ReactNode;
  linkText?: Maybe<Scalars["String"]>;
  externalLink?: Maybe<Scalars["String"]>;
  internalLink?: Maybe<any>;
  actions?: Maybe<Scalars["String"]>;
  className?: string;
  drawer?: Maybe<SanityDrawer>;
  guide?: Maybe<any>;
  urlParam?: Maybe<Scalars["String"]>;
  fileDownload?: Maybe<SanityFileDownload>;
  drawerId?: Maybe<any>;
  popUp?: Maybe<SanityVideo>;
  removeDrawerTransform?: boolean;
  internalLinkNewTab?: Maybe<boolean> | undefined;
  metguideForm?: Maybe<SanityForm> | undefined;
  availableRegions?: Maybe<Maybe<SanityRegion>[]>;
  variationTag?: Maybe<SanityVariation> | undefined;
}

const Link = ({
  linkText,
  internalLink,
  externalLink,
  actions,
  className,
  children,
  margin,
  colorvalue,
  theme,
  drawer,
  popUp,
  guide,
  urlParam,
  fileDownload,
  drawerId,
  removeDrawerTransform,
  internalLinkNewTab,
  metguideForm,
  availableRegions,
  variationTag,
}: Props) => {
  const { pagePaths, setDrawerVisible, personalisations, setPersonalisation } = useStore();

  if (popUp) {
    const [modalOpen, setModalOpen] = useState(false);
    return (
      <>
        <ModalBox isOpen={Boolean(modalOpen)} setIsOpen={setModalOpen}>
          <Video data={popUp} disableMargins />
        </ModalBox>
        <Button
          colorvalue={colorvalue}
          margin={margin}
          theme={colorvalue ? "colored" : theme}
          onClick={() => setModalOpen(true)}
        >
          {linkText}
        </Button>
      </>
    );
  }

  if (drawer) {
    return (
      <Drawer>
        <Trigger>
          <div>
            <Button colorvalue={colorvalue} margin={margin} theme={colorvalue ? "colored" : theme}>
              {linkText}
            </Button>
          </div>
        </Trigger>
        <DrawerTarget
          topLink={drawer.topBarLink}
          phoneNumber={drawer.phoneNumber}
          removeHeaderTransform={!removeDrawerTransform}
        >
          <LinkDrawerContent data={drawer} />
        </DrawerTarget>
      </Drawer>
    );
  }

  if (actions) {
    if (actions === "download" && fileDownload) {
      return (
        <a
          href={`${fileDownload.fileRef?.asset?.url}?dl=${fileDownload.fileRef?.asset?.originalFilename}`}
          aria-label="file-download-link"
          style={{ margin }}
          className={className}
          target="_blank"
          rel="noreferrer"
        >
          {children ?? linkText}
        </a>
      );
    }

    if (actions === "metguideFormDrawer") {
      return (
        <Drawer>
          <Trigger>
            <div>
              <Button
                colorvalue={colorvalue}
                margin={margin}
                theme={colorvalue ? "colored" : theme}
              >
                {linkText}
              </Button>
            </div>
          </Trigger>
          <DrawerTarget removeHeaderTransform drawerId="guides-form">
            <MetguideForm isDrawer form={metguideForm} isInnerPage regions={availableRegions} />
          </DrawerTarget>
        </Drawer>
      );
    }

    if (actions === "personalisationPopup") {
      const handleShowModal = () => {
        if (personalisations && variationTag?.marketoQuestionValue) {
          const personalisation = personalisations[variationTag?.marketoQuestionValue];
          if (personalisation) {
            setPersonalisation(
              variationTag?.marketoQuestionValue,
              personalisation.selectedState,
              true,
            );

            setTimeout(() => {
              if (variationTag._id) {
                const element = document.getElementById(variationTag?._id);
                if (element) {
                  element.scrollIntoView({ behavior: "smooth" });
                }
              }
            }, 100);
          }
        }
      };

      return (
        <a onClick={handleShowModal} style={{ textDecoration: "underline", cursor: "pointer" }}>
          {linkText}
        </a>
      );
    }

    const handleClick = () => setDrawerVisible(actions);

    if (className?.includes("base"))
      return (
        <Button onClick={handleClick} margin={margin} theme={"base"}>
          {linkText}
        </Button>
      );

    return (
      <Button
        onClick={handleClick}
        colorvalue={colorvalue}
        margin={margin}
        theme={colorvalue ? "colored" : theme}
      >
        {linkText}
      </Button>
    );
  }

  if (internalLink?.slug) {
    const { slug, _type } = internalLink;
    const villagesLanding = "our-retirement-villages";

    const to = () => {
      if (_type === "region") {
        const baseRegion = `/${villagesLanding}/${slug?.current}`;
        if (urlParam) return `${baseRegion}${urlParam}`;
        return baseRegion;
      }

      if (_type === "village") {
        const baseVillage = `/${villagesLanding}/${internalLink.region?.slug?.current}/${slug?.current}`;
        if (urlParam) return `${baseVillage}${urlParam}`;
        return baseVillage;
      }
      if (_type === "news") {
        if (pagePaths?.newsLanding?.slug?.current) {
          return `/${pagePaths?.newsLanding?.slug?.current}/${slug?.current}`;
        }
        return `/news/${slug?.current}`;
      }
      if (urlParam) return `/${slug?.current}?${urlParam}`;
      return slug?.current === "/" ? "/" : `/${slug?.current}`;
    };

    const context = () => {
      if (drawerId) {
        drawerId.close();
      }
      navigate(to());
    };

    const handleClick = () => {
      navigate(to());
    };

    if (internalLinkNewTab) {
      if (colorvalue)
        return (
          <a
            className={className}
            href={to()}
            target="_blank"
            style={{ margin, cursor: "pointer" }}
          >
            <Button
              colorvalue={colorvalue ?? colors.cyan}
              margin={margin}
              theme={colorvalue ? "colored" : theme}
            >
              {linkText}
            </Button>
          </a>
        );

      return (
        <a className={className} href={to()} target="_blank" style={{ margin, cursor: "pointer" }}>
          {linkText}
        </a>
      );
    }

    if (colorvalue) {
      return (
        <Button
          onClick={handleClick}
          colorvalue={colorvalue ?? colors.cyan}
          margin={margin}
          theme={colorvalue ? "colored" : theme}
        >
          {linkText}
        </Button>
      );
    }

    if (drawerId) {
      return (
        <a className={className} style={{ margin, cursor: "pointer" }} onClick={context}>
          {linkText}
        </a>
      );
    }

    return (
      <GatsbyLink className={className} to={to()} style={{ margin }}>
        {children ?? linkText}
      </GatsbyLink>
    );
  }

  if (externalLink) {
    return (
      <a
        href={externalLink ?? "/"}
        style={{ margin }}
        className={className}
        target="_blank"
        rel="noreferrer"
        aria-label={linkText ?? externalLink}
      >
        {children ?? linkText}
      </a>
    );
  }

  if (guide) {
    const { slug } = guide;

    const to = slug?.current === "/" ? "/" : `/guides/${slug?.current}`;

    if (colorvalue) {
      return (
        <Button
          onClick={() => navigate(to)}
          colorvalue={colorvalue ?? colors.cyan}
          margin={margin}
          theme={colorvalue ? "colored" : theme}
        >
          {linkText}
        </Button>
      );
    }

    return (
      <GatsbyLink className={className} to={to} style={{ margin }}>
        {children ?? linkText}
      </GatsbyLink>
    );
  }

  return null;
};

export default Link;
