import React from "react";
import styled from "styled-components";

import { Maybe, SanityChecklist, SanityChecklistItem, SanityChecklists } from "@graphql-types";
import { Section, PageWidth, Container } from "@util/standard";
import { Dropdown } from "@global";
import { useSelectOptions } from "@util/logicHooks";
import { assets, colors, mediaQuery } from "@util/constants";

interface Props {
  data: SanityChecklists;
}

export default function Checklists({ data }: Props) {
  if (data == null) return null;

  const { heading, checklistGroups } = data;
  if (checklistGroups == null || checklistGroups?.length === 0) return null;
  const { selectedObject, selectedOption, setSelectedOption } = useSelectOptions(checklistGroups);

  const options = checklistGroups?.map(group => {
    if (group == null) return { id: "", title: "" };
    return { id: group._key!, title: group.title! };
  });

  // get all items in their own array to map correctly in table
  const firstList = selectedObject?.items && selectedObject?.items[0];
  const secondList = selectedObject?.items && selectedObject?.items[1];
  const thirdList = selectedObject?.items && selectedObject?.items[2];
  const numberOfRows = new Array(firstList?.items?.length).fill("").map((_, i) => i + 1);

  const tableProps = {
    numberOfRows,
    firstList,
    secondList,
    thirdList,
  };

  // TODO: apply mobile styling
  return (
    <Section aria-labelledby="heading">
      <PageWidth>
        <HeaderWrapper>
          <h3 id="heading">{heading}</h3>
          {options && options.length > 1 && (
            <Container width="30%" tabletWidth="100%">
              <Dropdown
                name="list-options"
                defaultTitle=""
                options={options}
                optionState={[selectedOption, setSelectedOption]}
              />
            </Container>
          )}
        </HeaderWrapper>
        <ChecklistTable {...tableProps} items={selectedObject?.items} />
        {/* TODO: add CTA object */}
      </PageWidth>
    </Section>
  );
}

interface TableProps {
  heading?: Maybe<string> | undefined;
  items: Maybe<Maybe<SanityChecklist>[]> | undefined;
  numberOfRows: number[];
  firstList: Maybe<SanityChecklist> | undefined;
  secondList: Maybe<SanityChecklist> | undefined;
  thirdList: Maybe<SanityChecklist> | undefined;
}

export const ChecklistTable = ({
  items,
  numberOfRows,
  firstList,
  secondList,
  thirdList,
  heading,
}: TableProps) => {
  return (
    <TableWrapper>
      {heading && <h2>{heading}</h2>}
      <Table id="compare-table">
        <tbody>
          <tr>
            {items?.map(checklist => {
              return <th key={checklist?.title}>{checklist?.title}</th>;
            })}
          </tr>
          {numberOfRows.map((_, index) => {
            return (
              <tr key={index}>
                {firstList?.items && <TableCell items={firstList?.items} index={index} />}
                {secondList?.items && <TableCell items={secondList?.items} index={index} />}
                {thirdList?.items && <TableCell items={thirdList?.items} index={index} />}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableWrapper>
    // {/* TODO: add CTA object */}
  );
};

const TableCell = ({ items, index }: { items: Maybe<SanityChecklistItem>[]; index: number }) => {
  return (
    <td>
      <Container alignItems="center">
        <img src={items[index]?.checked ? assets.yes : assets.no} />
        {items[index]?.title}
      </Container>
    </td>
  );
};

const HeaderWrapper = styled(Container)`
  width: 100%;
  justify-content: space-between;
  ${mediaQuery.tabletDown} {
    h3 {
      margin-top: 30px;

      margin-bottom: 30px;
    }
    flex-direction: column;
  }
`;

const TableWrapper = styled.div`
  margin-top: 60px;
  h2 {
    margin-bottom: 30px;
  }
  ${mediaQuery.tabletDown} {
    margin-top: 30px;
  }
`;

const Table = styled.table`
  border: 1px solid ${colors.navy};
  table-layout: fixed;
  th {
    text-align: left;
    font-size: 20px;
  }
  th,
  td {
    border: 1px solid ${colors.navy};
    border-collapse: collapse;
    padding: 20px 35px;
    vertical-align: middle;
  }
  img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  ${mediaQuery.tabletDown} {
    th,
    td {
      padding: 20px 10px;
    }

    /* td:nth-child(2),
    th:nth-child(2) {
      padding: 20px 10% 20px 5%;
    } */
  }

  ${mediaQuery.mobileDown} {
    td {
      div {
        flex-direction: column;
        align-items: flex-start;

        img {
          margin-bottom: 5px;
        }
      }
    }
  }
`;
