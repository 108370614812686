import React from "react";
import styled from "styled-components";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import { Maybe, SanityFaq } from "@graphql-types";
import { BlocksContent, Link } from "@global";
import { Container } from "@util/standard";

interface Props {
  data: SanityFaq | null;
}

export default function Faq({ data }: Props) {
  if (data == null) return null;
  const { title, answer, link, useRichText, richTextAnswer } = data;

  return (
    <AccordionItem>
      <AccordionItemHeading tabIndex={0}>
        <AccordionItemButton aria-label={`Question - ${title}`}>{title}</AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <AccordionContent useRichText={useRichText} tabIndex={0} aria-label={`Answer - ${answer}`}>
          {useRichText && richTextAnswer ? (
            <BlocksContent data={richTextAnswer} />
          ) : (
            <p>{answer}</p>
          )}
        </AccordionContent>
        {link && (
          <Container margin="20px 0">
            <Link {...link} className="button-base" />
          </Container>
        )}
      </AccordionItemPanel>
    </AccordionItem>
  );
}

export const AccordionContent = styled.div<{ useRichText: Maybe<boolean> | undefined }>`
  width: 82%;
  margin-bottom: ${props => (props.useRichText ? 40 : 0)}px;
`;
