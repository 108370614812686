import React, { ReactNode, useState } from "react";
import styled from "styled-components";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Target, CloseButton } from "@accessible/drawer";

import { Maybe, SanityHeader } from "@graphql-types";
import { A, Container, GridContainer, P } from "@util/standard";
import { assets, colors, fontFamilies, fontWeights, mediaQuery } from "@util/constants";
import { StyledMobileMenuAccordion } from "@styles/accordionStyles";
import { Link, SearchInput } from "@global";
import { useStore } from "@state/store";

interface Props {
  data: SanityHeader;
}

interface MobileAccordionProps {
  title: Maybe<string> | undefined;
  children: ReactNode;
  onClick: () => void;
  id: string;
}

function MobileMenu({ data }: Props) {
  const [selectedAccordionIndex, setSelectedAccordionIndex] = useState(-1);
  const { pagePaths } = useStore();
  if (data == null) return null;

  const { phoneNumber, navigationItems, cta, bottomLinks, showPreferences } = data;

  const handleAccordionClick = (index: number) => () => {
    if (index === selectedAccordionIndex) {
      setSelectedAccordionIndex(-1);
      return;
    }
    setSelectedAccordionIndex(index);
  };

  return (
    <Target placement="right">
      <MobileMenuWrapper>
        <Container
          flexDirection="column"
          width="88%"
          height="90vh"
          justifyContent="space-between"
          margin="0 auto"
        >
          <div>
            <Container justifyContent="space-between" margin="40px 0">
              <PhoneNumber href={`tel:${phoneNumber}`}>{phoneNumber}</PhoneNumber>
              <Container flexDirection="column">
                <CloseButton>
                  <Container alignItems="flex-end" flexDirection="column">
                    <img src={assets.close} />
                    <P margin="10px 0 0 0">Close menu</P>
                  </Container>
                </CloseButton>
              </Container>
            </Container>
            <Container margin="0 0 20px 0">
              <SearchInput outlined buttonColor="transparent" placeholder="Search" />
            </Container>
            {navigationItems && (
              <StyledMobileMenuAccordion allowZeroExpanded>
                {navigationItems.map((item, index) => {
                  if (item?.links == null) return null;
                  const { title, links } = item;

                  const unstyledLinks = links.filter(navLink => navLink?.style !== "button");
                  const styledLinks = links.filter(navLink => navLink?.style === "button");

                  return (
                    <MobileAccordion
                      title={title}
                      onClick={handleAccordionClick(index)}
                      key={index}
                      id={index === selectedAccordionIndex ? "accordion-is-active" : ""}
                    >
                      <ul>
                        {unstyledLinks.map(link => {
                          if (link == null) return null;
                          return (
                            <li key={link._key}>
                              <Link {...link} />
                            </li>
                          );
                        })}
                        {styledLinks.map(link => {
                          if (link == null) return null;
                          return (
                            <li className="big-menu-link" key={link._key}>
                              <Link {...link} />
                            </li>
                          );
                        })}
                      </ul>
                    </MobileAccordion>
                  );
                })}
              </StyledMobileMenuAccordion>
            )}
            {/* TODO: Open form inside mobile menu */}
            <BottomWrapper>
              {cta && <Link margin="30px 0 40px 0" {...cta} className="button-base" />}
              {bottomLinks && (
                <Container className="bottom-links">
                  {bottomLinks?.map(link => {
                    if (link == null) return null;
                    return <StyledLink {...link} key={link._key} />;
                  })}
                  {showPreferences && (
                    <StyledLink
                      linkText="Change Preferences"
                      internalLink={{ slug: { current: "preferences" } }}
                    />
                  )}
                </Container>
              )}
            </BottomWrapper>
          </div>
        </Container>
      </MobileMenuWrapper>
    </Target>
  );
}

export default MobileMenu;

const MobileAccordion = ({ children, title, onClick, id }: MobileAccordionProps) => {
  return (
    <AccordionItem id={id}>
      <AccordionItemHeading onClick={onClick}>
        <AccordionItemButton>
          <p>{title}</p>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>{children}</AccordionItemPanel>
    </AccordionItem>
  );
};

const MobileMenuWrapper = styled.nav`
  position: fixed;
  left: 0px;
  width: 100vw;
  height: 110vh;

  z-index: 5;
  background-color: ${colors.white};

  transition: all 0.2s;
  overflow: auto;
  margin-bottom: 40px;
  ul {
    width: 82%;
    margin: auto;
  }

  img {
    width: 28px;
  }

  .big-menu-link {
    background: ${colors.navy};
    color: ${colors.white};
    font-family: ${fontFamilies.tobias};
    font-size: 26px;
    line-height: 30px;
    width: 100%;
    padding: 30px 28px;
  }
`;

const PhoneNumber = styled.a`
  font-weight: ${fontWeights.bold};
  display: block;
  width: max-content;
  height: max-content;
`;

const StyledLink = styled(Link)`
  font-weight: ${fontWeights.bold};
  text-decoration: underline;
  margin: auto 10px;
`;

const BottomWrapper = styled(Container)`
  ${mediaQuery.tabletLandScapeDown} {
    flex-direction: column;
    .bottom-links {
      margin: 0;
    }
  }

  ${mediaQuery.mobileDown} {
    flex-direction: column;

    .bottom-links {
      justify-content: space-evenly;
      margin: auto;
    }
  }
`;
