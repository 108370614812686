import React, { ChangeEvent, useEffect, useRef, useState } from "react";

import { Maybe, SanityRegion, SanityVillage } from "@graphql-types";
import { useDynamicsFormLoad, useIsOnScreen, useMarketo } from "@util/hooks";
import { useStore } from "@state/store";
import { sendFormFill, useFormData } from "@util/datalayer";
import { DataLayerContactForm, isBookatourForm } from "@util/types";
import { Form, RadioGroup, StyledSubmitButton } from "@styles/formStyles";
import { Section, PageWidth, Container, P, DynamicsFormContainer } from "@util/standard";
import CoreFormFields from "./coreFormFields";
import BookATourData from "./bookATourDataInput";
import { bindBookATourForm, isBrowser } from "@util/helper";
import FormSuccessText from "./formSuccessText";
import { BOOK_A_TOUR_FORM_ID } from "@util/constants";

interface Props {
  regions?: SanityRegion[];
  villages?: SanityVillage[];
  isDrawerStyling?: boolean;
  secondaryVillageColour?: Maybe<string> | undefined;
  selectedVillage?: Maybe<SanityVillage> | undefined;
}

/**
 * Form for booking a tour of a village
 *
 * @param param0
 * @returns Contact form with hidden marketo form
 */
const BookATourForm = ({
  regions,
  villages,
  isDrawerStyling,
  secondaryVillageColour,
  selectedVillage,
}: Props) => {
  const marketoFormConfig = marketoFormData();
  const ref = useRef<HTMLElement>(null);
  const onScreen = useIsOnScreen(ref);
  const marketoForm = useMarketo({ ...marketoFormConfig.data, onScreen });

  const { pageColor, lead, setLead, village } = useStore();

  const [enquiry, setEnquiry] = useState("prospect_only");
  const [opt, setOpt] = useState<string | null>();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [dropdownSelectedRegion, setDropdownSelectedRegion] = useState<Maybe<SanityRegion>>();
  const [dropdownSelectedVillage, setDropdownSelectedVillage] = useState<Maybe<SanityVillage>>();

  const formState = useFormData("bookatour", false);
  if (!isBookatourForm("bookatour", formState.formData)) return null;
  const { formData, setFormData } = formState;

  useDynamicsFormLoad();
  const dynamicsFormRef = useRef(null);

  const handleEnquiryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEnquiry(event.target.value);
    setFormData({ ...formData, metlifeEnquiry: event.target.value });
    setLead({ ...lead, metlifeEnquiry: event.target.value });
  };

  useEffect(() => {
    if (lead && lead.metlifeEnquiry) {
      setFormData({ ...formData, metlifeEnquiry: lead.metlifeEnquiry });
      setEnquiry(lead.metlifeEnquiry);
    }
  }, [lead]);

  const handleOptChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOpt(event.target.value);
    setFormData({ ...formData, optInMetlifecare: event.target.value });
  };

  const returnSelectedVillage = () => {
    if (village) {
      return village;
    }

    if (dropdownSelectedVillage) {
      return dropdownSelectedVillage;
    }

    return null;
  };
  useEffect(() => {
    if (formData == undefined) return;
  }, [formData]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isGeneralBookATour = isBrowser() && window.location.pathname.includes("book-a-tour");

    const villageToSend = !isGeneralBookATour ? village?.dynamicsVillageCode : null;
    const regionToSend = !isGeneralBookATour ? village?.regionDynamicsValue : null;

    console.log({ villageToSend, regionToSend });

    await bindBookATourForm(dynamicsFormRef, formData, villageToSend, regionToSend);

    try {
      setFormSubmitted(true);
      const comsConsent = Boolean(formData?.optInMetlifecare == "true");
      const dataLayer: DataLayerContactForm = {
        village_id: returnSelectedVillage()?.dynamicsVillageCode ?? undefined,
        village_name: returnSelectedVillage()?.title ?? undefined,
        region: returnSelectedVillage()?.region?.title ?? undefined,
        form_step: "success",
        form_name: "Book a tour Form",
        document_type: undefined,
        success_step: true,
        comms_consent: comsConsent,
        lead_generated: true,
        enquiry_type: "Sales",
        requesting_for: formData?.metlifeEnquiry,
      };

      sendFormFill(dataLayer);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Section marginOverride="50px 0px">
      <PageWidth fullWidth={isDrawerStyling} mobileFullWidth={isDrawerStyling}>
        <DynamicsFormContainer
          ref={dynamicsFormRef}
          data-form-block-id={BOOK_A_TOUR_FORM_ID}
          key="book_a_tour_form"
        />
        {!formSubmitted && (
          <Form id={marketoFormConfig.data.formId} onSubmit={handleSubmit}>
            {/* date and time */}
            <Container>
              <BookATourData
                formData={formData}
                setFormData={setFormData}
                regions={regions}
                villages={villages}
                isDrawer={isDrawerStyling}
                selectedVillage={selectedVillage}
                setDropdownSelectedRegion={setDropdownSelectedRegion}
                setDropdownSelectedVillage={setDropdownSelectedVillage}
              />
            </Container>
            {/* Left */}
            <Container
              width="100%"
              isMobileColumn
              flexDirection={isDrawerStyling ? "column" : "row"}
              columnGap="20px"
            >
              <Container
                width={isDrawerStyling ? "100%" : "50%"}
                flexDirection="column"
                margin="0px 15px 0px 0px"
                isMobileColumn
                mobileMargin="0px"
              >
                <CoreFormFields
                  isDrawer={isDrawerStyling}
                  formData={formData}
                  setFormData={setFormData}
                  isBookATour
                />
              </Container>
              {/* Right */}
              <Container flexDirection="column" isMobileColumn>
                <P className="update" margin="0 0 10px 0">
                  Who are you booking the tour for?*
                </P>
                <Container
                  flexDirection={isDrawerStyling ? "column" : "row"}
                  isTabletColumn
                  flexWrap="wrap"
                  rowGap="15px"
                >
                  <RadioGroup isSelected={enquiry == "881990000"}>
                    <input
                      id="myself"
                      type="radio"
                      name="enquire"
                      value="881990000"
                      checked={enquiry == "881990000"}
                      onChange={handleEnquiryChange}
                      aria-required="true"
                      required
                    />
                    <label htmlFor="myself">Myself</label>
                  </RadioGroup>
                  <RadioGroup isSelected={enquiry == "881990001"}>
                    <input
                      id="myPartner"
                      type="radio"
                      name="enquire"
                      value="881990001"
                      checked={enquiry == "881990001"}
                      onChange={handleEnquiryChange}
                      aria-required="true"
                      required
                    />
                    <label htmlFor="myPartner">My partner & I</label>
                  </RadioGroup>
                  <RadioGroup isSelected={enquiry == "881990002"}>
                    <input
                      id="family"
                      type="radio"
                      name="enquire"
                      value="881990002"
                      checked={enquiry == "881990002"}
                      onChange={handleEnquiryChange}
                      aria-required="true"
                      required
                    />
                    <label htmlFor="family">A family member / A friend</label>
                  </RadioGroup>
                </Container>

                <Container flexDirection="column">
                  <p className="update">
                    Would you like to keep updated with Metlifecare news and events?*
                  </p>
                  <Container isMobileColumn flexWrap="wrap" rowGap="15px">
                    <RadioGroup isSelected={opt == "true"}>
                      <input
                        id="yes"
                        type="radio"
                        name="opt"
                        value="true"
                        checked={opt == "true"}
                        onChange={handleOptChange}
                        required
                      />
                      <label htmlFor="yes">Yes, please</label>
                    </RadioGroup>
                    <RadioGroup isSelected={opt == "false"}>
                      <input
                        id="no"
                        type="radio"
                        name="opt"
                        value="false"
                        checked={opt == "false"}
                        onChange={handleOptChange}
                        required
                      />
                      <label htmlFor="no">No, thanks</label>
                    </RadioGroup>
                  </Container>
                </Container>
              </Container>
            </Container>

            <Container margin="20px 0 0 10px" flexDirection="column" width="50%" isTabletColumn>
              <P fontWeight="normal">
                Your personal information will be used for the purpose of providing you with
                information about Metlifecare and will not be given to any third parties or used for
                any other purposes.
              </P>
              <a
                href="/privacy-policy"
                style={{ textDecoration: "underline", marginBottom: "20px" }}
                target="_blank"
              >
                View our Privacy Policy
              </a>
              <StyledSubmitButton
                pageColour={secondaryVillageColour ?? pageColor}
                type="submit"
                value={"Request your tour"}
                overrideMargin={isDrawerStyling ? "20px 0 0 0" : "auto 0px 0px 0px"}
                enabled
              />
            </Container>
          </Form>
        )}

        {formSubmitted && <FormSuccessText formType="tour" />}
      </PageWidth>
    </Section>
  );
};

export default BookATourForm;

const marketoFormData = () => {
  return {
    data: {
      baseUrl: "//info.metlifecare.co.nz",
      munchkinId: "179-LVM-746",
      formId: "775",
    },
    form: "mktoForm_775",
  };
};
