import { Maybe, SanityContactPerson } from "@graphql-types";
import { IconPhone } from "@util/assets";
import React from "react";
import styled from "styled-components";

interface Props {
  contactPerson: Maybe<SanityContactPerson>;
  phoneUnit?: string;
  hideEmail?: boolean;
  hideOpening?: boolean;
  hideContactName?: boolean;
}

export default function ContactPersonDetail({
  contactPerson,
  phoneUnit,
  hideEmail,
  hideOpening = false,
  hideContactName = false,
}: Props) {
  if (contactPerson == null) return null;
  const { phone, weekendHours, hours, publicHolidayHours, email, name } = contactPerson;

  if (!phone && !hours && !weekendHours && !publicHolidayHours && hideEmail) return null;

  return (
    <Detail>
      {name && !hideContactName && <p>{name}</p>}
      {phone && (
        <p>
          {phoneUnit && phoneUnit}
          <a href={`tel:${phone}`}>{phone}</a>
        </p>
      )}
      {email && !hideEmail && <p>{email}</p>}
      {!hideOpening && hours && <p>{hours}</p>}
      {!hideOpening && weekendHours && <p>{weekendHours}</p>}
      {!hideOpening && publicHolidayHours && <p>{publicHolidayHours}</p>}
    </Detail>
  );
}

const Detail = styled.div`
  position: relative;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  svg {
    position: absolute;
    left: 0;
    top: 5px;
  }
  h4 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  p {
    margin: 0;
    line-height: 20px;
  }
`;
