import { Container, StickyContainer } from "@util/standard";
import {
  HEADER_HEIGHT,
  HEADER_MOBILE_OFFSET,
  HEADER_OFFSET,
  colors,
  mediaQuery,
} from "@util/constants";

import { Button } from "@global";
import React from "react";
import { Trigger } from "@accessible/drawer";
import styled from "styled-components";

interface GetYourSelectedProps {
  pageColor: string;
  type: "guide(s)" | "info pack(s)";
  length: number;
}

export const GetYourSelected = ({ pageColor, length, type }: GetYourSelectedProps) => {
  return (
    <StickyWrapper>
      <Trigger>
        <SelectedContainer pageColor={pageColor}>
          <span>{`${length} selected ${type}`}</span>
          <Button className="get-selected" theme="base">{`Get selected ${type}`}</Button>
        </SelectedContainer>
      </Trigger>
    </StickyWrapper>
  );
};

const SelectedContainer = styled(Container)<{ pageColor: string }>`
  padding: 15px;
  border-radius: 0px 0px 30px 0px;
  border-right: none;
  background-color: ${props => props.pageColor ?? colors.cyan};
  align-items: center;
  row-gap: 20px;
  column-gap: 20px;

  p {
    margin: 20px;
    color: ${colors.navy};
    font-weight: bold;
  }

  ${mediaQuery.mobileDown} {
    border-radius: 0;
    border: none;
    width: 100%;
    flex-direction: column;
  }
`;

const StickyWrapper = styled(StickyContainer)`
  height: 110px;
  top: calc(100vh - (20px + 110px));
  z-index: 2;
  margin-right: 20px;
  pointer-events: none;

  .get-selected {
    pointer-events: all;
  }

  ${mediaQuery.mobileDown} {
    top: "";
    bottom: 0;
    margin: 20px;
    height: 100%;
    top: calc(100vh - 162px);
    border-radius: 0px 0px 30px 30px;
    overflow: hidden;
  }
`;

export const AnchorContainer = styled(StickyContainer)<{ scrollOffset: boolean }>`
  top: ${HEADER_HEIGHT + HEADER_OFFSET}px;
  background-color: ${colors.navy};
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  transition: transform 0.5s;
  z-index: 2;
  ${({ scrollOffset }) => scrollOffset && `transform: translateY(-${HEADER_OFFSET}px);`}

  ${mediaQuery.tabletDown} {
    padding: 10px 20px;
  }

  ${mediaQuery.mobileDown} {
    top: ${HEADER_HEIGHT + HEADER_MOBILE_OFFSET}px;
    ${({ scrollOffset }) =>
      scrollOffset && `transform: translateY(-${Number(HEADER_OFFSET) - HEADER_MOBILE_OFFSET}px);`}
  }
`;

export const GuideCategoryDropdown = styled.div<{ dropdownVisible: boolean }>`
  position: relative;
  width: 100%;
  color: ${colors.navy};
  background-color: ${colors.white};
  padding: 15px 25px;
  border-radius: ${props => (props.dropdownVisible ? "30px 30px 0 0" : "60px")};
  height: 50px;
  border: 1px solid ${colors.navy};

  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  flex-direction: column;

  .selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    user-select: none;
    span {
      white-space: nowrap;
    }
  }
  .items {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: -1px;
    top: 48px;
    background-color: ${colors.white};
    flex-direction: column;
    width: calc(100% + 2px);
    padding: 20px;
    row-gap: 20px;
    border: 1px solid ${colors.navy};
    border-radius: 0 0 5px 5px;
    border-top: 0;
  }

  ${mediaQuery.mobileDown} {
    width: 100%;
  }
`;
