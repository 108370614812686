import React, { ReactNode, useRef, useEffect } from "react";
import { Target, CloseButton, useDrawer } from "@accessible/drawer";
import styled from "styled-components";

import { assets, colors, fontWeights, HEADER_OFFSET, mediaQuery } from "@util/constants";
import { AbsoluteWrapper, Container, Overlay, P } from "@util/standard";
import { isBrowser } from "@util/helper";
import { useOnClickOutside, useHasScrolled, useHasMounted } from "@util/hooks";
import { Maybe, SanityLink } from "@graphql-types";
import { Link } from "@global";
import { useStore } from "@state/store";

interface Props {
  children: ReactNode;
  topLink?: Maybe<SanityLink> | undefined;
  phoneNumber?: Maybe<string> | undefined;
  customDrawWidth?: string;
  customInnerPadding?: string;
  title?: Maybe<string> | undefined;
  drawerId?: string;
  removeHeaderTransform?: boolean;
}

export default function DrawerTarget({
  children,
  title,
  topLink,
  phoneNumber,
  customDrawWidth,
  customInnerPadding,
  drawerId,
  removeHeaderTransform,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const { drawerVisible, setDrawerVisible } = useStore();
  const { isOpen, close, open } = useDrawer();
  useOnClickOutside(ref, close);

  useEffect(() => {
    if (!isBrowser()) return;
    if (isOpen) {
      document.body.style.overflow = "hidden";
      return;
    }
    setDrawerVisible(undefined);
    document.body.style.overflow = "initial";
  }, [isOpen]);

  useEffect(() => {
    if (!isBrowser()) return;
    if (drawerVisible != null && drawerVisible === drawerId && !isOpen) {
      open();
    }
  }, [drawerVisible]);

  const { hasScrolled } = useHasScrolled();

  if (!useHasMounted()) return null;

  return (
    <>
      {isOpen && <Overlay />}
      <Target placement="right">
        <Wrapper
          ref={ref}
          hasScrolled={hasScrolled}
          customDrawWidth={customDrawWidth}
          customInnerPadding={customInnerPadding}
          removeHeaderTransform={removeHeaderTransform}
        >
          {topLink && (
            <TopLinkWrapper top="0px" left="0px">
              <Link {...topLink} />
            </TopLinkWrapper>
          )}
          <Container justifyContent="flex-end" alignItems="center" cursor="pointer" role="button">
            <CloseWrapper>
              {phoneNumber && (
                <a className="phone-number" href={`tel:${phoneNumber}`}>
                  {phoneNumber}
                </a>
              )}
              {title && <Title className="h4">{title}</Title>}
            </CloseWrapper>
            <AbsoluteWrapper top={topLink ? "90px" : "50px"} right="50px">
              <CloseButton>
                <Container width="70px">
                  <img style={{ margin: "auto" }} src={assets.close} />
                  <P className="close-text">Close</P>
                </Container>
              </CloseButton>
            </AbsoluteWrapper>
          </Container>
          {isOpen && <ChildrenContainer>{children}</ChildrenContainer>}
        </Wrapper>
      </Target>
    </>
  );
}

const Wrapper = styled.div<{
  hasScrolled?: boolean;
  customInnerPadding?: string;
  customDrawWidth?: string;
  removeHeaderTransform?: boolean;
}>`
  position: fixed;

  width: ${props => (props.customDrawWidth ? props.customDrawWidth : "50vw;")};
  height: 100vh;
  background-color: ${colors.white};
  padding: ${props =>
    props.customInnerPadding ? props.customInnerPadding : "50px 40px 40px 40px"};
  z-index: 5;
  transition: all 0.2s;
  overflow: auto;
  margin-bottom: 40px;

  right: 0px;
  ${({ hasScrolled, removeHeaderTransform }) =>
    hasScrolled && !removeHeaderTransform && `right: -${HEADER_OFFSET}px !important;`}

  ul {
    width: 82%;
    margin: auto;
  }
  img {
    width: 12px;
    height: 12px;
  }

  .close-text {
    color: ${colors.navy};
    margin: auto 0;
  }

  a.phone-number {
    color: ${colors.navy};
    font-weight: ${fontWeights.bold};
    margin: auto 0;
    height: max-content;
  }

  ${mediaQuery.tabletLandScapeDown} {
    width: 100vw;
    padding: ${props =>
      props.customInnerPadding ? props.customInnerPadding : "50px 25px 40px 25px"};

    ${({ hasScrolled }) => hasScrolled && `right: 0px !important;`}
  }
`;

const TopLinkWrapper = styled(AbsoluteWrapper)`
  width: 100%;
  background: ${colors.navy};
  padding: 13px 0px 13px 95px;
  a {
    color: ${colors.white};
  }

  ${mediaQuery.tabletDown} {
    padding: 13px 25px;
  }
`;

const Title = styled.p`
  margin: auto 0;
  height: max-content;
  ${mediaQuery.mobileDown} {
    max-width: 200px;
    width: 80%;
    // margin-left: 10%;
    // margin-top: 8%;
  }
`;

const CloseWrapper = styled(Container)`
  width: 100%;
  padding-left: 60px;
  margin: 0px auto;

  ${mediaQuery.tabletDown} {
    width: 100%;
    // margin: 20px auto 0px auto;
    padding-left: 0px;
  }
`;

const ChildrenContainer = styled(Container)`
  padding: 0px 60px 60px 60px;
  width: 100%;

  ${mediaQuery.tabletDown} {
    padding: 0;
  }
`;
