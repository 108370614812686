import React from "react";
import styled from "styled-components";

import { Maybe, Scalars } from "@graphql-types";
import {
  colors,
  HEADER_HEIGHT,
  HEADER_MOBILE_OFFSET,
  HEADER_OFFSET,
  mediaQuery,
} from "@util/constants";
import { useStore } from "@state/store";
import { useHasScrolled } from "@util/hooks";

// TODO: set frameId to update frame shape
interface Props {
  frameId?: Maybe<Scalars["String"]>;
}

export default function Frame({}: Props) {
  const { pageColor } = useStore();
  const { hasScrolled, hasScrolledToBottom } = useHasScrolled();

  return (
    <FrameWrapper hasScrolled={hasScrolled} hasScrolledToBottom={hasScrolledToBottom}>
      <FrameDiv color={pageColor ?? colors.cyan} hasScrolled={hasScrolled} />
    </FrameWrapper>
  );
}
const FrameWrapper = styled.div<{ hasScrolled: boolean; hasScrolledToBottom: boolean }>`
  position: fixed;
  z-index: 3;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  /* border-top: ${props => (props.hasScrolled ? HEADER_OFFSET : 0)}px solid white; */
  border-bottom: ${props => (props.hasScrolled && !props.hasScrolledToBottom ? HEADER_OFFSET : 0)}px
    solid white;

  ${mediaQuery.mobileDown} {
    border-bottom: ${props =>
        props.hasScrolled && !props.hasScrolledToBottom ? HEADER_MOBILE_OFFSET : 0}px
      solid white;
  }
`;

const FrameDiv = styled.div<{ color: string; hasScrolled: boolean }>`
  position: fixed;
  z-index: 3;
  border: 10px solid ${props => props.color};
  pointer-events: none;
  border-radius: 0 0 40px 40px;
  border-top: 0;
  transition: all 0.5s;
  top: ${props => (props.hasScrolled ? HEADER_HEIGHT : 100)}px;
  left: ${HEADER_OFFSET}px;
  right: ${HEADER_OFFSET}px;
  bottom: ${HEADER_OFFSET}px;

  ${mediaQuery.mobileDown} {
    top: ${props => (props.hasScrolled ? HEADER_HEIGHT : 100)}px;
    bottom: ${HEADER_MOBILE_OFFSET}px;
    left: ${HEADER_MOBILE_OFFSET}px;
    right: ${HEADER_MOBILE_OFFSET}px;
  }
`;
