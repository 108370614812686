import React, { useCallback, KeyboardEvent, useState } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import { Container } from "@util/standard";
import { assets, colors } from "@util/constants";
import { useStore } from "@state/store";
import { Color } from "@util/types";

export interface SearchInputProps {
  width?: string;
  buttonColor?: Color;
  placeholder?: string;
  outlined?: boolean;
}

export default function SearchInput({
  width,
  buttonColor,
  placeholder,
  outlined,
}: SearchInputProps) {
  const { pageColor } = useStore();
  const [searchInput, setSearchInput] = useState("");

  const handleSearch = (query: string) => {
    navigate(`/search-results/?${query.replace(/ /g, "_")}`);
  };

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (searchInput === "") return;
      if (event.key === "Enter") {
        handleSearch(searchInput);
      }
    },
    [handleSearch],
  );

  return (
    <InputContainer
      buttonColor={buttonColor ? colors[buttonColor] : pageColor}
      width={width}
      outlined={outlined}
    >
      <input
        autoFocus
        aria-label="Search Metlifecare"
        placeholder={placeholder ?? "Region, town, postcode, village"}
        value={searchInput}
        onChange={event => setSearchInput(event.target.value)}
        onKeyPress={handleKeyPress}
      />
      <button aria-label="Search Metlifecare" onClick={() => handleSearch(searchInput)}>
        <img alt="search" src={assets.search} />
      </button>
    </InputContainer>
  );
}

const InputContainer = styled(Container)<{
  buttonColor: string;
  width?: string;
  outlined?: boolean;
}>`
  overflow: hidden;
  background-color: ${colors.white};
  min-width: 300px;
  width: ${props => props.width ?? "100%"};
  border-radius: 60px;
  border: ${props => (props.outlined ? "1px solid" : "none")};

  input {
    padding: 14px 25px;
    width: 100%;
    border: none;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: ${props => props.buttonColor ?? colors.cyan};
    border: none;
    cursor: pointer;
    user-select: none;
    width: 70px;
    height: inherit;
    border-radius: inherit;
    padding: 0 25px;

    img {
      width: 16px;
      height: 16px;
      ${({ buttonColor }) => buttonColor === colors.navy && `filter: invert(1);`}
    }
  }
`;
