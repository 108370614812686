import { Container, PreviewCard } from "@util/standard";
import { Image, SelectableButton } from "@global";
import React, { useEffect, useState } from "react";
import {
  sendInfoPackAddToCart,
  sendInfoPackRemoveFromCart,
  sendMetguidRemoveFromCart,
  sendMetguideAddToCart,
} from "@util/datalayer";

import { navigate } from "gatsby";
import { usePrevious } from "@util/hooks";
import { useStore } from "@state/store";
import { Maybe, SanityRegion } from "@graphql-types";

interface Props {
  data: any;
  region?: Maybe<SanityRegion> | undefined;
  mobileFullWidth?: boolean;
}

export default function FormPreview({ data, region, mobileFullWidth = false }: Props) {
  const [selected, setSelected] = useState(false);
  const {
    selectedVillageInfoPacks,
    setSelectedVillageInfoPacks,
    setSelectedMetguides,
    selectedMetguides,
    setDrawerVisible,
  } = useStore();
  const prevMetGuidesLength = usePrevious(selectedMetguides?.length);
  const prevInfoPacksLength = usePrevious(selectedVillageInfoPacks?.length);

  const {
    title,
    slug,
    formType,
    villageCode,
    dynamicsVillageCode,
    munchkinId,
    guideCategory,
    description,
    image,
    infoPackImage,
    dynamicsName,
    suburb,
  } = data;

  const isGuide = formType === "guide";
  const imageToShow = isGuide ? image : infoPackImage;

  const handleMetGuideSelection = () => {
    if (!isGuide) return;
    if (selectedMetguides.length === 0) {
      setSelected(false);
      return;
    }
    if (selectedMetguides && selectedMetguides.length > 0) {
      const found = selectedMetguides.find(guide => guide.guidePDFName === dynamicsName);
      setSelected(Boolean(found));
    }
    if (prevMetGuidesLength === 0 && selectedMetguides?.length === 1) setDrawerVisible("guide");
  };

  const handleInfoPackSelection = () => {
    if (isGuide) return;
    if (selectedVillageInfoPacks.length === 0) {
      setSelected(false);
      return;
    }
    if (selectedVillageInfoPacks && selectedVillageInfoPacks.length > 0) {
      const found = selectedVillageInfoPacks.find(code => code.villageCode === dynamicsVillageCode);
      if (found) setSelected(true);
    }
    if (prevInfoPacksLength === 0 && selectedVillageInfoPacks?.length === 1)
      setDrawerVisible("info pack");
  };

  useEffect(() => {
    handleMetGuideSelection();
    handleInfoPackSelection();
  }, []);

  useEffect(() => {
    handleMetGuideSelection();
  }, [selectedMetguides]);

  useEffect(() => {
    handleInfoPackSelection();
  }, [selectedVillageInfoPacks]);

  const handleInfoPackChange = () => {
    const villageCodes = dynamicsVillageCode ?? villageCode;

    setSelected(!selected);
    if (!selected && selectedVillageInfoPacks) {
      const updatedVillages = [
        ...selectedVillageInfoPacks,
        { villageTitle: title, villageCode: villageCodes, region },
      ];
      setSelectedVillageInfoPacks(updatedVillages);
      sendInfoPackAddToCart(villageCodes, title, "village info pack");
    }
    if (selected && selectedVillageInfoPacks) {
      const removedVillages = selectedVillageInfoPacks.filter(
        code => code.villageCode != villageCodes,
      );
      setSelectedVillageInfoPacks(removedVillages);
      sendInfoPackRemoveFromCart(villageCodes, title, "village info pack");
    }
  };

  const handleGuideChange = () => {
    setSelected(!selected);
    if (!selected) {
      const updateMetguides = [
        ...selectedMetguides,
        {
          guideName: title,
          guidePDFName: dynamicsName,
          guideCategory: guideCategory && guideCategory[0]?.categoryId?.current,
        },
      ];
      setSelectedMetguides(updateMetguides);
      sendMetguideAddToCart(
        slug.current,
        title,
        guideCategory && guideCategory[0]?.categoryId?.current,
      );
    }
    if (selected && selectedMetguides.length > 0) {
      const removedGuides = selectedMetguides.filter(guide => guide.guidePDFName != dynamicsName);
      setSelectedMetguides(removedGuides);
      sendMetguidRemoveFromCart(
        slug.current,
        title,
        guideCategory && guideCategory[0]?.categoryId?.current,
      );
    }
  };

  return (
    <Container
      flexDirection="column"
      width="272px"
      justifyContent="space-between"
      className="formPreview-wrapper"
      mobileWidth={mobileFullWidth ? "100%" : undefined}
    >
      <PreviewCard
        onClick={() => {
          if (slug?.current == null) return;
          navigate(`/guides/${slug?.current}`);
        }}
        margin="0 0 30px 0"
      >
        {imageToShow && <Image objectFit="contain" isBackground data={imageToShow} />}
        {!isGuide && <h3>{`${title} info pack`}</h3>}
        {suburb && <p>{suburb}</p>}
      </PreviewCard>
      {imageToShow && isGuide && <h4>{title}</h4>}
      {imageToShow && description && <p>{description.slice(0, 100)}...</p>}
      <SelectableButton
        selected={selected}
        onClick={isGuide ? handleGuideChange : handleInfoPackChange}
      >
        {isGuide ? "Select this guide" : "Select info pack"}
      </SelectableButton>
    </Container>
  );
}
