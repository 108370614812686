import { Maybe, SanityLink } from "@graphql-types";
import { colors, mediaQuery } from "@util/constants";
import { openInNewTab } from "@util/helper";
import { navigate } from "gatsby";
import React from "react";
import styled from "styled-components";

interface Props {
  data: Maybe<SanityLink>[];
}

const CareNavigation = ({ data }: Props) => {
  if (data == null || data.length === 0) return null;

  const handleChange = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const url = e?.target?.value;
    if (!url) return;

    if (url.startsWith("http://") || url.startsWith("https://")) {
      openInNewTab(url);
      return;
    }
    navigate(url);
  };

  return (
    <StyledCareNavigation>
      <p>Today I'd like to</p>
      <select className="care-options" onChange={handleChange}>
        <option>click here to select</option>
        {data.map((item, index) => {
          if (item == null) return null;
          const { linkText, _key, internalLink, externalLink } = item;
          return (
            <option key={_key || index} value={internalLink?.slug?.current! || externalLink!}>
              {linkText}
            </option>
          );
        })}
      </select>
    </StyledCareNavigation>
  );
};

export default CareNavigation;

const StyledCareNavigation = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    font-size: 32px;
    width: fit-content !important;
    height: fit-content;
    margin: 44px 0 0 0;
  }

  .care-options {
    font-size: 32px;
    background-color: transparent;
    border: none;
    color: #fff;
    border-bottom: 2px solid #fff;
    height: fit-content;
    position: relative;
    padding: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: url("/assets/chervonWhite.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 15px;

    option {
      background-color: ${colors.navy};
    }

    ${mediaQuery.mobileDown} {
      width: 100%;
    }
  }

  ${mediaQuery.mobileDown} {
    .care-options,
    p {
      font-size: 24px;
    }
  }
`;
