import React, { RefObject, useEffect, useState } from "react";
import { SanityForm, SanityRegion, SanityVillage } from "@graphql-types";

import { Button } from "@global";
import { Container } from "@util/standard";
import FormPreview from "./preview";

import styled from "styled-components";
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { mediaQuery } from "@util/constants";
import { useCheckScreenSize } from "@util/hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Controller, Navigation } from "swiper/modules";
import { useStore } from "@state/store";

export interface Props {
  data?: Array<SanityVillage | SanityForm> | undefined;
  region?: SanityRegion;
  isInfoPack?: boolean;
  viewRef?: RefObject<HTMLDivElement>;
  regionOverrideString?: string | null | undefined;
}

export default function GuideCardsAccordion({ data, region, viewRef }: Props) {
  if (data == null || !Boolean(data.length)) return null;
  const { regionAccordionID } = useStore();
  const { mobileDown, mobileUp } = useCheckScreenSize();
  const [controller, setController] = useState<SwiperCore>();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (regionAccordionID == region?.marketoValue) {
      setExpanded(true);
    }
  }, [regionAccordionID]);

  return (
    <StyledAccordionWrapper>
      <AccordionItem dangerouslySetExpanded={expanded}>
        <Container ref={viewRef} flexDirection="column">
          <AccordionItemHeading
            tabIndex={0}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <AccordionItemButton aria-label={`${region?.title} info packs`}>
              <TitleWrapper>
                {region?.marketoValue && <h2>{region?.marketoValue}</h2>}
                <Button className="accordion-button">View villages in region</Button>
              </TitleWrapper>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {data && (
              <Container
                width="100%"
                columnGap="25px"
                rowGap="25px"
                flexFlow="wrap"
                margin="0 0 40px 0"
                isMobileColumn
                position="relative"
              >
                {mobileDown && (
                  <Swiper
                    modules={[Controller, Navigation]}
                    onSwiper={setController}
                    passiveListeners
                    slidesPerView={1.2}
                    spaceBetween={10}
                  >
                    {data.map((form, index) => {
                      if (form == null) return null;
                      return (
                        <SwiperSlide key={form._key}>
                          <FormPreview key={index} data={form} region={region} />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                )}

                {mobileUp &&
                  data.map((form, index) => {
                    if (form == null) return null;
                    return <FormPreview key={index} data={form} region={region} />;
                  })}
              </Container>
            )}
          </AccordionItemPanel>
        </Container>
      </AccordionItem>
    </StyledAccordionWrapper>
  );
}

const StyledAccordionWrapper = styled.div`
  .swiper-initialized {
    width: 100%;

    .formPreview-wrapper {
      width: 100%;
    }
  }
  .accordion__heading {
    padding: 31px 0;
  }

  .accordion__button {
    width: 100%;

    :after {
      display: none;
    }
  }

  ${mediaQuery.tabletDown} {
    .accordion-button {
      display: none;
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
