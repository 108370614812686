import CtaBlock from "@components/ctaBlock";
import PropertyPreviewGrid from "@components/property/previewGrid";
import { BlocksContent, Link } from "@global";
import { SanityVillageHomeOption, SanityVillageHomeOptions } from "@graphql-types";
import { sanityClient } from "@lib/sanityClient";
import { useStore } from "@state/store";
import { mediaQuery } from "@util/constants";
import { PageWidth, Section } from "@util/standard";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface Props {
  data: SanityVillageHomeOptions;
}

const HomeOptionsBlock = ({ data }: Props) => {
  const [fetchedHomeOptions, setFetchedHomeOptions] = useState<SanityVillageHomeOption[] | null>(
    null,
  );
  const { village } = useStore();
  if (data == null) return null;

  const fetchHomeOptions = async () => {
    const listings =
      await sanityClient.fetch(`*[_type == "village" && slug.current == "${village.slug}"][0]{
        listings[] 

       }`);

    console.log({ listings });
    const filteredListings = listings?.listings?.filter(listing => {
      return Boolean(homeOptions?.includes(listing._key));
    });
    setFetchedHomeOptions(filteredListings);
  };

  useEffect(() => {
    if (homeOptions == null) return;
    fetchHomeOptions();
  }, []);

  const { content, ctaButton, homeOptions } = data;
  return (
    <Section>
      <PageWidth>
        <StyledContentWrapper hasLink={Boolean(ctaButton != null)}>
          {content && <BlocksContent data={content} />}
          <span className="button-wrapper">
            {ctaButton && <Link className="button-base" key={ctaButton._key} {...ctaButton} />}
          </span>
        </StyledContentWrapper>
      </PageWidth>
      {fetchedHomeOptions && <PropertyPreviewGrid data={fetchedHomeOptions} />}
    </Section>
  );
};

export default HomeOptionsBlock;

const StyledContentWrapper = styled.span<{ hasLink?: boolean }>`
  display: grid;
  grid-template-columns: ${props => (props.hasLink ? "70% auto" : "100%")};
  margin-bottom: 40px;

  ${mediaQuery.tabletDown} {
    grid-template-columns: 100%;
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    ${mediaQuery.tabletDown} {
      align-items: flex-start;
      margin-top: 20px;
    }
  }
`;
