import { Dispatch, SetStateAction } from "react";
import {
  assets,
  buttonTheme,
  colors,
  colorsRGB,
  fontFamilies,
  fontWeights,
  mediaQueryWidth,
} from "./constants";

import {
  Maybe,
  SanityEventConnection,
  SanityHero,
  SanityImageWithMeta,
  SanityMapList,
  SanityTaggedCtaBlock,
  SanityTaggedImageTextColumn,
  SanityTestimonials,
  SanityVillageRelated,
  SanityTaggedVideo,
  SanityNewsConnection,
  SanityTaggedRowContent,
  SanityFaqsBlock,
  SanityFaqReference,
  SanityFaq,
  SanityTaggedGallery,
  SanityVillageFaces,
  SanityBlockContent,
  SanityVillage,
  SanityOtherVillages,
  SanityGlobalBlockRef,
  SanityCtaBlock,
  SanityImageTextColumn,
  SanityVideo,
  SanityRowContent,
  SanityGallery,
  SanityGlobalBlock,
  SanityInfoGrid,
  SanityInfoSlider,
  SanityChecklists,
  SanityInfoColumnList,
  SanityFormsBlock,
  SanityNews,
  SanityPage,
  SanityForm,
  SanitySlug,
  SanityMapBlock,
  SanityMapLocation,
  SanityCareJourneyBlock,
  SanityIframe,
  SanityDynamicsFieldValue,
  SanityDynamicsFormBlock,
  SanityAccordion,
  SanityTaggedAccordion,
  SanityVariation,
  SanityVillageHomeOptions,
} from "@graphql-types";

export type Asset = keyof typeof assets;
export type Color = keyof typeof colors;
export type ColorRGB = keyof typeof colorsRGB;
export type ButtonTheme = keyof typeof buttonTheme;
export type FontWeights = keyof typeof fontWeights;
export type FontFamily = keyof typeof fontFamilies;
export type AssetType = typeof assets;
export type MediaQueryWidth = keyof typeof mediaQueryWidth;

export type SetRegion = Dispatch<
  SetStateAction<{
    iban: string;
    langCode: string;
  }>
>;

export interface SelectOption {
  id: string;
  title: string;
}

export type AnimationDirection = {
  to: {
    transform?: string;
    opacity?: number;
  };
  from: {
    transform?: string;
    opacity?: number;
  };
};

export interface Marketo {
  baseUrl: string;
  munchkinId: string;
  formId: string;
  callback?: (args?: any) => void;
  onScreen?: boolean;
}

export interface GroqVillageData extends SanityVillage {
  livingOptionFilters: { title: string }[];
  previewImages: string[];
  phone: string;
}

export interface VillagesInMap {
  count: number;
  slug: SanitySlug;
  title: string;
}

export interface VariantBlock extends SanityGlobalBlock {
  content: any;
  [key: string]: any;
}

export interface VillageRelatedData {
  villageEvents: SanityEventConnection;
  regionEvents: SanityEventConnection;
  otherNews: SanityNewsConnection;
  generalNews: SanityNewsConnection;
  villageNews: SanityNewsConnection;
  regionNews: SanityNewsConnection;
  villagesNews: SanityNewsConnection;
}

export interface VillageSubpageData {
  hero: Maybe<SanityHero> | undefined;
  blocks: Maybe<Maybe<any>[]> | undefined;
}

//Data layer
export interface DataLayerContactForm {
  form_step?: Maybe<string> | undefined;
  form_name?: Maybe<string> | undefined;
  success_step?: boolean;
  document_type?: Maybe<string> | undefined;
  document_name?: Maybe<string> | undefined;
  village_name?: Maybe<string> | undefined;
  village_id?: Maybe<string> | undefined;
  user_location?: Maybe<string> | undefined;
  comms_consent?: boolean;
  lead_generated?: boolean;
  enquiry_type?: Maybe<string> | undefined;
  requesting_for?: Maybe<string> | undefined;
  region?: Maybe<string> | undefined;
}

export interface MetguideForm {
  FirstName: string;
  LastName: string;
  Phone: string;
  Email: string;
  metlife_metguide_name: string;
  metlife_stated_region_of_interest: string;
  metlifeEnquiry: string;
  optInMetlifecare: string | null;
}

export interface ContactForm {
  FirstName: string;
  LastName: string;
  Phone: string;
  Email: string;
  metlifeEnquiry: string;
  //Is string for contact
  optInMetlifecare: string | null;
  metlife_contact_us_reason: string;
  MktoPersonNotes: string;
  metlife_stated_region_of_interest?: string;
  metlife_villages_of_interest_brochure?: Maybe<string> | undefined;
  metlifecare_living_options?: Maybe<string> | undefined;
}

export interface CareForm {
  FirstName: string;
  LastName: string;
  Phone: string;
  Email: string;
  metlifeEnquiry: string;
  //Is string for contact
  optInMetlifecare: string | null;
  metlife_contact_us_reason: string;
  metlife_stated_region_of_interest?: string;
  metlife_villages_of_interest_brochure?: Maybe<string> | undefined;
  needs_assessment: string;
  level_of_care_known: string;
  MktoPersonNotes: string;
}
export interface BookATourForm {
  FirstName: string;
  LastName: string;
  Phone: string;
  Email: string;
  metlifeEnquiry: string;
  optInMetlifecare: string | null;
  metlife_villages_of_interest_brochure: Maybe<string> | undefined;
  metlife_stated_region_of_interest: Maybe<string> | undefined;
  metlife_book_a_tour_date: string;
  metlife_book_a_tour_ampm: string;
  metlife_book_a_tour_living_arrangements: string;
}

export interface InfoPackForm {
  FirstName: string;
  LastName: string;
  Phone: string;
  Email: string;
  metlifeEnquiry: string;
  optInMetlifecare: string | null;
  metlife_do_not_integrate: string;
  metlife_stated_region_of_interest: string;
  metlife_villages_of_interest_brochure: string;
}

export type FormDataTypes = MetguideForm | ContactForm | BookATourForm | InfoPackForm | CareForm;

export function isContactForm(formType: string, data: any): data is ContactForm {
  return formType === "contact" || formType === "villageContact";
}
export function isBookatourForm(formType: string, data: any): data is BookATourForm {
  return formType === "bookatour";
}
export function isMetguideForm(formType: string, data: any): data is MetguideForm {
  return formType === "metguide";
}
export function isInfoPackForm(formType: string, data: any): data is InfoPackForm {
  return formType === "infopack";
}

export function isCareForm(formType: string, data: any): data is CareForm {
  return formType === "care";
}

export function isSanityImage(data: any): data is SanityImageWithMeta {
  return data._type === "imageWithMeta";
}

// village types
export function isSanityTestimonial(data: any): data is SanityTestimonials {
  return data._type === "testimonials";
}

export function isSanityVillageRelated(data: any): data is SanityVillageRelated {
  return data._type === "villageRelated";
}

export function isSanityTaggedImageTextColumn(data: any): data is SanityTaggedImageTextColumn {
  return data._type === "taggedImageTextColumn";
}

export function isSanityTaggedCtaBlock(data: any): data is SanityTaggedCtaBlock {
  return data._type === "taggedCtaBlock";
}

export function isSanityMapList(data: any): data is SanityMapList {
  return data._type === "mapList";
}

export function isSanityTaggedVideo(data: any): data is SanityTaggedVideo {
  return data._type === "taggedVideo";
}

export function isSanityTaggedRowContent(data: any): data is SanityTaggedRowContent {
  return data._type === "taggedRowContent";
}

export function isSanityFaqsBlock(data: any): data is SanityFaqsBlock {
  return data?._type === "faqsBlock";
}

export function isSanityFaq(data: any): data is SanityFaq {
  return data._type === "faq";
}

export function isSanityFaqReference(data: any): data is SanityFaqReference {
  return data._type === "faqReference";
}

export function isSanityTaggedGallery(data: any): data is SanityTaggedGallery {
  return data._type === "taggedGallery";
}

export function isSanityVillageFaces(data: any): data is SanityVillageFaces {
  return data._type === "villageFaces";
}

export function isSanityBlockContent(data: any): data is SanityBlockContent {
  return data._type === "blockContent";
}

export function isSanityOtherVillages(data: any): data is SanityOtherVillages {
  return data?._type === "otherVillages";
}

export function isSanityGlobalBlockRef(data: any): data is SanityGlobalBlockRef {
  return data?._type === "globalBlockRef";
}

export function isSanityCtaBlock(data: any): data is SanityCtaBlock {
  return data._type === "ctaBlock";
}

export function isSanityImageTextColumn(data: any): data is SanityImageTextColumn {
  return data._type === "imageTextColumn";
}

export function isSanityVideo(data: any): data is SanityVideo {
  return data._type === "video";
}

export function isSanityRowContent(data: any): data is SanityRowContent {
  return data._type === "rowContent";
}

export function isSanityGallery(data: any): data is SanityGallery {
  return data._type === "gallery";
}

export function isSanityInfoGrid(data: any): data is SanityInfoGrid {
  return data._type === "infoGrid";
}

export function isSanityInfoSlider(data: any): data is SanityInfoSlider {
  return data._type === "infoSlider";
}

export function isSanityChecklists(data: any): data is SanityChecklists {
  return data._type === "checklists";
}

export function isSanityInfoColumnList(data: any): data is SanityInfoColumnList {
  return data._type === "infoColumnList";
}

export function isSanityFormsBlock(data: any): data is SanityFormsBlock {
  return data?._type === "formsBlock";
}

export function isSanityNews(data: any): data is SanityNews {
  return data._type === "news";
}

export function isSanityPage(data: any): data is SanityPage {
  return data._type === "page";
}

export function isSanityVillage(data: any): data is SanityVillage {
  return data._type === "village";
}

export function isSanityForm(data: any): data is SanityForm {
  return data._type === "form";
}

export function isSanityMapBlock(data: any): data is SanityMapBlock {
  return data._type === "mapBlock";
}

export function isSanityMapLocation(data: any): data is SanityMapLocation {
  return data._type === "mapLocation";
}

export function isSanityIframe(data: any): data is SanityIframe {
  return data._type === "iframe";
}

export function isArrayString(data: any[] | undefined | null) {
  if (data == null || data.length === 0) return false;
  return typeof data[0] === "string";
}

export function isSanityCareJourneyBlock(data: any): data is SanityCareJourneyBlock {
  return data._type === "careJourneyBlock";
}

export function isSanityDynamicsFormBlock(data: any): data is SanityDynamicsFormBlock {
  return data._type === "dynamicsFormBlock";
}

export function isSanityAccordion(data: any): data is SanityAccordion {
  return data._type === "accordion";
}

export function isSanityTaggedAccordion(data: any): data is SanityTaggedAccordion {
  return data._type === "taggedAccordion";
}

export function isSanityVariation(data: any): data is SanityVariation {
  return data._type === "variation";
}

export function isSanityPopup(data: any): data is SanityPopup {
  return data._type == "popup";
}

export function isSanityHomeOptionsBlock(data: any): data is SanityVillageHomeOptions {
  return data._type === "villageHomeOptions";
}

export function isStaticPage(data: any) {
  const staticPageTypes = [
    "contactPage",
    "bookatour",
    "eventsLanding",
    "faqsLanding",
    "guidesLanding",
    "investorCenter",
    "infoPacksLanding",
    "newDevelopmentLanding",
    "newsLanding",
    "policiesPage",
  ];
  return staticPageTypes.includes(data._type);
}
