import React from "react";

import ErrorMsg from "./errorMsg";
import {
  isSanityTaggedAccordion,
  isSanityTaggedCtaBlock,
  isSanityTaggedGallery,
  isSanityTaggedImageTextColumn,
  isSanityTaggedRowContent,
  isSanityTaggedVideo,
  VariantBlock,
} from "@util/types";
import { useDetermineVariantBlock } from "@util/logicHooks";

//components
import ImageTextColumn from "@components/imageTextColumn";
import CtaBlock from "@components/ctaBlock";
import { Video } from "@global";
import RowContent from "@components/rowContent";
import Gallery from "@components/gallery";
import { Maybe } from "@graphql-types";
import AccordionBlock from "@components/accordionBlock";

interface Props {
  data: { _type: string; taggedBlocks: Maybe<Partial<VariantBlock>>[] };
}

/**
 * Determine which block to render depending on the variants on village pages
 *
 * @param `data` - array of tagged blocks
 * @returns Block to render
 */
export default function TaggedBlocks({ data }: Props) {
  if (data == null) return <ErrorMsg data={data} msg="No data for tagged blocks" />;

  const { determinedBlock } = useDetermineVariantBlock(data?.taggedBlocks);

  if (determinedBlock == null)
    return <ErrorMsg data={data} msg={`Error loading tagged block ${data._type}`} />;

  if (isSanityTaggedImageTextColumn(determinedBlock)) {
    return <ImageTextColumn data={determinedBlock.content} />;
  }

  if (isSanityTaggedCtaBlock(determinedBlock)) {
    return <CtaBlock data={determinedBlock.content} />;
  }

  if (isSanityTaggedVideo(determinedBlock)) {
    return <Video data={determinedBlock.content} />;
  }

  if (isSanityTaggedRowContent(determinedBlock)) {
    return <RowContent data={determinedBlock.content} />;
  }

  if (isSanityTaggedGallery(determinedBlock)) {
    return <Gallery data={determinedBlock.content} />;
  }

  if (isSanityTaggedAccordion(determinedBlock)) {
    return <AccordionBlock data={determinedBlock.content} />;
  }

  return <ErrorMsg data={data} msg={`Error loading tagged block ${data._type}`} />;
}
