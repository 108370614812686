import React, { CSSProperties } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import SanityImage from "gatsby-plugin-sanity-image";
import styled from "styled-components";

import { Maybe, SanityImageWithMeta } from "@graphql-types";
import { AbsoluteWrapper, Container, P } from "@util/standard";

const HotSpotImage = styled(SanityImage)<{}>``;

const BackgroundImageWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
`;

export interface ImageProps {
  data: Maybe<SanityImageWithMeta> | undefined;
  minHeight?: string;
  isBackground?: boolean;
  width?: string;
  height?: string;
  onClick?: () => void;
  objectFit?: CSSProperties["objectFit"];
  margin?: string;
  filter?: string;
}

const Image = (props: ImageProps) => {
  const { objectFit, width, height, margin, filter, data, isBackground, onClick } = props;
  if (!data) return null;

  const { asset, hotspot } = data;

  const ImageCaption = () => {
    return (
      <AbsoluteWrapper bottom="0px" right="15px" tabletRight="45px">
        <P color={data.makeTextDark ? "navy" : "white"} fontSize={12}>
          {data.imageCaption}
        </P>
      </AbsoluteWrapper>
    );
  };

  if (hotspot || (asset as any)?._ref) {
    if (isBackground) {
      return (
        <BackgroundImageWrapper>
          <HotSpotImage
            {...data}
            style={{ margin, filter, objectFit: "cover", width: "100%", height: "100%" }}
            alt={data?.altText ?? "background image"}
            itemProp="image"
            onClick={onClick}
          />
          {data.imageCaption && <ImageCaption />}
        </BackgroundImageWrapper>
      );
    }

    return (
      <Container position="relative" width={width ?? "100%"} height={height ?? "100%"}>
        <HotSpotImage
          {...data}
          style={{ margin, filter, width, height, objectFit: objectFit ?? "contain" }}
          alt={data?.altText ?? "image"}
          itemProp="image"
          onClick={onClick}
        />
        {data.imageCaption && <ImageCaption />}
      </Container>
    );
  }

  if (asset == null) return null;
  const imageData = asset?.gatsbyImageData;

  if (imageData) {
    if (isBackground) {
      return (
        <BackgroundImageWrapper>
          <GatsbyImage
            image={imageData}
            style={{ margin, width, filter, height: "100%" }}
            itemProp="image"
            alt={data?.altText ?? "background image"}
            onClick={onClick}
            objectFit={objectFit ?? "cover"}
          />
          {data.imageCaption && <ImageCaption />}
        </BackgroundImageWrapper>
      );
    }

    return (
      <Container position="relative" width={width ?? "100%"} height={height ?? "100%"}>
        <GatsbyImage
          image={imageData}
          style={{ margin, filter, width, height: height ?? "100%" }}
          itemProp="image"
          alt={data?.altText ?? "image"}
          onClick={onClick}
          objectFit={objectFit ?? "contain"}
        />
        {data.imageCaption && <ImageCaption />}
      </Container>
    );
  }

  return null;
};

export default Image;
