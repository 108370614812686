import { Button, Link, PreviewCarousel, Image, BlocksContent } from "@global";
import {
  Container,
  DesktopContainer,
  MobileContainer,
  PageWidth,
  PreviewCard,
  Section,
} from "@util/standard";
import { Drawer, Trigger } from "@accessible/drawer";
import React, { useState } from "react";

import { DrawerTarget } from "@shared";
import FormPreview from "./preview";
import InfoPackForm from "./infoPackForm";
import MetguideForm from "./metguideForm";
import {
  Maybe,
  SanityBlockContent,
  SanityForm,
  SanityFormsBlock,
  SanityVillage,
} from "@graphql-types";
import { assets, mediaQuery } from "@util/constants";
import styled from "styled-components";
import { useStore } from "@state/store";
import { useVillagesInRegions } from "@util/logicHooks";
import { GroqVillageData } from "@util/types";
import SwiperCore from "swiper/modules";
import { SwiperSlide } from "swiper/react";
import { GeneralBrochureWrapper } from "./allInfoPacksInRegions";
import { useCheckScreenSize } from "@util/hooks";
import { GetYourSelected } from "./shared";

interface Props {
  data: SanityFormsBlock;
}

const ariaLabel = "Guides & Info packs";

export default function FormsBlock({ data }: Props) {
  if (data == null) return null;
  const {
    forms,
    title,
    infoPacks,
    infoPacksByRegion,
    layout,
    _key,
    hideViewAllButton,
    singleFormRichText,
  } = data;
  const { pageColor } = useStore();
  // TODO: add variation logic
  // TODO: add select functionality

  const isGuide = Boolean(forms && forms[0]?.formType === "guide");
  const villages = infoPacksByRegion && useVillagesInRegions(infoPacksByRegion);
  const isSingleForm = Boolean(layout === "single");
  const hasMultiple = Boolean(forms?.length > 4 || infoPacks?.length > 4 || villages?.length > 4);

  const { tabletDown, mobileDown } = useCheckScreenSize();

  const shouldShowNavigation = () => {
    if (tabletDown) return true;
    if (mobileDown) return true;
    if (hasMultiple) return true;
    return false;
  };

  return (
    <Section aria-labelledby={ariaLabel}>
      <Container backgroundColor={isSingleForm ? "white" : "cream"}>
        <PageWidth>
          <Wrapper layout={layout}>
            <FormsBlockItemsLayout
              {...{
                layout,
                title,
                forms,
                infoPacks,
                villages,
                isGuide,
                _key,
                hideViewAllButton,
                singleFormRichText,
              }}
            />
          </Wrapper>
        </PageWidth>
      </Container>
    </Section>
  );
}

interface FormsBlockItemsLayout {
  title?: Maybe<string> | undefined;
  forms?: Maybe<Maybe<SanityForm>[]> | undefined;
  infoPacks?: Maybe<Maybe<SanityVillage>[]> | undefined;
  villages?: GroqVillageData[] | null | undefined;
  isGuide?: boolean;
  layout?: Maybe<string> | undefined;
  _key?: Maybe<string> | undefined;
  hideViewAllButton?: Maybe<boolean> | undefined;
  singleFormRichText?: Maybe<SanityBlockContent> | undefined;
}

function FormsBlockItemsLayout({
  layout,
  title,
  forms,
  infoPacks,
  villages,
  isGuide,
  _key,
  hideViewAllButton = false,
  singleFormRichText,
}: FormsBlockItemsLayout) {
  const [controller, setController] = useState<SwiperCore>();
  const { pagePaths, pageColor } = useStore();

  if (layout === "carousel") {
    return (
      <Container>
        <PreviewCarousel
          controller={controller}
          setController={setController}
          swiperProps={{
            navigation: {
              nextEl: `#forms-block-${_key}-next`,
              prevEl: `#forms-block-${_key}-prev`,
            },
            slidesPerView: 1.2,
            breakpoints: {
              450: {
                slidesPerView: 1.5,
                spaceBetween: 20,
              },
              700: {
                slidesPerView: 2,
              },
              850: {
                slidesPerView: 2.2,
              },
              1122: {
                slidesPerView: 3.5,
              },
            },
          }}
        >
          <SwiperSlide key="first">
            <Container
              flexDirection="column"
              height="100%"
              justifyContent="space-between"
              width="272px"
            >
              <PreviewCard textColor="navy" backgroundColor="white">
                <h3 aria-label={ariaLabel}>{title}</h3>
                {!hideViewAllButton && (
                  <Link
                    className="button-base"
                    linkText={isGuide ? "View all guides" : "View all Village Info Packs"}
                    internalLink={isGuide ? pagePaths?.guidesLanding : pagePaths?.infoPacksLanding}
                  />
                )}
              </PreviewCard>
              <ButtonWrapper>
                {layout == "carousel" && (
                  <div className="swiper-nav-wrapper left">
                    <img
                      src={assets.circleArrowFill}
                      className="nav-left"
                      id={`forms-block-${_key}-prev`}
                    />
                    <img
                      src={assets.circleArrowFill}
                      className="nav-right"
                      id={`forms-block-${_key}-next`}
                    />
                  </div>
                )}

                <Drawer>
                  <Trigger>
                    <Container>
                      <Button
                        theme="colored"
                        colorvalue={pageColor ?? "cyan"}
                        className="formsBlock-trigger"
                      >
                        {`Get your selected ${isGuide ? "guides" : "info packs"}`}
                      </Button>
                    </Container>
                  </Trigger>
                  <DrawerTarget
                    removeHeaderTransform
                    title={isGuide ? "Get your selected Metguides" : "Order your info pack(s)"}
                  >
                    {isGuide ? <MetguideForm isDrawer /> : <InfoPackForm />}
                  </DrawerTarget>
                </Drawer>
              </ButtonWrapper>
            </Container>
          </SwiperSlide>
          {infoPacks &&
            infoPacks.map((form, index) => {
              if (form == null) return null;

              return (
                <SwiperSlide key={form._key}>
                  <FormPreview key={index} data={form} region={form?.region} />
                </SwiperSlide>
              );
            })}

          {forms &&
            forms.map((form, index) => {
              if (form == null) return null;

              return (
                <SwiperSlide key={form._key}>
                  <FormPreview key={index} data={form} />
                </SwiperSlide>
              );
            })}

          {villages &&
            villages?.map((village, index) => {
              if (village == null) return null;

              return (
                <SwiperSlide key={village._key}>
                  <FormPreview key={index} data={village} region={village?.region} />
                </SwiperSlide>
              );
            })}
        </PreviewCarousel>
      </Container>
    );
  }
  if (layout === "single") {
    const hasMetguide = forms && forms[0];
    const hasVillage = infoPacks && infoPacks[0];
    console.log(hasVillage);

    return (
      <GeneralBrochureWrapper backgroundColor="cream" id="general-brochure" bottomMargin="20px">
        {hasMetguide && <Image data={hasMetguide?.image} />}
        {hasVillage && <Image data={hasVillage?.infoPackImage} />}
        <Container className="content">
          <BlocksContent data={singleFormRichText} />
          <Drawer>
            <Trigger>
              <Container>
                <Button
                  theme="colored"
                  colorvalue={pageColor ?? "cyan"}
                  className="formsBlock-trigger"
                >
                  {`Download Guide`}
                </Button>
              </Container>
            </Trigger>
            <DrawerTarget
              removeHeaderTransform
              title={isGuide ? "Get your selected Metguide" : "Order your info pack"}
            >
              {isGuide ? (
                <MetguideForm isDrawer form={hasMetguide} isInnerPage />
              ) : (
                <InfoPackForm selectedVillage={hasVillage} />
              )}
            </DrawerTarget>
          </Drawer>
        </Container>
      </GeneralBrochureWrapper>
    );
  }
  return (
    <Container width="100%" columnGap="25px" rowGap="25px" flexFlow="wrap" margin="0 0 25px 0">
      <Container flexDirection="column" justifyContent="space-between">
        <PreviewCard textColor="navy" backgroundColor="white">
          <h3 aria-label={ariaLabel}>{title}</h3>
          {!hideViewAllButton && (
            <Link
              className="button-base"
              linkText={isGuide ? "View all guides" : "View all Village Info Packs"}
              internalLink={isGuide ? pagePaths?.guidesLanding : pagePaths?.infoPacksLanding}
            />
          )}
        </PreviewCard>
        <DesktopContainer>
          <ButtonWrapper>
            <Drawer>
              <Trigger>
                <Container>
                  <Button
                    theme="colored"
                    colorvalue={pageColor ?? "cyan"}
                    className="formsBlock-trigger"
                  >
                    {`Get your selected ${isGuide ? "guides" : "info packs"}`}
                  </Button>
                </Container>
              </Trigger>
              <DrawerTarget
                removeHeaderTransform
                title={isGuide ? "Get your selected Metguides" : "Order your info pack(s)"}
              >
                {isGuide ? <MetguideForm isDrawer /> : <InfoPackForm />}
              </DrawerTarget>
            </Drawer>
          </ButtonWrapper>
        </DesktopContainer>
      </Container>

      {infoPacks &&
        infoPacks.map((form, index) => {
          console.log("form", form);

          if (form == null) return null;

          return <FormPreview key={index} data={form} region={form?.region} mobileFullWidth />;
        })}

      {forms &&
        forms.map((form, index) => {
          if (form == null) return null;
          return <FormPreview key={index} data={form} mobileFullWidth />;
        })}

      {villages &&
        villages?.map((village, index) => {
          if (village == null) return null;

          return (
            <FormPreview key={index} data={village} region={village?.region} mobileFullWidth />
          );
        })}
      <MobileContainer>
        <ButtonWrapper>
          <Drawer>
            <Trigger>
              <Container>
                <Button
                  theme="colored"
                  colorvalue={pageColor ?? "cyan"}
                  className="formsBlock-trigger"
                >
                  {`Get your selected ${isGuide ? "guides" : "info packs"}`}
                </Button>
              </Container>
            </Trigger>
            <DrawerTarget
              removeHeaderTransform
              title={isGuide ? "Get your selected Metguides" : "Order your info pack(s)"}
            >
              {isGuide ? <MetguideForm isDrawer /> : <InfoPackForm />}
            </DrawerTarget>
          </Drawer>
        </ButtonWrapper>
      </MobileContainer>
    </Container>
  );
}

const Wrapper = styled(Container)<{
  layout?: Maybe<string> | undefined;
}>`
  margin: 60px 0;
  flex-direction: column;
  ${({ layout }) =>
    layout === "carousel" &&
    `
  
  ${mediaQuery.tabletDown} {
    .formsBlock-trigger {
      margin-top: 15px;
    }
  }
  `}

  ${({ layout }) => layout === "single" && `margin: 0;`};

  position: relative;

  ${mediaQuery.tabletDown} {
    margin: 50px 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin-top: 20px;
  .swiper-nav-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    img {
      margin-left: 20px;
      transition: all 0.2s;
      cursor: pointer;
    }
    .nav-left {
      &:hover {
        transform: scale(1.2);
      }
    }
    .nav-right {
      transform: rotate(180deg);
      &:hover {
        transform: scale(1.2) rotate(180deg);
      }
    }
  }
  ${mediaQuery.tabletDown} {
    margin-top: 20px;
    flex-direction: column-reverse;
    align-items: flex-start;
    .swiper-nav-wrapper {
      display: none;
      margin-top: 0px;

      .nav-left {
        margin-left: 0px;
      }
    }
  }
`;
