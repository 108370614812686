import React, { ReactNode } from "react";
import styled from "styled-components";

import { Maybe, SanityBlockContent, SanityLink } from "@graphql-types";
import { Container, PageWidth as StandardPageWidth, ScrollIcon, Section } from "@util/standard";
import { BlocksContent, Link } from "@global";
import { assets, colors, fontFamilies, mediaQuery } from "@util/constants";
import { useStore } from "@state/store";
import { isBrowser } from "@util/helper";
import moment from "moment";

interface Props {
  heading: Maybe<string> | undefined;
  blockContent?: Maybe<SanityBlockContent> | undefined;
  description?: Maybe<string> | undefined;
  ctas?: Maybe<Maybe<SanityLink>[]> | undefined;
  category?: Maybe<string> | undefined;
  children?: ReactNode;
  isFullHeight?: boolean;
  hideArrow?: boolean;
  publishDate?: Maybe<string> | undefined;
}

export default function BasicHero({
  heading,
  blockContent,
  ctas,
  category,
  description,
  publishDate,
  children,
  isFullHeight,
  hideArrow,
}: Props) {
  const { pageColor } = useStore();

  const formattedDate = publishDate && moment(publishDate).format("DD MMMM YYYY");

  return (
    <Section noMargins backgroundColor="navy">
      <PageWidth hasChildren={Boolean(isFullHeight && children)}>
        <ContentContainer pageColor={pageColor}>
          <TextWrapper>
            <Container id="hero-content" flexDirection="column" width="65%" mobileWidth="100%">
              {category && <CategoryText>{category}</CategoryText>}
              <h1>{heading}</h1>
              {formattedDate && <p>{formattedDate}</p>}
              <BlocksContent data={blockContent} />
              {description && <p>{description}</p>}
            </Container>

            {ctas && (
              <CtaWrapper>
                {ctas.map((link, index) => {
                  if (link == null) return null;
                  const { _key, ...rest } = link;

                  return (
                    <Link {...rest} key={_key ?? index} tab-index={0} className="button-white" />
                  );
                })}
              </CtaWrapper>
            )}
          </TextWrapper>
          {children}
        </ContentContainer>
      </PageWidth>
      {isFullHeight && children == null && !hideArrow && (
        <ScrollIconWrapper>
          <ScrollIcon src={assets.goToTop} onClick={handleClick} />
        </ScrollIconWrapper>
      )}
    </Section>
  );
}

const handleClick = () => {
  if (isBrowser()) {
    const viewHeight = window.innerHeight;
    window.scrollTo({ top: viewHeight, behavior: "smooth" });
  }
};

const PageWidth = styled(StandardPageWidth)<{ hasChildren: boolean }>`
  min-height: ${props => (props.hasChildren ? 100 : 65)}vh;
  padding-top: 200px;

  justify-content: center;

  .button-white {
    margin: 0 10px 0 0;
  }
  ${mediaQuery.smallLaptopDown} {
    padding-top: 150px;
  }

  ${mediaQuery.ipadProDown} {
    .button-white {
      margin: 20px 10px 0 0;
    }
  }

  ${mediaQuery.mobileDown} {
    padding-top: 15vh;

    .button-white {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 14px;
    }
  }
`;

const ContentContainer = styled(Container)<{ pageColor: string }>`
  position: relative;
  flex-direction: column;
  margin: 20px 0;

  h1 {
    display: flex;
    flex-direction: column;
    color: ${props => props.pageColor};
  }

  p,
  h3,
  h4 {
    color: ${colors.white};
  }

  #variation-select {
    width: 100%;
  }

  ${mediaQuery.smallLaptopDown} {
    max-width: unset;
  }

  ${mediaQuery.tabletDown} {
    h1 {
      margin-bottom: 20px;
    }
  }
`;

const TextWrapper = styled(Container)`
  justify-content: space-between;

  ${mediaQuery.tabletLandScapeDown} {
    flex-direction: column;
  }
`;

const CtaWrapper = styled(Container)`
  align-items: flex-end;
  margin: 20px 0 0 0;
  ${mediaQuery.mobileDown} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CategoryText = styled.p`
  color: ${colors.white};
  font-size: 20px;
  font-family: ${fontFamilies.beausite}, Arial;
  margin-bottom: 20px;
  font-weight: bold;
`;

const ScrollIconWrapper = styled.div`
  ${mediaQuery.smallLaptopDown} {
    display: none;
  }
`;
