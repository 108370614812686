import React from "react";
import styled from "styled-components";

import { Maybe, SanityVillageRelated } from "@graphql-types";
import { Container, PageWidth, Section } from "@util/standard";
import { useStore } from "@state/store";
import RelatedNews from "./news/relatedNews";
import EventCard from "./eventCard";
import { fontSizes, fontWeights, fontFamilies, mediaQuery } from "@util/constants";

interface Props {
  data: Maybe<SanityVillageRelated>;
}

export default function VillageRelated({ data }: Props) {
  if (data == null) return null;
  const getVillage = useStore(state => state.getVillage);

  const { relatedContent, title } = data;
  if (relatedContent === "events") {
    const villageEvents = getVillage().events;
    if (!Boolean(villageEvents?.length)) return null;

    const ariaLabel = "Village Events";
    return (
      <StyledSection aria-labelledby={ariaLabel}>
        <PageWidth>
          <p className="h3">{title}</p>
          <Container width="100%" flexDirection="column">
            <Container flexDirection="column" rowGap="20px">
              {villageEvents?.map((event, index) => {
                if (event == null) return null;

                return <EventCard key={index} data={event} />;
              })}
            </Container>
          </Container>
        </PageWidth>
      </StyledSection>
    );
  }

  if (relatedContent === "news") {
    return <RelatedNews title={title} />;
  }

  return null;
}

const StyledSection = styled(Section)`
  .h3 {
    font-size: ${fontSizes.h1.default}px;
    font-weight: ${fontWeights.extrabold};
    font-family: ${fontFamilies.tobias}, Arial;
    margin-bottom: 60px;
  }

  ${mediaQuery.tabletDown} {
    flex-direction: column;
    justify-content: flex-start;
    width: 80%;
    margin: auto;
    margin-bottom: 40px;

    .h3 {
      margin-bottom: 20px;
    }
  }
  ${mediaQuery.mobileDown} {
    .h3 {
      font-size: ${fontSizes.h1.mobile}px;
    }
  }
`;
