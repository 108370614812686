import { Container, DynamicsFormContainer, P, Pill, Section } from "@util/standard";
import { DataLayerContactForm, isInfoPackForm } from "@util/types";
import { Form, RadioGroup, StyledSubmitButton } from "@styles/formStyles";
import { Maybe, SanityVillage } from "@graphql-types";
import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from "react";
import { bindInfoPackToDynamics, isBrowser } from "@util/helper";

import { useDynamicsFormLoad, useHasMounted } from "@util/hooks";
import {
  sendFormFill,
  sendInfoPackPurchase,
  sendInfoPackViewCart,
  sendPersonalisation,
  useFormData,
} from "@util/datalayer";

import CoreFormFields from "./coreFormFields";
import FormSuccessText from "./formSuccessText";
import { SelectedVillageInfoPack } from "@state/types";
import { INFO_PACK_FORM_ID, assets } from "@util/constants";
import { useDrawer } from "@accessible/drawer";
import { useStore } from "@state/store";

interface Props {
  secondaryVillageColour?: Maybe<string> | undefined;
  selectedVillage?: Maybe<SanityVillage>;
  isDrawer?: boolean;
  isGeneralInfoPack?: boolean;
}

/**
 * Form for booking a tour of a village
 *
 * @param param0
 * @returns Contact form with hidden marketo form
 */
const InfoPackForm = ({
  secondaryVillageColour,
  selectedVillage,
  isDrawer,
  isGeneralInfoPack = true,
}: Props) => {
  const ref = useRef<HTMLElement>(null);
  const drawerId = isDrawer && useDrawer();
  const [formLoaded, setFormLoaded] = useState(false);
  const { pageColor, setSelectedVillageInfoPacks, selectedVillageInfoPacks, lead, setLead } =
    useStore();

  const [enquiry, setEnquiry] = useState("881990000");
  const [opt, setOpt] = useState<string | null>();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const formState = useFormData("infopack");
  if (!isInfoPackForm("infopack", formState.formData)) return null;
  const { formData, setFormData } = formState;

  const handleClose = () => {
    if (isDrawer && drawerId) {
      drawerId.close();
    }
  };

  useEffect(() => {
    if (lead && lead.metlifeEnquiry) {
      setFormData({ ...formData, metlifeEnquiry: lead.metlifeEnquiry });
      setEnquiry(lead.metlifeEnquiry);
    }
  }, [lead]);

  const handleEnquiryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEnquiry(event.target.value);
    setFormData({ ...formData, metlifeEnquiry: event.target.value });
    setLead({ ...lead, metlifeEnquiry: event.target.value });
  };

  const handleOptChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOpt(event.target.value);
    setFormData({ ...formData, optInMetlifecare: event.target.value });
  };

  const handleRemove = (toRemove: SelectedVillageInfoPack) => () => {
    const removedGuides = selectedVillageInfoPacks.filter(
      guide => guide.villageCode != toRemove.villageCode,
    );
    setSelectedVillageInfoPacks(removedGuides);
  };

  // DYNAMICS WORK

  const mounted = useHasMounted();

  useDynamicsFormLoad(mounted);

  const dynamicsFormRef = useRef(null);

  const handleDynamicsSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    const allSelectedRegions = selectedVillageInfoPacks
      .map(guide => guide?.region?.dynamicsValue)
      .filter(item => item != undefined);
    const regionSet = new Set(allSelectedRegions);
    const regionSetArray = Array.from(regionSet);
    const regionsToSend = regionSetArray.length > 1 ? "" : regionSetArray[0];
    console.log(regionsToSend);

    await bindInfoPackToDynamics(dynamicsFormRef, formData, selectedVillage, regionsToSend);
    try {
      const dataLayer: DataLayerContactForm = {
        village_id: selectedVillage ? selectedVillage.villageCode : undefined,
        village_name: selectedVillage ? selectedVillage.title : undefined,
        region: selectedVillage?.region?.dynamicsValue ?? undefined,
        form_step: "success",
        form_name: "Village Info Pack Form",
        document_type: "Village Info Pack",
        document_name: selectedVillage
          ? selectedVillage.dynamicsVillageCode
          : formData.metlife_villages_of_interest_brochure,
        success_step: true,
        comms_consent: formData.optInMetlifecare ?? false,

        lead_generated: true,
        enquiry_type: "Sales",
        requesting_for: formData?.metlifeEnquiry,
      };

      sendInfoPackPurchase(selectedVillageInfoPacks);
      setFormSubmitted(true);
      setSelectedVillageInfoPacks([]);
      sendFormFill(dataLayer);
    } catch (err) {
      console.log(err);
    }
    // @ts-ignore
  };

  if (isBrowser() && window.MsCrmMkt?.MsCrmFormLoader) {
    // @ts-ignore
    window.MsCrmMkt.MsCrmFormLoader.on("afterFormLoad", function () {
      setFormLoaded(true);
    });
  }

  return (
    <Section marginOverride="20px 0px">
      <DynamicsFormContainer ref={dynamicsFormRef} data-form-block-id={INFO_PACK_FORM_ID} />
      {!formSubmitted && (
        <>
          <Container flexDirection="column">
            <>
              {selectedVillageInfoPacks.length > 0 && <P fontWeight="bold">Selected villages:</P>}

              <Container columnGap="5px" flexWrap="wrap" margin="0 0 20px 0">
                {!selectedVillage && (
                  <Container columnGap="5px" flexWrap="wrap" margin="0 0 20px 0">
                    {selectedVillageInfoPacks?.map((guide, index) => (
                      <SelectedGuidePill key={index} guide={guide} handleRemove={handleRemove} />
                    ))}
                  </Container>
                )}
                {selectedVillage && <P>{selectedVillage.title}</P>}
              </Container>
            </>
          </Container>
          <Form id="dynamics-info-pack" onSubmit={handleDynamicsSubmit}>
            <Container flexDirection="column">
              <P fontWeight="bold" margin="0 0 10px 0">
                Who is this info pack for?*
              </P>
              <Container flexDirection="row" flexWrap="wrap" rowGap="20px" margin="0 0 40px 0">
                <RadioGroup isSelected={enquiry == "881990000"}>
                  <input
                    id="myself"
                    type="radio"
                    name="enquire"
                    value="881990000"
                    checked={enquiry == "881990000"}
                    onChange={handleEnquiryChange}
                  />
                  <label htmlFor="myself">Myself</label>
                </RadioGroup>
                <RadioGroup isSelected={enquiry == "881990001"}>
                  <input
                    id="myPartner"
                    type="radio"
                    name="enquire"
                    value="881990001"
                    checked={enquiry == "881990001"}
                    onChange={handleEnquiryChange}
                  />
                  <label htmlFor="myPartner">My partner & I</label>
                </RadioGroup>
                <RadioGroup isSelected={enquiry == "881990002"}>
                  <input
                    id="family"
                    type="radio"
                    name="enquire"
                    value="881990002"
                    checked={enquiry == "881990002"}
                    onChange={handleEnquiryChange}
                  />
                  <label htmlFor="family">A family member / A friend</label>
                </RadioGroup>
              </Container>
            </Container>
            <Container width="100%" isMobileColumn>
              <Container
                flexDirection="column"
                margin="0px 15px 0px 0px"
                isMobileColumn
                mobileMargin="0px"
                width="100%"
              >
                <CoreFormFields
                  formData={formData}
                  setFormData={setFormData}
                  isDrawer
                  isInfoPack
                  isGeneralInfoPack={isGeneralInfoPack}
                />
              </Container>
            </Container>
            <Container flexDirection="column">
              <p>Would you like to keep updated with Metlifecare news and events?*</p>
              <Container width="80%" flexWrap="wrap" rowGap="20px">
                <RadioGroup isSelected={opt == "true"}>
                  <input
                    id="yes"
                    type="radio"
                    name="opt"
                    value="true"
                    checked={opt == "true"}
                    onChange={handleOptChange}
                    required
                  />
                  <label htmlFor="yes">Yes, please</label>
                </RadioGroup>
                <RadioGroup isSelected={opt == "false"}>
                  <input
                    id="no"
                    type="radio"
                    name="opt"
                    value="false"
                    checked={opt == "false"}
                    onChange={handleOptChange}
                    required
                  />
                  <label htmlFor="no">No, thanks</label>
                </RadioGroup>
              </Container>
            </Container>

            <Container
              margin="20px 0 100px 10px"
              flexDirection="column"
              width="100%"
              isTabletColumn
            >
              <P fontWeight="normal">
                Your personal information will be used for the purpose of providing you with
                information about Metlifecare and will not be given to any third parties or used for
                any other purposes.
              </P>
              <a
                href="/privacy-policy"
                style={{ textDecoration: "underline", marginBottom: "20px" }}
                target="_blank"
              >
                View our Privacy Policy
              </a>
              <strong>* are required fields</strong>
              <StyledSubmitButton
                pageColour={secondaryVillageColour ?? pageColor}
                type="submit"
                value={"Order your info pack"}
                overrideMargin="30px 0 0 0 "
                enabled
              />
            </Container>
          </Form>
        </>
      )}

      {formSubmitted && (
        <FormSuccessText
          handleDrawerClose={isDrawer ? handleClose : undefined}
          formType="infoPack"
        />
      )}
    </Section>
  );
};

export default InfoPackForm;

interface PillProps {
  guide: SelectedVillageInfoPack;
  handleRemove: (toRemove: SelectedVillageInfoPack) => () => void;
}

const SelectedGuidePill = ({ guide, handleRemove }: PillProps) => {
  return (
    <Pill onClick={handleRemove(guide)}>
      <span>{guide.villageTitle}</span>
      <img src={assets.close} />
    </Pill>
  );
};
