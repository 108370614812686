import React, { createContext } from "react";

import { Container } from "@util/standard";
import { Maybe, SanityForm } from "@graphql-types";
import { Block, DrawerTarget, ErrorMsg } from "@shared";
import { useDetermineVariantBlock } from "@util/logicHooks";
import { isSanityGlobalBlockRef } from "@util/types";
import { useStore } from "@state/store";
import { GetYourSelected } from "@components/forms/shared";
import MetguideForm from "@components/forms/metguideForm";
import { Drawer } from "@accessible/drawer";
interface Props {
  data: Maybe<Maybe<any>[]> | undefined;
  guides?: SanityForm[];
}

interface ContextProps {
  guides: SanityForm[];
}

export const BlocksContext = createContext<ContextProps | null>(null);

/**
 * Determines which blocks are rendered for global blocks
 *
 * @returns Block to render
 */
export default function GlobalBlocks({ data, guides = [] }: Props) {
  const { pageColor, getSelectedMetguides } = useStore();
  const selectedGuides = getSelectedMetguides();
  if (data == null) return <ErrorMsg data={data} msg="Error for global block" />;

  return (
    <Drawer>
      <BlocksContext.Provider value={{ guides }}>
        {selectedGuides?.length > 0 && (
          <GetYourSelected type="guide(s)" length={selectedGuides.length} pageColor={pageColor} />
        )}
        <DrawerTarget
          removeHeaderTransform
          title="Get your selected Metguides"
          drawerId="guides-form"
        >
          <Container flexDirection="column">
            <MetguideForm isDrawer />
          </Container>
        </DrawerTarget>
        <Container display="block" width="100%" margin="auto" className="block-wrapper">
          {data.map((block, index) => {
            if (block == null) return null;

            if (isSanityGlobalBlockRef(block)) {
              if (!Boolean(block?.blockRefs?.length)) return null;
              const { determinedBlock } = useDetermineVariantBlock(block?.blockRefs!);
              if (determinedBlock?.block == null) return null;

              return (
                <div style={{ marginBottom: block?.bottomMargin ?? 0 }}>
                  <Block key={index} data={determinedBlock?.block[0]} />
                </div>
              );
            }

            return (
              <div style={{ marginBottom: block?.bottomMargin ?? 0 }}>
                <Block key={index} data={block} />
              </div>
            );
          })}
        </Container>
      </BlocksContext.Provider>
    </Drawer>
  );
}
