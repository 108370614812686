import React from "react";
import styled from "styled-components";

import { assets } from "@util/constants";
import { Container } from "@util/standard";

interface Props {
  msg: string;
  data: any;
}

export default function ErrorMsg({ msg, data }: Props) {
  if (process.env.GATSBY_NODE_ENV == null) return null;

  if (["production", "staging"].includes(process.env.GATSBY_NODE_ENV)) {
    return (
      <Container width="100%" height="90vh" justifyContent="center" alignItems="center">
        <img src={assets.logo} />
      </Container>
    );
  }

  return (
    <>
      <Msg>{msg}</Msg>
      {data && <pre>{JSON.stringify(data, null, 4)}</pre>}
    </>
  );
}

const Msg = styled.h3`
  color: red;
`;
