import React from "react";
import GoogleMapReact from "google-map-react";

import { MarkerProps } from "@components/mapMarker";

interface Props {
  children: React.ReactNode;
  mapCenter?: MarkerProps;
}

const mapOptions = {
  fullscreenControl: false,
  zoomControl: true,
  draggable: true,
  draggableCursor: "initial",
};

export default function GoogleMap({
  children,
  mapCenter = {
    lat: -36.8813254,
    lng: 174.7995514,
  },
}: Props) {
  return (
    <GoogleMapReact
      center={mapCenter as GoogleMapReact.Coords}
      bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_API ?? "" }}
      defaultZoom={14}
      options={mapOptions}
    >
      {children}
    </GoogleMapReact>
  );
}
