export const BUTTON_HEIGHT = 46;
export const HEADER_HEIGHT = 80;
export const HEADER_OFFSET = 20;
export const HEADER_OFFSET_TOP = 40;
export const HEADER_MOBILE_OFFSET = 20;
export const DESKTOP_MAX_WIDTH = "1200px";

// screen sizes
export const SMALLER_MOBILE = 400;
export const MOBILE_BREAKPOINT = 600;
export const IPAD_BREAKPOINT = 768;
export const TABLET_BREAKPOINT = 1000;
export const IPAD_PRO_BREAKPOINT = 1024;
export const TABLET_LANDSCAPE_BREAKPOINT = 1200;
export const SMALL_LAPTOP_BREAKPOINT = 1280;
export const LAPTOP_BREAKPOINT = 1441;
export const TWENTY_INCH_SCREEN = 1600;
export const IMAC_BREAKPOINT = 1921;
export const DEFAULT_OFFSET = 120;
export const MOBILE_OFFSET = 60;

export const CONTACT_US_FORM_ID = "7b2fcc24-10f9-ed11-8f6e-00224817f8ed";
export const VILLAGE_CONTACT_FORM_ID = "64d87bc7-279d-ed11-aad1-00224814fd52";
export const INFO_PACK_FORM_ID = "5d595bd7-289d-ed11-aad1-00224814fd52";
export const METGUIDE_FORM_ID = "df2b1b29-289d-ed11-aad1-00224814fd52";
export const CARE_FORM_ID = "1c3d24cc-77f0-ed11-8849-000d3a6a9bd9";
export const REGISTER_INTEREST_FORM = "17b84ac6-9058-ee11-be6f-000d3ae1a940";
// export const REGISTER_INTEREST_FORM = "6949cad0-145c-ee11-8def-000d3a6b19df";
export const BOOK_A_TOUR_FORM_ID = "920b9f05-3d63-ee11-8df0-000d3ae1ad84";

export const colors = {
  transparent: "transparent",
  white: "#fff",
  black: "#000",
  flightBlue: "#24468E",
  errorRed: "#FF0000",
  navy: "#262746",
  cream: "#F6F1EA",
  cyan: "#3FD6D4",
  pink: "#FD8BA5",
  yellow: "#FFBF13",
  green: "#00D283",
  purple: "#A575FF",
  orange: "#FF6318",
  red: "#FF505E",
  blue: "#2AC0DB",
};

export const fontWeights = {
  normal: 400,
  semibold: 600,
  bold: 700,
  extrabold: 800,
};

export const PRIMARY_COLOR = colors.navy;
export const SECONDARY_COLOR = colors.cream;

export const fontFamilies = {
  tobias: "Tobias",
  beausite: "Beausite",
};

export const fontSizes = {
  p: { default: 16, mobile: 15 },
  h1: { default: 50, mobile: 32 },
  h2: { default: 26, mobile: 26 },
  h3: { default: 50, mobile: 32 },
  h4: { default: 20, mobile: 20 },
  span: { default: 16, mobile: 15 },
};

export const colorsRGB = {
  flightBlue: (opacity?: number) => `rgba(0, 28, 168, ${opacity ?? "0.4"})`,
  black: (opacity?: number) => `rgba(0, 0, 0, ${opacity ?? "0.2"})`,
  white: (opacity?: number) => `rgba(255, 255, 255, ${opacity ?? "0.3"})`,
  navy: (opacity?: number) => `rgba(38, 39, 70, ${opacity ?? "0.2"})`,
};

export const pages = {
  home: "/",
  aboutUs: "/about-us",
  contactUs: "/contact-us",
  search: "/search",
  notFound: "/404",
};

// define paths to asset
export const assets = {
  goToTop: require("@static/assets/goToTop.svg").default,
  tick: require("@static/assets/tick.svg").default,
  whiteTick: require("@static/assets/white-tick.svg").default,
  circleArrowFill: require("@static/assets/circleArrowFill.svg").default,
  chervon: require("@static/assets/chervon.svg").default,
  chevronRight: require("@static/assets/chevronRight.svg").default,
  chevronWhite: require("@static/assets/chervonWhite.svg").default,
  hamburger: require("@static/assets/hamburger.svg").default,
  close: require("@static/assets/close.svg").default,
  closeWhite: require("@static/assets/closeWhite.svg").default,
  search: require("@static/assets/search.svg").default,
  defaultNews: require("@static/assets/defaultNews.svg").default,
  yes: require("@static/assets/yes.svg").default,
  no: require("@static/assets/no.svg").default,
  downArrowBlue: require("@static/assets/downArrowBlue.svg").default,
  emptySelect: require("@static/assets/emptySelect.svg").default,
  selectedIcon: require("@static/assets/selectedIcon.svg").default,
  emptyNavy: require("@static/assets/emptyNavy.svg").default,
  notFound: require("@static/assets/404.svg").default,
  nzMap: require("@static/assets/nzMap.svg").default,
  pin: require("@static/assets/pin.svg").default,
  logo: require("@static/assets/logo.svg").default,
  bath: require("@static/assets/bath.svg").default,
  bed: require("@static/assets/bed.svg").default,
  carpark: require("@static/assets/carpark.svg").default,
  preferencesIcon: require("@static/assets/preferences.svg").default,
};

export const buttonTheme = {
  base: {
    bg: `${colors.navy}`,
    text: `${colors.white}`,
    border: `${colors.navy}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.navy}`,
    hoverText: `${colors.navy}`,
  },
  inverse: {
    bg: `${colors.transparent}`,
    text: `${colors.navy}`,
    border: `${colors.navy}`,
    hoverBg: `${colors.navy}`,
    hoverBorder: `${colors.navy}`,
    hoverText: `${colors.white}`,
  },
  colored: {
    bg: `${colors.transparent}`,
    text: `${colors.navy}`,
    border: `${colors.transparent}`,
    hoverBg: `${colors.transparent}`,
    hoverBorder: `${colors.navy}`,
    hoverText: `${colors.white}`,
  },
  white: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.white}`,
    hoverText: `${colors.navy}`,
  },
  cream: {
    bg: `${colors.cream}`,
    text: `${colors.navy}`,
    border: `${colors.cream}`,
    hoverBg: `${colors.cream}`,
    hoverBorder: `${colors.navy}`,
    hoverText: `${colors.navy}`,
  },
  whiteInverse: {
    bg: `${colors.transparent}`,
    text: `${colors.white}`,
    border: `${colors.white}`,
    hoverBg: `${colors.white}`,
    hoverBorder: `${colors.navy}`,
    hoverText: `${colors.navy}`,
  },
};

export const breakPoint = {
  mobile: MOBILE_BREAKPOINT,
  ipad: IPAD_BREAKPOINT,
  ipadPro: IPAD_PRO_BREAKPOINT,
  tablet: TABLET_BREAKPOINT,
  tabletLandscape: TABLET_LANDSCAPE_BREAKPOINT,
  smallLaptop: SMALL_LAPTOP_BREAKPOINT,
  laptop: LAPTOP_BREAKPOINT,
  twenty: TWENTY_INCH_SCREEN,
};

export const mediaQueryWidth = {
  laptopUp: `(min-width: ${breakPoint.laptop + 1}px)`,
  laptopDown: `(max-width: ${breakPoint.laptop}px)`,
  twentyDown: `(max-width: ${breakPoint.twenty}px)`,
  smallLaptopUp: `(min-width: ${breakPoint.smallLaptop + 1}px)`,
  smallLaptopDown: `(max-width: ${breakPoint.smallLaptop}px)`,
  tabletUp: `(min-width: ${breakPoint.tablet + 1}px)`,
  tablet: `(min-width: ${breakPoint.tablet + 1}px) and (max-width: ${breakPoint.laptop}px)`,
  tabletDown: `(max-width: ${breakPoint.tablet}px)`,
  tabletLandScapeDown: `(max-width: ${breakPoint.tabletLandscape}px)`,
  tabletLandScapeUp: `(min-width: ${breakPoint.tabletLandscape + 1}px)`,
  ipadProUp: `(min-width: ${breakPoint.ipadPro + 1}px)`,
  ipadProDown: `(max-width: ${breakPoint.ipadPro}px)`,
  ipadUp: `(min-width: ${breakPoint.ipad + 1}px)`,
  ipad: `(min-width: ${breakPoint.ipad + 1}px) and (max-width: ${breakPoint.tablet}px)`,
  ipadDown: `(max-width: ${breakPoint.ipad}px)`,
  mobileUp: `(min-width: ${breakPoint.mobile + 1}px)`,
  mobileDown: `(max-width: ${breakPoint.mobile}px)`,
};

const m = `@media only screen and`;
export const mediaQuery = {
  mobileDown: `${m} ${mediaQueryWidth.mobileDown}`,
  mobileUp: `${m} ${mediaQueryWidth.mobileUp}`,
  ipadDown: `${m} ${mediaQueryWidth.ipadDown}`,
  ipad: `${m} ${mediaQueryWidth.ipad}`,
  ipadUp: `${m} ${mediaQueryWidth.ipadUp}`,
  tabletDown: `${m} ${mediaQueryWidth.tabletDown}`,
  tablet: `${m} ${mediaQueryWidth.tablet}`,
  tabletLandScapeDown: `${m} ${mediaQueryWidth.tabletLandScapeDown}`,
  tabletLandScapeUp: `${m} ${mediaQueryWidth.tabletLandScapeUp}`,
  tabletUp: `${m} ${mediaQueryWidth.tabletUp}`,
  smallLaptopDown: `${m} ${mediaQueryWidth.smallLaptopDown}`,
  smallLaptopUp: `${m} ${mediaQueryWidth.smallLaptopUp}`,
  laptopDown: `${m} ${mediaQueryWidth.laptopDown}`,
  laptopUp: `${m} ${mediaQueryWidth.laptopUp}`,
  ipadProUp: `${m} ${mediaQueryWidth.ipadProUp}`,
  ipadProDown: `${m} ${mediaQueryWidth.ipadProDown}`,
  twentyDown: `${m} ${mediaQueryWidth.twentyDown}`,
};

// const mobileBrowser = "@media screen and ";
export const mobileOSQueries = {
  iosMobile: `@supports (-webkit-touch-callout: none)`,
  androidMobile: `@supports not (-webkit-touch-callout: none)`,
};

export const space = {
  none: 0,
  tiny: 5,
  xxs: 15,
  xs: 30,
  s: 50,
  m: 75,
  l: 100,
  xl: 130,
  xxl: 175,
  xxxl: 300,
};

export const villageSubPagePaths = (baseSlug: string) => {
  const slugWithPrefix = `our-retirement-villages/${baseSlug}`;
  return {
    overview: { slug: { current: `${slugWithPrefix}` } },
    location: { slug: { current: `${slugWithPrefix}/location` } },
    lifeHere: { slug: { current: `${slugWithPrefix}/life-here` } },
    care: { slug: { current: `${slugWithPrefix}/care` } },
    homeOptions: { slug: { current: `${slugWithPrefix}/home-options` } },
    contact: { slug: { current: `${slugWithPrefix}/contact` } },
    independentLiving: { slug: { current: `${slugWithPrefix}/independent-living` } },
  };
};

export const NO_SEARCH_RESULTS_MESSAGE = "No results found with search query or filters provided.";

export const animations = {
  pulse: (prevTransform?: string) => `  @keyframes pulse {
    0% {
      transform: scale(1) ${prevTransform};
    }
    30% {
      transform: scale(1.2) ${prevTransform} translateX(-10%);
    }
    50% {
      transform: translateY(1) ${prevTransform};
    }
    100% {
      transform: translateY(1) ${prevTransform};
    }
  }
  animation-name: pulse;
  animation-timing-function: ease;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;`,
};
export const LEAD_CAPTURE_SET = [
  "prospect_only",
  "prospect_and_partner",
  "enquirer_only",
  "starting",
  "active",
  "ready",
  "ILU",
  "SA",
  "CARE",
];
