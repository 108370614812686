import {
  BookATourForm,
  CareForm,
  ContactForm,
  GroqVillageData,
  InfoPackForm,
  MetguideForm,
} from "./types";
import { Dispatch, RefObject, SetStateAction } from "react";
import Color from "color";
import { FacetGroup, LeadCapture, SelectedMetguides, VillagePageContext } from "@state/types";
import { Maybe, SanityEvent, SanityForm, SanityNews, SanityVillage } from "@graphql-types";

import algoliasearch from "algoliasearch/lite";
import moment from "moment";
import { REGION_OF_INTEREST } from "./dynamicsValues";
import { colors } from "./constants";

export const isBrowser = () => typeof window !== "undefined";

export const darkOrLight = (color: string) => {
  const colorObj = Color(color);

  if (colorObj.isLight()) {
    return colors.black;
  } else {
    return colors.white;
  }
};

export const openInNewTab = (url: string) => {
  if (!url) return;
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export function arraysEquality(array1: any[], array2: any[]) {
  return array1.length === array2.length && array1.every((value, index) => value === array2[index]);
}

export function objectEquality(object1: any, object2: any) {
  return Object.keys(object1).every(key => object1[key] === object2[key]);
}

export function splitArrayIntoChunks(array: any[], split: number) {
  const chunks = array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / split);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return chunks;
}

export function scrollWindow(position?: number) {
  if (!isBrowser()) return;

  window.scrollTo({
    behavior: "smooth",
    top: position ?? 0,
  });
}

export function formatAddress(data: GroqVillageData | SanityVillage) {
  const { street, suburb, city, postCode, googleMyBusinessUrl } = data;
  const string = `${street}, ${suburb}, ${city}, ${postCode}`;
  const url = googleMyBusinessUrl || `https://maps.google.com/?q=${string}`;

  return { string, url };
}

export function appendMarketoScript(
  baseUrl: string,
  setScriptLoaded: Dispatch<SetStateAction<boolean>>,
) {
  const marketoScriptId = "mktoForms2";
  if (!isBrowser()) return;

  const script = document.createElement("script");
  script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
  script.id = marketoScriptId;
  script.type = "text/javascript";
  script.async = true;

  // @ts-ignore
  // window.MktoForms2.whenReady((form: any) => setScriptLoaded(true));
  script.onload = () => setScriptLoaded(true);
  document.body.appendChild(script);
}

export function returnFormData(
  formType: string,
  villageCode: Maybe<string> | undefined,
  lead?: LeadCapture,
  selectedMetguides?: SelectedMetguides[],
  selectedVillageCodes?: string[],
  isMarketo?: boolean,
) {
  // const metlifeEnquiryLead = lead?.metlifeEnquiry ? lead?.metlifeEnquiry : "prospect_only";
  // const metlifeEnquiryLead = isMarketo ? "prospect_only" : "881990000";

  const metlifeEnquiryLead = () => {
    // if (lead?.metlifeEnquiry) {
    //   return lead?.metlifeEnquiry;
    // }
    if (isMarketo) {
      return "prospect_only";
    }
    return "881990000";
  };

  if (formType === "metguide") {
    const guidesArray = selectedMetguides?.map(guide => guide.guidePDFName);

    const returnType: MetguideForm = {
      FirstName: "",
      LastName: "",
      Phone: "",
      Email: "",
      metlife_metguide_name: guidesArray ? JSON.stringify(guidesArray) : "",
      metlife_stated_region_of_interest: "NULL",
      metlifeEnquiry: metlifeEnquiryLead(),
      optInMetlifecare: null,
    };

    return returnType;
  }
  if (formType === "infopack") {
    const formattedVillages = selectedVillageCodes?.map(guide => `'${guide}'`);

    const returnType: InfoPackForm = {
      FirstName: "",
      LastName: "",
      Phone: "",
      Email: "",
      metlifeEnquiry: metlifeEnquiryLead(),
      optInMetlifecare: null,
      metlife_villages_of_interest_brochure: selectedVillageCodes
        ? JSON.stringify(selectedVillageCodes)
        : "",
      metlife_stated_region_of_interest: "NULL",
      metlife_do_not_integrate: "yes",
    };

    return returnType;
  }

  if (formType === "bookatour") {
    const returnType: BookATourForm = {
      FirstName: "",
      LastName: "",
      Phone: "",
      Email: "",
      metlifeEnquiry: "prospect_only",
      optInMetlifecare: null,
      metlife_villages_of_interest_brochure: "",
      metlife_stated_region_of_interest: "",
      metlife_book_a_tour_date: "",
      metlife_book_a_tour_ampm: "",
      metlife_book_a_tour_living_arrangements: "",
    };
    return returnType;
  }

  if (formType === "contact") {
    const returnType: ContactForm = {
      FirstName: "",
      LastName: "",
      Phone: "",
      Email: "",
      metlifeEnquiry: metlifeEnquiryLead(),
      optInMetlifecare: null,
      metlife_contact_us_reason: isMarketo ? "Sales" : "881990000",
      metlife_stated_region_of_interest: "",
      MktoPersonNotes: "",
    };
    return returnType;
  }

  if (formType === "care") {
    const returnType: CareForm = {
      FirstName: "",
      LastName: "",
      Phone: "",
      Email: "",
      metlifeEnquiry: metlifeEnquiryLead(),
      optInMetlifecare: null,
      metlife_contact_us_reason: isMarketo ? "Sales" : "881990000",
      metlife_stated_region_of_interest: "",
      needs_assessment: "",
      level_of_care_known: "",
    };
    return returnType;
  }

  //Village contact
  const returnType: ContactForm = {
    FirstName: "",
    LastName: "",
    Phone: "",
    Email: "",
    metlifeEnquiry: metlifeEnquiryLead(),
    optInMetlifecare: null,
    metlife_contact_us_reason: isMarketo ? "Sales" : "881990000",
    MktoPersonNotes: "",
    metlife_villages_of_interest_brochure: villageCode,
    metlifecare_living_options: "",
  };
  return returnType;
}

export const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY as string,
);

export const algoliaSearchClient = {
  search(requests: any[]) {
    const shouldSearch = requests.some(({ params }) => params?.query?.length > 0);
    const hasFacetFilters = requests.some(({ params }) => params?.facetFilters?.length > 0);

    if (shouldSearch || hasFacetFilters) {
      return algoliaClient.search(requests);
    }

    return Promise.resolve({
      results: [{ hits: [] }],
    });
  },
};

export function uniqBy(items: any[], property: any) {
  const seen: any = {};

  return items.filter(item => {
    const val = item[property];

    if (seen[val]) {
      return false;
    } else {
      seen[val] = true;
      return true;
    }
  });
}

export function getFacetGroup(facets: FacetGroup | undefined, attribute: string) {
  if (facets == null || attribute == "") return undefined;
  const facetGroup = facets[attribute];
  if (facetGroup == null) return undefined;
  const initFacets = Object.keys(facetGroup).map((key, index) => {
    return {
      count: facetGroup[key] as number,
      label: key,
      isRefined: false,
    };
  });
  return initFacets;
}

/**
 * Check form id before sending to Marketo
 *
 * @param marketoForm
 * @param currentId
 */
export function submitMarketoForm(marketoForm: any, currentId: string) {
  if (!isBrowser() || marketoForm == null) return;
  const formId = marketoForm?.getId();

  // TODO: set loading state here and validate to show on frontend for submit status
  console.log(`Submitting form ${formId}`);
  console.log(`Current form id ${currentId}`);

  if (currentId == formId) marketoForm?.submit();
}

export const sortArticles = (articles: SanityNews[], sortValue: string) => {
  if (sortValue == null) return articles;

  const sortedArray = articles.sort((a, b) => {
    if (b.publishDate == null) return -1;

    const ADateTimeStamp = Number(moment(a.publishDate).format("X"));
    const BDateTimeStamp = Number(moment(b.publishDate).format("X"));

    if (sortValue === "Oldest") {
      if (ADateTimeStamp > BDateTimeStamp) {
        return 1;
      }
      if (ADateTimeStamp < BDateTimeStamp) {
        return -1;
      }
    }

    if (sortValue === "Latest") {
      if (ADateTimeStamp < BDateTimeStamp) {
        return 1;
      }
      if (ADateTimeStamp > BDateTimeStamp) {
        return -1;
      }
    }

    return 0;
  });
  return sortedArray;
};

export function findFormElements(ref: RefObject<HTMLFormElement | HTMLDivElement>) {
  if (ref.current) {
    const inputElements = Array.from(ref.current.querySelectorAll("input"));
    const selectElements = Array.from(ref.current.querySelectorAll("select"));
    const textAreaElements = Array.from(ref.current.querySelectorAll("textarea"));

    return [...inputElements, ...selectElements, ...textAreaElements];
  }
  return null;
}

export function bindCoreFormFields(
  elements: (HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement)[],
  formData: ContactForm | InfoPackForm | MetguideForm | CareForm | BookATourForm,
) {
  const { FirstName, LastName, Email, Phone } = formData;

  elements?.forEach(element => {
    if (element.id === "3f746946-34b4-442c-a677-e232cdd2bc40") element.value = FirstName;
    if (element.id === "e1dfc514-f301-4cb2-855a-4c8fa8331207") element.value = LastName;
    if (element.id === "7f685ebb-7c54-4cff-a1bc-772562d25c38") element.value = Email;
    if (element.id === "ac6a065d-364e-40d6-9a19-d9bf1ed4aa3e") element.value = Phone;
  });
}

export function dynamicsButtonClick(
  ref: RefObject<HTMLFormElement | HTMLDivElement>,
  name?: string,
) {
  if (ref.current == null) {
    console.log("Form ref is null");
    return null;
  }

  if (name) {
    const button = document.getElementsByName(name);
    console.log({ button });

    if (button) {
      return setTimeout(() => {
        console.log("starting button click");
        button[0].click();
        console.log("clicked button");
      }, 400);
    }
  }

  const submitButton = Array.from(ref.current.querySelectorAll("button"));

  if (submitButton && submitButton.length > 0) {
    console.log({ submitButton });
    console.log("clicked submit button");

    return submitButton[0].click();
  }
}

export function bindMetguideToDynamics(
  formRef: RefObject<HTMLDivElement>,
  formData: MetguideForm,
  form?: Maybe<SanityForm> | undefined,
) {
  if (formRef.current) {
    const elements = findFormElements(formRef);
    if (elements) {
      const {
        metlife_stated_region_of_interest,
        optInMetlifecare,
        metlifeEnquiry,
        metlife_metguide_name,
      } = formData;
      bindCoreFormFields(elements, formData);
      bindDynamicsHiddenFields(elements);
      const formatOptIn = Boolean(optInMetlifecare === "true");

      elements?.forEach(element => {
        const formatGuideNAmes =
          form !== null && form?.title ? `["${form?.title}"]` : `${metlife_metguide_name}`;
        if (element.id === "46821ff8-63ab-ed11-83fe-00224814f98a") element.value = formatGuideNAmes;
        if (element.id === REGION_OF_INTEREST) element.value = metlife_stated_region_of_interest;

        if (element.id === "7641a4b2-fff9-ed11-8f6e-00224817f864" && optInMetlifecare !== null)
          element.checked = !formatOptIn;
        if (metlifeEnquiry) {
          if (element.id == `ab1b0da7-729c-ed11-aad1-00224814fd52_${metlifeEnquiry}`) {
            element.checked = true;
            element.value = metlifeEnquiry;
          }
        }

        if (element.id === "2f97a2c7-9826-ee11-9965-000d3ae1ad84") {
          const prettyJSON = formatFormField({
            ...formData,
            metlife_metguide_name: formatGuideNAmes,
          });
          element.value = JSON.stringify(prettyJSON, null, 2);
        }

        return null;
      });

      dynamicsButtonClick(formRef);
      return true;
    }
  }
  throw new Error("Something went wrong when trying to submit the form.");
}

export function bindInfoPackToDynamics(
  formRef: RefObject<HTMLDivElement>,
  formData: InfoPackForm,
  village?: Maybe<SanityVillage> | undefined,
  region?: Maybe<string> | undefined,
) {
  if (formRef.current) {
    const elements = findFormElements(formRef);
    if (elements) {
      bindCoreFormFields(elements, formData);
      bindDynamicsHiddenFields(elements);

      const {
        metlife_stated_region_of_interest,
        optInMetlifecare,
        metlifeEnquiry,
        metlife_villages_of_interest_brochure,
      } = formData;

      console.log(JSON.stringify(formData, null, 2));

      const formatOptIn = Boolean(optInMetlifecare === "true");

      const villageCode = village?.dynamicsVillageCode ?? village?.villageCode;

      elements?.forEach(element => {
        const formatPackNames =
          village !== null && villageCode
            ? `['${villageCode}']`
            : `${metlife_villages_of_interest_brochure}`;

        if (element.id === "434a4bdf-63ab-ed11-83fe-00224814f98a") element.value = formatPackNames;
        if (element.id === REGION_OF_INTEREST) {
          if (region !== undefined) {
            element.value = region;
          } else if (metlife_stated_region_of_interest) {
            element.value = metlife_stated_region_of_interest;
          }
        }

        if (element.id === "7641a4b2-fff9-ed11-8f6e-00224817f864" && optInMetlifecare !== null)
          element.checked = !formatOptIn;

        if (metlifeEnquiry) {
          if (element.id == `ab1b0da7-729c-ed11-aad1-00224814fd52_${metlifeEnquiry}`) {
            element.checked = true;
            element.value = metlifeEnquiry;
          }
        }

        if (element.id === "2f97a2c7-9826-ee11-9965-000d3ae1ad84") {
          const prettyJSON = formatFormField({
            ...formData,
            metlife_villages_of_interest_brochure: formatPackNames,
          });
          element.value = JSON.stringify(prettyJSON, null, 2);
        }

        return null;
      });

      dynamicsButtonClick(formRef);
      return true;
    }
  }
  throw new Error("Something went wrong when trying to submit the form.");
}

export function bindContactForm(
  formRef: RefObject<HTMLDivElement>,
  formData: ContactForm,
  village?: Maybe<VillagePageContext> | undefined,
) {
  console.log({ formData, formRef, village });
  if (formRef.current) {
    const elements = findFormElements(formRef);
    console.log({ elements });

    if (elements) {
      const {
        metlife_stated_region_of_interest,
        optInMetlifecare,
        metlifeEnquiry,
        MktoPersonNotes,
        metlife_contact_us_reason,
        metlifecare_living_options,
      } = formData;

      bindDynamicsHiddenFields(elements);

      bindCoreFormFields(elements, formData);

      const formatOptIn = Boolean(optInMetlifecare === "true");
      elements?.forEach(element => {
        if (element.id === "434a4bdf-63ab-ed11-83fe-00224814f98a" && village && village.villageCode)
          element.value = village?.villageCode;
        if (element.id === "03fb9440-729c-ed11-aad1-00224814fd52") element.value = MktoPersonNotes;
        if (element.id === "058e30cb-719c-ed11-aad1-00224814fd52")
          if (metlife_contact_us_reason === "881990000C") element.value = "881990000";
          else element.value = metlife_contact_us_reason;
        if (element.id === REGION_OF_INTEREST && metlife_stated_region_of_interest)
          element.value = metlife_stated_region_of_interest;
        if (element.id === "7641a4b2-fff9-ed11-8f6e-00224817f864" && optInMetlifecare !== null)
          element.checked = !formatOptIn;
        if (metlifeEnquiry) {
          if (element.id == `ab1b0da7-729c-ed11-aad1-00224814fd52_${metlifeEnquiry}`) {
            element.checked = true;
            element.value = metlifeEnquiry;
          }
          if (element.id == `a1fa7e73-67f0-ed11-8849-000d3a6ad35b_${metlifeEnquiry}`) {
            element.checked = true;
            element.value = metlifeEnquiry;
          }
        }
        if (element.name === "cd0b0841-0ff9-ed11-8f6e-00224817f8ed") {
          if (metlife_contact_us_reason === "881990000C") {
            element.value = "881990001";
          }
        }
        if (element.id === "2f97a2c7-9826-ee11-9965-000d3ae1ad84") {
          const prettyJSON = formatFormField(formData);
          element.value = JSON.stringify(prettyJSON, null, 2);
        }
        return null;
      });
    }

    console.log({ elements });

    dynamicsButtonClick(formRef);
    return true;
  }
  throw new Error("Something went wrong when trying to submit the form.");
}

export function bindCareForm(
  formRef: RefObject<HTMLDivElement>,
  formData: CareForm,
  village?: Maybe<VillagePageContext> | undefined,
) {
  if (formRef.current) {
    const elements = findFormElements(formRef);
    if (elements) {
      const {
        metlife_stated_region_of_interest,
        optInMetlifecare,
        metlifeEnquiry,
        metlife_contact_us_reason,
        needs_assessment,
        level_of_care_known,
        metlife_villages_of_interest_brochure,
        MktoPersonNotes,
      } = formData;

      console.log({ metlife_stated_region_of_interest });

      bindDynamicsHiddenFields(elements);

      bindCoreFormFields(elements, formData);

      const formatOptIn = Boolean(optInMetlifecare === "true");
      elements?.forEach(element => {
        if (
          element.id === "1bf91707-0b9d-ed11-aad1-00224814fd52" &&
          metlife_villages_of_interest_brochure
        )
          element.value = metlife_villages_of_interest_brochure;
        if (element.id === "434a4bdf-63ab-ed11-83fe-00224814f98a")
          element.value = village?.dynamicsVillageCode ?? metlife_villages_of_interest_brochure;
        if (element.id === "058e30cb-719c-ed11-aad1-00224814fd52") element.value = "881990000";
        if (element.id === REGION_OF_INTEREST)
          element.value = village?.regionDynamicsValue ?? metlife_stated_region_of_interest;
        if (element.id === "7641a4b2-fff9-ed11-8f6e-00224817f864" && optInMetlifecare !== null)
          element.checked = !formatOptIn;

        if (metlifeEnquiry) {
          if (element.id == `ab1b0da7-729c-ed11-aad1-00224814fd52_${metlifeEnquiry}`) {
            element.checked = true;
            element.value = metlifeEnquiry;
          }
          if (element.id == `a1fa7e73-67f0-ed11-8849-000d3a6ad35b_${metlifeEnquiry}`) {
            element.checked = true;
            element.value = metlifeEnquiry;
          }
        }
        if (needs_assessment) {
          if (element.id === `382d384a-64f0-ed11-8849-000d3a6a9bd9_${needs_assessment}`) {
            element.checked = true;
            element.value = needs_assessment;
          }
        }
        if (level_of_care_known) {
          if (element.id === `6a8ec612-64f0-ed11-8849-000d3a6a9bd9_${level_of_care_known}`) {
            element.checked = true;
            element.value = level_of_care_known;
          }
        }
        if (element.id === "03fb9440-729c-ed11-aad1-00224814fd52") element.value = MktoPersonNotes;

        if (element.id === "2f97a2c7-9826-ee11-9965-000d3ae1ad84") {
          const prettyJSON = formatFormField(formData);
          element.value = JSON.stringify(prettyJSON, null, 2);
        }

        return null;
      });
    }

    console.log({ elements });

    dynamicsButtonClick(formRef, "submita4a70b5b-dd7b-7dd4-91ba-e1070ed483d6");
    return true;
  }
  throw new Error("Something went wrong when trying to submit the form.");
}

export function bindBookATourForm(
  formRef: RefObject<HTMLDivElement>,
  formData: BookATourForm,
  selectedVillageCode: Maybe<string> | undefined,
  selectedRion: Maybe<string> | undefined,
) {
  if (formRef.current) {
    const elements = findFormElements(formRef);
    if (elements) {
      const {
        metlife_stated_region_of_interest,
        optInMetlifecare,
        metlife_book_a_tour_ampm,
        metlife_book_a_tour_date,
        metlife_villages_of_interest_brochure,
        metlifeEnquiry,
      } = formData;

      bindDynamicsHiddenFields(elements);

      bindCoreFormFields(elements, formData);
      const formatOptIn = Boolean(optInMetlifecare === "true");

      elements?.forEach(element => {
        if (element.id === REGION_OF_INTEREST) {
          if (selectedRion) {
            element.value = selectedRion;
          } else if (metlife_stated_region_of_interest) {
            element.value = metlife_stated_region_of_interest;
          }
        }
        if (element.id === "434a4bdf-63ab-ed11-83fe-00224814f98a") {
          if (selectedVillageCode) {
            element.value = selectedVillageCode;
          } else if (metlife_villages_of_interest_brochure) {
            element.value = metlife_villages_of_interest_brochure;
          }
        }
        if (element.id === "5ad4cef1-0f9d-ed11-aad1-00224814fd52" && metlife_book_a_tour_date) {
          element.value = metlife_book_a_tour_date;
        }
        if (element.id === "e532d121-109d-ed11-aad1-00224814fd52" && metlife_book_a_tour_ampm) {
          element.value = metlife_book_a_tour_ampm;
        }
        if (element.id === "7641a4b2-fff9-ed11-8f6e-00224817f864" && optInMetlifecare !== null)
          element.checked = !formatOptIn;

        if (metlifeEnquiry) {
          if (element.id == `ab1b0da7-729c-ed11-aad1-00224814fd52_${metlifeEnquiry}`) {
            element.checked = true;
            element.value = metlifeEnquiry;
          }
        }

        if (element.id === "2f97a2c7-9826-ee11-9965-000d3ae1ad84") {
          const prettyJSON = formatFormField({
            ...formData,
            metlife_stated_region_of_interest:
              selectedRion || formData.metlife_stated_region_of_interest,
            metlife_villages_of_interest_brochure:
              selectedVillageCode || formData.metlife_villages_of_interest_brochure,
          });

          // console.log(JSON.stringify(prettyJSON, null, 2));
          element.value = JSON.stringify(prettyJSON, null, 2);
        }

        return null;
      });
    }

    dynamicsButtonClick(formRef);
    return true;
  }
  throw new Error("Something went wrong when trying to submit the form.");
}

export function bindDynamicsHiddenFields(
  elements: (HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement)[],
) {
  if (isBrowser()) {
    if (elements) {
      elements.forEach(element => {
        // UTM SOURCE
        if (element.id === "89f5283f-1696-ed11-aad0-002248143ca0") {
          element.value = getCookie("utm_source");
        }
        // UTM MEDIUM
        if (element.id === "ecf64252-1696-ed11-aad0-002248143ca0") {
          element.value = getCookie("utm_medium");
        }

        // UTM CAMPAIGN
        if (element.id === "f8ef395f-1696-ed11-aad0-002248143ca0") {
          element.value = getCookie("utm_campaign");
        }

        // UTM CONTENT
        if (element.id === "d8f66477-1696-ed11-aad0-002248143ca0") {
          element.value = getCookie("utm_content");
        }

        // UTM TERM
        if (element.id === "6805726b-1696-ed11-aad0-002248143ca0") {
          element.value = getCookie("utm_term");
        }

        // FBCLID
        if (element.id === "2dc05ee9-3496-ed11-aad1-00224817fb6a") {
          element.value = getCookie("fbclid");
        }

        // GCLID
        if (element.id === "ece40fd1-3496-ed11-aad1-00224817fb6a") {
          element.value = getCookie("gclid");
        }

        // REFERRER
        if (element.id === "2eb351f5-3496-ed11-aad1-00224817fb6a") {
          element.value = getCookie("referrer");
        }

        if (element.id === "a3e85df2-4dd3-ed11-a7c7-00224817f8ed") {
          element.value = getCookie("search");
        }
        if (element.id === "0e3d6ec8-9114-ee11-9cbd-000d3ae1a940") {
          element.value = getCookie("gclsrc");
        }
      });
    }
  }
}

export const formatUrlHiddenFields = () => {
  if (!isBrowser()) return null;

  const self = window.location.toString();
  const querystring = self.split("?");
  if (querystring.length > 1) {
    if (querystring.length > 1) {
      const pairs = querystring[1].split("&");
      const formatValues = pairs.map(pair => {
        const keyval = pair.split("=");

        if (keyval.length === 2) {
          return {
            name: keyval[0],
            value: keyval[1],
          };
        }
        return null;
      });
      return formatValues;
    }
    return null;
  }
  return null;
};
export function sortEventsByDate(events: SanityEvent[], sortValue: "earliest" | "latest") {
  if (sortValue == null) return events;

  const sortedArray = events.sort((a, b) => {
    if (b.dateTime == null) return -1;

    const ADateTimeStamp = Number(moment(a.dateTime).format("X"));
    const BDateTimeStamp = Number(moment(b.dateTime).format("X"));

    if (sortValue === "earliest") {
      if (ADateTimeStamp > BDateTimeStamp) {
        return 1;
      }
      if (ADateTimeStamp < BDateTimeStamp) {
        return -1;
      }
    }

    if (sortValue === "latest") {
      if (ADateTimeStamp < BDateTimeStamp) {
        return 1;
      }
      if (ADateTimeStamp > BDateTimeStamp) {
        return -1;
      }
    }

    return 0;
  });
  return sortedArray;
}

interface GetDirectionsLink {
  suburb: Maybe<string> | undefined;
  street: Maybe<string> | undefined;
  city: Maybe<string> | undefined;
  postCode: Maybe<string> | undefined;
}

export function getDirectionsLink({ suburb, street, city, postCode }: GetDirectionsLink) {
  if (suburb == null || street == null || city == null || postCode == null) return null;
  const directionBaseURL = "https://www.google.com/maps/dir/?api=1&destination=";
  const address = street + ", " + suburb + ", " + city + ", " + postCode;
  const encodedAddress = encodeURIComponent(address);
  return encodedAddress && directionBaseURL + encodedAddress;
}

export function getCookie(name: string): string {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return "";
}

export const formatFormField = (formData: any) => {
  const contactUsReason = () => {
    const contactUs = formData.metlife_contact_us_reason;
    if (contactUs == "881990000") return "I have an Independent living enquiry";
    if (contactUs === "881990001") return "I have a general enquiry";
    if (contactUs === "881990000C") return "I have a care home enquiry";
    return "I have a general enquiry";
  };

  const enquiringFor = () => {
    const enquiring = formData.metlifeEnquiry;
    if (enquiring === "881990000") return "Myself";
    if (enquiring === "881990001") return "My partner & I";
    if (enquiring === "881990002") return "A family member / A friend";
    return "Myself";
  };

  const nasc = () => {
    const na = formData.needs_assessment;
    if (na === "881990000") return "Not started";
    if (na === "881990001") return "In progress";
    if (na === "881990002") return "Approved";
    return "";
  };

  const levelofCare = () => {
    const care = formData.level_of_care_known;
    if (care === "881990000") return "Rest home";
    if (care === "881990001") return "Hospital";
    if (care === "881990002") return "Dementia";
    return "";
  };

  const amOrPm = formData.metlife_book_a_tour_ampm === "881990000" ? "AM" : "PM";
  const mappedData = {
    "First Name": formData.FirstName,
    "Last Name": formData.LastName,
    Email: formData.Email,
    Phone: formData.Phone,
    Message: formData.MktoPersonNotes ?? "",
    "Type of enquiry": contactUsReason(),
    "Who are you enquiring for": enquiringFor(),
    "Needs (NAS) Assessmen completed?": nasc(),
    "Level of care known?": levelofCare(),
    "Region of interest": formData.metlife_stated_region_of_interest ?? "",
    "Village of interest": formData.metlife_villages_of_interest_brochure ?? "",
    "Would you like to keep updated with Metlifecare news and events?": formData.optInMetlifecare,
    "Book a tour date": formData.metlife_book_a_tour_date ?? "",
    "Book a tour AM/PM": formData.metlife_book_a_tour_ampm ? amOrPm : "",
  };

  return mappedData;
};
