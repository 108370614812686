import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import { Maybe, SanityVariation } from "@graphql-types";
import { PageWidth, Section as StandardSection } from "@util/standard";
import { useStore } from "@state/store";
import { assets, colors, mediaQuery } from "@util/constants";
import { Color } from "@util/types";
import { MetlifeUnion } from "@state/types";
import { BlocksContent, ModalBox } from "@global";
import { usePersonalisationHook } from "@util/personalisationHooks";
import { useIsOnScreen } from "@util/hooks";
import { isBrowser } from "@util/helper";

interface Props {
  data: Maybe<SanityVariation> | undefined;
  color?: Maybe<string> | undefined;
  marginOverride?: string;
  location: "hero" | "fold" | "block";
}

const ariaLabel = "Qualifying question";

export default function VariationSelect({ data, color = "navy", location }: Props) {
  if (data == null) return null;
  const { noSelectionMessage, previousSelectionMessage } = data;

  const { pageColor } = useStore();
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const isOnScreen = useIsOnScreen(sectionRef, "10px");

  const {
    selectedAnswer,
    hasSubmitted,
    currentPersonalisation,
    handleAnswerSelect,
    handleCustomModalClose,
  } = usePersonalisationHook(data);

  return (
    <Section
      noMargins
      aria-labelledby={ariaLabel}
      color={(color as Color) ?? "navy"}
      id="qualifying-questions"
      ref={sectionRef}
    >
      <InnerContainer id="variation-select">
        {location === "block" && (
          <ModalBox
            handleCustomClose={handleCustomModalClose}
            setIsOpen={null}
            isOpen={Boolean(currentPersonalisation?.modalOpen ?? true) && isOnScreen}
            maxWidth="870px"
            hideClose
          >
            <VariationModal
              hasSubmitted={hasSubmitted}
              data={data}
              pageColor={pageColor}
              color={color}
              handleAnswerSelect={handleAnswerSelect}
              selectedAnswer={selectedAnswer}
              handleCustomClose={handleCustomModalClose}
            />
          </ModalBox>
        )}
        {location !== "hero" && (
          <span className="information-blocks">
            {currentPersonalisation?.selectedState === "selected" && (
              <BlocksContent data={previousSelectionMessage} />
            )}
            {currentPersonalisation?.selectedState === "dismissed" && (
              <BlocksContent data={noSelectionMessage} />
            )}
          </span>
        )}
      </InnerContainer>
    </Section>
  );
}

interface VariationModalProps {
  hasSubmitted: boolean;
  data: SanityVariation;
  pageColor: string;
  color: Maybe<string>;
  handleAnswerSelect: (id: boolean | MetlifeUnion) => void;
  selectedAnswer: boolean | MetlifeUnion | undefined;
  isHero?: boolean;
  hasClose?: boolean;
  handleCustomClose?: () => void;
  containerRef?: React.RefObject<HTMLDivElement> | null;
}

export const VariationModal = ({
  hasSubmitted,
  data,
  pageColor,
  color,
  handleAnswerSelect,
  selectedAnswer,
  isHero = false,
  hasClose = true,
  handleCustomClose,
}: VariationModalProps) => {
  if (data == null) return null;
  const { question, answers, thankYouMessage, questionHeading, _id } = data;

  const handleSelect = (e: React.MouseEvent<HTMLButtonElement>, marketoId: string) => {
    e.stopPropagation();

    handleAnswerSelect(marketoId as MetlifeUnion | boolean);
  };

  const handleClickOutside = (event: MouseEvent) => {
    const containerID = document.getElementById(_id ?? "");

    if (containerID && !containerID.contains(event.target as Node)) {
      handleCustomClose && handleCustomClose();
    }
  };

  useEffect(() => {
    if (!isBrowser()) return;
    requestAnimationFrame(() => {
      document.addEventListener("click", handleClickOutside);
    });

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <ModalInner
      isHero={isHero}
      id={_id ?? ""}
      onClick={() => {
        hasSubmitted && handleCustomClose && handleCustomClose();
      }}
    >
      {hasClose && (
        <span
          className="modal-child-close-button"
          onClick={() => {
            handleCustomClose && handleCustomClose();
          }}
        >
          <img src={assets.close} />
        </span>
      )}
      {hasSubmitted ? (
        <span className="submitted-thanks">
          <BlocksContent data={thankYouMessage} />
        </span>
      ) : (
        <>
          <BlocksContent data={questionHeading} />
          <p className="question" id={ariaLabel}>
            {question}
          </p>
          <AnswersList color={color!} pageColor={pageColor}>
            {answers &&
              answers.map(answerVariation => {
                if (answerVariation == null) return null;
                const { answer, marketoId } = answerVariation;

                const isSelected = marketoId === selectedAnswer;

                return (
                  <Answer
                    id={color!}
                    key={marketoId}
                    onClick={e => handleSelect(e, marketoId)}
                    className={`${isSelected ? "active-answer" : ""} answer-button`}
                    data-marketo-id={marketoId}
                  >
                    <button id={marketoId!}>
                      <Check selected={isSelected} aria-hidden="true">
                        <img src={assets.tick} />
                      </Check>
                      <p className="answer-text">{answer}</p>
                    </button>
                  </Answer>
                );
              })}
          </AnswersList>
        </>
      )}
    </ModalInner>
  );
};

const ModalInner = styled.div<{ isHero?: boolean }>`
  max-width: 870px;
  background: ${props => (props.isHero ? "rgba(38, 39, 70, 0.70)" : colors.navy)};
  margin: auto;
  padding: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  position: relative;
  scroll-margin: 180px;

  p {
    width: auto !important;
  }

  h3 {
    margin: 0px auto !important;
  }

  .question {
    margin: 24px auto 30px auto;
    width: 80% !important;
  }

  .submitted-thanks {
    margin: 88px 82px;
  }

  .modal-child-close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: ${colors.cream};
    display: flex;
    border-radius: 50%;
    height: 55px;
    width: 55px;
    filter: none;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
      margin: auto;
    }
  }

  ${mediaQuery.smallLaptopDown} {
    padding: 50px 30px;

    h3 {
      font-size: 40px;
    }

    .question {
      font-size: 15px;
    }

    .submitted-thanks {
      margin: 50px 30px;
    }

    .modal-child-close-button {
      width: 40px;
      height: 40px;
    }
  }

  @media screen and (max-width: 950px) {
    margin: auto 50px;
  }

  ${mediaQuery.mobileDown} {
    padding: 50px 20px;
    margin: ${props => (props.isHero ? "auto 20px" : "auto 0px")};
    h3 {
      font-size: 32px;
    }
    .submitted-thanks {
      margin: 50px 30px;
    }

    .modal-child-close-button {
      width: 23px;
      height: 23px;

      img {
        width: 11px;
        height: 11px;
        margin: auto;
      }
    }
  }
`;

const Section = styled(StandardSection)<{ color: string }>`
  display: flex;
  background-color: ${props => colors[(props.color as Color) ?? "navy"]};

  div {
    h2 {
      color: ${props => (props.color === "navy" ? colors.white : colors.navy)};
    }
  }
`;

const InnerContainer = styled(PageWidth)`
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .information-blocks {
    margin: 50px auto;
    text-align: center;
    font-weight: bold;
    max-width: 80%;
  }

  ${mediaQuery.tabletDown} {
    margin: 40px auto;

    .information-blocks {
      margin: 0px auto;
    }
  }

  h2 {
    max-width: 80%;
    align-self: flex-start;
    font-size: 24px;
    ${mediaQuery.smallLaptopDown} {
      max-width: 100%;
      width: 100%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    color: ${colors.white};
    margin: 0;
  }

  p {
    color: ${colors.white};
  }

  ${mediaQuery.smallLaptopDown} {
    .modal-pagewidth {
      max-width: 692px;
    }
  }

  ${mediaQuery.mobileDown} {
    .modal-pagewidth {
      width: 85%;
    }
    .modal-child-wrapper-absolute {
      top: 90px;
      left: 0px;
      transform: none;
      height: fit-content;
    }
  }
`;

export const AnswersList = styled.ul<{ color: string; pageColor: string }>`
  display: flex;
  column-gap: 15px;
  row-gap: 15px;
  justify-content: center;

  width: 100%;
  li,
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid currentColor;
    color: ${props => (props.color === "navy" ? colors.white : colors.navy)};
  }
  #cream.active-answer {
    background-color: ${colors.navy};
    p {
      color: ${colors.white};
    }
  }

  #navy.active-answer {
    background-color: ${props => props.pageColor};
    p {
      color: ${colors.navy};
    }
  }

  ${mediaQuery.ipadDown} {
    flex-direction: column;
  }
`;

export const Answer = styled.li`
  padding: 10px 25px 10px 10px;
  border-radius: 50px;

  .answer-text {
    text-align: left;
    margin: 0px;
  }

  button {
    display: flex;
    padding: 0;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    cursor: pointer;
    user-select: none;
    color: currentColor;
  }

  ${mediaQuery.smallLaptopDown} {
    justify-content: flex-start !important;
  }
`;

export const Check = styled.div<{ selected: boolean }>`
  margin-right: 15px;
  min-width: 30px;
  min-height: 30px;
  border: 2px solid ${colors.navy};
  border-radius: 50%;
  img {
    display: ${props => (props.selected ? "block" : "none")};
  }

  ${({ selected }) => selected && `background-color: white;`}
`;
