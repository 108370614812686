import { BlocksContent, Link } from "@global";
import { SanityAccordion } from "@graphql-types";
import { StyledAccordion } from "@styles/accordionStyles";
import { Container, PageWidth, Section } from "@util/standard";
import React from "react";
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { AccordionContent } from "./faq";
import styled from "styled-components";
import { mediaQuery } from "@util/constants";

interface Props {
  data: SanityAccordion;
}

const AccordionBlock = ({ data }: Props) => {
  if (data == null) return null;

  return (
    <Section>
      <PageWidth>
        <Container maxWidth="70%">
          {data.titleBlock && <BlocksContent data={data.titleBlock} />}
        </Container>
        <ExpandedStyledAccordion allowZeroExpanded>
          {data.content &&
            data.content.map((item, index) => {
              return (
                <AccordionItem key={item?.title}>
                  <AccordionItemHeading tabIndex={0}>
                    <AccordionItemButton aria-label={`Question - ${item?.title}`}>
                      {item?.title}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <ExpandedStyledAccordionContent
                      useRichText={true}
                      tabIndex={0}
                      aria-label={`Answer - ${item?.title}`}
                      hasLink={item?.link != null}
                    >
                      <BlocksContent data={item?.content} />
                      <Link {...item?.link} className="button-base" />
                    </ExpandedStyledAccordionContent>
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
        </ExpandedStyledAccordion>
      </PageWidth>
    </Section>
  );
};

export default AccordionBlock;

const ExpandedStyledAccordion = styled(StyledAccordion)`
  margin-top: 50px;

  .accordion-button {
    font-size: 26px;
    font-weight: bold;
  }

  a {
    margin: auto;
    flex-shrink: 0;
  }
`;

const ExpandedStyledAccordionContent = styled(AccordionContent)<{ hasLink?: boolean }>`
column-gap: 10px;

  ${props =>
    props.hasLink &&
    `
    width: 100%;
    display: flex;
    flex-direction: row;
  `}

    ${mediaQuery.tabletDown} {
      ${props => props.hasLink && `flex-direction: column;`}
`;
