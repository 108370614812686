import { AbsoluteWrapper, Container, InlineTag, PageWidth, SpanBefore } from "@util/standard";
import { BlocksContent, Carousel, Image, Link, VillageLivingOptions } from "@global";
import { SanityImageWithMeta, SanityVillage, SanityVillageLivingOptionsRef } from "@graphql-types";
import { mediaQuery } from "@util/constants";

import { GroqVillageData } from "@util/types";
import React from "react";
import { SwiperSlide } from "swiper/react";
import { formatAddress } from "@util/helper";
import styled from "styled-components";

interface Props {
  data: GroqVillageData | SanityVillage;
  isGroqData?: boolean;
}

export default function VillagePreview(props: Props) {
  if (props.data == null) return null;

  const {
    title,
    livingOptions,
    slug,
    _type,
    previewImages,
    villageExcerpt,
    villageExcerptRichText,
    phone,
    isDevelopment,
    region,
  } = villagePreviewData(props);
  const { url, string } = formatAddress(props?.data);

  return (
    <Container width="100%" flexDirection="column">
      {previewImages?.length > 0 && (
        <Carousel>
          {previewImages?.map((image, index) => {
            if (image == null) return null;
            return (
              <SwiperSlide key={index}>
                <ImageContainer>
                  {props.isGroqData ? (
                    <img src={`${image}?auto=format&w=600`} />
                  ) : (
                    <Image data={image as SanityImageWithMeta} width="100%" objectFit="cover" />
                  )}
                  {isDevelopment && (
                    <NewDevelopmentTab>
                      <InlineTag>New Development</InlineTag>
                    </NewDevelopmentTab>
                  )}
                </ImageContainer>
              </SwiperSlide>
            );
          })}
        </Carousel>
      )}
      <ContentContainer fullWidth>
        <Container rowGap="15px" flexDirection="column" className="preview-content">
          <Link internalLink={{ slug, _type, region }}>
            <p className="h4">{title}</p>
          </Link>
          {villageExcerptRichText?._rawBlockContent?.length ? (
            <BlocksContent data={villageExcerptRichText} />
          ) : (
            <p>{villageExcerpt}</p>
          )}
          {livingOptions && livingOptions.length > 0 && (
            <VillageLivingOptions data={livingOptions as SanityVillageLivingOptionsRef[]} />
          )}
          {!string.includes("undefined") && (
            <SpanBefore before="Address">
              <a target="_blank" href={url}>
                {string}
              </a>
            </SpanBefore>
          )}
          {phone && (
            <Container margin="10px 0 0 0">
              <SpanBefore before="Phone">
                <a href={`tel:${phone}`} data-villagename={props.data.title}>
                  {phone}
                </a>
              </SpanBefore>
            </Container>
          )}
        </Container>
        <Link
          className="button-base"
          margin="20px 0 0 0"
          linkText={`Go to ${title}`}
          internalLink={{ slug, _type, region }}
        />
      </ContentContainer>
    </Container>
  );
}

function villagePreviewData({ data, isGroqData }: Props) {
  if (isGroqData) {
    return data as GroqVillageData;
  }

  const { filters, receptionContact, ...rest } = data as SanityVillage;

  const livingOptionFilters = filters?.livingOptions?.map(option => {
    return { title: option?.title };
  });

  return {
    ...rest,
    livingOptionFilters,
    phone: receptionContact?.phone,
  } as GroqVillageData;
}

const ContentContainer = styled(PageWidth)<{ hasLivingOptions?: boolean }>`
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  .preview-content {
    margin-top: 25px;
    * {
      margin: 0;
    }
  }
`;

const ImageContainer = styled(Container)`
  width: 100%;
  height: 400px;
  position: relative;

  img {
    -webkit-user-drag: none;
    object-fit: cover;
    filter: brightness(0.9);
    width: inherit;
  }

  ${mediaQuery.mobileDown} {
    height: 250px;
  }
`;

const NewDevelopmentTab = styled(AbsoluteWrapper)`
  top: 20px;
  left: 20px;
  ${mediaQuery.tabletDown} {
    left: 10%;
  }
`;
