import { useStore } from "@state/store";
import { useEffect, useState } from "react";

export default function useReorderGuidesCategory(items: any) {
  const lead = useStore(state => state.lead);
  const [ordered, setOrdered] = useState<any>([]);

  useEffect(() => {
    if (!items) {
      setOrdered([]);
      return;
    }
    const sortedItems = [...items].sort((a, b) => {
      const aValue = lead[a?.accordion?.reference?.variationTag?.variationAnswer?.variantValue];
      const aMarketoValue = a?.accordion?.reference?.variationTag?.variationAnswer?.marketoId;
      const aMatch = aValue && aMarketoValue && aValue === aMarketoValue ? 1 : 0;

      const bValue = lead[b?.accordion?.reference?.variationTag?.variationAnswer?.variantValue];
      const bMarketoValue = b?.accordion?.reference?.variationTag?.variationAnswer?.marketoId;
      const bMatch = bValue && bMarketoValue && bValue === bMarketoValue ? 1 : 0;
      return bMatch - aMatch;
    });

    setOrdered(sortedItems);
  }, [items, lead]);

  return ordered;
}
