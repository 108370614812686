import { Container, DesktopContainer, PageWidth, Section, TabletContainer } from "@util/standard";
import { Dropdown, GoogleMap, Image } from "@global";
import { MapMarker, MapPoint, MarkerProps } from "@components/mapMarker";
import { Maybe, SanityMapList, SanityVillageMap } from "@graphql-types";
import React, { useEffect, useState } from "react";
import { colors, mediaQuery } from "@util/constants";

import { isSanityMapLocation } from "@util/types";
import styled from "styled-components";
import { useSelectOptions } from "@util/logicHooks";
import { useStore } from "@state/store";

interface Props {
  data: SanityMapList;
}

interface SelectedMapProps {
  selectedObject: Maybe<SanityVillageMap> | undefined;
  mapCenter: MarkerProps;
}

const ariaLabel = "Map List";

export default function MapList({ data }: Props) {
  const { village } = useStore();

  const [mapCenter, setMapCenter] = useState<MarkerProps>({ lat: village.lat, lng: village.lng });

  useEffect(() => {
    if (village?.lat && village?.lng) {
      setMapCenter({ lat: village.lat, lng: village.lng });
    }
  }, []);

  const { title, maps } = data;
  if (maps == null) return null;

  const { selectedObject, selectedOption, setSelectedOption } = useSelectOptions(maps);
  const listText =
    selectedObject?.locations?.length === 0
      ? selectedObject?.numberedList
      : selectedObject?.locations;

  if (selectedObject == null) return null;

  const List = () => {
    if (listText) {
      return (
        <Container flexDirection="column" width="25%" tabletWidth="100%">
          {maps && Number(maps?.length) > 1 && (
            <Container margin="0 0 20px 0">
              <Dropdown
                name="maps"
                defaultTitle=""
                options={maps.map(map => {
                  if (map == null) return { id: "", title: "" };
                  return { id: map._key!, title: map.title! };
                })}
                optionState={[selectedOption, setSelectedOption]}
              />
            </Container>
          )}
          {listText?.map((item, index) => {
            if (item == null) return null;

            if (isSanityMapLocation(item)) {
              const { _key, title, address } = item;

              const handleOnClick = () => {
                setMapCenter({ lat: address?.lat, lng: address?.lng });
              };

              return (
                <ListItem selectable key={_key} isFirst={index === 0} onClick={handleOnClick}>
                  <strong>{index + 1}</strong>
                  <p>{title}</p>
                </ListItem>
              );
            }

            return (
              <ListItem key={item + index} isFirst={index === 0}>
                <strong>{index + 1}</strong>
                <p>{item}</p>
              </ListItem>
            );
          })}
        </Container>
      );
    }

    return null;
  };

  return (
    <Section aria-labelledby={ariaLabel}>
      <PageWidth>
        <Title aria-label={ariaLabel}>{title}</Title>
      </PageWidth>
      {selectedObject.mapImage && (
        <TabletContainer height="max-content" margin="20px 0">
          <Image data={selectedObject?.mapImage} width="100%" />
        </TabletContainer>
      )}
      <PageWidth>
        <Container isTabletColumn>
          <Map selectedObject={selectedObject} mapCenter={mapCenter} />
          <List />
        </Container>
      </PageWidth>
    </Section>
  );
}

const Map = ({ selectedObject, mapCenter }: SelectedMapProps) => {
  const { village } = useStore();
  if (selectedObject == null) return null;

  if (selectedObject.mapImage?.asset) {
    return (
      <DesktopContainer width="74%" height="max-content" margin="0 2% 0 0">
        <Image data={selectedObject.mapImage} width="100%" />
      </DesktopContainer>
    );
  }

  return (
    <Container width="100%" height="60vh" flexDirection="column" margin="0 30px 0 0">
      <GoogleMap mapCenter={mapCenter}>
        <MapMarker lat={village?.lat} lng={village?.lng} />
        {selectedObject.locations &&
          selectedObject.locations?.length > 0 &&
          selectedObject.locations.map((location, index) => {
            if (location == null) return null;
            const { title, address } = location;
            return (
              <MapPoint
                key={index}
                index={index + 1}
                title={title}
                lat={address?.lat}
                lng={address?.lng}
              />
            );
          })}
      </GoogleMap>
    </Container>
  );
};

const Title = styled.h2`
  margin-bottom: 40px;
  ${mediaQuery.tabletDown} {
    margin-bottom: 20px;
  }
`;

const ListItem = styled(Container)<{ isFirst?: boolean; selectable?: boolean }>`
  ${({ isFirst }) => isFirst && `border-top: 1px solid ${colors.navy};`}
  ${({ selectable }) => selectable && `cursor: pointer;`}
  border-bottom: 1px solid ${colors.navy};
  padding: 10px 0px;
  strong {
    margin-right: 21px;
  }
  p {
    margin: 0;
    width: 70%;
  }
`;
