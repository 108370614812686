import React from "react";
import styled from "styled-components";

import { Container, DesktopContainer, MediaLink, P, PageWidth } from "@util/standard";
import { Link, Image } from "@global";
import { SanityNews } from "@graphql-types";
import { assets, fontSizes, mediaQuery } from "@util/constants";
import moment from "moment";

interface Props {
  data: SanityNews;
}

export default function NewsPreview({ data }: Props) {
  const { previewImage, categories, title, excerpt, slug, _type, publishDate } = data;

  const formatPublishDate = moment(publishDate).format("DD MMMM YYYY");

  return (
    <Container width="100%" flexDirection="column">
      <MediaLink
        to={slug?.current ? `/news/${slug?.current}` : "#"}
        aria-label={title ? `${title} article` : "News article"}
      >
        {previewImage ? (
          <Image width="100%" height="100%" data={previewImage} objectFit="cover" />
        ) : (
          <img alt="news article" src={assets.defaultNews} width="auto" height="auto" />
        )}
      </MediaLink>

      <PageWidth fullWidth margin="0 auto" height="100%">
        <Container flexDirection="column" justifyContent="space-between" height="100%">
          <div>
            <DesktopContainer breakPointOverride={mediaQuery.mobileDown}>
              {Boolean(categories?.length) && (
                <P margin="30px 0 10px 0">
                  {categories?.map((category, index) => {
                    const isLast = categories.length - 1 === index;
                    return `${category?.title}${isLast ? "" : ", "}`;
                  })}
                </P>
              )}
            </DesktopContainer>
            <Title className="h4" noCategories={!Boolean(categories?.length)}>
              {title}
            </Title>
            <P margin="10px 0 0 0">{excerpt}</P>
            {publishDate && <P margin="10px 0 0 0">{formatPublishDate}</P>}
          </div>
          <div></div>
          <Link
            className="button-base"
            margin="20px 0 0 0"
            linkText="Read more"
            internalLink={{ slug, _type }}
          />
        </Container>
      </PageWidth>
    </Container>
  );
}

const Title = styled.p<{ noCategories: boolean }>`
  font-size: ${fontSizes.h2.mobile}px;
  ${({ noCategories }) => noCategories && `margin-top: 65px;`};
  ${mediaQuery.mobileDown} {
    margin-top: 20px;
  }
`;
