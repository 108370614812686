import {
  DrawerId,
  Facet,
  FacetGroup,
  LeadCapture,
  SelectedMetguides,
  SelectedVillageInfoPack,
  Village,
  VillagePageContext,
} from "./types";
import { Maybe, SanityEvent, SanityFormSuccessText, SanityNews } from "@graphql-types";
import { devtools, persist } from "zustand/middleware";

import { StaticPagePaths } from "./../query/staticPageSlugsQuery";
import { SyntheticEvent } from "react";
import { colors } from "@util/constants";
import create from "zustand";
import { getFacetGroup } from "@util/helper";
import { sendPersonalisation } from "@util/datalayer";

export interface State {
  currentPagePath: string;
  pageColor: string;
  pageType: string;
  village: Village;
  pagePaths?: StaticPagePaths;
  selectedVillageInfoPacks: SelectedVillageInfoPack[];
  selectedMetguides: SelectedMetguides[];
  viewedVillages: string[];
  lead: LeadCapture;
  formSuccessText?: Maybe<SanityFormSuccessText>;
  searchQuery?: string;
  drawerVisible?: DrawerId;
  searchFacets: string[];
  initFacets?: FacetGroup; // village facets
  newsInitFacets?: FacetGroup; // news facets
  eventsInitFacets?: FacetGroup; // Events facets
  regionAccordionID?: string;
  personalisations: {
    [key: string]: {
      selectedState: string;
      modalOpen: boolean;
    };
  };
  setPagePaths: (pagePath: StaticPagePaths) => void;
  setCurrentPagePath: (currentPagePath: string) => void;
  setPageColor: (pageColor: string) => void;
  setPageType: (pageType: string) => void;
  setSearchQuery: (event: SyntheticEvent<any, Event>) => void;
  clearSearchQuery: () => void;
  setSearchFacets: (searchFacets: string[]) => void;
  setDrawerVisible: (drawerVisible: DrawerId) => void;
  // village actions
  setVillagePageContext: (pageContext: VillagePageContext) => void;
  setVillageEvents: (events: SanityEvent[] | undefined) => void;
  setVillageNews: (news: SanityNews[] | undefined) => void;
  getVillage: () => Village;
  setSelectedVillageInfoPacks: (selectedVillageInfoPacks: SelectedVillageInfoPack[]) => void;
  getSelectedVillageCodes: () => string[];
  setSelectedMetguides: (selectedMetguides: SelectedMetguides[]) => void;
  getSelectedMetguides: () => (string | undefined)[];
  setViewedVillages: (viewedVillages: string[]) => void;
  setLead: (lead: LeadCapture) => void;
  setFormSuccessText: (formSuccessText: Maybe<SanityFormSuccessText>) => void;
  setInitFacets: (initFacets: FacetGroup) => void;
  setNewsInitFacets: (newsInitFacets: FacetGroup) => void;
  setEventsInitFacets: (eventsInitFacets: FacetGroup) => void;
  getInitFacetItems: (attribute: string) => Array<Facet> | undefined;
  getNewsInitFacetItems: (attribute: string) => Array<Facet> | undefined;
  getEventsInitFacetItems: (attribute: string) => Array<Facet> | undefined;
  getLeadIds: () => Array<string | undefined>;
  setRegionAccordionOpen: (regionAccordionID: string) => void;
  setPersonalisation: (key: string, selectedState: string, modalOpen: boolean) => void;
}

const defaultState = {
  currentPagePath: "/",
  pageType: "page",
  pageColor: colors.cyan,
  village: {},
  selectedVillageInfoPacks: [],
  selectedMetguides: [],
  viewedVillages: [],
  searchFacets: [],
  lead: {},
  personalisations: {},
};

/**
 * Only enable devTools function for development
 */
const devtoolsInNonProd =
  process.env.GATSBY_NODE_ENV === "production" ? (store: any) => store : devtools;

export const useStore = create<State>()(
  devtoolsInNonProd(
    persist<State>(
      (set, get) => ({
        ...defaultState,
        setPagePaths: pagePaths => set({ pagePaths }),
        setCurrentPagePath: currentPagePath => set({ currentPagePath }),
        setPageColor: pageColor => set({ pageColor }),
        setPageType: pageType => set({ pageType }),
        setVillageEvents: events => set({ village: { ...get().village, events } }),
        setVillageNews: news => set({ village: { ...get().village, news } }),
        setVillagePageContext: pageContext =>
          set({ village: { ...get().village, ...pageContext } }),
        getVillage: () => get().village,
        setSelectedVillageInfoPacks: selectedVillageInfoPacks => set({ selectedVillageInfoPacks }),
        getSelectedVillageCodes: () => {
          if (!Boolean(get().selectedVillageInfoPacks.length)) return [];
          return get().selectedVillageInfoPacks.map(pack => pack.villageCode!);
        },
        setSelectedMetguides: selectedMetguides => set({ selectedMetguides }),
        getSelectedMetguides: () => {
          if (!Boolean(get().selectedMetguides.length)) return [];
          return get().selectedMetguides.map(pack => pack.guidePDFName);
        },
        setViewedVillages: viewedVillages => set({ viewedVillages }),
        setLead: lead => {
          set({ lead });
          sendPersonalisation(lead);
        },
        setFormSuccessText: formSuccessText => set({ formSuccessText }),
        setSearchQuery: event => set({ searchQuery: event.currentTarget.value }),
        clearSearchQuery: () => set({ searchQuery: undefined, searchFacets: [] }),
        setSearchFacets: searchFacets => set({ searchFacets }),
        setInitFacets: initFacets => set({ initFacets }),
        setNewsInitFacets: newsInitFacets => set({ newsInitFacets }),
        setEventsInitFacets: eventsInitFacets => set({ eventsInitFacets }),
        getInitFacetItems: attribute => {
          const facets = get().initFacets;
          return getFacetGroup(facets, attribute);
        },
        getNewsInitFacetItems: attribute => {
          const facets = get().newsInitFacets;
          return getFacetGroup(facets, attribute);
        },
        getEventsInitFacetItems: attribute => {
          const facets = get().eventsInitFacets;
          return getFacetGroup(facets, attribute);
        },
        getLeadIds: () => {
          const lead = get().lead;
          if (lead && Object.keys(lead).length === 0) return [];
          return Object.keys(lead).map(key => {
            return lead[key];
          });
        },
        setDrawerVisible: drawerVisible => set({ drawerVisible }),
        setRegionAccordionOpen: regionAccordionID => set({ regionAccordionID }),
        setPersonalisation: (key, selectedState, modalOpen) => {
          const personalisations = get().personalisations;
          const personalisation = personalisations[key];
          if (personalisation) {
            personalisation.selectedState = selectedState;
            personalisation.modalOpen = modalOpen;
            set({ personalisations });
          } else {
            set({
              personalisations: {
                ...personalisations,
                [key]: {
                  selectedState,
                  modalOpen,
                },
              },
            });
          }
        },
      }),
      {
        name: "metlifecare-website-store", // unique name
        getStorage: () => localStorage,
        // omit from persisted storage
        // @ts-ignore
        partialize: state =>
          Object.fromEntries(
            Object.entries(state).filter(([key]) => {
              return ![
                "currentPagePath",
                "village",
                "pagePaths",
                "searchQuery",
                "searchFacets",
                "initFacets",
                "newsInitFacets",
                "eventsInitFacets",
                "drawerVisible",
              ].includes(key);
            }),
          ),
      },
    ),
  ),
);
