import React from "react";
import styled from "styled-components";

import { assets } from "@util/constants";
import { StyledButton as BaseButton, Props as ButtonProps } from "./button";

interface Props extends ButtonProps {
  selected?: boolean;
}

const SelectableButton = ({
  theme,
  onClick,
  children,
  loading,
  type,
  selected,
  ...rest
}: Props) => {
  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  return (
    <StyledButton
      type={type ?? "button"}
      buttontheme={selected ? "base" : "inverse"}
      onClick={handleOnClick}
      {...rest}
    >
      <img src={selected ? assets.selectedIcon : assets.emptyNavy} width={"22px"} height={"22px"} />
      {children}
    </StyledButton>
  );
};

export default SelectableButton;

const StyledButton = styled(BaseButton)`
  display: flex;
  img {
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }
`;
