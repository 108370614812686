import {
  Children,
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Marketo, MediaQueryWidth } from "./types";
import { appendMarketoScript, findFormElements, isBrowser, splitArrayIntoChunks } from "./helper";

import { Maybe, SanityContactOptions } from "@graphql-types";
import { globalHistory } from "@reach/router";
import { mediaQueryWidth } from "./constants";
import { sendPageView } from "./datalayer";
import { useLocation } from "@reach/router";
import { useStore } from "@state/store";
import { sanityClient } from "@lib/sanityClient";

const defaultScreenSize = {
  ipadProDown: false,
  ipadProUp: false,
  laptopUp: false,
  laptopDown: false,
  tabletUp: false,
  tablet: false,
  tabletDown: false,
  ipadUp: false,
  ipad: false,
  ipadDown: false,
  mobileUp: false,
  mobileDown: false,
};

// get previous const for comparsion
export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

// similar to use previous but will do an comparsion between prev & next prop
export function usePreviousCompare<T>(next: T, compare: (prev: T, next: T) => T) {
  const previousRef = useRef<T>(next);
  const previous = previousRef.current;

  const isEqual = compare(previous, next);

  useEffect(() => {
    if (previousRef.current == null) {
      return;
    }

    if (!isEqual && previousRef.current) {
      previousRef.current = next;
    }
  });

  return isEqual ? previous : next;
}

// check if component has been mounted
export function useHasMounted() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, []);

  if (!mounted) {
    return false;
  }

  return mounted;
}

// the same as useState but will store the value in localStorage
function useStorage<T>(key: string, defaultValue: T | (() => T), storage: Storage) {
  const [value, setValue] = useState(() => {
    const jsonValue = storage.getItem(key);
    if (jsonValue != null) return JSON.parse(jsonValue);

    if (defaultValue instanceof Function) {
      return defaultValue();
    } else {
      return defaultValue;
    }
  });

  useEffect(() => {
    if (value === undefined) return storage.removeItem(key);
    storage.setItem(key, JSON.stringify(value));
  }, [key, value, storage]);

  const remove = useCallback(() => {
    setValue(undefined);
  }, []);

  return [value, setValue, remove];
}

export function useLocalStorage<T>(key: string, defaultValue: T | (() => T)) {
  if (!isBrowser()) return;
  return useStorage(key, defaultValue, window.localStorage);
}

export function useSessionStorage<T>(key: string, defaultValue: T | (() => T)) {
  if (!isBrowser()) return;
  return useStorage(key, defaultValue, window.sessionStorage);
}

// event listener hook
export function useEventListener(
  eventName: string,
  handler: (args: any) => void,
  elementToListen?: any,
) {
  if (!isBrowser()) return;
  const element = elementToListen ?? window;

  const savedHandler = useRef<typeof handler>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    //@ts-ignore
    const eventListener = (event: any) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);

    return () => element.removeEventListener(eventName, eventListener);
  }, [eventName, element]);
}

// set dark dark mode
export function useDarkMode(refObject?: RefObject<any>) {
  if (!isBrowser()) {
    return;
  }
  const [enabled, setEnabled] = useState(false);
  const element = refObject?.current ?? window.document.body;

  useEffect(() => {
    const className = "dark-mode";

    if (element) {
      if (enabled) {
        element.classList.add(className);
      } else {
        element.classList.remove(className);
      }
    }
  }, [enabled]);

  return [enabled, setEnabled];
}

// console logs the state when it gets updated
export function useUpdateLogger(value: any) {
  useEffect(() => {
    console.log(value);
  }, [value]);
}

// changes the boolean value to it's opposite value
export function useToggle(
  initialState = false,
): [boolean, () => void, Dispatch<SetStateAction<boolean>>] {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => setState(state => !state), []);

  return [state, toggle, setState];
}

// timeout hook, returns reset and clear function
export function useTimeout(callback: (args?: any) => void, delay: number) {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<any>();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const clear = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delay, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  useEffect(() => {
    return () => clear();
  }, []);

  return { reset, clear };
}

// debounce hook - run a callback after a certain delay
export function useDebounce(callback: (args?: any) => void, delay: number, dependencies: any[]) {
  const { reset, clear } = useTimeout(callback, delay);
  useEffect(reset, [...dependencies, reset]);
  useEffect(clear, []);
}

export function useArray(defaultValue: any[]) {
  const [array, setArray] = useState(defaultValue);

  const push = (element: any) => {
    setArray(a => [...a, element]);
  };

  const filter = (callback: (args?: any) => void) => {
    setArray(a => a.filter(callback));
  };

  const update = (index: number, newElement: any) => {
    setArray(a => [...a.slice(0, index), newElement, ...a.slice(index + 1, a.length)]);
  };

  const remove = (index: number) => {
    setArray(a => [...a.slice(0, index), ...a.slice(index + 1, a.length)]);
  };

  const clear = () => {
    setArray([]);
  };

  return { array, set: setArray, push, filter, update, remove, clear };
}

// counts the number of re-renders
export function useRenderCount() {
  const count = useRef(1);
  useEffect(() => {
    count.current++;
  }, []);
  return count.current;
}

/**
 * Updated screenWidth hook using media query match
 *
 * @returns object with all the keys from mediaQueryWidth
 */
export function useCheckScreenSize() {
  if (!isBrowser()) {
    return defaultScreenSize;
  }

  const [screenWidth, setScreenWidth] = useState(defaultScreenSize);
  const hasMounted = useHasMounted();

  const checkScreenWidth = () => {
    Object.keys(mediaQueryWidth).forEach(key => {
      if (window.matchMedia(mediaQueryWidth[key as MediaQueryWidth]).matches) {
        setScreenWidth(prev => ({ ...prev, [key]: true }));
      } else {
        setScreenWidth(prev => ({ ...prev, [key]: false }));
      }
    });
  };

  useEventListener("resize", checkScreenWidth);

  useEffect(() => {
    if (hasMounted) {
      checkScreenWidth();
    }
  }, [hasMounted]);

  return screenWidth;
}

/**
 * Update page path change to store
 */
export function useUpdatePagePathChange() {
  const { pathname } = useLocation();
  const prevPathname = usePrevious(pathname);
  const { setCurrentPagePath, pageType, getVillage } = useStore();
  const villageTitle = getVillage()?.title;
  const location = useLocation();

  const pageData = {
    page_url: pathname,
    page_title: isBrowser() ? document?.title : "",
  };

  const page =
    pageType === "village"
      ? {
          ...pageData,
          site_section: "village_site",
          village_name: villageTitle,
        }
      : pageData;

  useEffect(() => {
    setCurrentPagePath(pathname);
    sendPageView(page);
  }, []);

  useEffect(() => {
    if (pathname === prevPathname) return;
    setTimeout(() => {
      const pageDataUpdated = {
        page_url: pathname,
        page_title: isBrowser() ? document?.title : "",
      };

      const pageUpdated =
        pageType === "village"
          ? {
              ...pageDataUpdated,
              site_section: "village_site",
              village_name: villageTitle,
            }
          : pageDataUpdated;
      setCurrentPagePath(pathname);
      sendPageView(pageUpdated);
    }, 1000);
  }, [location]);
}

/**
 * Check if window has scrolled passed 50px and 100px from bottom
 *
 * @returns hasScrolled, hasScrolledToBottom
 */
export function useHasScrolled() {
  const footerOffset = 100;
  const [hasScrolled, setHasScrolled] = useState(false);
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);

  const checkForScroll = () => {
    if (!isBrowser()) return;
    if (
      window.innerHeight + window.scrollY >= document.body.scrollHeight - footerOffset &&
      hasScrolled
    ) {
      setHasScrolledToBottom(true);
    } else {
      setHasScrolledToBottom(false);
    }

    if (window.scrollY > 50) {
      setHasScrolled(true);
      return;
    }
    if (hasScrolled) {
      setHasScrolled(false);
    }
  };

  useEventListener("scroll", checkForScroll);

  return { hasScrolled, hasScrolledToBottom };
}

export function useArrayChunks(contentArray: Maybe<any[]> | undefined, chunkSize: number) {
  if (contentArray == null) return;
  const [chunks, setChunks] = useState<any[]>();

  useEffect(() => {
    if (contentArray == null) return;
    const chunksArray = splitArrayIntoChunks(contentArray, chunkSize);
    if (!chunksArray.length) return;

    setChunks(chunksArray);
  }, []);

  return chunks;
}

/**
 * Load Marketo stuff
 *
 * @param Marketo object
 * @returns MktoForms2 form object
 */
export function useMarketo({ baseUrl, munchkinId, formId, callback, onScreen }: Marketo) {
  const [form, setForm] = useState<any>();
  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (!isBrowser() || !onScreen) return;
    if (onScreen && scriptLoaded) {
      // @ts-ignore
      window.MktoForms2.loadForm(baseUrl, munchkinId, formId, (form: any) => {
        setForm(form);
      });

      callback && callback();
      return;
    }

    // @ts-ignore
    if (!Boolean(window.MktoForms2)) {
      appendMarketoScript(baseUrl, setScriptLoaded);
    } else {
      setScriptLoaded(true);
    }
  }, [scriptLoaded, onScreen]);

  return form;
}

/**
 * Handle click outside of ref object
 *
 * @param ref
 * @param handler
 */
export function useOnClickOutside(ref: RefObject<any>, handler: (args: any) => void) {
  useEffect(() => {
    if (!isBrowser()) return;
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements

      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export function useUrlSearchParam() {
  const { search } = useLocation();
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (!isBrowser() || search === "") return;
    const input = search ? search.substring(1).replace(/_|%20/g, " ") : "";
    setSearchInput(input);
  }, [search]);

  return searchInput;
}

/**
 * Check if a ref object is on screen
 *
 * @param ref object to check if on screen
 * @param rootMargin
 * @returns is this thingy is on screen
 */
export function useIsOnScreen(ref: RefObject<any>, rootMargin = "0px") {
  const [isIntersecting, setIntersecting] = useState(false);
  useLayoutEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      },
    );
    if (ref.current) {
      observer?.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer?.unobserve(ref.current);
      }
    };
  }, []);
  return isIntersecting;
}

// PREVENTS SCROLLING WHEN FREEZEVALUE IS TRUE
export function useScrollFreeze(freezeValue: boolean) {
  useEffect(() => {
    if (!isBrowser()) return;
    document.body.style.overflow = freezeValue ? "hidden" : "initial";
  }, [freezeValue]);
}

export const queryCookies = function () {
  const urlParamsHasCookies = true;

  if (urlParamsHasCookies) {
    document.cookie = "gclid=0;max-age=0";
    document.cookie = "utm_campaign=0;max-age=0";
    document.cookie = "utm_source=0;max-age=0";
    document.cookie = "utm_medium=0;max-age=0";
    document.cookie = "utm_term=0;max-age=0";
    document.cookie = "utm_content=0;max-age=0";
    document.cookie = "fbclid=0;max-age=0";
  }

  if (urlParamsHasCookies) {
    const self = window.location.toString();
    const querystring = self.split("?");
    if (querystring.length > 1) {
      const pairs = querystring[1].split("&");
      pairs.forEach(pair => {
        const lowerCasePair = pair.toLowerCase();
        const keyval = lowerCasePair.split("=");

        if (keyval.length === 2) {
          document.cookie = lowerCasePair + ";max-age=604800";
        }
      });
    }
  }
};

export function useMarketoCookies() {
  if (!isBrowser()) return;

  useEffect(() => {
    setTimeout(function () {
      queryCookies();
    }, 1500);
  }, []);
}

// DYNAMICS HOOKS
export function useScript(scriptSrc: string) {
  if (!isBrowser()) return null;
  useEffect(() => {
    const script = document.createElement("script");
    script.src = scriptSrc;
    script.async = true;

    document.head.appendChild(script);

    return () => {
      const scripts = document.getElementsByTagName("script");

      // Loop through each script element
      for (let i = 0; i < scripts.length; i++) {
        const script = scripts[i];

        // Check if the src attribute matches the specified value
        if (script.src === scriptSrc) {
          // Remove the script element
          if (script.parentNode) {
            script.parentNode.removeChild(script);
          }
        }
      }
    };
  }, []);
  return;
}

export function useDynamics() {
  useScript("https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.85.2002");
}

export function useDynamicsFormLoad(valueForChange?: any) {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  if (!isBrowser()) return null;

  const addFormScript = () => {
    // @ts-ignore
    if (window.MsCrmMkt?.MsCrmFormLoader == null) {
      const newScript = document.createElement("script");
      newScript.src =
        "https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.85.2002";
      newScript.async = true;
      document.head.appendChild(newScript);
      setScriptLoaded(true);
    }
  };

  const removeFormScript = () => {
    const scripts = document.getElementsByTagName("script");
    for (let i = 0; i < scripts.length; i++) {
      const script = scripts[i];
      if (
        script.src ===
        "https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.85.2002"
      ) {
        // Remove the script element
        if (script.parentNode) {
          script.parentNode.removeChild(script);
          // @ts-ignore
          if (window.MsCrmMkt?.MsCrmFormLoader) window.MsCrmMkt = undefined;
        }
      }
    }
  };

  useEffect(() => {
    addFormScript();
    return () => {
      removeFormScript();
    };
  }, []);

  useEffect(() => {
    removeFormScript();
    addFormScript();
  }, [valueForChange]);

  return scriptLoaded;
}

export function useRescueMetrics() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://fqcweejv.metlifecare.co.nz/metlife-care.js";
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);
}

export function useRegionAndVillages() {
  const [villages, setVillages] = useState<any[]>([]);
  const [regions, setRegions] = useState<any[]>([]);
  const [originalVillages, setOriginalVillages] = useState<any[]>([]);

  const fetchVillages = async () => {
    const res =
      await sanityClient.fetch(`*[_type == "village" && displayCareOptionsInForm == true && !(_id in path('drafts.**'))] {
        _id,
            title,
            villageCode,
          region -> {
            _id,
            title,
            order,
            marketoValue,
            dynamicsValue,
            slug {current},
          isParentRegion,
          parentRegion ->
          }
          }`);

    if (res) {
      setVillages(res);
      setOriginalVillages(res);

      // Create an empty object to store unique regions based on _id
      const uniqueRegions = {};

      // Loop through the response to filter and store unique regions
      res.forEach(village => {
        const regionId = village.region._id;
        uniqueRegions[regionId] = village.region;
      });

      // Convert the uniqueRegions object to an array of region objects
      const uniqueRegionsArray = Object.values(uniqueRegions);
      uniqueRegionsArray.sort((a, b) => a.order - b.order);

      console.log({ uniqueRegionsArray });

      setRegions(uniqueRegionsArray);
    }
  };

  const updateRegion = (e: string) => {
    console.log(e.target.value);

    const filterVillages = originalVillages.filter(village =>
      [
        village.region?.marketoValue,
        village.region?.title,
        village?.region?.dynamicsValue,
      ].includes(e.target.value),
    );
    console.log({ filterVillages });

    setVillages(filterVillages);
  };

  useEffect(() => {
    fetchVillages();
  }, []);

  return { villages, regions, updateRegion };
}

export function useContactOptions() {
  const [contactOptions, setContactOptions] = useState<SanityContactOptions | null>(null);

  const fetchOptions = async () => {
    const res = await sanityClient.fetch(`*[_type == "contactOptions"][0]`);
    if (res) {
      setContactOptions(res);
    }
  };

  useEffect(() => {
    if (contactOptions == null) {
      fetchOptions();
    }
  }, []);

  return { contactOptions };
}

export function useResolveReferenceLink(reference: string) {
  const [link, setLink] = useState();

  const fetchLink = async () => {
    const res = await sanityClient.fetch(`*[_id == '${reference}'][0]{ _type, slug }`);
    if (res) {
      console.log("link res", { res });

      setLink(res);
    }
  };

  useEffect(() => {
    console.log(`*[_id == '${reference}'][0]{ _type, slug }`);
    if (!reference) return;
    if (link == null) {
      fetchLink();
    }
  }, [reference]);

  return link;
}
