import React from "react";
import { AnchorLink as GatsbyAnchorLink } from "gatsby-plugin-anchor-links";
import styled from "styled-components";
import { useLocation } from "@reach/router";

import { Maybe } from "@graphql-types";
import { colors } from "@util/constants";
import { Color } from "@util/types";

interface Props {
  title: Maybe<string> | undefined;
  anchor?: Maybe<string> | undefined;
  isBold?: boolean;
  color?: Color;
  onClick?: () => void;
}

const AnchorLink = ({ title, anchor, ...rest }: Props) => {
  if (title == null) return null;
  const { pathname } = useLocation();
  const to = `${pathname}#${anchor}`;

  return <StyledLink to={to} title={title} {...rest} />;
};

export default AnchorLink;

const StyledLink = styled(GatsbyAnchorLink)<Props>`
  color: ${props => (props.color ? colors[props.color] : colors.white)};
  height: 120%;
  ${({ isBold }) => isBold && `font-weight: bold;`}
`;
