import React, { useEffect, useState } from "react";

import { Maybe, SanityImageWithMeta, SanityVariableImage } from "@graphql-types";
import { useStore } from "@state/store";
import Image, { ImageProps } from "./image";

interface Props {
  data: Maybe<Maybe<SanityVariableImage>[]> | undefined;
  defaultImage?: Maybe<SanityImageWithMeta> | undefined;
  imageProps?: Partial<ImageProps>;
}

export default function VariedImages({ data, defaultImage, imageProps = {} }: Props) {
  const lead = useStore(state => state.lead);
  const [image, setImage] = useState(defaultImage);

  useEffect(() => {
    if (data && lead) {
      const variableImage = data.find(
        variable =>
          lead[variable?.variationTag?.variationAnswer?.variantValue!] ===
          variable?.variationTag?.variationAnswer?.marketoId,
      );
      if (variableImage) setImage(variableImage.image);
    }
  }, [data, lead]);

  if (!data) return null;
  if (image) return <Image data={image} objectFit="cover" width="100%" {...imageProps} />;
  return null;
}
