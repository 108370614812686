import React from "react";
import styled from "styled-components";
import * as Icons from "react-icons/fa";

import { SanitySocialLink, Maybe } from "@graphql-types";
import { ErrorMsg } from "@shared";

export type IconKeys = keyof typeof Icons;

interface Props {
  data: Maybe<SanitySocialLink>;
  showText?: boolean;
}

function SocialIcon({ data, showText = true }: Props) {
  if (data == null) return <ErrorMsg data={data} msg="Error loading social icon" />;

  const { externalLink, icon, linkText } = data;

  const IconComponent = Icons[(icon?.name as IconKeys) ?? "FaLink"];

  return (
    <Icon href={externalLink as string} target="_blank">
      <IconComponent />
      {showText && linkText}
    </Icon>
  );
}

export default SocialIcon;

const Icon = styled.a`
  height: fit-content;
  svg {
    width: 18px;
    height: 18px;
    margin: 0 10px 0 20px;
  }
`;
