import { ButtonTheme, Color, FontWeights } from "./types";
import {
  DEFAULT_OFFSET,
  HEADER_MOBILE_OFFSET,
  HEADER_OFFSET,
  PRIMARY_COLOR,
  animations,
  buttonTheme,
  colors,
  colorsRGB,
  fontFamilies,
  fontSizes,
  fontWeights,
  mediaQuery,
  mobileOSQueries,
} from "./constants";
import styled, { css } from "styled-components";

import { CSSProperties } from "react";
import { Link as GatsbyLink } from "gatsby";
import { Maybe } from "@graphql-types";

export const Overlay = styled.div<{ zIndex?: number }>`
  height: 100vh;
  width: 150vw;
  position: fixed;
  z-index: ${props => props.zIndex ?? 3};
  left: -${DEFAULT_OFFSET}px;
  top: 0;
  background-color: ${colorsRGB.black(0.8)};
  overflow: hidden;
  transition: 0.5s;
`;

export const P = styled.p<{
  color?: Color;
  fontSize?: number;
  width?: number;
  lineHeight?: number;
  underlined?: boolean;
  fontWeight?: FontWeights;
  opacity?: number;
  display?: CSSProperties["display"];
  whiteSpace?: CSSProperties["whiteSpace"];
  cursor?: CSSProperties["cursor"];
  padding?: string;
  textAlign?: CSSProperties["textAlign"];
  letterSpacing?: CSSProperties["letterSpacing"];
  margin?: string;
  userSelect?: string;
}>`
  background-color: transparent;
  text-decoration-line: ${props => (props.underlined ? "underline" : "none")};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : fontWeights.normal)};
  ${({ letterSpacing }) => letterSpacing && `letter-spacing: ${letterSpacing};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ userSelect }) => userSelect && `user-select: ${userSelect};`}
  ${({ color }) => color && `color: ${colors[color]};`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}px;`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ display }) => display && `display: ${display};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
`;

export const A = styled.a<{
  color?: Color;
  underlined?: boolean;
  bold?: boolean;
  hoverColor?: Color;
  opacity?: number;
  margin?: string;
  display?: CSSProperties["display"];
  width?: string;
  height?: string;
}>`
  color: ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  ${({ width }) => width && `width: ${width};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ display }) => display && `display: ${display};`}
  ${({ underlined }) => (underlined ? "text-decoration: underline;" : "text-decoration: none;")}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ bold }) => bold && "font-weight: bold;"}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ hoverColor }) =>
    hoverColor &&
    `&:hover {
    color: ${colors[hoverColor]};
  }`}
`;

export const VerticalSeparator = styled.div<{
  color?: Color;
  width?: string;
  height?: string;
  shouldShowMobile?: boolean;
  margin?: string;
}>`
  border-left: 1.5px solid ${props => (props.color ? colors[props.color] : colors.white)};
  height: ${props => (props.height ? props.height : `auto`)};
  width: ${props => (props.width ? props.width : `1.5px`)};
  ${({ margin }) => margin && `margin: ${margin};`}
`;

export const Separator = styled.div<{
  color?: Color;
  height?: string;
  opacity?: number;
  thickness?: number;
  shouldHideDesktop?: boolean;
  margin?: string;
  width?: string;
}>`
  border-bottom: ${props => props.thickness ?? 1}px solid
    ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  ${({ width }) => width && `width: ${width ?? "100%"};`}
  height: ${props => props.height ?? `20px`};
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ width }) => width && `width: ${width};`}

  ${mediaQuery.tabletDown} {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:block`}
  }
  ${mediaQuery.tabletUp} {
    ${({ shouldHideDesktop }) => shouldHideDesktop && `display:none`}
  }
`;

export const Container = styled.div<{
  backgroundColor?: Color;
  backgroundRGBColor?: string;
  flexDirection?: CSSProperties["flexDirection"];
  flex?: CSSProperties["flex"];
  flexGrow?: CSSProperties["flexGrow"];
  flexWrap?: CSSProperties["flexWrap"];
  flexFlow?: CSSProperties["flexFlow"];
  alignItems?: CSSProperties["alignItems"];
  alignSelf?: CSSProperties["alignSelf"];
  mobileAlignSelf?: CSSProperties["alignSelf"];
  borderRadius?: number;
  overflow?: CSSProperties["overflow"];
  paddingVertical?: number;
  paddingHorizontal?: number;
  img?: string;
  isMobileColumn?: boolean;
  isTabletColumn?: boolean;
  mobilePadding?: string;
  mobileMargin?: string;
  tabletWidth?: string;
  mobileWidth?: string;
  justifyContent?: CSSProperties["justifyContent"];
  mobileJustifyContent?: CSSProperties["justifyContent"];
  width?: string;
  maxWidth?: string;
  height?: string;
  opacity?: number;
  margin?: string;
  padding?: string;
  cursor?: CSSProperties["cursor"];
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  showOnTablet?: boolean;
  zIndex?: number;
  display?: CSSProperties["display"];
  backgroundSize?: CSSProperties["backgroundSize"];
  position?: CSSProperties["position"];
  columnGap?: string;
  rowGap?: string;
}>`
  display: ${props => props.display ?? "flex"};
  flex-direction: ${props => props.flexDirection ?? "initial"};
  justify-content: ${props => props.justifyContent ?? "initial"};
  align-items: ${props => props.alignItems ?? "initial"};
  align-self: ${props => props.alignSelf ?? "initial"};
  overflow: ${props => props.overflow ?? "initial"};
  border-radius: ${props => props.borderRadius ?? 0}px;

  ${({ rowGap }) => rowGap && `row-gap: ${rowGap};`}
  ${({ columnGap }) => columnGap && `column-gap: ${columnGap};`}
  ${({ position }) => position && `position: ${position};`}
  ${({ backgroundSize }) => backgroundSize && `background-size: ${backgroundSize};`}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ flexFlow }) => flexFlow && `flex-flow: ${flexFlow};`}
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ img }) => img && `background-image: url(${img});`}
  ${({ backgroundColor }) => backgroundColor && `background-color: ${colors[backgroundColor]};`}
  ${({ backgroundRGBColor }) => backgroundRGBColor && `background-color: ${backgroundRGBColor};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ height }) => height && `height: ${height};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ opacity }) => opacity && `opacity: ${opacity};`}
  ${({ showOnTablet }) => showOnTablet && `display: none;`}

  ${mediaQuery.tabletDown} {
    ${({ mobilePadding }) => mobilePadding && `padding: ${mobilePadding};`};
    ${({ mobileMargin }) => mobileMargin && `margin: ${mobileMargin};`}
    ${({ isTabletColumn }) =>
      isTabletColumn &&
      `
      flex-direction: column;
      height: auto;
      width: 100%;
    `}

    ${({ hideOnTablet }) => hideOnTablet && `display: none;`}
    ${({ showOnTablet }) => showOnTablet && `display: flex !important;`}
    ${({ tabletWidth }) => tabletWidth && `width: ${tabletWidth};`};
  }

  ${mediaQuery.mobileDown} {
    ${({ mobileJustifyContent }) =>
      mobileJustifyContent && `justify-content: ${mobileJustifyContent};`}
    ${({ hideOnMobile }) => hideOnMobile && `display: none;`}
    ${({ mobileAlignSelf }) => mobileAlignSelf && `align-self: ${mobileAlignSelf}`}
    ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`};
    ${({ isMobileColumn }) =>
      isMobileColumn &&
      `
      flex-direction: column;
      height: auto;
      align-items: center;
      width: 100%;
    `}
  }
`;

export const GridContainer = styled.div<{
  gridTemplateColumns?: string;
  width?: string;
  margin?: string;
  repeat?: number;
  mobileRepeat?: number;
  tabletRepeat?: number;
  smallLaptopRepeat?: number;
  columnGap?: number;
  rowGap?: number;
  mobileColumnGap?: number;
  mobileRowGap?: number;
  rtl?: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(${props => props.repeat ?? 3}, 1fr);
  ${({ columnGap }) => columnGap && `grid-column-gap: ${columnGap}px;`}
  ${({ rowGap }) => rowGap && `grid-row-gap: ${rowGap}px;`}
  ${({ width }) => width && `width: ${width};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ rtl }) => rtl && `direction: rtl;`}

  ${mediaQuery.smallLaptopDown} {
    grid-template-columns: repeat(${props => props.smallLaptopRepeat ?? 2}, 1fr);
  }
  ${mediaQuery.tabletDown} {
    grid-template-columns: repeat(${props => props.tabletRepeat ?? 2}, 1fr);
  }
  ${mediaQuery.mobileDown} {
    grid-template-columns: repeat(${props => props.mobileRepeat ?? 1}, 1fr);
    ${({ mobileColumnGap }) => mobileColumnGap && `grid-column-gap: ${mobileColumnGap}px;`}
    ${({ mobileRowGap }) => mobileRowGap && `grid-row-gap: ${mobileRowGap}px;`}
  }
`;

export const TextButton = styled(P)<{
  color?: Color;
  hoverColor?: Color;
  underlined?: boolean;
  lineHeight?: number;
  margin?: string;
}>`
  line-height: ${props => props.lineHeight ?? 18}px;
  text-decoration-line: ${props => (props.underlined ? "underline" : "none")};
  color: ${props => (props.color ? colors[props.color] : PRIMARY_COLOR)};
  cursor: pointer;
  margin: ${props => props.margin ?? "0"};
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:focus {
    outline: 0;
  }
  &:hover {
    /* color: ${props => (props.hoverColor ? colors[props.hoverColor] : colors.flightBlue)}; */
  }
`;

export const GiveMeSomeSpace = styled.div<{ space?: number }>`
  padding: ${props => props.space ?? 10}px;
`;

export const AbsoluteWrapper = styled.div<{
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
  zIndex?: number;
  tabletRight?: string;
}>`
  position: absolute;
  ${({ left }) => left && `left: ${left};`}
  ${({ right }) => right && `right: ${right};`}
  ${({ top }) => top && `top: ${top};`}
  ${({ bottom }) => bottom && `bottom: ${bottom};`}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}

  ${mediaQuery.tabletDown} {
    ${({ tabletRight }) => tabletRight && `right : ${tabletRight};`}
  }
`;

export const FullHeightContainer = styled(Container)<{
  height?: string | undefined;
  width?: string | undefined;
}>`
  flex-direction: column;
  position: relative;
  width: ${props => props.width ?? "100%"};
  height: ${props => props.height ?? "100vh"};
  overflow: hidden;
`;

export const TabletContainer = styled(Container)<{ breakPointOverride?: string }>`
  ${({ breakPointOverride }) =>
    breakPointOverride
      ? `${breakPointOverride} {display: none;}`
      : `
  ${mediaQuery.tabletUp} {
    display: none;
  }
  `};
`;

export const MobileContainer = styled(Container)`
  ${mediaQuery.mobileUp} {
    display: none;
  }
`;

export const DesktopContainer = styled(Container)<{ breakPointOverride?: string }>`
  ${({ breakPointOverride }) =>
    breakPointOverride
      ? `${breakPointOverride} {display: none;}`
      : `
  ${mediaQuery.tabletDown} {
    display: none;
  }
  `};
`;

export const Span = styled.span<{
  fontSize?: number;
  display?: CSSProperties["display"];
  margin?: string;
}>`
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px;`}
  ${({ display }) => display && `display: ${display};`}
  ${({ margin }) => margin && `margin: ${margin};`}
`;

export const PageWidth = styled(Container)<{
  mobileFullWidth?: Maybe<boolean>;
  fullWidth?: Maybe<boolean>;
  noMax?: boolean;
}>`
  flex-flow: column nowrap;
  width: ${props => (props.fullWidth ? "100%" : "85%")};
  margin: ${props => props.margin ?? "auto"};
  max-width: ${props => (props.noMax ? "none" : "1200px")};

  ${mediaQuery.tabletDown} {
    max-width: 800px;
    width: ${props => (props.mobileFullWidth ? "100%" : "80%")};
  }
`;

export const MediaLink = styled(GatsbyLink)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 300px;
  height: 300px;

  background-color: ${colors.cream};

  ${mediaQuery.tabletDown} {
    width: initial;
    min-height: 50vh;
    height: 50vh;
  }

  ${mediaQuery.mobileDown} {
    height: 250px;
  }
`;

export const Section = styled.section<{
  noMargins?: boolean;
  display?: CSSProperties["display"];
  marginOverride?: Maybe<string> | undefined;
  backgroundColor?: Color;
}>`
  width: 100%;
  margin: ${props => props.marginOverride ?? "75px auto 0px 0px"};
  ${({ backgroundColor }) => backgroundColor && `background-color: ${colors[backgroundColor]};`}

  ${mediaQuery.mobileDown} {
    margin: 25px auto;
    ${({ marginOverride }) => marginOverride && `margin: ${marginOverride};`};
  }

  ${({ display }) => display && `display: ${display};`}
  ${({ noMargins }) => noMargins && `margin: 0 !important`}
`;

export const InlineTag = styled.span<{ isNavyTag?: boolean }>`
  padding: 10px 20px;
  border: 1px solid black;
  background: ${props => (props.isNavyTag ? colors.navy : colors.white)};
  color: ${props => (props.isNavyTag ? colors.white : colors.navy)};

  ${mediaQuery.tabletDown} {
    display: inline-block;
  }
`;

export const H2Heading = styled.h2`
  font-size: ${fontSizes.h1.default}px;
  font-weight: ${fontWeights.extrabold};
  font-family: ${fontFamilies.tobias}, Arial;
  margin-bottom: 60px;

  ${mediaQuery.tabletDown} {
    margin-bottom: 20px;
  }

  ${mediaQuery.mobileDown} {
    font-size: ${fontSizes.h1.mobile}px;
  }
`;

export const Select = styled.select<{ color?: Color }>`
  width: 100%;
  color: ${colors.navy};
  padding: 15px 25px;
  border-radius: 60px;
  height: 50px;
  ${({ color }) => color && `background-color: ${colors[color]};`}
`;

export const SpanBefore = styled.span<{ before: string }>`
  display: inline-block;
  margin-right: 8px;
  &::before {
    ${({ before }) => before && `content: "${before}:  ";`}
    font-weight: ${fontWeights.bold};

    ${mediaQuery.mobileDown} {
      display: flex;
    }
  }
`;

export const defaultButtonStyle = css`
  text-align: center;
  cursor: pointer;
  user-select: none;
  position: relative;
  height: fit-content;
  padding: 15px 25px;
  box-sizing: border-box;
  border-radius: 50px;
  width: fit-content;
  overflow: hidden;
  transition: 0.3s transform ease-in-out;
  will-change: transform;
  z-index: 0;
  flex-shrink: 0;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(1);
  }

  &[disabled],
  &.disabled,
  &[aria-disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const buttonBaseStyle = `
  color: ${buttonTheme.base.text};
  background-color: ${buttonTheme.base.bg};
  border: none;
`;

export const buttonInverseStyle = `
  color: ${buttonTheme.inverse.text};
  background-color: ${buttonTheme.inverse.bg};
  border: 1.5px solid ${buttonTheme.inverse.border};
`;

export const buttonWhiteStyle = `
  color: ${buttonTheme.white.text};
  background-color: ${buttonTheme.white.bg};
  border: 1.5px solid ${buttonTheme.white.border};
`;

export const buttonWhiteInverseStyle = `
  color: ${buttonTheme.whiteInverse.text};
  background-color: ${buttonTheme.whiteInverse.bg};
  border: 1.5px solid ${buttonTheme.whiteInverse.border};
`;

export const buttonThemeStyle = css<{ buttontheme: ButtonTheme; colorvalue?: Maybe<string> }>`
  color: ${props => buttonTheme[props.buttontheme].text};
  background-color: ${props => props.colorvalue ?? buttonTheme[props.buttontheme].bg};
  border: 1.5px solid ${props => buttonTheme[props.buttontheme].border};
`;

export const ScrollIcon = styled.img`
  position: absolute;
  left: 50%;
  bottom: 8%;
  transform: translateX(-50%) rotateZ(180deg);
  z-index: 2;

  ${animations.pulse("translateX(-50%) rotateZ(180deg)")};
  ${mobileOSQueries.iosMobile} {
    bottom: 80px;
  }

  &:hover {
    transform: translateX(-50%) rotateZ(180deg) scale(1.1);
  }
  cursor: pointer;
  transition: all 0.2s;

  ${mediaQuery.tabletDown} {
    bottom: 6%;
    display: none;
    ${mobileOSQueries.iosMobile} {
      bottom: 80px;
    }
  }

  ${mediaQuery.mobileDown} {
    display: none;
  }
`;

export const PreviewCard = styled(Container)<{
  textColor?: Color;
  backgroundColor?: Color;
  overrideMobileHeight?: string;
}>`
  position: relative;
  cursor: pointer;
  padding: 30px;
  justify-content: space-between;
  flex-direction: column;
  flex-shrink: 0;
  background-color: ${props =>
    props.backgroundColor ? colors[props.backgroundColor] : colors.navy};
  width: 272px;
  height: 384px;

  h3,
  h4,
  p {
    color: ${props => (props.textColor ? colors[props.textColor] : colors.white)};
  }
  h3 {
    font-size: 26px;
  }
  .contact-link {
    color: ${props => (props.textColor ? colors[props.textColor] : colors.white)};
  }

  ${mediaQuery.mobileDown} {
    width: 100%;
    // height: unset;
    padding-bottom: 100%;
    ${({ overrideMobileHeight }) => overrideMobileHeight && `height: ${overrideMobileHeight};`}

    a {
      margin-top: 20px;
    }
  }
`;

export const SearchContainer = styled(Container)`
  width: 64%;
  flex-direction: column;

  column-gap: 20px;
  row-gap: 20px;

  h2 {
    white-space: nowrap;
    color: ${colors.white};
  }

  ${mediaQuery.tabletDown} {
    align-items: flex-start;
    width: 100%;
    flex-direction: column;

    h2 {
      white-space: unset;
    }
  }
`;

export const CreamCardContainer = styled(Container)<{ invert?: boolean }>`
  width: 100%;
  background-color: ${props => (props.invert ? `${colors.navy}` : `${colors.cream}`)};
  color: ${props => (props.invert ? `${colors.white}` : `${colors.navy}`)};
  flex-direction: column;
  padding: 60px 85px;

  h4 {
    font-size: ${fontSizes.h2.default}px;
    margin-bottom: 10px;
  }
  .button-base {
    background-color: ${props => (props.invert ? `${colors.white}` : `${colors.navy}`)};
    color: ${props => (props.invert ? `${colors.navy}` : `${colors.white}`)};
  }

  h3 {
    font-size: 28px;
    margin-bottom: 15px;
  }

  ${mediaQuery.tabletDown} {
    padding: 50px;
  }
  ${mediaQuery.mobileDown} {
    padding: 50px 30px;
  }
  .wrapper {
    margin: 20px 0;
    column-gap: 50px;
    justify-content: space-between;

    ${mediaQuery.tabletDown} {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  ${mediaQuery.tabletDown} {
    h4 {
      font-size: ${fontSizes.h4.default}px;
    }
  }
`;

export const StickyContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  justify-content: flex-end;
  z-index: 2;
  top: 82vh;
  margin-right: ${HEADER_OFFSET + 10}px;
  margin-left: ${HEADER_OFFSET + 10}px;

  ${mediaQuery.mobileDown} {
    margin-right: ${HEADER_MOBILE_OFFSET}px;
    margin-left: ${HEADER_MOBILE_OFFSET}px;
  }
`;

export const Pill = styled(Container)`
  padding: 8px 10px;
  background-color: ${colors.white};
  border: 1px solid ${colors.navy};
  border-radius: 50px;
  cursor: pointer;
  user-select: none;
  align-items: center;
  margin: 0 10px 10px 0;

  span {
    color: ${colors.navy};
  }
  img {
    width: 8px;
    height: 8px;
    margin: 0 10px;
  }

  &:hover {
    opacity: 0.6;
  }
`;

export const DynamicsFormContainer = styled.div`
  height: 0px;
  width: 0px;
  visibility: none;

  position: relative;
  overflow: auto;
  top: 0px;
  left: 0px;
`;
