import React, { useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Controller, Navigation } from "swiper/modules";

import { SanityInfoSlider } from "@graphql-types";
import { Container, DesktopContainer, PageWidth, Section, TabletContainer } from "@util/standard";
import { assets, HEADER_MOBILE_OFFSET, HEADER_OFFSET, mediaQuery } from "@util/constants";
import { BlocksContent, SliderPreviewCard } from "@global";

interface Props {
  data: SanityInfoSlider;
  overrideBackgroundColour?: boolean;
}

function InfoSlider({ data, overrideBackgroundColour }: Props) {
  if (data == null) return null;

  const [controller, setController] = useState<SwiperCore>();
  const { title, description, slides, richDescription } = data;

  return (
    <Section>
      <ContentWrapper>
        <TextWrapper>
          <span>
            {richDescription?._rawBlockContent?.length ? (
              <BlocksContent data={richDescription} />
            ) : (
              <>
                <h3>{title}</h3>
                <p>{description}</p>
              </>
            )}

            <DesktopContainer>
              <Container width="100%" margin="35px auto 0 auto">
                <NavArrow
                  src={assets.circleArrowFill}
                  onClick={() => controller && controller.slidePrev()}
                />
                <NavArrow
                  src={assets.circleArrowFill}
                  flip
                  onClick={() => controller && controller.slideNext()}
                />
              </Container>
            </DesktopContainer>
          </span>
        </TextWrapper>
        <Container width="53%" tabletWidth="100%" flexGrow={1}>
          {slides && (
            <Swiper
              modules={[Controller, Navigation]}
              breakpoints={{
                2200: { slidesPerView: 2.4, spaceBetween: 25 },
                1940: { slidesPerView: 2.4, spaceBetween: 25 },
                1300: { slidesPerView: 1.7, spaceBetween: 25 },
                320: { slidesPerView: 1.2, spaceBetween: 8 },
              }}
              centerInsufficientSlides
              onSwiper={setController}
              onReachEnd={() => controller?.slideTo(slides.length - 1)}
              passiveListeners
            >
              {slides.map((slide, index) => {
                if (slide == null) return null;
                const handleFocus = () => controller?.slideTo(index);

                return (
                  <SwiperSlide key={slide._key}>
                    <SliderPreviewCard
                      {...slide}
                      index={index + 1}
                      slideCount={slides.length}
                      overrideBackgroundColour={overrideBackgroundColour}
                      onFocus={handleFocus}
                    />
                  </SwiperSlide>
                );
              })}
              <SwiperSlide>
                <div />
              </SwiperSlide>
            </Swiper>
          )}
        </Container>
      </ContentWrapper>

      <TabletContainer>
        <Container width="77%" margin="35px auto 0 auto">
          <NavArrow
            src={assets.circleArrowFill}
            onClick={() => controller && controller.slidePrev()}
          />
          <NavArrow
            src={assets.circleArrowFill}
            flip
            onClick={() => controller && controller.slideNext()}
          />
        </Container>
      </TabletContainer>
    </Section>
  );
}

export default InfoSlider;

const ContentWrapper = styled(Container)`
  width: calc(100% - ${(HEADER_OFFSET + 10) * 2}px);
  justify-content: flex-start;
  margin: auto;

  @media screen and (min-width: 1200px) {
    margin-left: calc((100vw - 1200px) / 2);
    width: calc(100vw - (calc((100vw - 1200px) / 2)) - 40px);
  }

  ${mediaQuery.tabletDown} {
    flex-direction: column;
  }
  ${mediaQuery.mobileDown} {
    width: calc(100% - ${(HEADER_MOBILE_OFFSET + 10) * 2}px);
  }
`;

const TextWrapper = styled(Container)`
  flex-direction: column;
  width: 50%;
  max-width: 600px;
  flex-shrink: 0;

  span {
    width: 72%;
    margin-left: 4%;
  }

  @media screen and (min-width: 1300px) {
    margin-left: 0px;
  }

  ${mediaQuery.tabletDown} {
    width: 93%;
    margin: 0 auto 50px auto;
  }
`;

const NavArrow = styled.img<{ flip?: boolean }>`
  ${({ flip }) => flip && `transform: rotateZ(180deg);`};
  margin-right: 20px;
  cursor: pointer;
`;
