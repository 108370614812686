import React from "react";
import styled from "styled-components";

import FacebookPlayer from "react-player/facebook";
import VimeoPlayer from "react-player/vimeo";
import YoutubePlayer from "react-player/youtube";

import { SanityVideo } from "@graphql-types";
import { Container, PageWidth, Section } from "@util/standard";
import { BlocksContent } from "@global";

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0px;
  height: 0;
  iframe {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
`;

interface Props {
  data: SanityVideo;
  disableMargins?: boolean;
}

function Video({ data, disableMargins }: Props) {
  if (data == null) return null;

  const { facebookVideoId, vimeoVideoId, youtubeId, videoHeader } = data;

  if (facebookVideoId == null && vimeoVideoId == null && youtubeId == null) return null;
  const ariaLabel = "Video Block";

  const facebookUrl = `https://www.facebook.com/facebook/videos/${facebookVideoId}`;
  const vimeoId = `https://player.vimeo.com/video/${vimeoVideoId}?texttrack=en&controls=1`;
  const youtubeUrl = `https://www.youtube.com/watch?v=${youtubeId}`;

  return (
    <Section aria-labelledby={ariaLabel} marginOverride={disableMargins ? "0 auto" : null}>
      <PageWidth>
        {videoHeader && (
          <Container width="70%" tabletWidth="100%" margin="0 auto 40px auto">
            <BlocksContent data={videoHeader} />
          </Container>
        )}
        <Container flexDirection="column" width="70%" margin="0 auto" tabletWidth="100%">
          <VideoWrapper>
            {facebookVideoId && <FacebookPlayer controls width="100%" url={facebookUrl} />}
            {vimeoVideoId && <VimeoPlayer controls width="100%" url={vimeoId} />}
            {youtubeId && <YoutubePlayer controls width="100%" url={youtubeUrl} />}
          </VideoWrapper>
        </Container>
      </PageWidth>
    </Section>
  );
}

export default Video;
