import React from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";

import { Link } from "@global";
import { Maybe, SanityNavItem, SanityNavLink } from "@graphql-types";
import {
  assets,
  colors,
  fontFamilies,
  HEADER_HEIGHT,
  mediaQuery,
  TWENTY_INCH_SCREEN,
} from "@util/constants";
import { useArrayChunks } from "@util/hooks";

interface Props {
  data: Maybe<Maybe<SanityNavItem>[]> | undefined;
  pageColor: string;
}

export default function MegaMenu({ data, pageColor }: Props) {
  if (data == null) return null;

  // TODO: make hover accessible

  return (
    <Nav pageColor={pageColor}>
      <ul id="list">
        {data.map((navItem, index) => {
          if (navItem == null) return null;
          return (
            <SubMenu key={index} navItem={navItem} isLast={data.length - 1 === index && true} />
          );
        })}
      </ul>
    </Nav>
  );
}

interface SubMenuProps {
  navItem: SanityNavItem;
  isLast: boolean;
}

const SubMenu = ({ navItem, isLast }: SubMenuProps) => {
  const { title, links } = navItem;
  const chunks = useArrayChunks(links, 6);

  return (
    <li {...(isLast && { className: `last-parent-li` })}>
      <button>
        {title} <img src={assets.chervon} aria-hidden="true" />
      </button>
      <ul className="submenu">
        {chunks &&
          (chunks as SanityNavLink[][]).map((links, index) => {
            if (links == null) return null;
            const unstyledLinks = links.filter(navLink => navLink.style !== "button");
            const styledLinks = links.filter(navLink => navLink.style === "button");

            return (
              <ul id="inner-list" key={`list-${index}`}>
                {unstyledLinks && (
                  <li key={`list-item-${index}`}>
                    {unstyledLinks?.map((link, index) => {
                      if (link == null) return null;

                      return <Link key={index} {...link} />;
                    })}
                  </li>
                )}
                {styledLinks && (
                  <li key={`list-big-item-${index}`}>
                    {styledLinks.map((link, index) => {
                      if (link == null) return null;

                      return (
                        <BigLink key={index} to={`/${link.internalLink?.slug?.current}`}>
                          <p>{link.linkText}</p>
                        </BigLink>
                      );
                    })}
                  </li>
                )}
              </ul>
            );
          })}
      </ul>
    </li>
  );
};

const Nav = styled.nav<{ pageColor: string }>`
  width: fit-content;
  top: 0px;
  left: 250px;
  display: flex;
  align-items: center;
  z-index: 4;

  #list {
    height: inherit;
    display: flex;
    align-items: center;
  }

  ul li,
  ul#inner-list {
    position: relative;
    display: inline-block;
    list-style: none;
    height: 100%;
  }

  ul li button {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding-right: 40px;
    padding-left: 0px;
    height: 100%;
    color: ${colors.navy};
    border: none;
    background-color: transparent;
    cursor: pointer;

    img {
      margin-left: 8px;
    }
  }

  ul li a {
    display: flex;
    align-items: center;
    text-decoration: none;
    height: 100%;
  }

  button:hover {
    text-shadow: 0px 0px 1px ${colors.navy};
  }

  ul li a:hover,
  button:hover {
    text-decoration: underline;
    img {
      transform: rotate(180deg);
    }
  }

  ul li ul.submenu {
    display: none;
    width: max-content;
    background-color: ${colors.white};
    border-color: ${props => props.pageColor};
    border-style: solid;
    border-width: 0 10px 10px 10px;
    position: absolute;
    left: -20px;
    padding: 40px;
  }

  ul li:hover ul.submenu {
    display: flex;
  }
  ul.submenu li a {
    padding: 10px;
  }

  .last-parent-li ul.submenu {
    left: unset;
    right: -250px;
  }

  ${mediaQuery.smallLaptopDown} {
    ul li button {
      padding-right: 20px;
    }
  }
`;

const BigLink = styled(GatsbyLink)`
  background-color: ${colors.navy};
  max-width: 270px;
  max-height: 220px;
  margin-left: 40px;
  transition: transform ease-in-out 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  p {
    color: ${colors.white};
    font-size: 28px;
    line-height: 34px;
    font-family: ${fontFamilies.tobias};
    padding: 77px 40px;
  }
`;
