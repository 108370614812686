import React, { useEffect, useState } from "react";

import { Maybe, SanityMapBlock } from "@graphql-types";
import { Container, PageWidth, Section } from "@util/standard";
import { useStore } from "@state/store";
import GoogleMap from "./googleMap";
import { MapMarker, MarkerProps } from "@components/mapMarker";

interface Props {
  data: Maybe<SanityMapBlock>;
}

const MapBlock = ({ data }: Props) => {
  const { village } = useStore();
  const [mapCenter, setMapCenter] = useState<MarkerProps>({ lat: village.lat, lng: village.lng });

  useEffect(() => {
    if (village?.lat && village?.lng) {
      setMapCenter({ lat: village.lat, lng: village.lng });
    }
  }, [village]);

  return (
    <Section>
      <PageWidth>
        <Container height="60vh" flexDirection="column" rowGap="20px">
          <h3>{data?.title ?? "Location"}</h3>
          <GoogleMap mapCenter={mapCenter}>
            <MapMarker lat={village.lat} lng={village.lng} />
          </GoogleMap>
        </Container>
      </PageWidth>
    </Section>
  );
};

export default MapBlock;
