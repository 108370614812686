import { GlobalStyle, Main } from "@styles/globalStyles";
import React, { ReactNode } from "react";
import {
  useDynamics,
  useDynamicsFormLoad,
  useMarketoCookies,
  useUpdatePagePathChange,
  useRescueMetrics,
} from "@util/hooks";
import { useInitialFacetsFromAlgolia, useSetStaticQueriesToStore } from "@util/logicHooks";
import { useSendVillageDataLayer } from "@util/datalayer";

import { Footer } from "@shared";
import { isBrowser } from "@util/helper";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
interface Props {
  children: ReactNode;
}

export default function Layout(props: Props) {
  const { children } = props;

  // hooks
  useInitialFacetsFromAlgolia();
  useUpdatePagePathChange();
  useSetStaticQueriesToStore();
  useSendVillageDataLayer();
  useMarketoCookies();

  // useRescueMetrics();
  // useTeknklSimpleDTO();

  return (
    <div>
      <DynamicsTracking />
      <GlobalStyle />
      <Main>{children}</Main>
      {/* <LeadCaptureForm /> */}
      <Footer />
      <ToastContainer position="bottom-left" theme="colored" autoClose={3000} />
    </div>
  );
}

const DynamicsTracking = () => {
  useDynamics();

  if (isBrowser()) {
    const path = window.location.pathname;
    if (path.includes("eventregistration")) {
      return <></>;
    }
  }

  return (
    <>
      <div id={process.env.GATSBY_DYNAMICS_CONFIG_ID}></div>
      <div
        className="d365-mkt-config"
        style={{ display: "none" }}
        data-website-id={process.env.GATSBY_DYNAMICS_WEBSITE_ID}
        data-hostname={process.env.GATSBY_DYNAMICS_DATA_HOSTNAME}
      ></div>
    </>
  );
};
