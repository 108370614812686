import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

export default function FormSuccessTextQuery() {
  const { sanityFormSuccessText }: Query = useStaticQuery(graphql`
    {
      sanityFormSuccessText {
        content {
          _rawBlockContent(resolveReferences: { maxDepth: 2 })
        }
        link {
          ...sanityLink
        }
        infoPacksSuccessText {
          _rawBlockContent(resolveReferences: { maxDepth: 2 })
        }
        metguidesSuccessText {
          _rawBlockContent(resolveReferences: { maxDepth: 2 })
        }
        bookATourSuccessText {
          _rawBlockContent(resolveReferences: { maxDepth: 2 })
        }
        contactSuccessText {
          _rawBlockContent(resolveReferences: { maxDepth: 2 })
        }
      }
    }
  `);

  return sanityFormSuccessText;
}
