import React from "react";
import styled from "styled-components";

import { Maybe, SanitySlug } from "@graphql-types";
import { Container } from "@util/standard";
import { colorsRGB } from "@util/constants";
import { Link } from "@global";

interface Props {
  title: Maybe<string> | undefined;
  description: Maybe<string> | undefined;
  slug: Maybe<SanitySlug> | undefined;
  linkText: string;
  region?: any;
  _type?: Maybe<string> | undefined;
}

export default function SearchResult({ title, description, slug, linkText, region, _type }: Props) {
  return (
    <Wrapper>
      <h4>{title}</h4>
      <p>{description}</p>
      <Link className="button-base" internalLink={{ slug, region, _type }} linkText={linkText} />
    </Wrapper>
  );
}

const Wrapper = styled(Container)`
  flex-direction: column;
  border-top: 1px solid ${colorsRGB.navy()};
  padding: 30px 0;
`;
