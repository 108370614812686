import React from "react";
import styled from "styled-components";

import { SanityTestimonials } from "@graphql-types";
import { colors, fontWeights, mediaQuery } from "@util/constants";
import { Container, GridContainer, PageWidth, Section } from "@util/standard";

interface Props {
  data: SanityTestimonials;
}

function Testimonials({ data }: Props) {
  if (data.testimonies == null) return null;

  const { testimonies } = data;

  const ariaLabel = "Testimonials-section";

  return (
    <Section aria-labelledby={ariaLabel}>
      <Wrapper>
        <Title>Testimonials</Title>
        <GridContainer repeat={3} tabletRepeat={1} columnGap={25} rowGap={10}>
          {testimonies.map((testimony, index) => {
            if (testimony == null) return null;
            const { testimonyText, name } = testimony;
            return (
              <TestimonyWrapper key={index}>
                <span className="span-wrapper">
                  <span className="hr-wrapper">
                    <hr></hr>
                  </span>
                  <p className="testimony-text">"{testimonyText}"</p>
                </span>
                <p className="testimony-name">{name}</p>
              </TestimonyWrapper>
            );
          })}
        </GridContainer>
      </Wrapper>
    </Section>
  );
}

export default Testimonials;

const Wrapper = styled(PageWidth)`
  ${mediaQuery.tabletDown} {
    width: 100%;
    padding: 0;
  }
`;

const Title = styled.h3`
  margin-bottom: 40px;
  ${mediaQuery.tabletDown} {
    width: 80%;
    padding: 0 10px;
    margin: 0 auto 40px auto;
  }
`;

const TestimonyWrapper = styled(Container)`
  flex-direction: column;
  justify-content: space-between;

  .span-wrapper {
    width: 77%;
    margin: 0px auto;
  }

  hr {
    width: 30%;
    margin: 0 auto 0 0;
    border-bottom: 1px solid ${colors.navy};
  }

  justify-content: space-between;
  p {
    width: 77%;
    margin: 0px auto;
  }

  .testimony-text {
    font-weight: ${fontWeights.bold};
    font-family: "Tobias";
    margin-top: 20px;
    margin: 20px 0;
    font-size: 20px;
    line-height: 24px;
  }
`;
