import React, { ReactNode } from "react";

import FacebookPlayer from "react-player/facebook";
import VimeoPlayer from "react-player/vimeo";
import YoutubePlayer from "react-player/youtube";
import { BaseReactPlayerProps } from "react-player/base";
import styled from "styled-components";

import { Maybe, SanityVideoFields } from "@graphql-types";
import { colorsRGB, mediaQuery } from "@util/constants";

interface Props {
  video: Maybe<SanityVideoFields> | undefined;
  playerProps?: BaseReactPlayerProps;
  // fallback image required for mobile
  children: ReactNode;
}

const BackgroundVideo = ({ video, playerProps, children }: Props) => {
  if (!video) return null;

  const { facebookVideoId, vimeoVideoId, youtubeId } = video;
  const facebookUrl = `https://www.facebook.com/facebook/videos/${facebookVideoId}`;
  const vimeoId = `https://player.vimeo.com/video/${vimeoVideoId}?texttrack=en&controls=1`;
  const youtubeUrl = `https://www.youtube.com/watch?v=${youtubeId}`;

  const commonProps = {
    ...playerProps,
    width: "100%",
    height: "100%",
    controls: false,
    playing: true,
    muted: true,
    volume: 0,
    loop: true,
    playsinline: true,
    className: "react-player",
  };

  const Video = () => {
    if (facebookVideoId) {
      return <FacebookPlayer {...commonProps} url={facebookUrl} />;
    }

    if (vimeoVideoId) {
      return <VimeoPlayer {...commonProps} url={vimeoId} />;
    }

    if (youtubeId) {
      return <YoutubePlayer {...commonProps} url={youtubeUrl} />;
    }

    return null;
  };

  return (
    <VideoWrapper>
      {children}
      <Video />
    </VideoWrapper>
  );
};

export default BackgroundVideo;

const VideoWrapper = styled.div`
  overflow: hidden;
  ::after {
    content: "";
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${colorsRGB.black(0.2)};
    position: absolute;
  }
  iframe {
    width: 100vw !important;
    height: 56.25vw !important;
    min-height: 100vh;
    min-width: 250vh;
    border: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  ${mediaQuery.mobileDown} {
    .react-player {
      display: none;
    }
  }
`;
