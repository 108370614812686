import React from "react";
import styled from "styled-components";
import { SwiperSlide } from "swiper/react";

import { Container, PageWidth } from "@util/standard";
import { Carousel, Image, Link } from "@global";
import { fontSizes, mediaQuery } from "@util/constants";
import { Maybe, SanityImageWithMeta, SanityVillageHomeOption } from "@graphql-types";
import { useStore } from "@state/store";

interface Props {
  data: Maybe<SanityVillageHomeOption>;
}

export default function PropertyPreview({ data }: Props) {
  const { village } = useStore();
  if (data == null || village == null) return null;
  const { listingGallery, title, listingPrice, excerpt, featuresLists, slug: propertySlug } = data;
  const slug = {
    current: `our-retirement-villages/${village.region}/${village.slug}/home-options/${propertySlug?.current}`,
  };

  const featureList = featuresLists && featuresLists?.length > 0 ? featuresLists[0] : null;

  return (
    <Wrapper>
      {Boolean(listingGallery?.images?.length) && (
        <Carousel>
          {listingGallery?.images?.map((image, index) => {
            if (image == null) return null;
            return (
              <SwiperSlide key={index}>
                <ImageContainer>
                  <Image data={image as SanityImageWithMeta} width="100%" objectFit="cover" />
                </ImageContainer>
              </SwiperSlide>
            );
          })}
        </Carousel>
      )}
      <ContentContainer fullWidth>
        <div>
          <Link internalLink={{ slug }}>
            <h4>{title}</h4>
          </Link>
          {listingPrice && <p className="price">{listingPrice}</p>}
          <p>{excerpt}</p>
          {featureList && (
            <div key={featureList.title}>
              <p>
                <strong>{title}</strong>
              </p>
              <ul>
                {featureList.listItems?.map(item => {
                  if (item == null) return null;
                  return <li key={item}>{item}</li>;
                })}
              </ul>
            </div>
          )}
        </div>

        <Link
          className="button-base"
          margin="20px 0 0 0"
          linkText={`View ${title?.toLowerCase()}`}
          internalLink={{ slug }}
        />
        {data.disclaimerText && <p className="disclaimer-text">{data.disclaimerText}</p>}
      </ContentContainer>
    </Wrapper>
  );
}

const ContentContainer = styled(PageWidth)`
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  .price {
    font-weight: bold;
    font-size: 20px;
    margin: 10px 0;
  }
  h4 {
    margin-top: 25px;
  }
  li {
    list-style: initial;
    margin-left: 20px;
  }
  .disclaimer-text {
    font-size: ${fontSizes.p.mobile}px;
  }
  ${mediaQuery.tabletDown} {
    margin: 0;
  }
`;

const Wrapper = styled(Container)`
  width: 100%;
  flex-direction: column;

  ${mediaQuery.tabletDown} {
    width: 80%;
    margin: auto;
  }
`;

const ImageContainer = styled(Container)`
  width: 100%;
  height: 400px;
  position: relative;

  img {
    -webkit-user-drag: none;
    object-fit: cover;
    filter: brightness(0.9);
    width: inherit;
  }

  ${mediaQuery.mobileDown} {
    height: 250px;
  }
`;
