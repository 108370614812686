import React, { useState } from "react";
import styled from "styled-components";

import { Maybe, SanityHeroSlide, SanityLink } from "@graphql-types";
import { BlocksContent, Image, Link, VariedImages } from "@global";
import { assets, mediaQuery } from "@util/constants";
import { Container, PageWidth, ScrollIcon } from "@util/standard";
import { useStore } from "@state/store";
import { isBrowser } from "@util/helper";
import BackgroundVideo from "./backgroundVideo";
import CareNavigation from "./careNavigation";

interface Props {
  data: SanityHeroSlide;
  childContent?: any;
  careNavigation?: Maybe<Maybe<SanityLink>[]> | undefined;
  slideIndex: number;
  hasVariantSelector?: boolean;
}

export default function HeroSlide({
  data,
  childContent,
  careNavigation,
  slideIndex,
  hasVariantSelector = false,
}: Props) {
  const { pageColor } = useStore();
  const [videoError, setVideoError] = useState(false);

  if (!data) return null;
  const { backgroundImage, links, title, blockContent, video, variedImages } = data;

  const hasMedia = Boolean(backgroundImage || video);

  const handleClick = () => {
    if (isBrowser()) {
      const questions = document.getElementById("qualifying-questions");

      if (questions) {
        questions.scrollIntoView({ behavior: "smooth", block: "center" });
      } else {
        const viewHeight = window.innerHeight;
        window.scrollTo({ top: viewHeight, behavior: "smooth" });
      }
    }
  };

  const ImageRender = () => {
    if (variedImages && variedImages.length > 0) {
      return (
        <VariedImages
          data={variedImages}
          defaultImage={backgroundImage}
          imageProps={{
            isBackground: true,
            filter: "brightness(0.7)",
          }}
        />
      );
    }

    return (
      <Image
        isBackground
        data={backgroundImage}
        objectFit="cover"
        width="100%"
        filter="brightness(0.7)"
      />
    );
  };

  const Content = () => (
    <ContentWrapper
      hasImage
      pageColor={!hasMedia ? pageColor : undefined}
      maxWidth={"50%"}
      hasVariantSelector={hasVariantSelector}
      className="hero-content-wrapper"
    >
      <h1 tabIndex={0}>{title}</h1>
      {blockContent && <BlocksContent data={blockContent} />}
      {careNavigation && slideIndex === 0 && careNavigation.length > 0 && (
        <CareNavigation data={careNavigation} />
      )}
      {links && links.length > 0 && (
        <Container flexFlow="wrap" margin="30px 0 0 0" columnGap="10px" id="hero-links">
          {links.map(link => {
            if (link == null) return null;
            const { _key, ...rest } = link;

            return (
              <Container margin="10px 10px 0 0" key={_key}>
                <Link {...rest} colorvalue={pageColor} />
              </Container>
            );
          })}
        </Container>
      )}
      {childContent}
    </ContentWrapper>
  );

  if (video && !videoError) {
    return (
      <>
        <BackgroundVideo
          video={video}
          playerProps={{
            onError: () => setVideoError(true),
          }}
        >
          <ImageRender />
        </BackgroundVideo>
        <Content />
        {hasMedia && (
          <ScrollIcon
            tabIndex={0}
            src={assets.goToTop}
            onClick={handleClick}
            className="scroll-down"
            aria-label="Scroll down"
          />
        )}
      </>
    );
  }

  return (
    <>
      <ImageRender />
      <Content />
      {hasMedia && (
        <ScrollIcon
          tabIndex={0}
          src={assets.goToTop}
          onClick={handleClick}
          className="scroll-down"
          aria-label="Scroll down"
        />
      )}
    </>
  );
}

export const ContentWrapper = styled(PageWidth)<{
  hasImage?: boolean;
  pageColor?: string;
  hasVariantSelector?: boolean;
}>`
  position: ${props => (props.hasImage ? "absolute" : "unset")};
  opacity: ${props => (props.hasVariantSelector ? "0" : "1")};

  bottom: 39%;
  @media screen and (max-height: 650px) {
    bottom: 15%;
  }

  left: 10%;

  h1 {
    ${({ pageColor }) => pageColor && `color: ${pageColor};`}
  }

  ${mediaQuery.tabletDown} {
    width: 69%;
    left: calc(10% + 20px);
    bottom: calc(17% + 15px);
  }
`;
