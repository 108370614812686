import { DataLayerContactForm, FormDataTypes } from "./types";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Maybe, SanityForm } from "@graphql-types";
import { LeadCapture, SelectedMetguides, SelectedVillageInfoPack } from "@state/types";
import { appendMarketoScript, isBrowser, returnFormData } from "./helper";

import { usePrevious, useUpdateLogger } from "./hooks";
import { useStore } from "@state/store";
import useWhyDidYouUpdate from "./useWhyDidYouUpdate";

interface PageDataLayer {
  site_section?: string;
  village_name?: string | undefined;
  page_url: string;
  page_title: string;
}

/*
 *
 * Data Layer Variables
 */

//Send Personalisation / Lead update
export function sendPersonalisation(lead: LeadCapture) {
  if (lead.metlifeEnquiry == null) return;
  if (!isBrowser()) return null;
  window.dataLayer = window.dataLayer || [];
  return dataLayer.push({
    event: "personalisation_update",
    // user_id: "<< USER_ID >>",
    user_info: {
      enquiring_for: lead?.metlifeEnquiry,
    },
  });
}

//Page view
export function sendPageView(page: PageDataLayer) {
  if (!isBrowser()) return null;
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];

  //@ts-ignore
  return dataLayer.push({
    event: "page_view",
    page,
    personalisation: {
      researching_for: "<researching for>",
      research_stage: "<research stage>",
      q3_tbc: "<q3_tbc>",
      q4_tbc: "<q4_tbc>",
      g5_tbc: "<q5_tbc>",
      g6_tbc: "<q6_tbc>",
    },
  });
}

//Village Views
export function useSendVillageDataLayer() {
  const { village, currentPagePath, pageType } = useStore();
  const prevCurrentPagePath = usePrevious(currentPagePath);

  useEffect(() => {
    if (pageType !== "village") return;
    if (village?.slug == null) return;
    if (!prevCurrentPagePath.includes(village.slug)) {
      sendVillageView(
        village.title,
        village.villageCode,
        village.region,
        village.isDevelopment,
        village.parentRegion,
      );
    }
  }, [currentPagePath, village]);
}

export function sendVillageView(
  villageName: string | undefined,
  villageCode: string | undefined,
  villageRegion: string | undefined,
  isDevelopment: boolean | undefined,
  villageParentRegion: string | undefined,
) {
  // const development = Boolean(isDevelopment == "true");

  if (!isBrowser()) return null;
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  dataLayer.push({ ecommerce: null });
  //@ts-ignore
  return dataLayer.push({
    event: "view_item",
    ecommerce: {
      items: [
        {
          item_id: villageCode,
          item_name: villageName,
          item_category2: villageRegion,
          item_category: villageParentRegion,
          item_variant: isDevelopment ? "In development" : "Active",
        },
      ],
    },
  });
}

//Form fills
export function sendFormFill(data: DataLayerContactForm) {
  if (!isBrowser) return null;
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

  //@ts-ignore
  return dataLayer.push({
    event: "view_form_step",
    event_info: {
      ...data,
    },
  });
}

export function useTeknklSimpleDTO() {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [marketoLoaded, setMarketoLoaded] = useState(false);
  if (!isBrowser()) return;

  useEffect(() => {
    appendMarketoScript("//info.metlifecare.co.nz", setMarketoLoaded);
  }, []);

  useEffect(() => {
    if (marketoLoaded) {
      appendTeknklScript(setScriptLoaded);
    }
  }, [marketoLoaded]);

  useEffect(() => {
    if (!scriptLoaded) return;
    // @ts-ignore
    const DTO = new window.SimpleDTO({
      domain: "metlifestaging.flightdigital.co.nz",
      dataSrc: "https://go.metlifestaging.flightdigital.co.nz/DTP-104.html",
      debug: true,
      mode: "receive",
      cb: () => {
        const mktoFields = DTO.getGlobal()["mktoPreFillFields"];
        DTO.cleanup();
        console.log({ DTO });

        // @ts-ignore
        window.MktoForms2.whenReady((form: any) => {
          console.log({ form });
          const formVals = form.getValues();
          console.log({ formVals });

          form.setValuesCoerced(mktoFields);
        });
      },
    });
  }, [scriptLoaded]);

  return scriptLoaded;
}

export function appendTeknklScript(setScriptLoaded: Dispatch<SetStateAction<boolean>>) {
  if (!isBrowser() || !window.location.href.includes("flightdigital")) return;

  const script = document.createElement("script");
  script.src = `https://go.metlifestaging.flightdigital.co.nz/rs/118-HBS-435/images/teknkl-simpledto-1.0.4.js`;
  script.id = "teknklSimpleDTO-1.0.4";
  script.onload = () => setScriptLoaded(true);
  document?.body?.appendChild(script);
}

export function useFormData(type: string, isMarketo = false) {
  const { lead, village, selectedMetguides, selectedVillageInfoPacks, getSelectedVillageCodes } =
    useStore();
  // TODO: add default state for radio buttons

  const data = returnFormData(
    type,
    village?.villageCode,
    lead,
    selectedMetguides,
    getSelectedVillageCodes(),
    isMarketo,
  );

  const [formData, setFormData] = useState<FormDataTypes>(data);

  useEffect(() => {
    setFormData(data);
  }, [selectedVillageInfoPacks]);

  useEffect(() => {
    if (type == "") return;
    setFormData(data);
  }, []);

  return { formData, setFormData };
}

// ecommerce tracking for metguides
export function sendMetguideViewItems(guides: any) {
  if (guides == null) return;
  const items = guides.map((category: any) => {
    return category.forms.map((form: any) => {
      return {
        item_id: form.slug.current,
        item_name: form.title,
        item_category: category.category.categoryId.current,
        item_brand: "Guides",
      };
    });
  });

  const view_items = {
    item_list_id: "metguides",
    item_list_name: "Metguides",
    items: items.flat(),
  };

  if (!isBrowser) return null;
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  dataLayer.push({ docs_ecommerce: null }); // Clear the previous ecommerce object.

  //@ts-ignore
  return dataLayer.push({
    event: "view_item_list",
    docs_ecommerce: view_items,
    ecom_context: "docs",
  });
}

export function sendMetguideViewItem(
  title: Maybe<string> | undefined,
  slug: Maybe<string> | undefined,
  category: Maybe<string> | undefined,
) {
  if (title == null) return;

  const view_item = {
    item_id: slug,
    item_name: title,
    item_category: category,
    item_brand: "Guides",
  };

  if (!isBrowser) return null;
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  dataLayer.push({ event_info: null });
  //@ts-ignore
  return dataLayer.push({
    event: "view_item",
    ecom_context: "docs",
    docs_ecommerce: view_item,
  });
}

export function sendMetguideAddToCart(
  title: Maybe<string> | undefined,
  slug: Maybe<string> | undefined,
  category: Maybe<string> | undefined,
) {
  if (title == null) return;

  const add_to_cart = [
    {
      item_id: slug,
      item_name: title,
      item_category: category,
      item_brand: "Guides",
    },
  ];

  if (!isBrowser) return null;
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  dataLayer.push({ docs_ecommerce: null }); // Clear the previous ecommerce object.

  //@ts-ignore
  return dataLayer.push({
    ecom_context: "docs",
    event: "add_to_cart",
    docs_ecommerce: { items: add_to_cart },
  });
}

export function sendMetguidRemoveFromCart(
  title: Maybe<string> | undefined,
  slug: Maybe<string> | undefined,
  category: Maybe<string> | undefined,
) {
  if (title == null) return;

  const remove_from_cart = {
    item_id: slug,
    item_name: title,
    item_category: category,
    item_brand: "Guides",
  };

  if (!isBrowser) return null;
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  dataLayer.push({ docs_ecommerce: null }); // Clear the previous ecommerce object.

  //@ts-ignore
  return dataLayer.push({
    event: "remove_from_cart",
    ecom_context: "docs",
    docs_ecommerce: { items: remove_from_cart },
  });
}

export function sendMetguideViewCart(selectedMetguides: SelectedMetguides[]) {
  const items = selectedMetguides.map(metguide => {
    return {
      item_id: metguide.guidePDFName,
      item_name: metguide.guideName,
      item_brand: "Guides",
    };
  });

  if (!isBrowser) return null;
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  dataLayer.push({ docs_ecommerce: null }); // Clear the previous ecommerce object.

  //@ts-ignore
  return dataLayer.push({
    ecom_context: "docs",
    event: "view_cart",
    docs_ecommerce: { items },
  });
}

export function sendMetguidesPurchase(
  selectedMetguides: SelectedMetguides[],
  form?: Maybe<SanityForm> | undefined,
) {
  if (selectedMetguides == null && form == null) return;

  const items =
    selectedMetguides &&
    selectedMetguides.map(metguide => {
      return {
        item_id: metguide.guidePDFName,
        item_name: metguide.guideName,
        item_brand: "Guides",
        item_category: metguide.guideCategory,
      };
    });

  const formItem = form && [
    {
      item_id: form.dynamicsName,
      item_name: form.title,
      item_brand: "Guides",
      item_category: form.guideCategory,
    },
  ];

  if (!isBrowser) return null;
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  dataLayer.push({ docs_ecommerce: null }); // Clear the previous ecommerce object.

  //@ts-ignore
  return dataLayer.push({
    ecom_context: "docs",
    event: "purchase",
    docs_ecommerce: formItem ? { formItem } : { items },
  });
}

//ecommerce tracking for info packs
export function sendInfoPackViewItems(guides: any) {
  if (guides == null) return;
  const flattened = guides.map((guide: any) => {
    return guide.forms.map((form: any) => {
      return {
        item_id: form.villageCode,
        item_name: form.title,
        item_category: "village info pack",
        item_brand: "Info Packs",
      };
    });
  });

  const view_items = {
    item_list_id: "infoPacks",
    item_list_name: "InfoPacks",
    items: flattened.flat(),
  };

  if (!isBrowser) return null;
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  dataLayer.push({ docs_ecommerce: null }); // Clear the previous ecommerce object.

  //@ts-ignore
  return dataLayer.push({
    event: "view_item_list",
    docs_ecommerce: view_items,
    ecom_context: "docs",
  });
}

export function sendInfoPackViewItem(
  title: Maybe<string> | undefined,
  slug: Maybe<string> | undefined,
  category: Maybe<string> | undefined,
) {
  if (title == null) return;

  const view_item = {
    item_id: slug,
    item_name: title,
    item_category: category,
    item_brand: "Guides",
  };

  if (!isBrowser) return null;
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  dataLayer.push({ event_info: null });
  //@ts-ignore
  return dataLayer.push({
    event: "view_item",
    ecom_context: "docs",
    docs_ecommerce: view_item,
  });
}

export function sendInfoPackAddToCart(
  title: Maybe<string> | undefined,
  slug: Maybe<string> | undefined,
  category: Maybe<string> | undefined,
) {
  if (title == null) return;

  const add_to_cart = [
    {
      item_id: slug,
      item_name: title,
      item_category: category,
      item_brand: "Info Packs",
    },
  ];

  if (!isBrowser) return null;
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  dataLayer.push({ docs_ecommerce: null }); // Clear the previous ecommerce object.

  //@ts-ignore
  return dataLayer.push({
    ecom_context: "docs",
    event: "add_to_cart",
    docs_ecommerce: { items: add_to_cart },
  });
}

export function sendInfoPackRemoveFromCart(
  title: Maybe<string> | undefined,
  slug: Maybe<string> | undefined,
  category: Maybe<string> | undefined,
) {
  if (title == null) return;

  const remove_from_cart = {
    item_id: slug,
    item_name: title,
    item_category: category,
    item_brand: "Info Packs",
  };

  if (!isBrowser) return null;
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  dataLayer.push({ docs_ecommerce: null }); // Clear the previous ecommerce object.

  //@ts-ignore
  return dataLayer.push({
    event: "remove_from_cart",
    ecom_context: "docs",
    docs_ecommerce: { items: remove_from_cart },
  });
}

export function sendInfoPackViewCart(selectedMetguides: SelectedMetguides[]) {
  const items = selectedMetguides.map(metguide => {
    return {
      item_id: metguide.guidePDFName,
      item_name: metguide.guideName,
      item_brand: "Guides",
    };
  });

  if (!isBrowser) return null;
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  dataLayer.push({ docs_ecommerce: null }); // Clear the previous ecommerce object.

  //@ts-ignore
  return dataLayer.push({
    ecom_context: "docs",
    event: "view_cart",
    docs_ecommerce: { items },
  });
}

export function sendInfoPackPurchase(selectedVillageInfoPacks: SelectedVillageInfoPack[]) {
  if (selectedVillageInfoPacks == null) return;

  const items = selectedVillageInfoPacks.map(infoPack => {
    return {
      item_id: infoPack.villageCode,
      item_name: infoPack.villageTitle,
      item_brand: "Info Packs",
      item_category: "village info pack",
    };
  });

  if (!isBrowser) return null;
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  dataLayer.push({ docs_ecommerce: null }); // Clear the previous ecommerce object.

  //@ts-ignore
  return dataLayer.push({
    ecom_context: "docs",
    event: "purchase",
    docs_ecommerce: { items },
  });
}
