import React, { createElement } from "react";
import PortableText from "@sanity/block-content-to-react";
import styled from "styled-components";

import { Maybe, SanityBlockContent } from "@graphql-types";
import { InlineTag } from "@util/standard";
import { urlForImageRef } from "@lib/sanityClient";
import { fontFamilies, fontSizes, mediaQuery } from "@util/constants";
import { Link, VillageLivingOptions } from "@global";
import { useStore } from "@state/store";
import { IconMarkerPlain, IconPhone, IconSalesIndependent } from "@util/assets";

interface Props {
  data: Maybe<SanityBlockContent> | undefined;
}

const BlocksContent = ({ data }: Props) => {
  if (data == null) return null;

  const { _rawBlockContent, blockContent } = data;

  return (
    <Wrapper className="rte">
      <PortableText
        blocks={_rawBlockContent ?? blockContent}
        serializers={{
          types: { block, link, imageWithMeta: ImageRenderer, showVillageFilter: VillageFilters },
          marks: { inlineTag, inlineLink, variantText, icon },
        }}
        imageOptions={{ auto: "format", fit: "fill" }}
        projectId={process.env.SANITY_PROJECT_ID}
        dataset={process.env.GATSBY_NODE_ENV ?? "development"}
      />
    </Wrapper>
  );
};

export default BlocksContent;

const block = (props: any) => {
  const { style = "normal", markDefs } = props?.node;
  const tag = style;
  const seoTag = markDefs?.[0]?.tag;

  const tagToRender = seoTag || tag;
  if (/^h\d/.test(tagToRender)) {
    return createElement(
      tagToRender,
      {
        style: { margin: "0" },
        tabIndex: 0,
        className: tag,
      },
      props.children,
    );
  }
  return <p>{props.children}</p>;
};

const inlineTag = (props: any) => (
  <InlineTag isNavyTag={props?.mark?.navyTag}>{props.children}</InlineTag>
);

const ImageRenderer = (props: any) => (
  <img src={urlForImageRef(props?.node?.asset).url() as string} />
);

const VillageFilters = (props: any) => {
  if (props == null || props?.node == null) return null;
  if (props?.node?.showVillageFilter === false) return null;
  const { village } = useStore();
  const livingOptions = village?.livingOptions;

  return <VillageLivingOptions data={livingOptions} />;
};

const link = (props: any) => {
  if (props == null || props?.node == null) return null;
  return <Link {...props?.node} className="button-base" />;
};

interface Variant {
  variationTag: {
    variationAnswer: {
      marketoId: string;
      variantValue: string;
    };
  };
  title: string;
}

const variantText = ({ mark, children }: any) => {
  const lead = useStore(state => state.lead);
  if (!mark?.variant) return null;

  const variantTitle = mark.variant.find(
    (v: Variant) =>
      lead[v?.variationTag?.variationAnswer?.variantValue || ""] ===
      v?.variationTag?.variationAnswer?.marketoId,
  )?.title;

  return <span>{variantTitle || children}</span>;
};

const inlineLink = (props: any) => {
  if (props == null) return null;

  const linkText = props.children.join(" ");

  const linkNode = {
    ...props.mark,
    linkText: linkText,
  };

  const StyledLink = styled(Link)`
    text-decoration: underline;

    &:hover {
      opacity: 0.7;
    }
  `;

  if (props.mark?.addIndentation)
    return (
      <ul>
        <StyledLink {...linkNode} />
      </ul>
    );

  return <StyledLink {...linkNode} />;
};

const icon = ({ mark, children }: any) => {
  if (mark.icon === "marker")
    return (
      <StyledIcon>
        <IconMarkerPlain />
        {children}
      </StyledIcon>
    );

  if (mark.icon === "phone")
    return (
      <StyledIcon>
        <IconPhone />
        {children}
      </StyledIcon>
    );

  if (mark.icon === "sales")
    return (
      <StyledIcon>
        <IconSalesIndependent />

        {children}
      </StyledIcon>
    );

  return <StyledIcon>{children}</StyledIcon>;
};

const StyledIcon = styled.ul`
  position: relative;
  margin-bottom: 0px !important;
  svg {
    position: absolute;
    left: -20px;
    top: 3px;
  }
`;

const Wrapper = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 20px;
    margin-bottom: 20px;

    &:first-child {
      margin-top: 0;
    }
  }
  h2 {
    font-size: ${fontSizes.h3.default}px;
    font-family: ${fontFamilies.tobias};
  }
  h5,
  h6 {
    font-size: ${fontSizes.h4.default}px;
  }

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    margin-bottom: 5px;
    list-style: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul,
  ol {
    margin: 0 0 10px 20px;

    &.list--inline {
      margin-left: 0;
    }
  }

  ul {
    list-style: disc outside;

    ul {
      list-style: circle outside;

      ul {
        list-style: square outside;
      }
    }
  }

  img {
    height: auto;
    width: auto;
    max-width: 100%;
    margin: 25px 0;
  }

  ${mediaQuery.tabletDown} {
    p {
      margin-bottom: 0px;
    }
    h2 {
      font-size: ${fontSizes.h3.mobile}px;
    }
    h5,
    h6 {
      font-size: ${fontSizes.h4.mobile}px;
    }
  }
`;
