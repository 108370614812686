import { Container, DynamicsFormContainer, P, PageWidth, Pill, Section } from "@util/standard";
import { DataLayerContactForm, isMetguideForm } from "@util/types";
import { Form, RadioGroup, StyledSubmitButton } from "@styles/formStyles";
import { Maybe, SanityForm, SanityRegion } from "@graphql-types";
import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from "react";
import { bindMetguideToDynamics } from "@util/helper";
import {
  sendFormFill,
  sendMetguideViewCart,
  sendMetguidesPurchase,
  useFormData,
} from "@util/datalayer";
import { useDynamicsFormLoad } from "@util/hooks";

import CoreFormFields from "./coreFormFields";
import FormSuccessText from "./formSuccessText";
import { SelectedMetguides } from "@state/types";
import { METGUIDE_FORM_ID, assets } from "@util/constants";
import { useDrawer } from "@accessible/drawer";
import { useStore } from "@state/store";

interface Props {
  isInnerPage?: boolean;
  form?: Maybe<SanityForm> | undefined;
  isDrawer?: boolean;
  regions?: Maybe<Maybe<SanityRegion>[]> | undefined;
}

interface PillProps {
  guide: SelectedMetguides;
  handleRemove: (toRemove: SelectedMetguides) => () => void;
}

/**
 * Form for booking a tour of a village
 *
 * @param param0
 * @returns Contact form with hidden marketo form
 */
const MetguideForm = ({ isInnerPage = false, isDrawer = false, form, regions }: Props) => {
  const { pageColor, selectedMetguides, setSelectedMetguides, lead, setLead } = useStore();
  const ref = useRef<HTMLElement>(null);
  const drawerId = isDrawer && useDrawer();

  useEffect(() => {
    sendMetguideViewCart(selectedMetguides);
  }, []);

  const [opt, setOpt] = useState<string | null>();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const formState = useFormData("metguide");

  const handleClose = () => {
    if (isDrawer && drawerId) {
      if (formSubmitted) setSelectedMetguides([]);
      drawerId.close();
    }
  };

  useEffect(() => {
    const guidesArray = selectedMetguides?.map(guide => guide.guidePDFName);
    if (formData !== undefined){
    setFormData({
      ...formData,
      metlife_metguide_name: guidesArray ? JSON.stringify(guidesArray) : "",
    });
    };

    if (selectedMetguides.length === 0 && !isDrawer) handleClose();
  }, [selectedMetguides]);

  if (!isMetguideForm("metguide", formState.formData)) return null;
  const { formData, setFormData } = formState;
  const [enquiry, setEnquiry] = useState("881990000");

  const handleEnquiryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEnquiry(event.target.value);
    setFormData({ ...formData, metlifeEnquiry: event.target.value });
    setLead({ ...lead, metlifeEnquiry: event.target.value });
  };

  useEffect(() => {
    if (lead && lead.metlifeEnquiry) {
      setFormData({ ...formData, metlifeEnquiry: lead.metlifeEnquiry });
      setEnquiry(lead.metlifeEnquiry);
    }
  }, [lead]);

  const handleOptChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOpt(event.target.value);
    setFormData({ ...formData, optInMetlifecare: event.target.value });
  };

  const handleRemove = (toRemove: SelectedMetguides) => () => {
    const removedGuides = selectedMetguides.filter(
      guide => guide.guidePDFName != toRemove.guidePDFName,
    );
    setSelectedMetguides(removedGuides);
  };

  // DYNAMICS WORK

  useDynamicsFormLoad();

  // // @ts-ignore
  // if (isBrowser() && window.MsCrmMkt?.MsCrmFormLoader) {
  //   // @ts-ignore
  //   window.MsCrmMkt.MsCrmFormLoader.on("afterFormLoad", function () {
  //     setFormLoaded(true);
  //   });
  // }

  const dynamicsFormRef = useRef(null);

  const handleDynamicsSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    await bindMetguideToDynamics(dynamicsFormRef, formData, form);
    try {
      sendMetguidesPurchase(selectedMetguides, form);
      setFormSubmitted(true);

      const metguideNames =
        selectedMetguides && selectedMetguides.map(metguide => metguide.guidePDFName);

      const document_name = () => {
        if (isInnerPage && form) {
          return form.munchkinId;
        }
        if (metguideNames) {
          return metguideNames.join(", ");
        }

        return undefined;
      };

      const dataLayer: DataLayerContactForm = {
        form_step: "success",
        form_name: "Metguide Form",
        document_type: "Metguides",
        success_step: true,
        comms_consent: formData.optInMetlifecare,
        lead_generated: true,
        document_name: document_name(),
        enquiry_type: "Sales",
        requesting_for: formData?.metlifeEnquiry,
        region: formData.metlife_stated_region_of_interest,
      };

      sendFormFill(dataLayer);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Section marginOverride="20px 0px">
      <DynamicsFormContainer ref={dynamicsFormRef} data-form-block-id={METGUIDE_FORM_ID} />
      <PageWidth fullWidth={isDrawer} mobileFullWidth={isDrawer}>
        {!formSubmitted && selectedMetguides.length == 0 && !isInnerPage && (
          <Container margin="auto" height="100%">
            <h3>Please select a Metguide to continue</h3>
          </Container>
        )}
        {!formSubmitted && (selectedMetguides.length > 0 || isInnerPage) && (
          <>
            {!isInnerPage && (
              <Container flexDirection="column">
                <>
                  <P fontWeight="bold">Selected guides:</P>
                  <Container columnGap="5px" flexWrap="wrap" margin="0 0 20px 0">
                    {selectedMetguides?.map((guide, index) => (
                      <SelectedGuidePill key={index} guide={guide} handleRemove={handleRemove} />
                    ))}
                  </Container>
                </>
              </Container>
            )}
            <Form id={METGUIDE_FORM_ID} onSubmit={handleDynamicsSubmit}>
              <Container flexDirection="column">
                <P fontWeight="bold" margin="0 0 10px 0">
                  Who is the guide for?*
                </P>
                <Container flexDirection="row" flexWrap="wrap" rowGap="20px" margin="0 0 40px 0">
                  <RadioGroup isSelected={enquiry == "881990000"}>
                    <input
                      id="myself"
                      type="radio"
                      name="enquire"
                      value="881990000"
                      checked={enquiry == "881990000"}
                      onChange={handleEnquiryChange}
                    />
                    <label htmlFor="myself">Myself</label>
                  </RadioGroup>
                  <RadioGroup isSelected={enquiry == "881990001"}>
                    <input
                      id="myPartner"
                      type="radio"
                      name="enquire"
                      value="881990001"
                      checked={enquiry == "881990001"}
                      onChange={handleEnquiryChange}
                    />
                    <label htmlFor="myPartner">My partner & I</label>
                  </RadioGroup>
                  <RadioGroup isSelected={enquiry == "881990002"}>
                    <input
                      id="friend"
                      type="radio"
                      name="enquire"
                      value="881990002"
                      checked={enquiry == "881990002"}
                      onChange={handleEnquiryChange}
                    />
                    <label htmlFor="family">A family member / A friend</label>
                  </RadioGroup>
                </Container>
              </Container>
              <Container width="100%" isMobileColumn>
                <Container
                  flexDirection="row"
                  margin="0px 15px 0px 0px"
                  isMobileColumn
                  mobileMargin="0px"
                  width="100%"
                  flexWrap="wrap"
                  columnGap="15px"
                >
                  <CoreFormFields
                    formData={formData}
                    setFormData={setFormData}
                    isDrawer={isDrawer}
                    isMetguide
                    regionOverride={regions ?? undefined}
                  />
                </Container>
              </Container>
              <Container flexDirection="column">
                <P id="optLabel" fontWeight="bold">
                  Would you like to keep updated with Metlifecare news and events?*
                </P>
                <Container width="80%" flexWrap="wrap" rowGap="20px">
                  <RadioGroup isSelected={opt == "true"} aria-labelledby="optLabel">
                    <input
                      id="yes"
                      type="radio"
                      name="opt"
                      value="true"
                      checked={opt == "true"}
                      onChange={handleOptChange}
                      required
                    />
                    <label htmlFor="yes">Yes, please</label>
                  </RadioGroup>
                  <RadioGroup isSelected={opt == "false"}>
                    <input
                      id="no"
                      type="radio"
                      name="opt"
                      value="false"
                      checked={opt == "false"}
                      onChange={handleOptChange}
                      required
                    />
                    <label htmlFor="no">No, thanks</label>
                  </RadioGroup>
                </Container>
              </Container>

              <Container
                margin="20px 0 100px 10px"
                flexDirection="column"
                width="100%"
                isTabletColumn
              >
                <P fontWeight="normal">
                  Your personal information will be used for the purpose of providing you with
                  information about Metlifecare and will not be given to any third parties or used
                  for any other purposes.
                </P>
                <a
                  href="/privacy-policy"
                  style={{ textDecoration: "underline", marginBottom: "20px" }}
                  target="_blank"
                >
                  View our Privacy Policy
                </a>
                <strong>
                  <p>* are required fields</p>
                </strong>
                <StyledSubmitButton
                  pageColour={pageColor}
                  type="submit"
                  value={"Get your guide"}
                  enabled
                />
              </Container>
            </Form>
          </>
        )}

        {formSubmitted && (
          <FormSuccessText
            handleDrawerClose={isDrawer ? handleClose : undefined}
            formType="guide"
          />
        )}
      </PageWidth>
    </Section>
  );
};

export default MetguideForm;

const SelectedGuidePill = ({ guide, handleRemove }: PillProps) => {
  return (
    <Pill onClick={handleRemove(guide)}>
      <span>{guide.guideName}</span>
      <img src={assets.close} />
    </Pill>
  );
};
