import React from "react";
import styled from "styled-components";
import { ContactForm } from "./forms/contactForm";
import { SanityDynamicsFormBlock } from "@graphql-types";
import { BlocksContent } from "@global";
import { Container, PageWidth, Section } from "@util/standard";
import { VillageContactDetails } from "./village/contactHeading";
import { useStore } from "@state/store";

import { colors, fontFamilies, mediaQuery } from "@util/constants";
import { CareForm } from "./forms/careForm";

interface Props {
  data: SanityDynamicsFormBlock;
}

const OnPageForm = ({ data }: Props) => {
  if (data == null) return null;
  const { village } = useStore();

  return (
    <Section>
      <PageWidth>
        {data.titleBlock && (
          <Container maxWidth="65%" margin="0px 0px 50px 0px">
            <BlocksContent data={data.titleBlock} />
          </Container>
        )}
        <StyledFormWrapper showContact={data.showContactOptions ?? false}>
          <div>
            {data.form === "villageContact" && <ContactForm isDrawer />}
            {data.form === "care" && <CareForm />}
          </div>
          {data.showContactOptions && (
            <div className="contentDetails">
              <VillageContactDetails data={village.villageContact} />
            </div>
          )}
        </StyledFormWrapper>
      </PageWidth>
    </Section>
  );
};

export default OnPageForm;

const StyledFormWrapper = styled.div<{ showContact?: boolean }>`
  display: grid;
  width: 100%;
  max-width: calc(100% - 90px);
  grid-template-columns: ${props => (props.showContact ? "auto 350px" : "1fr")};

  ${mediaQuery.smallLaptopDown} {
    grid-template-columns: 1fr;
    max-width: 100%;
  }

  .contact-form-wrapper, .care-form-wrapper {
    justify-content: center;
  }
  

  .contentDetails {
    padding-top: 138px;
    padding-left: 60px;
    padding-right: 25px;
    background: ${colors.cream};
    min-height: calc(100vh - 244px);
    border-bottom-right-radius: 40px;

    ${mediaQuery.smallLaptopDown} {
      padding-top: 50px;
    }

    * {
      color: ${colors.navy};
    }
    h3 {
      font-family: ${fontFamilies.beausite};
      font-size: 26px;
      margin-bottom: 30px;
    }
    .detailAddress {
      a {
        text-decoration: underline;
      }
    }
    .detail {
      padding-left: 25px;
      position: relative;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      svg {
        position: absolute;
        left: 0;
        top: 5px;
      }
      h4 {
        font-size: 20px;
        margin-bottom: 5px;
      }
      p {
        margin: 0;
        line-height: 20px;
      }
    }

 

    
`;
