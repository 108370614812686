import React from "react";
import styled from "styled-components";

import { Section, PageWidth, Container, GridContainer } from "@util/standard";
import { Link } from "@global";
import NewsPreview from "./preview";
import { Maybe } from "@graphql-types";
import { useStore } from "@state/store";
import { fontFamilies, fontSizes, fontWeights, mediaQuery } from "@util/constants";

interface Props {
  title?: Maybe<string>;
  margin?: string | undefined;
}

const ariaLabel = "Village News";

export default function RelatedNews({ title = "Latest news.", margin }: Props) {
  const getVillage = useStore(state => state.getVillage);
  const pagePaths = useStore(state => state.pagePaths);

  const { news, title: villageTitle, regionId } = getVillage();

  const filterAllNews = () => {
    if (news == null || !Boolean(news?.length)) return null;

    if (news.length > 0) {
      return news.filter(article => article.title != null);
    }
    const regionNews = news?.filter(
      newsItem => newsItem.title != null && newsItem?.regionRef?._id === regionId,
    );

    if (regionNews && regionNews.length < 2) {
      return [...(regionNews || []), ...(news || [])];
    }
    return regionNews;
  };

  const filteredNews = filterAllNews();

  if (filteredNews?.length === 0) return null;
  const newsToDisplay = filteredNews?.slice(0, 2);

  return (
    <Section aria-labelledby={ariaLabel} marginOverride={margin}>
      <PageWidth mobileFullWidth>
        <Container flexDirection="column">
          <HeaderWrapper>
            <p className="h3">{title}</p>
            <Link
              className="button-base"
              linkText="More news"
              internalLink={pagePaths?.newsLanding}
              urlParam={villageTitle}
            />
          </HeaderWrapper>
          <GridContainer repeat={2} tabletRepeat={1} rowGap={20} columnGap={20}>
            {newsToDisplay?.map((news, index) => {
              if (news == null) return null;

              return <NewsPreview key={index} data={news} />;
            })}
          </GridContainer>
        </Container>
      </PageWidth>
    </Section>
  );
}

const HeaderWrapper = styled(Container)<{ margin?: string }>`
  a {
    margin-left: 40px;
  }
  .h3 {
    font-size: ${fontSizes.h1.default}px;
    font-weight: ${fontWeights.extrabold};
    font-family: ${fontFamilies.tobias}, Arial;
    margin-bottom: 60px;
  }

  ${mediaQuery.tabletDown} {
    flex-direction: column;
    justify-content: flex-start;
    width: 80%;
    margin: auto;
    margin-bottom: 40px;

    a {
      margin-left: 0;
    }
    .h3 {
      margin-bottom: 20px;
    }
  }
  ${mediaQuery.mobileDown} {
    .h3 {
      font-size: ${fontSizes.h1.mobile}px;
    }
  }
`;
