import { Container, DynamicsFormContainer, MobileContainer, P, PageWidth } from "@util/standard";
import { isCareForm } from "@util/types";
import {
  DropdownContainer,
  Form,
  RadioGroup,
  StyledSubmitButton,
  SubmitWrapper,
} from "@styles/formStyles";
import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from "react";
import { bindCareForm, isBrowser } from "@util/helper";
import { useFormData } from "@util/datalayer";
import { useDynamicsFormLoad, useRegionAndVillages } from "@util/hooks";

import CoreFormFields from "./coreFormFields";
import FormSuccessText from "./formSuccessText";
import { CARE_FORM_ID, colors } from "@util/constants";
import { useStore } from "@state/store";

/**
 * Form that is added to the village and general contact pages
 *
 * @param param0
 * @returns Contact form with hidden marketo form
 */
export function CareForm() {
  const dynamicsFormRef = useRef(null);
  const { village, pageColor } = useStore();

  const [enquiry, setEnquiry] = useState("881990000");
  const [opt, setOpt] = useState<string | null>();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);
  const [nasc, setNsac] = useState("");
  const [levelOfCare, setLevelOfCare] = useState("");

  const { villages, regions, updateRegion } = useRegionAndVillages();

  const handleVillageChange = (e: any) => {
    setFormData({ ...formData, metlife_villages_of_interest_brochure: e.target.value });
  };

  const formState = useFormData("care");
  if (!isCareForm("care", formState.formData)) return null;

  const { formData, setFormData } = formState;

  useEffect(() => {
    console.log({ formData });
  }, [formData]);

  const handleEnquiryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEnquiry(event.target.value);
    setFormData({ ...formData, metlifeEnquiry: event.target.value });
  };
  const handleNascChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNsac(event.target.value);
    setFormData({ ...formData, needs_assessment: event.target.value });
  };
  const handleLevelOfCareChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setLevelOfCare(event.target.value);
    setFormData({ ...formData, level_of_care_known: event.target.value });
  };

  const handleOptChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOpt(event.target.value);
    setFormData({ ...formData, optInMetlifecare: event.target.value });
  };

  // DYNAMICS WORK

  useDynamicsFormLoad();

  const handleFormSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    await bindCareForm(dynamicsFormRef, formData, null);
    try {
      setFormSubmitted(true);
    } catch (err) {
      console.log(err);
    }
  };

  // @ts-ignore
  if (isBrowser() && window.MsCrmMkt?.MsCrmFormLoader) {
    // @ts-ignore
    window.MsCrmMkt.MsCrmFormLoader.on("afterFormLoad", function () {
      setFormLoaded(true);
    });
  }

  return (
    <Container margin="0px 0px 75px 0px" className="care-form-wrapper">
      <DynamicsFormContainer ref={dynamicsFormRef} data-form-block-id={CARE_FORM_ID} />

      {!formSubmitted && (
        <Form id={CARE_FORM_ID} onSubmit={handleFormSubmit}>
          <Container width="100%" isMobileColumn columnGap="20px">
            <Container
              width="50%"
              flexDirection="column"
              margin="0px 15px 0px 0px"
              isMobileColumn
              mobileMargin="0px"
            >
              <CoreFormFields formData={formData} setFormData={setFormData} isBookATour />
              <DropdownContainer
                id="enquiryType"
                aria-required="true"
                required
                onChange={e => {
                  setFormData({
                    ...formData,
                    metlife_stated_region_of_interest: e.target.value,
                  });
                  updateRegion(e);
                }}
                defaultValue="default"
                noLabel
              >
                <option value="default" disabled>
                  Select region*
                </option>
                {regions?.map(region => {
                  if (region == null) return null;
                  const regionValue =
                    Number(region?.dynamicsValue?.length) > 0
                      ? region?.dynamicsValue
                      : region?.title;

                  return (
                    <option key={region?._id} value={regionValue!}>
                      {region.title}
                    </option>
                  );
                })}
              </DropdownContainer>
              <DropdownContainer
                id="village"
                aria-required="true"
                required
                onChange={handleVillageChange}
                defaultValue="default"
                noLabel
              >
                <option value="default" disabled>
                  Select village*
                </option>
                {villages &&
                  villages.map(village => {
                    if (village == null) return null;
                    const villageCode = village.dynamicsVillageCode ?? village.villageCode;
                    return (
                      <option value={villageCode!} key={villageCode}>
                        {village.title}
                      </option>
                    );
                  })}
              </DropdownContainer>
            </Container>
            <Container
              width="50%"
              flexDirection="column"
              margin="0px 0px 0px 15px"
              mobileWidth="100%"
              mobileMargin="0px"
              className="group-2"
            >
              <MobileContainer>
                <label htmlFor="message">Message*</label>
              </MobileContainer>

              <textarea
                id="message"
                placeholder="Message"
                onChange={e => setFormData({ ...formData, MktoPersonNotes: e.target.value })}
                aria-required="true"
                required
              />
            </Container>
          </Container>

          <Container flexDirection="column" isMobileColumn>
            <p>Who are you enquiring for?*</p>
            <Container isMobileColumn flexWrap="wrap" rowGap="15px">
              <RadioGroup isSelected={enquiry == "881990000"}>
                <input
                  id="myself"
                  type="radio"
                  name="enquire"
                  value="881990000"
                  checked={enquiry == "881990000"}
                  onChange={handleEnquiryChange}
                  aria-required="true"
                  required
                />
                <label htmlFor="myself">Myself</label>
              </RadioGroup>
              <RadioGroup isSelected={enquiry == "881990001"}>
                <input
                  id="myPartner"
                  type="radio"
                  name="enquire"
                  value="881990001"
                  checked={enquiry == "881990001"}
                  onChange={handleEnquiryChange}
                  aria-required="true"
                  required
                />
                <label htmlFor="myPartner">My partner & I</label>
              </RadioGroup>
              <RadioGroup isSelected={enquiry == "881990002"}>
                <input
                  id="family"
                  type="radio"
                  name="enquire"
                  value="881990002"
                  checked={enquiry == "881990002"}
                  onChange={handleEnquiryChange}
                  aria-required="true"
                  required
                />
                <label htmlFor="family">A family member / A friend</label>
              </RadioGroup>
            </Container>
          </Container>

          {/* NASC */}
          <Container flexDirection="column" isMobileColumn>
            <p>Needs assessment (NASC) completed?</p>
            <Container isMobileColumn flexWrap="wrap" rowGap="15px">
              <RadioGroup isSelected={nasc == "881990000"}>
                <input
                  id="nasc1"
                  type="radio"
                  name="nasc"
                  value="881990000"
                  checked={nasc == "881990000"}
                  onChange={handleNascChange}
                  aria-required="true"
                  required
                />
                <label htmlFor="nasc1">Not started</label>
              </RadioGroup>
              <RadioGroup isSelected={nasc == "881990001"}>
                <input
                  id="nasc2"
                  type="radio"
                  name="nasc"
                  value="881990001"
                  checked={nasc == "881990001"}
                  onChange={handleNascChange}
                  aria-required="true"
                  required
                />
                <label htmlFor="nasc2">In progress</label>
              </RadioGroup>
              <RadioGroup isSelected={nasc == "881990002"}>
                <input
                  id="nasc3"
                  type="radio"
                  name="nasc"
                  value="881990002"
                  checked={nasc == "881990002"}
                  onChange={handleNascChange}
                  aria-required="true"
                  required
                />
                <label htmlFor="nasc3">Approved</label>
              </RadioGroup>
            </Container>
          </Container>

          {/* Level */}
          <Container flexDirection="column" isMobileColumn>
            <p>Level of care if known</p>
            <Container isMobileColumn flexWrap="wrap" rowGap="15px">
              <RadioGroup isSelected={levelOfCare == "881990000"}>
                <input
                  id="care_level1"
                  type="radio"
                  name="care_level"
                  value="881990000"
                  checked={levelOfCare == "881990000"}
                  onChange={handleLevelOfCareChanged}
                  aria-required="true"
                  required
                />
                <label htmlFor="care_level1">Rest home</label>
              </RadioGroup>
              <RadioGroup isSelected={levelOfCare == "881990001"}>
                <input
                  id="care_level2"
                  type="radio"
                  name="care_level"
                  value="881990001"
                  checked={levelOfCare == "881990001"}
                  onChange={handleLevelOfCareChanged}
                  aria-required="true"
                  required
                />
                <label htmlFor="care_level2">Hospital</label>
              </RadioGroup>
              <RadioGroup isSelected={levelOfCare == "881990003"}>
                <input
                  id="care_level3"
                  type="radio"
                  name="care_level"
                  value="881990003"
                  checked={levelOfCare == "881990003"}
                  onChange={handleLevelOfCareChanged}
                  aria-required="true"
                  required
                />
                <label htmlFor="care_level3">Dementia</label>
              </RadioGroup>
            </Container>
          </Container>

          <SubmitWrapper generalContact={false}>
            <Container flexDirection="column" width="97%" tabletWidth="60%" mobileWidth="100%">
              <p className="update">
                Would you like to keep updated with Metlifecare news and events?*
              </p>
              <Container isMobileColumn flexWrap="wrap" rowGap="15px">
                <RadioGroup isSelected={opt == "true"}>
                  <input
                    id="yes"
                    type="radio"
                    name="opt"
                    value="true"
                    checked={opt == "true"}
                    onChange={handleOptChange}
                    aria-required="true"
                    required
                  />
                  <label htmlFor="yes">Yes, please</label>
                </RadioGroup>
                <RadioGroup isSelected={opt == "false"}>
                  <input
                    id="no"
                    type="radio"
                    name="opt"
                    value="false"
                    checked={opt == "false"}
                    onChange={handleOptChange}
                    aria-required="true"
                    required
                  />
                  <label htmlFor="no">No, thanks</label>
                </RadioGroup>
              </Container>
            </Container>
            <Container margin="20px 0 0 10px" flexDirection="column" width="100%" isTabletColumn>
              <P fontWeight="normal">
                Your personal information will be used for the purpose of providing you with
                information about Metlifecare and will not be given to any third parties or used for
                any other purposes.
              </P>
              <a
                href="/privacy-policy"
                style={{ textDecoration: "underline", marginBottom: "20px", color: colors.navy }}
                target="_blank"
              >
                View our Privacy Policy
              </a>
              <strong>
                <p>* are required fields</p>
              </strong>

              <StyledSubmitButton
                pageColour={pageColor}
                type="submit"
                value={"Submit form"}
                overrideMargin="20px 0 0 0 "
                enabled
              />
            </Container>
          </SubmitWrapper>
        </Form>
      )}
      {formSubmitted && <FormSuccessText formType="contact" />}
    </Container>
  );
}
