import React from "react";
import styled from "styled-components";
import { Maybe, SanityVillage } from "@graphql-types";
import { BlocksContent } from "@global";
import { colors, fontFamilies, mediaQuery } from "@util/constants";
import { ContactForm } from "@components/forms/contactForm";
import { IconMarkerPlain, IconPhone } from "@util/assets";
import ContactPersonDetail from "./contactPersonDetail";
import { getDirectionsLink } from "@util/helper";
import { PageWidth } from "@util/standard";

interface Props {
  data: SanityVillage;
  secondaryVillageColour: Maybe<string> | undefined;
}

const ariaLabel = "Village Contact Heading";

export default function ContactHeading({ data, secondaryVillageColour }: Props) {
  if (data == null) return null;
  const { heading, headingText } = data;

  return (
    <ContactHeadingSection aria-labelledby={ariaLabel}>
      <div className="section-inner">
        <div className="col-1">
          <div className="heading-intro">
            <h1 aria-label={ariaLabel}>{heading}</h1>
            <BlocksContent data={headingText} />
            <ContactForm secondaryVillageColour={secondaryVillageColour} isDrawer />
          </div>
        </div>
        <div className="col-2">
          <div className="contentDetails">
            <VillageContactDetails data={data} />
          </div>
        </div>
      </div>
    </ContactHeadingSection>
  );
}

export function VillageContactDetails({ data }: { data: SanityVillage }) {
  const {
    city,
    street,
    careHomeContacts,
    suburb,
    receptionContact,
    salesEnquiryContacts,
    postCode,
    googleMyBusinessUrl,
  } = data;
  const address = getDirectionsLink({ suburb, street, city, postCode });
  return (
    <>
      <div className="detail detailAddress">
        <IconMarkerPlain />
        <p className="heading">Address:</p>
        <div className="detail-content">
          {street && <p>{street}</p>}
          <p>
            {suburb && suburb}, {city && city}, {postCode && postCode}
          </p>
          {googleMyBusinessUrl && (
            <a href={googleMyBusinessUrl} target="_blank">
              Get directions on Google
            </a>
          )}
        </div>
      </div>
      {receptionContact && (
        <div className="detail">
          <IconPhone />
          <p className="heading">Reception:</p>
          <div className="detail-content">
            {receptionContact.phone && (
              <p>
                Ph <a href={`tel:${receptionContact.phone}`}>{receptionContact.phone}</a>
              </p>
            )}
            {receptionContact.hours && <p>{receptionContact.hours}</p>}
            {receptionContact.weekendHours && <p>{receptionContact.weekendHours}</p>}
            {receptionContact.publicHolidayHours && <p>{receptionContact.publicHolidayHours}</p>}
          </div>
        </div>
      )}
      {careHomeContacts && careHomeContacts.length >= 1 && (
        <div className="detail">
          <IconPhone />
          <p className="heading">Care home:</p>
          <div className="detail-content">
            {careHomeContacts.map(contactPerson => (
              <ContactPersonDetail contactPerson={contactPerson} phoneUnit="Ph " />
            ))}
          </div>
        </div>
      )}
      {salesEnquiryContacts?.[0]?.phone && (
        <div className="detail">
          <IconPhone />
          <p className="heading">Independent living sales:</p>
          <div className="detail-content">
            {salesEnquiryContacts.map(contactPerson => (
              <ContactPersonDetail contactPerson={contactPerson} phoneUnit="Ph " hideEmail />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

const ContactHeadingSection = styled.section`
  margin-top: 204px;
  .section-inner {
    display: grid;
    grid-template-columns: auto 533px;
    width: 100%;
    max-width: calc(100% - 90px);
    margin: 0 auto;
    .col-1 {
      padding-top: 50px;
      .group-1,
      .group-2 {
        margin: 0;
      }
      h1 {
        margin-bottom: 25px;
      }
      .heading-intro {
        width: 80%;
        margin: 0 auto;
        .contact-form {
          margin-top: 0;
          .contact-form-page-width {
            margin: 0;
            width: 100%;
          }
        }
      }
      .rte {
        margin-bottom: 30px;
      }
    }
    .col-2 {
      .contentDetails {
        padding-top: 138px;
        padding-left: 60px;
        padding-right: 25px;
        background: ${colors.cream};
        min-height: calc(100vh - 244px);
        border-bottom-right-radius: 40px;
        * {
          color: ${colors.navy};
        }
        h3 {
          font-family: ${fontFamilies.beausite};
          font-size: 26px;
          margin-bottom: 30px;
        }
        .detailAddress {
          a {
            text-decoration: underline;
          }
        }
        .detail {
          padding-left: 25px;
          position: relative;
          &:not(:last-child) {
            margin-bottom: 20px;
          }
          svg {
            position: absolute;
            left: 0;
          }
          .heading {
            font-size: 20px;
            margin-bottom: 5px;
            font-weight: 700;
          }
          p {
            margin: 0;
            line-height: 20px;
          }
        }
      }
    }
  }
  ${mediaQuery.laptopDown} {
    .section-inner {
      .col-1 {
        .heading-intro {
          width: 90%;
        }
      }
    }
  }
  ${mediaQuery.smallLaptopDown} {
    .section-inner {
      max-width: calc(100% - 60px);
      grid-template-columns: 1fr;
      .col-1 {
        .heading-intro {
          width: 90%;
        }
      }
      .col-2 {
        .contentDetails {
          border-bottom-right-radius: 0;
          padding: 35px 25px;
          min-height: auto;
        }
      }
    }
  }
`;
