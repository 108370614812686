import React, { ReactNode, SetStateAction, Dispatch } from "react";
import styled from "styled-components";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import SwiperCore, { Controller, Navigation } from "swiper/modules";
import { useCheckScreenSize } from "@util/hooks";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

interface Props {
  children: ReactNode;
  controller: SwiperCore | undefined;
  setController: Dispatch<SetStateAction<SwiperCore | undefined>>;
  loop?: boolean;
  length?: number;
  swiperProps?: SwiperProps;
}

export default function PreviewCarousel({
  children,
  controller,
  setController,
  loop,
  length,
  swiperProps,
}: Props) {
  const { ipadProDown } = useCheckScreenSize();

  return (
    <StyledSwiper
      modules={[Controller, Navigation]}
      spaceBetween={8}
      slidesPerView={ipadProDown ? 1.1 : 1.2}
      onSwiper={setController}
      loop={loop}
      centerInsufficientSlides
      onReachEnd={() => length && controller && controller.slideTo(length - 1)}
      passiveListeners
      {...(swiperProps && swiperProps)}
    >
      {children}
      <SwiperSlide>
        <div />
      </SwiperSlide>
    </StyledSwiper>
  );
}

const StyledSwiper = styled(Swiper)`
  width: 100%;
  .swiper-slide {
    height: auto;
  }
`;
