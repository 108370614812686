import React from "react";

import { BlocksContent, Button, Link } from "@global";
import { StyledSubmitContainer } from "@styles/formStyles";
import { useStore } from "@state/store";

interface Props {
  handleDrawerClose?: () => void;
  formType: "infoPack" | "guide" | "tour" | "contact";
}

export default function FormSuccessText({ handleDrawerClose, formType }: Props) {
  const { formSuccessText } = useStore();
  if (formSuccessText == null) return null;
  const {
    link,
    content,
    infoPacksSuccessText,
    metguidesSuccessText,
    bookATourSuccessText,
    contactSuccessText,
  } = formSuccessText;

  const SuccessLink = () => {
    if (handleDrawerClose)
      return (
        <Button onClick={handleDrawerClose} theme="base">
          Close
        </Button>
      );

    if (link) return <Link {...link} className="button-base" />;
    return null;
  };

  const text = () => {
    if (formType === "infoPack" && infoPacksSuccessText) return infoPacksSuccessText;
    if (formType === "guide" && metguidesSuccessText) return metguidesSuccessText;
    if (formType === "tour" && bookATourSuccessText) return bookATourSuccessText;
    if (formType === "contact" && contactSuccessText) return contactSuccessText;
    return content;
  };

  return (
    <StyledSubmitContainer flexDirection="column">
      <BlocksContent data={text()} />
      <SuccessLink />
    </StyledSubmitContainer>
  );
}
