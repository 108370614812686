import styled, { createGlobalStyle } from "styled-components";

import {
  fontSizes,
  PRIMARY_COLOR,
  fontFamilies,
  mediaQuery,
  fontWeights,
} from "@util/constants";
import {
  buttonBaseStyle,
  buttonInverseStyle,
  buttonWhiteInverseStyle,
  buttonWhiteStyle,
  defaultButtonStyle,
} from "@util/standard";

const hideFastRefresh = 'true' === (process.env.GATSBY_HIDE_FAST_REFRESH || 'false');

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0px;
    font-family: ${fontFamilies.beausite}, Arial;
    font-size: ${fontSizes.p.default}px;
    font-weight: ${fontWeights.normal};
    color: ${PRIMARY_COLOR};
    line-height: 1.6;
  }

  body,
  input,
  textarea,
  button,
  select {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
  }

  em {
    font-style: italic;
  }

  small {
    font-size: 80%;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  img {
    max-width: 100%;
    border: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font: inherit;
    margin: 0;
  }

  button,
  html input {
    &[disabled] {
      cursor: default;
    }
  }

  .block-wrapper section:last-of-type {
    margin-bottom: 75px;
  }

  a[class^="button"] {
   ${defaultButtonStyle}
  }
  a[class$="base"] {
   ${buttonBaseStyle}
  }
  a[class$="inverse"] {
    ${buttonInverseStyle}
  }
  a[class$="white"] {
    ${buttonWhiteStyle}
  }
  a[class$="whiteInverse"] {
    ${buttonWhiteInverseStyle}
  }

  input {
    &[type="search"],
    &[type="number"],
    &[type="email"],
    &[type="password"] {
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  td,
  th {
    padding: 0;
  }

  h1, .h1 {
    font-size: ${fontSizes.h1.default}px;
    font-weight: ${fontWeights.extrabold};
    font-family: ${fontFamilies.tobias}, Arial;
  }
  h2, .h2 {
    font-size: ${fontSizes.h2.default}px;
    font-weight: ${fontWeights.extrabold};
    font-family: ${fontFamilies.beausite}, Arial;
  }
  h3, .h3 {
    font-size: ${fontSizes.h3.default}px;
    font-weight: ${fontWeights.bold};
    font-family: ${fontFamilies.tobias}, Arial;
  }
  h4, .h4 {
    font-size: ${fontSizes.h4.default}px;
    font-weight: ${fontWeights.bold};
    font-family: ${fontFamilies.beausite}, Arial;
  }

  h1,
  h2,
  h3,
  h4, .h1, .h2,.h3,.h4 {
    margin: 0;
    line-height: 1.2;
    overflow-wrap: break-word;
    word-wrap: break-word;
  
    a {
      color: inherit;
      text-decoration: none;
      font-weight: inherit;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 0.3s ease all;

    &:hover {
      text-decoration: underline;
    }
  }

  /* button,
  select,
  a,  */
  input,
  textarea,
  img, div {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    &:focus {
      outline: none;
    } 
    &:focus-visible {
      outline: none;
    }
  }


  ${mediaQuery.mobileDown} {   
    h1, .h1 {
      font-size: ${fontSizes.h1.mobile}px;
    }
    h2, .h2 {
      font-size: ${fontSizes.h2.mobile}px;
    }
    h3, .h3 {
      font-size: ${fontSizes.h3.mobile}px;
    }
    h4, .h4 {
      font-size: ${fontSizes.h4.mobile}px;
    }
  }

  ${hideFastRefresh ? 'gatsby-fast-refresh { display: none !important; } body { overflow: auto !important; }' : ''}
`;

export const Main = styled.main<{}>`
  min-height: 100vh;
`;
