import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

export default function useRegionQuery() {
  const { allSanityRegion }: Query = useStaticQuery(graphql`
    {
      allSanityRegion(
        filter: { isParentRegion: { ne: true } }
        sort: { order: ASC, fields: order }
      ) {
        nodes {
          ...sanityRegion
        }
      }
    }
  `);

  return allSanityRegion.nodes;
}
