import {
  GroqVillageData,
  SelectOption,
  VariantBlock,
  VillageSubpageData,
  VillagesInMap,
} from "./types";
import {
  Maybe,
  SanityEventConnection,
  SanityFaq,
  SanityFaqCategory,
  SanityNewsConnection,
  SanityRegion,
  SanityVillage,
} from "@graphql-types";
import { useEffect, useState } from "react";
import { useFormSuccessTextQuery, useStaticPageSlugs } from "@api";
import { usePrevious, useUrlSearchParam } from "./hooks";

import Fuse from "fuse.js";
import { VillagePageContext } from "@state/types";
import { algoliaClient } from "./helper";
import { colors } from "./constants";
import { sanityClient } from "@lib/sanityClient";
import { useStore } from "@state/store";

/**
 * Update page meta to store
 *
 * @param colorValue page color
 * @param pageType
 * @param villagePageContext
 */
export function usePageMeta(
  colorValue: Maybe<string> | undefined,
  pageType: Maybe<string> | undefined,
  villagePageContext?: VillagePageContext,
) {
  const { setPageColor, setPageType, setVillagePageContext } = useStore();

  useEffect(() => {
    if (pageType) setPageType(pageType);
    setPageColor(colorValue ?? colors.cyan);
    if (villagePageContext && pageType === "village") setVillagePageContext(villagePageContext);
  }, []);
}

/**
 * Determine which block to render depending on variant selected from store
 *
 * @param blocks
 * @returns the block to render
 */
export function useDetermineVariantBlock(blocks: Maybe<Partial<VariantBlock>>[]) {
  const [determinedBlock, setDeterminedBlock] = useState<Partial<VariantBlock>>();
  const { getLeadIds } = useStore();
  const leads = getLeadIds();

  useEffect(() => {
    if (blocks == null) return;

    const foundVariant = blocks.find(blockVariant => {
      if (blockVariant == null) return null;
      const { block, variants } = blockVariant;

      if (!Boolean(variants?.length)) return block ?? blockVariant; // if no variants, return block

      const fuse = new Fuse(variants!, {
        includeScore: true,
        keys: ["variationAnswer.marketoId"],
        ignoreLocation: true,
      });

      // TODO: this is a bit of a hack, but it works for now
      // if has variants, perform fuzzy search to determine which variant to render
      const fuzzyResult = fuse.search(leads.join(" "));
      if (fuzzyResult.length > 0 && fuzzyResult[0]) return block ?? blockVariant;
      return null;
    });

    if (foundVariant) setDeterminedBlock(foundVariant);
  }, [leads]);

  return { determinedBlock };
}

/**
 * Set all village events to global state
 *
 * @param data EventData from village page query
 */
export function useSetVillageRelatedData(data: {
  events?: {
    villageEvents: SanityEventConnection;
    regionEvents: SanityEventConnection;
  };
  news?: {
    regionNews: SanityNewsConnection;
    villageNews: SanityNewsConnection;
    generalNews: SanityNewsConnection;
    villagesNews: SanityNewsConnection;
  };
}) {
  const { events, news } = data;

  const { setVillageEvents, setVillageNews } = useStore();

  useEffect(() => {
    if (events?.villageEvents || events?.regionEvents) {
      const combinedEvents = [...events.villageEvents.nodes];

      if (combinedEvents?.length > 0) {
        const todayTime = new Date().setHours(0, 0, 0, 0);
        const villageEvents = combinedEvents.filter(
          event => new Date(event.dateTime).setHours(0, 0, 0, 0) >= todayTime,
        );

        setVillageEvents(villageEvents);
      }
    }

    const generalNews = news?.generalNews?.nodes || [];

    if (news) {
      const combinedNews = [
        ...(news.villagesNews?.nodes || []),
        ...(news.villageNews?.nodes || []),
        ...(news.regionNews?.nodes || []),
        ...generalNews,
      ];
      if (combinedNews?.length > 0) {
        setVillageNews(combinedNews);
      }
    }

    return () => {
      setVillageEvents(undefined);
      setVillageNews(undefined);
    };
  }, []);
}

/**
 * Return the correct data for each subpage
 *
 * @param village SanityVillage
 * @param pageType subpage type from page context
 * @returns VillageSubpageData { hero, seo, blocks }
 */
export function useVillageSubpageData(village: SanityVillage, pageType: string | undefined) {
  const [data, setData] = useState<VillageSubpageData>();

  useEffect(() => {
    if (pageType === "location") {
      const { locationHero, locationBlocks } = village;
      setData({ hero: locationHero, blocks: locationBlocks });
      return;
    }

    if (pageType === "lifeHere") {
      const { lifeHereHero, lifeHereBlocks } = village;
      setData({ hero: lifeHereHero, blocks: lifeHereBlocks });
      return;
    }

    if (pageType === "care") {
      const { careHero, careBlocks } = village;
      setData({ hero: careHero, blocks: careBlocks });
      return;
    }

    if (pageType === "homeOptions") {
      const { homeOptionsHero, homeOptionsBlocks } = village;
      setData({
        hero: homeOptionsHero,
        blocks: homeOptionsBlocks,
      });
      return;
    }
    if (pageType == "independentLiving") {
      const { independentLivingHero, independentLivingBlocks } = village;
      setData({
        hero: independentLivingHero,
        blocks: independentLivingBlocks,
      });
      return;
    }

    const { overviewHero, overviewBlocks } = village;
    setData({ hero: overviewHero, blocks: overviewBlocks });
  }, []);

  return data;
}

/**
 * Get FAQs in accordance to faqCategory with groq
 *
 * @returns faqs
 */
export function useFaqsFromCategory(categories: Maybe<Maybe<SanityFaqCategory>[]> | undefined) {
  const [faqs, setFaqs] = useState<SanityFaq[]>();
  if (categories == null || categories.length === 0) return;
  const categoryIds = categories.map(category => category?._id);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == 'faq' && references($categoryIds) ]`, {
        categoryIds,
      })
      .then((results: any[]) => {
        if (!results.length) return;
        setFaqs(results);
      });
  }, []);

  return faqs;
}

/**
 * Get related villages in accordance to region with groq
 *
 * @returns relatedVillages
 */
export function useRelatedVillagesData() {
  const { village } = useStore();
  const [relatedVillages, setRelatedVillages] = useState<GroqVillageData[]>();

  useEffect(() => {
    if (village.slug == null) return;
    const params = { slug: village.slug ?? "", regionId: village.regionId ?? "" };
    sanityClient
      .fetch(
        `*[_type == 'village' && slug.current != $slug && references($regionId) && !(_id in path("drafts.**"))]
        [0..1]
        { _type, slug, title, absoluteSlug, villageExcerpt, 
          "previewImages": previewImages[].asset->url, 
          livingOptions[]{
            ...,
               livingOption ->
           },
          "region": region-> { slug }, 
          "phone": receptionContact.phone,
          street, suburb, city, postCode, isDevelopment }`,
        params,
      )
      .then((results: any[]) => {
        if (!results.length) return;
        setRelatedVillages(results);
      });
  }, [village]);

  return relatedVillages;
}

/**
 * Get all villages in accordance to region with groq
 *
 * @returns relatedVillages
 */
export function useAllVillagesData(shouldFetch: Maybe<boolean> | undefined) {
  const [allVillages, setAllVillages] = useState<GroqVillageData[]>();

  useEffect(() => {
    if (!shouldFetch) return;
    sanityClient
      .fetch(
        `*[_type == 'village' && !(_id in path("drafts.**"))]  
        { _id, _type, slug, title, absoluteSlug, villageExcerpt, 
          "previewImages": previewImages[].asset->url, 
          "region": region-> {slug, marketoValue }, 
          "phone": receptionContact.phone,
          street, suburb, city, postCode, isDevelopment, livingOptions[]{
         ...,
            livingOption ->
        }, filters{
          careOptions[] ->
        }  }`,
      )
      .then((results: any[]) => {
        if (!results.length) return;
        setAllVillages(results);
      });
  }, [shouldFetch]);

  return allVillages;
}

export function useCareVillagePreviews(villages: GroqVillageData[] | null | undefined) {
  const [regionState, setRegionState] = useState<null | any>(null);
  const [allRegions, setAllRegions] = useState<null | any[]>(null);
  const [careState, setCareState] = useState<null | any>(null);
  const [allCareOptions, setAllCareOptions] = useState<null | any[]>(null);
  const [filteredVillages, setFilteredVillages] = useState(villages);

  useEffect(() => {
    let filtered = villages;

    if (regionState && regionState !== "all") {
      filtered = filtered?.filter(village => village?.region?.marketoValue === regionState);
    }

    if (careState && careState !== "all") {
      filtered = filtered?.filter(village =>
        village?.filters?.careOptions?.find(option => option?.title === careState),
      );
    }

    setFilteredVillages(filtered);

    // Recalculate available regions and care options based on filtered villages
    if (filtered) {
      const uniqueSlugs = new Set<string>();
      let uniqueRegions =
        villages &&
        villages
          .filter(village => {
            const currentSlug = village?.region?.slug?.current;
            if (currentSlug && !uniqueSlugs.has(currentSlug)) {
              uniqueSlugs.add(currentSlug);
              return true;
            }
            return false;
          })
          .map(village => village.region);

      // Sorting the unique regions alphabetically by marketoValue property
      uniqueRegions = uniqueRegions?.sort((a, b) => a?.marketoValue.localeCompare(b?.marketoValue));

      setAllRegions(uniqueRegions);

      const uniqueTitles = new Set<string>();
      let uniqueCareOptions: any[] = [];

      villages &&
        villages.forEach(village => {
          village?.filters?.careOptions?.forEach(option => {
            console.log({ option });

            const title = option?.title;
            if (title && !uniqueTitles.has(title)) {
              uniqueTitles.add(title);
              uniqueCareOptions.push(option);
            }
          });
        });

      if (uniqueCareOptions.length > 0) {
        // Sorting the care options by title in alphabetical order
        uniqueCareOptions = uniqueCareOptions.sort((a, b) => a.title.localeCompare(b.title));
        setAllCareOptions(uniqueCareOptions);
      }
    }
  }, [regionState, careState, villages]);

  return {
    regionState,
    careState,
    allRegions,
    allCareOptions,
    filteredVillages,
    setAllCareOptions,
    setAllRegions,
    setCareState,
    setRegionState,
  };
}

/**
 * Gets Villages associated with a region or regions
 *
 * @returns relatedVillages
 */
export function useVillagesInRegions(regions: Maybe<SanityRegion>[], fetchDisabledInfoPack = true) {
  const [villages, setVillages] = useState<GroqVillageData[]>();

  useEffect(() => {
    if (regions) {
      const idsOnly = regions.map(region => region?._id);
      const params = { regionId: idsOnly, fetchDisabledInfoPack };
      sanityClient
        .fetch(
          `*[_type == 'village' && references($regionId) && !(_id in path("drafts.**"))  ${
            fetchDisabledInfoPack ? " && (disabledInfoPack == null || !disabledInfoPack)" : ""
          }]
          { _type, slug, title, region -> {_id, title}, villageCode, disabledInfoPack,  "previewImages": previewImages[].asset->url, 
          livingOptions[]{
            ...,
               livingOption ->
           },
          "region": region-> { slug }, 
          "phone": receptionContact.phone,
          street, suburb, city, postCode, isDevelopment, infoPackImage{..., asset ->} }`,
          params,
        )
        .then((results: any[]) => {
          if (!results.length) return;
          setVillages(results);
        });
    }
  }, [regions]);

  return villages;
}

/**
 * Get static page slugs and set in store only once
 */
export function useSetStaticQueriesToStore() {
  const slugs = useStaticPageSlugs();
  const formSuccessTextData = useFormSuccessTextQuery();
  const { pagePaths, setPagePaths, setFormSuccessText } = useStore();

  useEffect(() => {
    if (pagePaths) return;
    if (slugs) {
      setPagePaths(slugs);
    }
  }, []);

  useEffect(() => {
    if (formSuccessTextData) setFormSuccessText(formSuccessTextData);
  }, [formSuccessTextData]);

  return pagePaths;
}

// TODO: move this to the node to reduce the amount of aloglia requests
/**
 * Get Facets from Aloglia to show in the filter section on load
 */
export function useInitialFacetsFromAlgolia() {
  const {
    setInitFacets,
    setNewsInitFacets,
    newsInitFacets,
    initFacets,
    eventsInitFacets,
    setEventsInitFacets,
  } = useStore();

  useEffect(() => {
    if (!Boolean(initFacets?.length)) {
      const villageIndex = algoliaClient.initIndex("villages");

      villageIndex
        .search("", {
          facets: ["*"],
        })
        .then((result: any) => {
          if (!result?.facets) return;
          setInitFacets(result?.facets);
        });
    }

    if (!Boolean(newsInitFacets?.length)) {
      const newsIndex = algoliaClient.initIndex("news");
      newsIndex
        .search("", {
          facets: ["*"],
        })
        .then((result: any) => {
          if (!result?.facets) return;
          setNewsInitFacets(result.facets);
        });
    }
    if (!Boolean(eventsInitFacets?.length)) {
      const eventsIndex = algoliaClient.initIndex("events");
      eventsIndex
        .search("", {
          facets: ["*"],
        })
        .then((result: any) => {
          if (!result?.facets) return;
          setEventsInitFacets(result.facets);
        });
    }
  }, []);
}

/**
 * Format data for `Dropdown` selects
 *
 * @param data array of data with these props `{ title, _key }`
 * @returns The selected object and SelectOption state
 */
export function useSelectOptions<T>(data: T[]) {
  const [selectedObject, setSelectedObject] = useState<T>();
  const [selectedOption, setSelectedOption] = useState<SelectOption>();

  useEffect(() => {
    if (data && data[0]) {
      const firstItem = data[0];
      // @ts-ignore
      setSelectedOption({ title: firstItem.title, id: firstItem._key });
    }
  }, []);

  useEffect(() => {
    if (selectedOption == null) return;
    // @ts-ignore
    const objectToSet = data.find(item => item?._key === selectedOption.id);
    if (objectToSet == null) return;
    setSelectedObject(objectToSet);
  }, [selectedOption]);

  return { selectedObject, selectedOption, setSelectedOption };
}

/**
 * Get region map data with groq
 *
 * @returns Formatted region map data `VillagesInMap`
 */
export function useRegionMapsData() {
  const [villages, setVillages] = useState<VillagesInMap[]>();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == 'region' && !(_id in path("drafts.**"))]
        { 
          title, 
          slug, 
          "parentRegion": parentRegion->{slug, title },
          "count": count(*[_type=='village' && !(_id in path("drafts.**")) && references(^._id)])
        }`,
      )
      .then((results: any[]) => {
        if (!results.length) return;
        const auckland = results
          .filter(item => item?.slug?.current.includes("auckland"))
          .reduce((acc, item) => {
            let count = acc.count + item.count;
            return {
              count,
              slug: item?.parentRegion?.slug,
              title: item?.parentRegion?.title,
            };
          });

        const rest = results.filter(item => !item?.slug?.current.includes("auckland"));

        const data = [auckland, ...rest];
        setVillages(data);
      });
  }, []);

  return villages;
}

/**
 * Grabs the search param from the current url
 *
 * @param callback
 * @returns searchInput: string, shouldClear: boolean
 */
export function useUrlSearchParamForRefinement(callback: Function) {
  const { searchFacets } = useStore();
  const [shouldClear, setShouldClear] = useState(false);
  const prevSearchFacets = usePrevious(searchFacets);
  const searchInput = useUrlSearchParam();

  useEffect(() => {
    if (Boolean(searchInput?.length)) {
      callback();
    }
  }, [searchInput]);

  useEffect(() => {
    if (prevSearchFacets.length > 0 && searchFacets.length === 0) {
      setShouldClear(true);
    }

    if (searchFacets?.length > 0) {
      setShouldClear(false);
    }
  }, [searchFacets]);

  return { searchInput, shouldClear };
}
