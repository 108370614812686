import React from "react";
import styled from "styled-components";

import { BlocksContent, Image, Link } from "@global";
import { SanityRowContent } from "@graphql-types";
import { Container, PageWidth, Section } from "@util/standard";
import { mediaQuery } from "@util/constants";

interface Props {
  data: SanityRowContent;
}

const ariaLabel = "Row Content";

function RowContent({ data }: Props) {
  if (data == null) return null;

  const { text, cta, rows } = data;

  return (
    <StyledSection aria-label={ariaLabel}>
      <PageWidth>
        <div>
          {text && (
            <Container
              isTabletColumn
              justifyContent="space-between"
              margin="0 0 60px 0"
              rowGap="20px"
            >
              <Container width="74%" tabletWidth="100%">
                <BlocksContent data={text} />
              </Container>
              {cta && (
                <Container
                  width="max-content"
                  tabletWidth="100%"
                  flexDirection="column"
                  justifyContent="flex-start"
                  margin="50px 0 0 0"
                >
                  <Link className="button-base" {...cta} />
                </Container>
              )}
            </Container>
          )}
          {rows && (
            <div>
              {rows.map(row => {
                if (row == null) return null;
                return (
                  <RowWrapper flexDirection={row.reverse ? "row-reverse" : "row"} key={row._key}>
                    <Container width="50%" tabletWidth="100%">
                      {row.firstColumn && (
                        <Image data={row.firstColumn[0]} width="100%" objectFit="cover" />
                      )}
                    </Container>
                    <Container width="50%" tabletWidth="100%" backgroundColor="cream">
                      <CtasWrapper>
                        {row.secondColumn && <BlocksContent data={row.secondColumn} />}
                        {row.ctas && (
                          <Container flexWrap="wrap">
                            {row.ctas?.map(cta => {
                              if (cta == null) return null;
                              return (
                                <Link
                                  {...cta}
                                  margin="10px 0 15px 0"
                                  key={cta._key}
                                  className="button-base"
                                />
                              );
                            })}
                          </Container>
                        )}
                      </CtasWrapper>
                    </Container>
                  </RowWrapper>
                );
              })}
            </div>
          )}
        </div>
      </PageWidth>
    </StyledSection>
  );
}

export default RowContent;

const StyledSection = styled(Section)`
  h2:first-of-type {
    margin-top: 0px !important;
  }
`;

const RowWrapper = styled(Container)`
  margin: 30px auto 0px auto;

  ${mediaQuery.tabletDown} {
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0 auto 27px auto;
  }
`;

const CtasWrapper = styled.div`
  margin: 100px;

  ${mediaQuery.tabletDown} {
    width: 80%;
    margin: 40px auto;
  }

  ${mediaQuery.mobileDown} {
    width: 80%;
    margin: 50px auto;
  }
`;
