import React from "react";
import styled from "styled-components";

import { Container } from "@util/standard";
import { Maybe } from "@graphql-types";
import { Block, ErrorMsg } from "@shared";
interface Props {
  data: Maybe<Array<any>> | undefined;
  sectionMargins?: string;
}

/**
 * Determines which blocks are rendered
 *
 * @returns Block to render
 */
export default function Blocks({ data, sectionMargins }: Props) {
  if (data == null) return <ErrorMsg data={data} msg="No data for blocks" />;

  return (
    <BlockWrapper sectionMargins={sectionMargins}>
      {data.map(item => {
        return <Block data={item} key={item._key} />;
      })}
    </BlockWrapper>
  );
}

const BlockWrapper = styled(Container)<{ sectionMargins?: string }>`
  display: block;
  width: 100%;
  /* overflow: hidden; */
  section {
    ${({ sectionMargins }) => sectionMargins && `margin: ${sectionMargins};`};
  }
  section:last-child {
    margin-bottom: 80px;
  }
`;
