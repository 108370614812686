import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { SanityIframe } from "@graphql-types";
import { mediaQuery } from "@util/constants";
import { Container } from "@util/standard";

interface Props {
  data: SanityIframe;
}

export default function Iframe({ data }: Props) {
  const [iframeHeight, setIframeHeight] = useState<string | null>();

  useEffect(() => {
    if (data?.code?.code) {
      const domParser = new DOMParser();
      const doc = domParser.parseFromString(data.code.code, "text/html");
      const iframe = doc.querySelector("iframe");
      if (iframe) {
        const height = iframe.getAttribute("height");
        setIframeHeight(height);
      }
    }
  }, [data]);

  if (!data) return null;
  const code = data.code?.code;

  return (
    <Container
      width="100%"
      height={iframeHeight ? `${iframeHeight}px` : "100%"}
      position="relative"
    >
      <CodeContainer dangerouslySetInnerHTML={{ __html: `${code}` }} />
    </Container>
  );
}

const CodeContainer = styled.div<{}>`
  width: 100%;
  height: 100%;

  * {
    transition: all 0.3s ease;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;
