import { Maybe, SanityVariation } from "@graphql-types";
import { useStore } from "@state/store";
import { MetlifeUnion, isMarketoQuestionValue } from "@state/types";
import { useEffect, useState } from "react";

type SelectionState = "unselected" | "selected" | "dismissed";

export const usePersonalisationHook = (data: Maybe<SanityVariation> | undefined) => {
  const [selectedAnswer, setSelectedAnswer] = useState<MetlifeUnion | boolean>();
  const [selectionState, setSelectionState] = useState<SelectionState>("unselected");
  const [modalOpen, setModalOpen] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { pageColor, lead, setLead, personalisations, setPersonalisation } = useStore();

  useEffect(() => {
    if (personalisations && data?.marketoQuestionValue) {
      const personalisation = personalisations[data?.marketoQuestionValue];
      if (personalisation && personalisation.modalOpen) {
        setModalOpen(true);
      }
    }
  }, [personalisations]);

  useEffect(() => {
    if (data?.marketoQuestionValue == null) return;
    setHasSubmitted(false);
    if (data.marketoQuestionValue == null || lead == null) return;
    if (!isMarketoQuestionValue(data.marketoQuestionValue)) return;
    const personalisedValue = lead[data.marketoQuestionValue];
    if (personalisedValue == null) return;
    setSelectedAnswer(personalisedValue);
    setSelectionState("selected");
  }, []);

  const returnCurrentPersonalisation = () => {
    if (personalisations && data?.marketoQuestionValue) {
      const personalisation = personalisations[data?.marketoQuestionValue];
      return personalisation;
    }
    return null;
  };

  const handleAnswerSelect = (id: MetlifeUnion | boolean) => {
    console.log({ id });

    if (data?.marketoQuestionValue == null) return;

    if (data.marketoQuestionValue == null || lead == null) return;
    setSelectedAnswer(id);
    setLead({ ...lead, [data.marketoQuestionValue]: id });
    setPersonalisation(data?.marketoQuestionValue, "selected", true);
    setHasSubmitted(true);
  };

  const handleCustomModalClose = () => {
    if (data?.marketoQuestionValue == null) return;
    const current = returnCurrentPersonalisation();
    if (current) {
      if (current?.selectedState == "unselected") {
        setPersonalisation(data?.marketoQuestionValue, "dismissed", false);
      }
      handleUpdateCurrentPersonalisation(false);
      setHasSubmitted(false);
    } else {
      setPersonalisation(data?.marketoQuestionValue, "dismissed", false);
    }
  };

  const handleUpdateCurrentPersonalisation = (modalOpen: boolean) => {
    if (data?.marketoQuestionValue == null) return;
    const current = returnCurrentPersonalisation();

    setPersonalisation(data?.marketoQuestionValue, current?.selectedState ?? "", modalOpen);
  };

  return {
    selectedAnswer,
    selectionState,
    modalOpen,
    hasSubmitted,
    pageColor,
    currentPersonalisation: returnCurrentPersonalisation(),
    handleAnswerSelect,
    handleCustomModalClose,
    setModalOpen,
    handleUpdateCurrentPersonalisation,
  };
};
