import { BlocksContent } from "@global";
import { Maybe, SanityCareJourneyBlock } from "@graphql-types";
import { StyledAccordion } from "@styles/accordionStyles";
import { PageWidth } from "@util/standard";
import React from "react";
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import styled from "styled-components";

import { colors, fontFamilies, mediaQuery } from "@util/constants";

interface Props {
  data: SanityCareJourneyBlock;
}
const CareJourneyBlock = ({ data }: Props) => {
  if (data == null) return null;

  return (
    <PageWidth>
      {data.titleBlock && <BlocksContent data={data.titleBlock} />}

      <StyledJourneyTitle isMobile>{data.title}</StyledJourneyTitle>

      <StyledCareJourneyBlock>
        <div>
          <StyledJourneyTitle>{data.title}</StyledJourneyTitle>
        </div>

        <div className="stages">
          {data.stages?.map((stage, index) => {
            if (stage == null) return;
            return (
              <StyledStage
                colour={stage?.accordionColour?.colour?.hex ?? undefined}
                key={stage._key}
              >
                <div key={index} className="text-content">
                  <span className="stage-number">{index + 1}</span>
                  <h2>{stage?.title}</h2>
                  <div className="block-content-wrapper">
                    <BlocksContent data={stage?.description} />
                  </div>
                </div>

                {stage?.accordions && stage?.accordions.length > 0 && (
                  <div className="accordion-wrapper">
                    <StyledExtendedAccordion
                      allowZeroExpanded
                      colour={stage?.accordionColour?.colour?.hex ?? undefined}
                    >
                      {stage.accordions.map((accordion, index) => {
                        return (
                          <AccordionItem key={accordion?.title}>
                            <AccordionItemHeading tabIndex={index}>
                              <AccordionItemButton aria-label={`${accordion?.title}`}>
                                {accordion?.title}
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <AccordionContent
                                useRichText={true}
                                tabIndex={index}
                                aria-label={`${accordion?.title}`}
                              >
                                <BlocksContent data={accordion?.content} />
                              </AccordionContent>
                            </AccordionItemPanel>
                          </AccordionItem>
                        );
                      })}
                    </StyledExtendedAccordion>
                  </div>
                )}
              </StyledStage>
            );
          })}
        </div>
      </StyledCareJourneyBlock>
    </PageWidth>
  );
};

export default CareJourneyBlock;

const StyledJourneyTitle = styled.p<{ isMobile?: boolean }>`
  font-weight: bold;
  padding-right: 25%;
  display: ${props => (props.isMobile ? "none" : "block")};

  ${mediaQuery.tabletDown} {
    display: ${props => (props.isMobile ? "block" : "none")};
    padding-right: 0px;
    margin-top: 50px;
  }
`;

const StyledCareJourneyBlock = styled.div`
  display: grid;
  grid-template-columns: 30% 65%;
  margin: 100px 0 50px 0;

  ${mediaQuery.tabletDown} {
    margin-top: 25px;
    grid-template-columns: 8% 85%;
  }

  a {
    display: block;
  }

  .title p {
    font-weight: bold;
    padding-right: 25%;
  }

  .stages {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
`;

const StyledExtendedAccordion = styled(StyledAccordion)<{ colour?: string }>`
  padding-left: 62px;
  border-left: 1px solid ${props => props.colour};

  .accordion-button {
    font-size: 26px;
    font-weight: bold;
  }

  // ${mediaQuery.tabletDown} {
  //   padding-left: 0;
  //   border-left: none;
  // }
`;

const StyledStage = styled.div<{ colour?: string }>`
  margin-bottom: 30px;

  .text-content {
    position: relative;
    h2 {
      color: ${props => props.colour};
      padding-left: 62px;
      padding-bottom: 30px;
      text-align: left;
    }
    .block-content-wrapper {
      border-left: 1px solid ${props => props.colour};
      padding-left: 62px;
      padding-bottom: 30px;
    }
  }

  .stage-number {
    font-size: 32px;
    font-family: ${fontFamilies.tobias};
    font-weight: bold;
    border-radius: 50%;
    border: 1px solid ${props => props.colour};
    color: ${props => props.colour};
    width: 74px;
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -37px;
    top: -25px;
  }

  ${mediaQuery.tabletDown} {
    .text-content {
      h2 {
        padding-left: 35px;
      }
      .stage-number {
        width: 40px;
        height: 40px;
        top: 0px;
        left: -20px;
      }
      .block-content-wrapper {
        padding-left: 35px;
      }
    }
  }
`;

const AccordionContent = styled.div<{ useRichText: Maybe<boolean> | undefined }>`
  width: 82%;
  margin-bottom: ${props => (props.useRichText ? 40 : 0)}px;
`;
