import React from "react";

export function IconMarkerPlain() {
  return (
    <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.31579 16C6.61528 15.9987 6.89247 15.8754 7.09766 15.6532C7.10976 15.6405 7.12028 15.6273 7.13016 15.6127L11.5642 9.50124C12.1527 8.68905 12.6316 7.23855 12.6316 6.26766C12.6316 2.81152 9.79819 0 6.31515 0C4.63035 0 3.04495 0.652848 1.8508 1.83778C0.656654 3.02302 -0.000636755 4.59618 4.62884e-07 6.26766C4.62884e-07 7.23855 0.47887 8.68905 1.06702 9.50092L5.50143 15.614C5.51194 15.6285 5.52182 15.6409 5.53392 15.6535C5.73879 15.8751 6.01598 15.9984 6.3142 15.9997L6.31579 16ZM6.45184 15.0917C6.41265 15.1281 6.36581 15.1477 6.31515 15.1486C6.26513 15.1477 6.2167 15.1274 6.17783 15.0914L1.78804 9.0406L1.76127 9.0033C1.21964 8.25592 0.855784 6.98532 0.855784 6.26703C0.855784 3.28005 3.30493 0.849809 6.31515 0.849809C9.32537 0.849809 11.7745 3.28005 11.7745 6.26703C11.7745 6.98469 11.4104 8.25561 10.8684 9.00362L6.45184 15.0917Z"
        fill="#262746"
      />
      <path
        d="M6.31515 9.93499C8.40682 9.93499 10.1085 8.24581 10.1085 6.16965C10.1085 4.0935 8.40682 2.40495 6.31515 2.40495C4.22348 2.40495 2.52115 4.09413 2.52115 6.17029C2.52115 8.24644 4.22316 9.93499 6.31515 9.93499ZM6.31515 3.25539C7.93432 3.25539 9.25146 4.56267 9.25146 6.16965C9.25146 7.77664 7.93432 9.08392 6.31515 9.08392C4.69598 9.08392 3.37821 7.77664 3.37821 6.16965C3.37821 4.56267 4.69566 3.25539 6.31515 3.25539Z"
        fill="#262746"
      />
    </svg>
  );
}

export function IconPhone() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9824 15.9997C9.21939 15.9997 6.49948 15.3817 3.55417 12.4387C-0.474501 8.40847 -0.148084 4.75612 0.179363 3.37322C0.43603 2.28916 0.938024 1.42459 1.4005 1.27036C1.54241 1.22261 2.05299 1.06667 2.65394 1.06667C3.86684 1.06667 4.19429 1.70762 4.28156 2.08993C4.3465 2.3685 4.51658 2.86107 4.69663 3.38284L4.71278 3.42989C5.40718 5.43658 5.53706 5.98685 5.19862 6.32657L3.92869 7.5944C4.18261 8.33772 5.07424 9.56673 5.75147 10.2441C6.6053 11.0949 7.77869 11.8496 8.40025 12.0639L9.66744 10.7971C9.78701 10.6783 9.95468 10.6182 10.1663 10.6182C10.6144 10.6182 11.4084 10.8809 12.5684 11.2821C13.0828 11.4607 13.6147 11.6452 13.9084 11.7125L13.9947 11.7325L13.9954 11.7359C14.2984 11.8279 14.542 12.0258 14.7032 12.3116C15.1798 13.161 14.776 14.4481 14.7286 14.5924C14.5049 15.2574 12.8557 16 10.9831 16L10.9824 15.9997ZM1.71421 2.13939C1.62212 2.22595 1.13353 2.89336 0.967573 4.20035C0.784437 5.63958 0.961732 8.53866 4.20734 11.785C6.92897 14.5048 9.39393 15.0757 10.9824 15.0757C12.6928 15.0757 13.7408 14.4254 13.8638 14.2664C13.9974 13.8477 14.0947 13.116 13.8978 12.7629C13.8493 12.6781 13.7903 12.6324 13.7013 12.6118C13.3707 12.5362 12.8467 12.3549 12.2918 12.1632L12.2472 12.1477C11.549 11.9069 10.6061 11.5813 10.2282 11.5452L8.86823 12.9041C8.78199 12.99 8.6631 13.0394 8.54147 13.0394C8.51604 13.0394 8.49027 13.0374 8.46519 13.0329C7.46292 12.8673 5.86382 11.661 5.09898 10.8981C4.33551 10.1338 3.12845 8.53488 2.96078 7.52982C2.93535 7.38349 2.98345 7.23236 3.09031 7.12656L4.45267 5.76599C4.41866 5.39914 4.03142 4.28313 3.84038 3.73217L3.83145 3.70641C3.63972 3.15132 3.45865 2.62715 3.38237 2.29706C3.3353 2.09371 3.09066 1.99066 2.65463 1.99066C2.30141 1.99066 1.94201 2.06623 1.71524 2.13939H1.71421Z"
        fill="#262746"
      />
      <path
        d="M15.5435 9.06667C15.2926 9.06667 15.0888 8.86247 15.0888 8.6112C15.0874 4.36571 11.6331 0.911611 7.38878 0.910934C7.13752 0.910934 6.93333 0.706735 6.93333 0.455467C6.93333 0.221807 7.11043 0.0284455 7.33731 0.00304773L7.34104 0H7.38912C12.1353 0.000677274 15.998 3.86351 16 8.6112C16 8.86247 15.7951 9.06667 15.5435 9.06667Z"
        fill="#262746"
      />
      <path
        d="M11.8051 9.6C11.55 9.6 11.3426 9.39295 11.3426 9.13818C11.3426 6.66804 9.3323 4.658 6.86153 4.65732H6.74787L6.74512 4.64221C6.66648 4.62195 6.59436 4.58075 6.5353 4.52169C6.44808 4.43448 6.4 4.31842 6.4 4.19515C6.4 3.94072 6.60707 3.73333 6.86187 3.73333C9.84225 3.73402 12.2667 6.15849 12.2667 9.13818C12.2667 9.39295 12.0593 9.6 11.8051 9.6Z"
        fill="#262746"
      />
    </svg>
  );
}

export function IconSalesIndependent() {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.93707 13.9618C4.67975 13.9618 4.47062 13.7552 4.47062 13.5018C4.47062 11.5054 6.69432 10.8922 7.26516 10.7678C7.28111 10.7449 7.30192 10.7099 7.32585 10.6582C6.70611 10.0615 6.30972 9.10645 6.30972 8.19457C6.30972 7.49062 6.54069 6.88773 6.978 6.45131C7.41567 6.01456 8.02049 5.78368 8.72658 5.78368C10.1731 5.78368 11.1448 6.75242 11.1448 8.19457C11.1448 9.10748 10.7484 10.0629 10.1287 10.6586C10.1519 10.7093 10.1727 10.7442 10.189 10.7682C10.7602 10.8925 12.9832 11.505 12.9829 13.5018C12.9829 13.7556 12.7738 13.9618 12.5171 13.9618H4.93707ZM11.9747 13.041C11.5943 11.9548 9.90883 11.6527 9.89114 11.6496C9.82906 11.6383 9.77253 11.6167 9.72051 11.5848C9.4618 11.4228 9.27037 11.1135 9.13407 10.639C9.08171 10.4541 9.15141 10.2543 9.30747 10.1416C9.84016 9.75696 10.2119 8.95675 10.2119 8.1956C10.2119 7.27687 9.64283 6.70618 8.72658 6.70618C7.81033 6.70618 7.24227 7.27687 7.24227 8.1956C7.24227 8.95504 7.61439 9.75559 8.14707 10.1427C8.30348 10.2561 8.37284 10.4554 8.31978 10.6383C8.18557 11.1121 7.99413 11.4218 7.73438 11.5841C7.68375 11.6157 7.62514 11.6383 7.56445 11.6492L7.5582 11.6503C7.54294 11.6527 5.85922 11.9579 5.47913 13.041H11.9747Z"
        fill="#262746"
      />
      <path
        d="M1.80961 16C0.811864 16 0 15.1981 0 14.2119V4.03768C0 3.05215 0.811864 2.25024 1.80961 2.25024H6.83269V0.792327C6.83269 0.355571 7.19267 0 7.63485 0H9.81797C10.2605 0 10.6205 0.355571 10.6205 0.792327V2.25058H15.6439C16.6416 2.25058 17.4539 3.05215 17.4545 4.03768V14.2119C17.4545 15.1977 16.6423 16 15.6442 16H1.80961ZM1.81031 3.17205C1.32652 3.17205 0.932897 3.5605 0.932897 4.03802V14.2115C0.932897 14.6894 1.32652 15.0782 1.81031 15.0782H15.6446C16.1284 15.0782 16.522 14.6897 16.522 14.2119V14.0879H16.5234V4.03837C16.5234 3.56085 16.1298 3.17239 15.646 3.17239H10.6222V3.61291H12.2605C12.5182 3.61291 12.7276 3.81947 12.7276 4.07365C12.7276 4.32782 12.5182 4.53438 12.2605 4.53438H10.6215C10.6017 4.95435 10.2497 5.2904 9.8197 5.2904H7.63658C7.20689 5.2904 6.85489 4.9547 6.83547 4.53438H5.19579C4.93846 4.53438 4.72934 4.32782 4.72934 4.07365C4.72934 3.81947 4.93846 3.61291 5.19579 3.61291H6.83477V3.17239L1.81031 3.17205ZM9.68029 4.36859V0.920784H7.77461V4.36825L9.68029 4.36859Z"
        fill="#262746"
      />
    </svg>
  );
}
