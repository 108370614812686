import React, { ReactNode, RefObject } from "react";
import { SanityForm, SanityGuideCategory, SanityRegion, SanityVillage } from "@graphql-types";

import { BlocksContent } from "@global";
import { Container } from "@util/standard";
import FormPreview from "./preview";
import { mediaQuery } from "@util/constants";
import styled from "styled-components";

export interface Props {
  data?: Array<SanityVillage | SanityForm> | undefined;
  category?: SanityGuideCategory;
  region?: SanityRegion;
  isInfoPack?: boolean;
  dataOverride?: {
    title?: string;
    buttonText?: string;
    children?: ReactNode;
  };
  viewRef?: RefObject<HTMLDivElement>;
  regionOverrideString?: string | null | undefined;
}

export default function GuidesCards({
  data,
  dataOverride,
  category,
  region,
  viewRef,
  regionOverrideString,
}: Props) {
  if ((data == null || !Boolean(data.length)) && dataOverride == null) return null;

  return (
    <Container
      ref={viewRef}
      id={category?.categoryId?.current!}
      flexDirection="column"
      className="guide-cards"
    >
      <TitleContainer>
        {dataOverride && dataOverride.title && <h3>{dataOverride.title}</h3>}
        {category?.title && <h3>{category?.title}</h3>}
        {region?.title && <h2>{region?.title}</h2>}
        {regionOverrideString && <h2>{regionOverrideString}</h2>}
        {category?.description && <BlocksContent data={category?.description} />}
      </TitleContainer>
      {data && (
        <Container
          width="100%"
          columnGap="25px"
          rowGap="25px"
          overflow="auto"
          margin="0 0 40px 0"
          padding="0 0 20px 0"
        >
          {data.map((form, index) => {
            if (form == null) return null;
            return <FormPreview key={index} data={form} region={region} />;
          })}
        </Container>
      )}
      {dataOverride && dataOverride.children && <Container>{dataOverride?.children}</Container>}
    </Container>
  );
}

const TitleContainer = styled(Container)`
  padding: 40px 0;
  flex-direction: column;
  h3 {
    font-size: 32px;
  }

  button {
    margin: 0 0 0 15px;
  }

  ${mediaQuery.tabletDown} {
    width: 100%;
  }

  ${mediaQuery.mobileDown} {
    flex-direction: column;
    align-items: flex-start;

    button {
      margin: 15px 0 0 0;
    }
  }
`;
