import { BlocksContent, Button, Image } from "@global";
import { Container, PageWidth, PreviewCard, Section, Separator } from "@util/standard";
import { Drawer, Trigger } from "@accessible/drawer";
import { Maybe, SanityBlockContent, SanityImageWithMeta } from "@graphql-types";
import React, { useEffect } from "react";
import { colors, mediaQuery } from "@util/constants";

import { DrawerTarget } from "@shared";
import { GetYourSelected } from "./shared";
import GuideCardsAccordion from "./guideCardAccordion";
import InfoPackForm from "./infoPackForm";
import { InfoPacksInRegion } from "@state/types";
import { sendInfoPackViewItems } from "@util/datalayer";
import styled from "styled-components";
import { useStore } from "@state/store";
import { StyledAccordion } from "@styles/accordionStyles";

type GeneralBrochure = {
  generalBrochureImage: Maybe<SanityImageWithMeta> | undefined;
  generalBrochureText: Maybe<SanityBlockContent> | undefined;
};
interface Props {
  data: InfoPacksInRegion[];
  title: Maybe<string> | undefined;
  descriptionText: Maybe<SanityBlockContent> | undefined;
  isInfoPack?: boolean;
  generalBrochure: GeneralBrochure;
}

interface GeneralCardProps {
  generalBrochure: GeneralBrochure;
  pageColor: string;
}

export default function AllInfoPacksInRegions({
  data,
  descriptionText,
  isInfoPack,
  title,
  generalBrochure,
}: Props) {
  if (!Boolean(data.length)) return null;
  const { pageColor, selectedVillageInfoPacks } = useStore();

  useEffect(() => {
    sendInfoPackViewItems(data);
  }, []);

  return (
    <Drawer>
      <Section aria-label="Info Packs" noMargins>
        {selectedVillageInfoPacks.length > 0 && (
          <GetYourSelected
            type="info pack(s)"
            length={selectedVillageInfoPacks.length}
            pageColor={pageColor}
          />
        )}
        <DrawerTarget
          removeHeaderTransform
          title="Order your info pack(s)"
          drawerId="info-packs-form"
        >
          <Container flexDirection="column">
            <InfoPackForm isDrawer isGeneralInfoPack={false} />
          </Container>
        </DrawerTarget>

        <PageWidth>
          <Container flexDirection="column">
            <StyledAccordion allowZeroExpanded allowMultipleExpanded>
              {data.map((infoPack, index) => {
                if (infoPack == null) return null;
                const { forms, region } = infoPack;
                const filteredForms = forms.filter(form => !form.disabledInfoPack);

                return (
                  <div key={index} className="accordion-section" id={region?.marketoValue}>
                    <GuideCardsAccordion
                      data={filteredForms}
                      region={region}
                      isInfoPack={isInfoPack}
                    />
                  </div>
                );
              })}
            </StyledAccordion>
          </Container>
          <GeneralFormCard pageColor={pageColor} generalBrochure={generalBrochure} />
        </PageWidth>
      </Section>
    </Drawer>
  );
}

const GeneralFormCard = ({ pageColor, generalBrochure }: GeneralCardProps) => {
  const { setSelectedVillageInfoPacks } = useStore();
  const { generalBrochureImage, generalBrochureText } = generalBrochure;
  if (generalBrochureText == null) return null;

  return (
    <GeneralBrochureWrapper backgroundColor="cream" id="general-brochure">
      {generalBrochureImage && <Image data={generalBrochureImage} />}
      <Container className="content">
        <BlocksContent data={generalBrochureText} />
        <Drawer>
          <Trigger>
            <Container width="max-content">
              <Button
                theme="colored"
                colorvalue={pageColor ?? "cyan"}
                margin="0 0 10px 0"
                onClick={() => setSelectedVillageInfoPacks([])}
              >
                Get general brochure
              </Button>
            </Container>
          </Trigger>

          <DrawerTarget removeHeaderTransform title="General info pack">
            <Container flexDirection="column">
              <FormWrapper>
                <InfoPackForm isGeneralInfoPack isDrawer />
              </FormWrapper>
            </Container>
          </DrawerTarget>
        </Drawer>
      </Container>
    </GeneralBrochureWrapper>
  );
};

const FormWrapper = styled(Container)`
  p {
    color: ${colors.navy};
  }
`;

export const GeneralBrochureWrapper = styled(PreviewCard)<{ bottomMargin?: string }>`
  margin: 100px auto 200px auto;
  ${props => props.bottomMargin && `margin-bottom: ${props.bottomMargin};`};

  flex-direction: row;
  width: 100%;
  padding: 0;
  scroll-margin: 180px;

  .gatsby-image-wrapper {
    position: absolute;
    bottom: 0px;
    height: 125% !important;
  }

  ${mediaQuery.tabletDown} {
    flex-direction: column;
    height: fit-content;

    .gatsby-image-wrapper {
      position: relative;
      bottom: 0px;
      height: 100% !important;
    }
  }

  ${mediaQuery.mobileDown} {
    flex-direction: column;
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;

    ${mediaQuery.tabletDown} {
      display: none;
    }

    ${mediaQuery.mobileDown} {
      display: block;
    }
  }

  .content {
    flex-direction: column;
    row-gap: 30px;
    justify-content: flex-start;
    margin: auto 30px auto auto;

    ${mediaQuery.tabletDown} {
      padding: 30px;
    }

    h2 {
      margin-bottom: 0px !important;
    }
  }

  h1,
  h2,
  h3,
  h4,
  p,
  a {
    color: ${colors.navy};
  }
`;
