import React from "react";

import { Maybe } from "@graphql-types";
import {
  isSanityBlockContent,
  isSanityChecklists,
  isSanityCtaBlock,
  isSanityFaqsBlock,
  isSanityGallery,
  isSanityImage,
  isSanityImageTextColumn,
  isSanityInfoGrid,
  isSanityInfoSlider,
  isSanityMapList,
  isSanityOtherVillages,
  isSanityRowContent,
  isSanityTestimonial,
  isSanityVideo,
  isSanityVillageFaces,
  isSanityVillageRelated,
  isSanityInfoColumnList,
  isSanityMapBlock,
  isSanityCareJourneyBlock,
  isSanityIframe,
  isSanityFormsBlock,
  isSanityDynamicsFormBlock,
  isSanityAccordion,
  isSanityTaggedAccordion,
  isSanityVariation,
  isSanityPopup,
  isSanityHomeOptionsBlock,
} from "@util/types";
import { ErrorMsg } from "@shared";
import ContentPreviewGrid from "@components/contentPreviewGrid";
import FaqsBlock from "@components/faqsBlock";
import Testimonials from "@components/testimonials";
import VillageRelated from "@components/villageRelated";
import TaggedBlocks from "./taggedBlocks";
import { BlocksContent, FramePopup, Image, MapBlock, Video } from "@global";
import { PageWidth, Section } from "@util/standard";
import OtherVillages from "@components/village/otherVillages";
import CtaBlock from "@components/ctaBlock";
import ImageTextColumn from "@components/imageTextColumn";
import RowContent from "@components/rowContent";
import Gallery from "@components/gallery";
import InfoGrid from "@components/infoGrid";
import InfoSlider from "@components/infoSlider";
import Checklists from "@components/checklists";
import CompareChecklist from "@components/compareChecklist";
import MapList from "@components/mapList";
import CareJourneyBlock from "@components/careJourneyBlock";
import Iframe from "@components/iframe";
import FormsBlock from "@components/forms/formsBlock";
import { ContactForm } from "@components/forms/contactForm";
import { CareForm } from "@components/forms/careForm";
import OnPageForm from "@components/onPageForm";
import AccordionBlock from "@components/accordionBlock";
import VariationSelect from "@components/variationSelect";
import HomeOptionsBlock from "@components/village/homeOptionsBlock";

interface Props {
  data: Maybe<any | Array<any>>;
}

export default function Block({ data }: Props) {
  if (data == null) return null;

  if (data._type?.includes("tagged")) {
    return <TaggedBlocks data={data} />;
  }

  if (isSanityVideo(data)) {
    return <Video disableMargins data={data} />;
  }

  if (isSanityRowContent(data)) {
    return <RowContent data={data} />;
  }

  if (isSanityGallery(data)) {
    return <Gallery data={data} />;
  }

  if (isSanityImageTextColumn(data)) {
    return <ImageTextColumn data={data} />;
  }

  if (isSanityCtaBlock(data)) {
    return <CtaBlock data={data} />;
  }

  if (isSanityVillageRelated(data)) {
    return <VillageRelated data={data} />;
  }

  if (isSanityMapList(data)) {
    return <MapList data={data} />;
  }

  if (isSanityTestimonial(data)) {
    return <Testimonials data={data} />;
  }

  if (isSanityFaqsBlock(data)) {
    return <FaqsBlock data={data} />;
  }

  if (isSanityVillageFaces(data)) {
    return <ContentPreviewGrid data={data} />;
  }

  if (isSanityOtherVillages(data)) {
    return <OtherVillages data={data} />;
  }

  if (isSanityBlockContent(data)) {
    return (
      <Section>
        <PageWidth>
          <BlocksContent data={data} />
        </PageWidth>
      </Section>
    );
  }

  if (isSanityImage(data)) {
    return (
      <Section>
        <PageWidth>
          <Image data={data} width="100%" />
        </PageWidth>
      </Section>
    );
  }

  if (isSanityInfoGrid(data)) {
    return <InfoGrid data={data} />;
  }

  if (isSanityInfoSlider(data)) {
    return <InfoSlider data={data} />;
  }

  if (isSanityChecklists(data)) {
    return <Checklists data={data} />;
  }

  if (isSanityInfoColumnList(data)) {
    return <CompareChecklist data={data} />;
  }

  if (isSanityMapBlock(data)) {
    return <MapBlock data={data} />;
  }

  if (isSanityCareJourneyBlock(data)) {
    return <CareJourneyBlock data={data} />;
  }

  if (isSanityIframe(data)) {
    return <Iframe data={data} />;
  }

  if (isSanityFormsBlock(data)) {
    return <FormsBlock data={data} />;
  }

  if (isSanityDynamicsFormBlock(data)) {
    return <OnPageForm data={data} />;
  }

  if (isSanityAccordion(data)) {
    return <AccordionBlock data={data} />;
  }

  if (isSanityVariation(data)) {
    return <VariationSelect data={data} location="block" />;
  }

  if (isSanityPopup(data)) {
    return <FramePopup data={data} />;
  }

  if (isSanityHomeOptionsBlock(data)) {
    return <HomeOptionsBlock data={data} />;
  }

  return <ErrorMsg data={data} msg={`Error loading block ${data._type}`} />;
}
