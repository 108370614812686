import { Accordion } from "react-accessible-accordion";
import styled from "styled-components";

import { Color } from "@util/types";
import { colors, fontWeights } from "@util/constants";

export const StyledAccordion = styled(Accordion)<{ color?: Color }>`
  width: 100%;

  .accordion__item {
    border-top: 1px solid ${props => colors[props.color ?? "navy"]};
  }

  .accordion__button {
    color: ${props => colors[props.color ?? "navy"]};
    font-size: 16px;
    font-weight: bold;
    position: relative;
    width: 82%;
  }

  .accordion__heading {
    cursor: pointer;
    padding: 14px 0px;
  }

  .accordion__button:after {
    display: inline-block;
    right: -22.5%;
    top: 25%;
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
    transition: transform 0.3s ease;
  }

  .accordion__panel {
    margin-top: 14px;
  }

  li {
    margin-bottom: 15px;
  }

  .accordion-section {
    scroll-margin: 150px;
  }
`;

export const StyledMobileMenuAccordion = styled(Accordion)`
  width: 100%;

  .accordion__item {
    border: 1px solid ${colors.navy};
    margin-bottom: 10px;
  }

  .accordion__button {
    color: ${colors.navy};
    font-size: 16px;
    position: relative;
    width: 100%;
    cursor: pointer;
  }

  .accordion__button[aria-expanded="true"] {
    font-weight: ${fontWeights.bold};
    text-decoration: underline;
  }

  .accordion__button[aria-expanded="true"]:after {
    transform: rotate(225deg);
  }

  .accordion__heading {
    p {
      margin: 0;
      cursor: pointer;
      padding: 30px 0px 30px 9%;
      width: 91%;
    }
  }

  .accordion__button:after {
    display: inline-block;
    right: 9%;
    top: 30px;
    position: absolute;
    content: "";
    height: 10px;

    width: 10px;

    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
    transition: transform 0.3s ease;
  }

  .accordion__panel {
    margin-top: 14px;
  }

  li {
    margin-bottom: 15px;
  }

  #accordion-is-active {
    border: 5px solid ${colors.cyan};
    .accordion__heading {
      p {
        padding: 30px 0px 0px 9%;
      }
    }
    .accordion__button:after {
      right: calc(9% - 5px);
    }
  }
`;
