import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";

import { Maybe, SanityRegion, SanityVillage } from "@graphql-types";
import { DropdownContainer, LabelInput } from "@styles/formStyles";
import { mediaQuery } from "@util/constants";
import { Container, P } from "@util/standard";

interface Props {
  formData: any;
  setFormData: (formData: any) => void;
  regions?: SanityRegion[];
  villages?: SanityVillage[];
  isDrawer?: boolean;
  selectedVillage?: Maybe<SanityVillage> | undefined;
  setDropdownSelectedVillage?: (village: Maybe<SanityVillage>) => void;
  setDropdownSelectedRegion?: (region: Maybe<SanityRegion>) => void;
}

const BookATourData = ({
  formData,
  setFormData,
  regions,
  villages,
  isDrawer,
  selectedVillage,
  setDropdownSelectedVillage,
  setDropdownSelectedRegion,
}: Props) => {
  const [refinedVillages, setRefinedVillages] = useState(villages);

  const removeRegionsWithNoTours = regions?.filter(region => {
    const filterVillagesWithTours = villages?.filter(village => {
      return village.region?.title == region.title && village.canBookATour;
    });

    return filterVillagesWithTours && filterVillagesWithTours.length > 0;
  });

  const handleRegionChange = (e: any) => {
    const selectedRegion = e.target.value;
    setFormData({ ...formData, metlife_stated_region_of_interest: e.target.value });
    if (setDropdownSelectedRegion != null) {
      setDropdownSelectedRegion(selectedRegion);
    }

    const regionalVillages = villages?.filter(village =>
      [village.region?.dynamicsValue, village.region?.title].includes(selectedRegion),
    );

    setRefinedVillages(regionalVillages);
  };

  const handleVillageChange = (e: any) => {
    setFormData({ ...formData, metlife_villages_of_interest_brochure: e.target.value });
    if (setDropdownSelectedVillage) {
      const selectedVillage = villages?.find(village => village.villageCode == e.target.value);
      if (selectedVillage) setDropdownSelectedVillage(selectedVillage);
    }
  };

  const today = new Date().toISOString().split("T")[0];

  const handleDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setFormData({ ...formData, metlife_book_a_tour_date: event.target.value });
  };

  return (
    <TourFieldsWrapper width="100%" columnGap="25px" flexDirection={isDrawer ? "column" : "row"}>
      {/* Region */}
      {selectedVillage && <P>{selectedVillage.title}</P>}
      {!selectedVillage && (
        <>
          <DropdownContainer
            id="enquiryType"
            aria-required="true"
            required
            onChange={handleRegionChange}
            noLabel
          >
            <option value="" selected>
              Select region*
            </option>
            {removeRegionsWithNoTours?.map(region => {
              if (region == null) return null;
              const regionValue =
                Number(region?.marketoValue?.length) > 0 ? region?.marketoValue : region?.title;

              return (
                <option key={region?.dynamicsValue} value={region?.dynamicsValue!}>
                  {region?.marketoValue ?? region.title}
                </option>
              );
            })}
          </DropdownContainer>

          <DropdownContainer
            id="enquiryType"
            aria-required="true"
            required
            onChange={handleVillageChange}
            noLabel
          >
            <option selected value="">
              Select village*
            </option>
            {refinedVillages &&
              refinedVillages.map(village => {
                if (village == null) return null;
                return <option value={village.dynamicsVillageCode!}>{village.title}</option>;
              })}
          </DropdownContainer>
        </>
      )}
      {/* date */}
      <LabelInput customWidth="fit-content">
        <label htmlFor="date">Date*</label>
        <input
          id="date"
          type="date"
          onChange={handleDateChange}
          aria-required="true"
          required
          min={today}
        />
      </LabelInput>
      {/* time */}
      <DropdownContainer
        id="enquiryType"
        aria-required="true"
        required
        onChange={e => {
          setFormData({ ...formData, metlife_book_a_tour_ampm: e.target.value });
        }}
        defaultValue="default"
        noLabel
      >
        <option value="default" disabled>
          Time*
        </option>
        <option value="881990000">Morning</option>
        <option value="881990001">Afternoon</option>
      </DropdownContainer>
    </TourFieldsWrapper>
  );
};

export default BookATourData;

const TourFieldsWrapper = styled(Container)`
  column-gap: 25px;

  ${mediaQuery.tabletLandScapeDown} {
    flex-direction: column;
  }
`;
