import React, { useState } from "react";
import styled from "styled-components";
import { SwiperSlide } from "swiper/react";

import { SanityInfo, SanityVillageFaces } from "@graphql-types";
import { Section, PageWidth, Container, DesktopContainer, TabletContainer } from "@util/standard";
import { BlocksContent, PreviewCarousel, SliderPreviewCard } from "@global";
import { useArrayChunks } from "@util/hooks";
import { assets, mediaQuery } from "@util/constants";

interface Props {
  data: SanityVillageFaces;
}

const ariaLabel = "Content Preview Grid";

function ContentPreviewGrid({ data }: Props) {
  if (data == null) return null;
  const [controller, setController] = useState<SwiperCore>();

  const { text, infoBlocks } = data;

  const chunks = useArrayChunks(infoBlocks, 5);

  const handleNextSlide = () => {
    if (controller) controller.slideNext();
  };

  const handlePrevSlide = () => {
    if (controller) controller.slidePrev();
  };

  return (
    <Section aria-labelledby={ariaLabel}>
      <Wrapper>
        {text && (
          <HeaderContainer>
            <BlocksContent data={text} />
          </HeaderContainer>
        )}
        {chunks && (
          <>
            <DesktopContainer flexDirection="column" breakPointOverride={mediaQuery.ipadProDown}>
              {chunks.map((chunk, index) => {
                if (chunk == null) return null;

                return (
                  <Container key={index} flexWrap="wrap">
                    {chunk.map((rowItem: SanityInfo, index: number) => {
                      if (rowItem == null) return;

                      const width = index === 1 ? "66%" : "32%";
                      const margin = index === 4 || index === 1 ? "0 0 2% 0" : "0 2% 2% 0";

                      return (
                        <Container width={width} margin={margin} key={rowItem._key}>
                          <SliderPreviewCard {...rowItem} index={index + 1} imageStyle="cover" />
                        </Container>
                      );
                    })}
                  </Container>
                );
              })}
            </DesktopContainer>
            <TabletContainer flexDirection="column" breakPointOverride={mediaQuery.ipadProUp}>
              {infoBlocks && (
                <PreviewCarousel
                  controller={controller}
                  setController={setController}
                  length={infoBlocks.length}
                >
                  {infoBlocks.map((block, index) => {
                    if (block == null) return null;
                    const handleFocus = () => controller?.slideTo(index);

                    return (
                      <SwiperSlide key={block._key}>
                        <SliderPreviewCard
                          {...block}
                          index={index + 1}
                          slideCount={infoBlocks.length}
                          onFocus={handleFocus}
                          imageStyle="cover"
                        />
                      </SwiperSlide>
                    );
                  })}
                </PreviewCarousel>
              )}
              <PageWidth>
                <Container margin="20px 0 0 0">
                  <NavArrow src={assets.circleArrowFill} onClick={handlePrevSlide} />
                  <NavArrow src={assets.circleArrowFill} flip onClick={handleNextSlide} />
                </Container>
              </PageWidth>
            </TabletContainer>
          </>
        )}
      </Wrapper>
    </Section>
  );
}

export default ContentPreviewGrid;

const Wrapper = styled(PageWidth)`
  ${mediaQuery.tabletDown} {
    width: 100%;
    padding: 0;
  }
`;

const NavArrow = styled.img<{ flip?: boolean }>`
  ${({ flip }) => flip && `transform: rotateZ(180deg);`};
  margin-right: 20px;
`;

const HeaderContainer = styled(Container)`
  width: 73%;
  margin: 0 0 60px 0;

  ${mediaQuery.tabletDown} {
    width: 80%;
    margin: auto auto 50px auto;
  }
`;
