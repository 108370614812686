import React from "react";
import styled from "styled-components";

import { BlocksContent, Link } from "@global";
import { Container, PageWidth, Section as StandardSection } from "@util/standard";
import { SanityCtaBlock, Maybe } from "@graphql-types";
import { colors, mediaQuery } from "@util/constants";

interface Props {
  data: Maybe<SanityCtaBlock>;
  noBackgroundColor?: boolean;
}

export default function CtaBlock({ data, noBackgroundColor }: Props) {
  if (data == null) return null;
  const { text, ctas, layout } = data;

  return (
    <Section noBackgroundColor={noBackgroundColor}>
      <PageWidth>
        <CtaWrapper className="container" layout={layout}>
          <Container width="100%" className="blocks-wrapper">
            <BlocksContent data={text} />
          </Container>

          <Container
            height="100%"
            width="100%"
            alignSelf="flex-end"
            justifyContent="center"
            mobileJustifyContent="flex-start"
          >
            {ctas && (
              <Container
                columnGap="20px"
                rowGap="20px"
                margin={layout === "center" ? "0px" : "50px 0 0 0"}
                flexWrap="wrap"
                isMobileColumn={layout === "center" ? true : false}
                mobileMargin="20px 0 0 0 "
              >
                {ctas.map(link => {
                  if (link == null) return null;

                  return <Link className="button-base" key={link._key} {...link} />;
                })}
              </Container>
            )}
          </Container>
        </CtaWrapper>
      </PageWidth>
    </Section>
  );
}

const Section = styled(StandardSection)<{ noBackgroundColor: boolean | undefined }>`
  background-color: ${props => (props.noBackgroundColor ? colors.transparent : colors.cream)};
  position: relative;

  .container {
    ${mediaQuery.tabletDown} {
      flex-direction: column;
    }
  }
`;

const CtaWrapper = styled(Container)<{ layout?: Maybe<string> | undefined }>`
  justify-content: space-between;
  margin: 75px 0;
  row-gap: 30px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0px !important;
  }

  ${props =>
    props.layout === "center" &&
    `
    flex-direction: column;

    .blocks-wrapper {
      justify-content: center;
    }

    h1,h2,h3,h4,h5,h6,p {
      text-align: center;
    }
  
  `}

  ${mediaQuery.tabletDown} {
    margin: 30px 0 60px 0;
    row-gap: 0;
  }
`;
