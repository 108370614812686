import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";

export default function regionMapQuery() {
  const { sanityRegionMap, allSanityVillage }: Query = useStaticQuery(graphql`
    {
      sanityRegionMap {
        mapImage {
          asset {
            url
          }
        }
        hotspots {
          _key
          _type
          details
          y
          x
          region {
            _id
            _key
            title
            slug {
              current
            }
          }
        }
      }
      allSanityVillage {
        nodes {
          _id
          title
          slug {
            current
          }
          region {
            _id
            _key
            title
            slug {
              current
            }
            parentRegion {
              _id
              _key
              title
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);

  const regionMapData = { regions: sanityRegionMap, villages: allSanityVillage.nodes };

  return regionMapData;
}
