import React from "react";
import styled from "styled-components";

import { useFooterQuery } from "@api";
import { ErrorMsg } from "@shared";
import { Container, DesktopContainer, PageWidth, TabletContainer } from "@util/standard";
import {
  DESKTOP_MAX_WIDTH,
  assets,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  mediaQuery,
} from "@util/constants";
import { Button, Link, SocialIcon } from "@global";
import { scrollWindow } from "@util/helper";

import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { StyledAccordion } from "@styles/accordionStyles";

export default function Footer() {
  // TODO: move this static query to a page query in layout
  const data = useFooterQuery();

  if (data == null) return <ErrorMsg data={data} msg="Error fetching data for footer" />;
  const { groupedLinks, newsletterTitle, newsletterCta, socials } = data;

  const groupLinksLength = groupedLinks && groupedLinks?.length - 1;

  const lastGroupedLinks =
    groupLinksLength && groupedLinks && groupedLinks[groupLinksLength]?.links;

  const handleArrowClick = () => {
    scrollWindow();
  };

  return (
    <FooterWrapper>
      <PageWidth>
        <DesktopContainer breakPointOverride={mediaQuery.ipadProDown}>
          <FooterContainer id="footer">
            {newsletterTitle && <p className="newsletter-title">{newsletterTitle}</p>}

            {/* TODO: Add CTA action */}
            <Container alignItems="center" isMobileColumn>
              {newsletterCta && (
                <Button theme="cream" margin="0 25px 0 0">
                  {newsletterCta.linkText}
                </Button>
              )}
              <Container>
                {socials?.map(social => (
                  <SocialIcon key={social?._key} data={social} />
                ))}
              </Container>
            </Container>
            <Container justifyContent="space-between" margin="100px 0 0 0">
              {groupedLinks &&
                groupedLinks.map(groupedLink => {
                  if (groupedLink == null) return null;

                  const { title, links, _key } = groupedLink;

                  return (
                    <Nav key={_key} aria-labelledby="nav-group">
                      {title && <p id="nav-group-title">{title}</p>}

                      <ul>
                        {links &&
                          links.map(link => {
                            if (link == null) return null;

                            return (
                              <li key={link._key}>
                                <Link {...link} theme="white" />
                              </li>
                            );
                          })}
                      </ul>
                    </Nav>
                  );
                })}
            </Container>
          </FooterContainer>
          <GoToTop aria-label="Go to top" onClick={handleArrowClick}>
            <img aria-hidden="true" src={assets.goToTop} alt="go to top arrow" />
            <p>Go to top</p>
          </GoToTop>
        </DesktopContainer>

        <TabletContainer flexDirection="column" breakPointOverride={mediaQuery.ipadProUp}>
          <FooterContainer>
            {newsletterTitle && <p className="newsletter-title">{newsletterTitle}</p>}

            {newsletterCta && (
              <Button theme="cream" margin="0">
                {newsletterCta.linkText}
              </Button>
            )}
            <Container margin="40px 0px">
              {socials?.map(social => (
                <SocialIcon key={social?._key} data={social} showText={false} />
              ))}
            </Container>
            <Container flexDirection="column">
              <StyledAccordion allowZeroExpanded color="white">
                {groupedLinks &&
                  groupedLinks.map((groupedLink, index) => {
                    if (groupedLink == null || index == groupedLinks.length - 1) return null;
                    const { title, links, _key } = groupedLink;

                    return (
                      <AccordionItem key={_key}>
                        <AccordionItemHeading>
                          <AccordionItemButton>{title}</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          {links &&
                            links.map(link => {
                              if (link == null) return null;

                              return (
                                <li key={link._key}>
                                  <Link {...link} theme="white" />
                                </li>
                              );
                            })}
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  })}
              </StyledAccordion>
            </Container>
            <Container justifyContent="space-between">
              {lastGroupedLinks && lastGroupedLinks[0] && (
                <Container margin="50px 0">
                  <Link {...lastGroupedLinks[0]} theme="whiteInverse" />
                </Container>
              )}
              <GoToTop aria-label="Go back to top of page" onClick={handleArrowClick}>
                <img src={assets.goToTop} alt="go to top" />
                <p>Go to top</p>
              </GoToTop>
            </Container>
          </FooterContainer>
        </TabletContainer>
      </PageWidth>
    </FooterWrapper>
  );
}

// TODO: add mobile styling
const FooterWrapper = styled.footer`
  width: 100%;
  background-color: ${colors.navy};
  position: relative;
`;

const FooterContainer = styled(Container)`
  width: 100%;
  margin: auto;
  flex-direction: column;
  padding: 64px 20px 200px 0;

  ${mediaQuery.tabletDown} {
    padding: 60px 20px;
  }

  h3,
  h2,
  .newsletter-title {
    margin-top: 0;
    font-family: ${fontFamilies.tobias};
    margin-bottom: 20px;
    line-height: 1.2;
    font-size: ${fontSizes.h3.default}px;
    ${mediaQuery.tabletDown} {
      width: 100%;
    }
  }

  p,
  a,
  h4,
  h3,
  h2 {
    color: ${colors.white};
  }

  ${mediaQuery.tabletDown} {
    .newsletter-title {
      font-size: ${fontSizes.h2.mobile}px;
    }
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  row-gap: 10px;

  li {
    margin-bottom: 10px;
  }
  #nav-group-title {
    font-size: ${fontSizes.h4.default}px;
    line-height: 1.2;
    font-weight: ${fontWeights.bold};
    margin-bottom: 20px;
    margin-top: 0;
  }
`;

const GoToTop = styled.button`
  display: block;
  position: absolute;
  bottom: 75px;
  right: 95px;
  cursor: pointer;
  background-color: transparent;
  border: none;

  img {
    width: 50px;
    height: auto;
  }

  p {
    margin: 10px;
    color: ${colors.white};
    ${mediaQuery.mobileDown} {
      display: none;
    }
  }

  ${mediaQuery.ipadProDown} {
    position: unset;
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-self: flex-end;
    align-items: center;
    margin: 50px 0;
  }
`;
