import React, { useState } from "react";
import styled from "styled-components";

import { assets, colors } from "@util/constants";
import { Container } from "@util/standard";
import { Maybe } from "@graphql-types";

export interface MarkerProps {
  lat: Maybe<number> | undefined;
  lng: Maybe<number> | undefined;
  index?: number;
  title?: Maybe<string>;
}

export function MapMarker(_: MarkerProps) {
  return <Icon src={assets.pin} alt="map pin" />;
}

export function MapPoint({ title, index }: MarkerProps) {
  const [hover, setHover] = useState(false);

  return (
    <Circle onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <p className="h4">{index}</p>
      {title && (
        <Tooltip visible={hover as boolean}>
          <p className="h4">{title}</p>
        </Tooltip>
      )}
    </Circle>
  );
}

const Icon = styled.img`
  position: "absolute";
  transform: translate(-50%, -75%);
`;

const Circle = styled(Container)`
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${colors.navy};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  h4 {
    color: ${colors.white};
    margin: 0;
  }
`;

const Tooltip = styled.div<{ visible: boolean }>`
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  width: max-content;
  background-color: ${colors.navy};
  position: absolute;
  z-index: 1;
  bottom: 125%;
  padding: 5px 10px;
  text-align: center;
  transition: opacity 0.3s;
  ::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${colors.black} transparent transparent transparent;
  }
`;
