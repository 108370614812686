import { useRegionQuery } from "@api";
import { SanityRegion } from "@graphql-types";
import { DropdownContainer, LabelInput } from "@styles/formStyles";
import { parsePhoneNumber } from "awesome-phonenumber";
import React, { ChangeEvent } from "react";

interface Props {
  formData: any;
  setFormData: (formData: any) => void;
  isBookATour?: boolean;
  isDrawer?: boolean;
  isInfoPack?: boolean;
  isMetguide?: boolean;
  isGeneralInfoPack?: boolean;
  isMarketoForm?: boolean;
  regionOverride?: SanityRegion[] | undefined;
  isVillageContactForm?: boolean;
}

const CoreFormFields = ({
  formData,
  setFormData,
  isBookATour = false,
  isDrawer = false,
  isInfoPack = false,
  isMetguide = false,
  isGeneralInfoPack = true,
  isMarketoForm = false,
  regionOverride,
  isVillageContactForm = false,
}: Props) => {
  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, Phone: e.target.value });
    const phoneValidation = parsePhoneNumber(e.target.value, "NZ");

    if (!phoneValidation.isValid()) {
      e.target.setCustomValidity("Please enter a correct phone number");
      e.target.reportValidity();
    }
    if (phoneValidation.isValid()) {
      e.target.setCustomValidity("");
      e.target.reportValidity();
    }
  };

  const regions = regionOverride ? regionOverride : useRegionQuery();

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const validityState = e.target.validity;
    console.log({ validityState });

    if (validityState.patternMismatch) {
      e.target.setCustomValidity("Set the message to show");
    }

    e.target.reportValidity();
    setFormData({ ...formData, Email: e.target.value });
  };

  return (
    <>
      <LabelInput isDrawer={isDrawer} isMetguide={isMetguide}>
        <label htmlFor="firstName">First Name*</label>
        <input
          id="firstName"
          type="text"
          autoComplete="firstName"
          onChange={e => setFormData({ ...formData, FirstName: e.target.value })}
          aria-required="true"
          required
          maxLength={50}
        />
      </LabelInput>
      <LabelInput isDrawer={isDrawer} isMetguide={isMetguide}>
        <label htmlFor="lastName">Last Name*</label>
        <input
          id="lastName"
          type="text"
          autoComplete="lastName"
          onChange={e => setFormData({ ...formData, LastName: e.target.value })}
          aria-required="true"
          maxLength={50}
          required
        />
      </LabelInput>
      <LabelInput isDrawer={isDrawer} isMetguide={isMetguide}>
        <label htmlFor="email">Email*</label>
        {/* <input
          id="email"
          type="email"
          onChange={e => setFormData({ ...formData, Email: e.target.value })}
          aria-required="true"
        /> */}
        <input
          type="email"
          pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
          required
          title="Please enter a valid email address"
          onChange={e => setFormData({ ...formData, Email: e.target.value })}
          aria-required="true"
          id="email"
        />
      </LabelInput>
      <LabelInput isDrawer={isDrawer} isMetguide={isMetguide}>
        <label htmlFor="phone">Phone*</label>
        <input
          id="phone"
          type="text"
          inputMode="numeric"
          onChange={e => setFormData({ ...formData, Phone: e.target.value })}
          aria-required="true"
          required
        />
      </LabelInput>
      {!isVillageContactForm &&
        !isBookATour &&
        isGeneralInfoPack &&
        formData?.metlife_contact_us_reason !== "881990000C" && (
          <LabelInput>
            <label htmlFor="region_of_interest">Region*</label>
            <DropdownContainer
              id="region_of_interest"
              aria-required="true"
              required
              onChange={e => {
                setFormData({ ...formData, metlife_stated_region_of_interest: e.target.value });
              }}
              defaultValue="default"
              isDrawer={isDrawer}
            >
              <option value="">Select...</option>
              {regions?.map(region => {
                if (region == null || region?.title == null) return null;
                const regionValue = region?.dynamicsValue ?? region.marketoValue;

                return (
                  <option
                    key={region.id}
                    value={isMarketoForm ? region?.marketoValue : regionValue}
                  >
                    {region?.marketoValue ?? region.title}
                  </option>
                );
              })}
            </DropdownContainer>
          </LabelInput>
        )}
    </>
  );
};

export default CoreFormFields;
