import React from "react";

import { Section, PageWidth, Container, GridContainer } from "@util/standard";
import { SanityInfoColumnList } from "@graphql-types";
import { Link } from "@global";
import { InfoPreview } from "./infoGrid";
import { ChecklistTable } from "./checklists";
import { useCheckScreenSize } from "@util/hooks";

interface Props {
  data: SanityInfoColumnList;
}

const ariaLabel = "Compare checklist";

export default function CompareChecklist({ data }: Props) {
  if (data == null) return null;
  const { heading, cta, description, infoCards, checklistTable, tableHeading } = data;

  const firstList = checklistTable && checklistTable[0];
  const secondList = checklistTable && checklistTable[1];
  const thirdList = checklistTable && checklistTable[2];
  const numberOfRows = new Array(firstList?.items?.length).fill("").map((_, i) => i + 1);

  const tableProps = {
    numberOfRows,
    firstList,
    secondList,
    thirdList,
  };

  const { mobileDown } = useCheckScreenSize();

  if (mobileDown && thirdList) return null;

  return (
    <Section aria-labelledby={ariaLabel}>
      <PageWidth>
        <Container isTabletColumn justifyContent="space-between" margin="0 0 60px 0" rowGap="20px">
          <Container flexDirection="column" width="74%" tabletWidth="100%">
            <h3>{heading}</h3>
            <p>{description}</p>
          </Container>
          {cta && (
            <Container
              width="max-content"
              tabletWidth="100%"
              flexDirection="column"
              justifyContent="flex-end"
            >
              <Link className="button-base" {...cta} />
            </Container>
          )}
        </Container>
        {/* TODO: Change this to a slider for tablet */}
        {infoCards && (
          <GridContainer repeat={3} columnGap={25} rowGap={50}>
            {infoCards.map(item => {
              if (item == null) return null;

              return <InfoPreview data={item} key={item._key} useVariantStyling />;
            })}
          </GridContainer>
        )}
        <ChecklistTable {...tableProps} items={checklistTable} heading={tableHeading} />
      </PageWidth>
    </Section>
  );
}
