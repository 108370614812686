import React, { memo, useState } from "react";
import styled from "styled-components";
import { SwiperSlide } from "swiper/react";

import { Maybe, SanityInfo, SanityInfoGrid } from "@graphql-types";
import {
  Container,
  DesktopContainer,
  GridContainer,
  PageWidth,
  Section,
  TabletContainer,
} from "@util/standard";
import { BlocksContent, Image, Link, PreviewCarousel, VariedImages } from "@global";
import { assets, colors, fontSizes, mediaQuery } from "@util/constants";
import InfoAccordionList from "./infoAccordionList";

interface Props {
  data: SanityInfoGrid;
}

interface InfoPreviewProps {
  data: Maybe<SanityInfo>;
  useVariantStyling?: boolean;
}

const ariaLabel = "Information Grid";

function InfoGrid({ data }: Props) {
  const [controller, setController] = useState<any>();

  if (!data) return null;
  const { text, cta, grid, accordionList } = data;

  if (accordionList) return <InfoAccordionList data={data} />;

  return (
    <Section aria-labelledby={ariaLabel}>
      <PageWidth mobileFullWidth>
        <HeaderWrapper>
          {text && (
            <Container className="info-grid-header-text">
              <BlocksContent data={text} />
            </Container>
          )}

          {cta && (
            <Container flexDirection="column" justifyContent="flex-end">
              <Link {...cta} className="button-base" />
            </Container>
          )}
        </HeaderWrapper>
        <DesktopContainer>
          {grid && (
            <GridContainer repeat={3} columnGap={25} rowGap={50}>
              {grid.map(item => {
                if (item == null) return null;

                return <InfoPreview data={item} key={item._key} />;
              })}
            </GridContainer>
          )}
        </DesktopContainer>
        <TabletContainer flexDirection="column">
          <Container width="80%" flexDirection="column" margin="0 auto">
            {grid && (
              <PreviewCarousel
                controller={controller}
                setController={setController}
                length={grid.length}
              >
                {grid.map(item => {
                  if (item == null) return null;

                  return (
                    <SwiperSlide key={item._key}>
                      <InfoPreview data={item} />
                    </SwiperSlide>
                  );
                })}
              </PreviewCarousel>
            )}

            <Container margin="35px 0 0 0">
              <NavArrow
                src={assets.circleArrowFill}
                onClick={() => controller && controller.slidePrev()}
              />
              <NavArrow
                src={assets.circleArrowFill}
                flip
                onClick={() => controller && controller.slideNext()}
              />
            </Container>
          </Container>
        </TabletContainer>
      </PageWidth>
    </Section>
  );
}

export default memo(InfoGrid);

export const InfoPreview = ({ data, useVariantStyling }: InfoPreviewProps) => {
  if (!data) return null;
  const { title, description, image, cta, variedImages, richDescription } = data;

  return (
    <PreviewWrapper useVariantStyling={useVariantStyling}>
      <ImageWrapper>
        {Boolean(variedImages?.length) ? (
          <VariedImages data={variedImages} defaultImage={image} />
        ) : (
          <Image data={image} objectFit="cover" width="100%" />
        )}
      </ImageWrapper>
      <Container flexDirection="column" className="preview-content">
        <h4>{title}</h4>
        {Boolean(richDescription?._rawBlockContent?.length) ? (
          <BlocksContent data={richDescription} />
        ) : (
          <p>{description}</p>
        )}
        {cta && (
          <Container margin="30px 0 0 0">
            <Link {...cta} className="button-base" />
          </Container>
        )}
      </Container>
    </PreviewWrapper>
  );
};

// STYLED COMPONENTS

const HeaderWrapper = styled(Container)`
  justify-content: space-between;
  margin: 0 0 50px 0;
  .info-grid-header-text {
    width: 71%;
  }
  h3 {
    margin-top: 0px;
  }

  ${mediaQuery.tabletDown} {
    flex-direction: column;
    width: 80%;
    margin: 0 auto 50px auto;
    .info-grid-header-text {
      width: 100%;
      margin-bottom: 20px;
    }
  }
`;

const PreviewWrapper = styled.div<{ useVariantStyling?: boolean }>`
  height: auto;

  h4 {
    font-size: ${fontSizes.h2.default}px;
    margin: 30px 0 15px 0;
  }

  ${({ useVariantStyling }) =>
    useVariantStyling &&
    `
    background: ${colors.cream};
    .preview-content {
      h4 {
        margin-top: 0;
      }
      width: 75%;
      margin: 40px auto;
    }
  `}
`;

const ImageWrapper = styled(Container)`
  height: 450px;
  ${mediaQuery.mobileDown} {
    height: 250px;
  }
`;

const NavArrow = styled.img<{ flip?: boolean }>`
  ${({ flip }) => flip && `transform: rotateZ(180deg);`};
  margin-right: 20px;
`;
