import React from "react";
import styled from "styled-components";

import { BlocksContent, Image, Link } from "@global";
import { Maybe, SanityImageTextColumn } from "@graphql-types";
import { mediaQuery } from "@util/constants";
import { Container, PageWidth, Section } from "@util/standard";

interface Props {
  data: Maybe<SanityImageTextColumn> | undefined;
}

const ariaLabel = "image-text";

export default function ImageTextColumn({ data }: Props) {
  if (data == null) return null;
  const { reverse, firstColumn, secondColumn, ctas } = data;

  return (
    <PageWidth fullWidth>
      <MainContainer
        marginOverride="75px auto 0px auto"
        reverse={reverse}
        aria-labelledby={ariaLabel}
      >
        {firstColumn && (
          <MediaContainer>
            <Image width="100%" height="100%" objectFit="cover" data={firstColumn[0]} />
          </MediaContainer>
        )}

        <ContentContainer>
          <BlockContainer aria-label={ariaLabel}>
            <BlocksContent data={secondColumn} />
            <Container>
              {ctas && ctas[0] && (
                <Container margin="30px 0 0 0">
                  <Link {...ctas[0]} className="button-base" />
                </Container>
              )}
            </Container>
          </BlockContainer>
        </ContentContainer>
      </MainContainer>
    </PageWidth>
  );
}

const MainContainer = styled(Section)<{
  reverse: Maybe<boolean> | undefined;
}>`
  display: flex;
  height: 100%;
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  max-height: 90vh;
  ${mediaQuery.tabletDown} {
    max-height: unset;
    flex-direction: column;
    width: 100%;
  }
`;

const ContentContainer = styled(Container)`
  position: relative;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  ${mediaQuery.tabletDown} {
    height: 100%;
  }
`;

const BlockContainer = styled(Container)`
  width: 70%;
  position: relative;
  flex-direction: column;
  margin: auto 20% auto 12%;

  ${mediaQuery.smallLaptopDown} {
    margin: 20% auto;
  }

  ${mediaQuery.tabletDown} {
    width: 100%;
    margin: 20px auto 0px auto;
  }
`;

const MediaContainer = styled(Container)`
  position: relative;
  width: 100%;

  ${mediaQuery.tabletDown} {
    width: initial;
    height: 350px;
    div {
      max-height: 350px;
    }
  }

  ${mediaQuery.mobileDown} {
    width: initial;
    height: 250px;
    div {
      max-height: 250px;
    }
  }
`;
