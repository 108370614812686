import React from "react";

import { Maybe, SanityVillageHomeOption } from "@graphql-types";
import { GridContainer, PageWidth, Section } from "@util/standard";
import PropertyPreview from "./preview";

interface Props {
  data: Maybe<SanityVillageHomeOption>[];
}

const ariaLabel = "Home option properties";

export default function PropertyPreviewGrid({ data }: Props) {
  if (data == null) return null;

  return (
    <Section aria-label={ariaLabel} marginOverride="0 0 120px 0">
      <PageWidth>
        <GridContainer repeat={2} tabletRepeat={1} columnGap={30} rowGap={30}>
          {data.map((listing, index) => {
            if (listing == null) return null;
            return <PropertyPreview key={index} data={listing} />;
          })}
        </GridContainer>
      </PageWidth>
    </Section>
  );
}
