import React from "react";
import { useStaticQuery, graphql, HeadProps } from "gatsby";
import { Helmet } from "react-helmet";

import { Maybe, Query, SanitySeo } from "@graphql-types";

interface Props extends Omit<HeadProps, "pageContext" | "data"> {
  seoData?: SanitySeo | null;
  isArticle?: boolean;
  overwriteTitle?: Maybe<string>;
  overwriteImage?: Maybe<string>;
  overwriteDescription?: Maybe<string>;
  slug?: string;
}

const isProd = process.env.GATSBY_NODE_ENV === "production";

export default function SEO(props: Props) {
  const { sanitySiteConfig }: Query = useStaticQuery(graphql`
    query defaultSEOQuery {
      sanitySiteConfig {
        defaultSEO {
          ...sanitySeo
        }
      }
    }
  `);

  const urlString = props.slug
    ? `${process.env.GATSBY_BASE_URL}${props?.location?.pathname || props.slug}`
    : process.env.GATSBY_BASE_URL;

  return (
    <>
      <Helmet
        title={
          props.overwriteTitle ||
          props.seoData?.pageTitle ||
          (sanitySiteConfig?.defaultSEO?.pageTitle as string)
        }
      >
        {!isProd && <meta name="robots" content="noindex"></meta>}
        {props.seoData?.disabledIndexing && <meta name="robots" content="noindex" />}
        {props.seoData?.disabledIndexing && <meta name="robots" content="nofollow" />}
        <link rel="icon" href="https://metlifecare.co.nz/favicon.ico"></link>
        <meta
          name="description"
          content={
            props.seoData?.pageDescription ||
            props.overwriteDescription ||
            (sanitySiteConfig?.defaultSEO?.pageDescription as string)
          }
        />
        <meta
          name="keywords"
          content={
            props.seoData?.pageKeyWords || (sanitySiteConfig?.defaultSEO?.pageKeyWords as string)
          }
        />
        <html lang="en" />

        <meta
          property="og:title"
          content={
            props.overwriteTitle ||
            props.seoData?.pageTitle ||
            (sanitySiteConfig?.defaultSEO?.pageTitle as string)
          }
        />
        <meta property="og:type" content={props.isArticle ? `article` : `website`} />
        <meta property="og:url" content={urlString} />
        <meta
          property="og:image"
          content={
            props.seoData?.ogImage?.asset?.url ||
            props.overwriteImage ||
            (sanitySiteConfig?.defaultSEO?.ogImage?.asset?.url as string)
          }
        />
        <meta
          property="og:description"
          content={
            props.seoData?.pageDescription ||
            props.overwriteDescription ||
            (sanitySiteConfig?.defaultSEO?.pageDescription as string)
          }
        />

        {/* Twitter Card tags */}
        <meta
          name="twitter:card"
          content={
            props.seoData?.pageDescription ||
            props.overwriteDescription ||
            (sanitySiteConfig?.defaultSEO?.pageDescription as string)
          }
        />
        <meta
          name="twitter:title"
          content={
            props.overwriteTitle ||
            props.seoData?.pageTitle ||
            (sanitySiteConfig?.defaultSEO?.pageTitle as string)
          }
        />
        <meta
          name="twitter:image"
          content={
            props.seoData?.ogImage?.asset?.url ||
            props.overwriteImage ||
            (sanitySiteConfig?.defaultSEO?.ogImage?.asset?.url as string)
          }
        />
        <meta property="twitter:site" content={urlString} />
        <meta
          name="twitter:description"
          content={
            props.seoData?.pageDescription ||
            props.overwriteDescription ||
            (sanitySiteConfig?.defaultSEO?.pageDescription as string)
          }
        />
        {props.seoData?.jsonLD && (
          <script type="application/ld+json">{props.seoData.jsonLD.code}</script>
        )}
      </Helmet>
    </>
  );
}
