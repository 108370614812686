import { Container, H2Heading, PageWidth, Section } from "@util/standard";
import { Maybe, SanityFaqOrFaqReference, SanityFaqsBlock } from "@graphql-types";
import { isSanityFaq, isSanityFaqReference } from "@util/types";

import Faq from "./faq";
import { Link } from "@global";
import React from "react";
import { StyledAccordion } from "@styles/accordionStyles";
import { mediaQuery } from "@util/constants";
import styled from "styled-components";
import { useFaqsFromCategory } from "@util/logicHooks";
import { useStore } from "@state/store";

interface Props {
  data: SanityFaqsBlock;
}

const ariaLabel = "Village Event";

export default function FaqsBlock({ data }: Props) {
  if (data == null) return null;
  const { faqs, categories, toggleViewAll } = data;

  const faqsFromCategory = useFaqsFromCategory(categories);
  const { pagePaths } = useStore();

  // TODO: add hook to check variants for each faq
  return (
    <Section aria-labelledby={ariaLabel} >
      <PageWidth>
        <HeaderWrapper>
          <H2Heading id='65404919-79a3-4e56-b114-ca41f3c8ccfd'>FAQs</H2Heading>
          {!toggleViewAll && (
            <Link
              className="button-base"
              linkText="View all FAQs"
              // TODO: add FAQs landing page link here
              internalLink={pagePaths?.faqsLanding}
            />
          )}
        </HeaderWrapper>
        <StyledAccordion allowZeroExpanded>
          {faqs?.map((faqData, index) => {
            const faq = determineFaqData(faqData);
            if (faq == null) return null;

            return <Faq data={faq} key={faq._id ?? index} />;
          })}
          {faqsFromCategory?.map((faqData, index) => {
            const faq = determineFaqData(faqData);
            if (faq == null) return null;

            return <Faq data={faq} key={faq._id ?? index} />;
          })}
        </StyledAccordion>
      </PageWidth>
    </Section>
  );
}

const HeaderWrapper = styled(Container)`
  .button-base {
    margin-left: 40px;
  }
  ${mediaQuery.tabletDown} {
    flex-direction: column;

    .button-base {
      margin: 0 0 40px 0;
    }
  }
`;

const determineFaqData = (data: Maybe<SanityFaqOrFaqReference>) => {
  if (isSanityFaq(data)) return data;

  if (isSanityFaqReference(data)) {
    if (data?.faqRef == null) return null;
    return data.faqRef;
  }

  return null;
};
