import React, { ReactNode, useState } from "react";
import styled from "styled-components";

import { Maybe, SanityHero } from "@graphql-types";
import { ErrorMsg } from "@shared";
import { AbsoluteWrapper, Container, PageWidth, ScrollIcon } from "@util/standard";
import { BlocksContent, Image, Link } from "@global";
import { assets, colors, mediaQuery, mobileOSQueries } from "@util/constants";
import { isBrowser } from "@util/helper";
import { useStore } from "@state/store";

import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import BackgroundVideo from "@components/backgroundVideo";

interface Props {
  data: Maybe<SanityHero> | undefined;
  extraContent?: ReactNode;
}

const ariaLabel = "Hero Heading";

export default function Hero({ data, extraContent }: Props) {
  const { pageColor } = useStore();
  if (data == null) return <ErrorMsg data={data} msg="Error fetching data for hero" />;
  const { title, backgroundImages, links, blockContent, villageVideo } = data;
  const hasImages = Boolean(Number(backgroundImages?.length) > 0 || villageVideo);

  return (
    <HeroSection aria-labelledby={ariaLabel}>
      <HeroContainer
        pageColor={!hasImages ? pageColor : undefined}
        hasImage={hasImages}
        className={extraContent ? "hasExtraContent" : ""}
      >
        <Media data={data} />
        <div id="hero-content" className="content">
          <PageWidth className="content-wrapper">
            <div className="col-1">
              <h1 tabIndex={0}>{title}</h1>

              {blockContent && <BlocksContent data={blockContent} />}
              {links && links.length > 0 && (
                <Container flexFlow="wrap" margin="30px 0 0 0" columnGap="10px" id="hero-links">
                  {links.map(link => {
                    if (link == null) return null;
                    const { _key, ...rest } = link;

                    return (
                      <Container margin="10px 10px 0 0" key={_key}>
                        <Link {...rest} colorvalue={pageColor} />
                      </Container>
                    );
                  })}
                </Container>
              )}
            </div>
            {extraContent && <div className="col-2">{extraContent}</div>}
          </PageWidth>
          {hasImages && (
            <ScrollIcon
              tabIndex={0}
              src={assets.goToTop}
              onClick={handleClick}
              className="scroll-down"
              aria-label="Scroll down"
            />
          )}
        </div>
      </HeroContainer>
      {extraContent && <div className="heroExtraContentRow">{extraContent}</div>}
    </HeroSection>
  );
}

const Media = ({ data }: Partial<Props>) => {
  const [controller, setController] = useState<SwiperClass>();

  if (!data) return null;
  const { backgroundImages, villageVideo, slideInterval } = data;

  const FirstImage = () => (
    <Image isBackground data={backgroundImages![0]} objectFit="cover" filter="brightness(0.7)" />
  );

  if (villageVideo?.vimeoVideoId || villageVideo?.youtubeId || villageVideo?.facebookVideoId) {
    return (
      <BackgroundVideo video={villageVideo}>
        <FirstImage />
      </BackgroundVideo>
    );
  }

  if (Number(backgroundImages?.length) === 1) {
    return <FirstImage />;
  }

  if (Number(backgroundImages?.length) > 1) {
    return (
      <SliderWrapper>
        <Swiper
          modules={[Autoplay, Pagination, Navigation]}
          pagination={{
            clickable: true,
          }}
          autoplay={
            slideInterval
              ? {
                  delay: slideInterval,
                }
              : false
          }
          onSwiper={setController}
          passiveListeners
        >
          {backgroundImages?.map(image => {
            if (image == null) return null;
            return (
              <SwiperSlide>
                <Image
                  isBackground
                  data={image}
                  objectFit="cover"
                  width="100%"
                  filter="brightness(0.7)"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>

        {controller && (
          <NavArrow src={assets.circleArrowFill} onClick={() => controller.slidePrev()} />
        )}
        {controller && (
          <NavArrow right src={assets.circleArrowFill} onClick={() => controller.slideNext()} />
        )}
      </SliderWrapper>
    );
  }

  return null;
};

const handleClick = () => {
  if (isBrowser()) {
    const questions = document.getElementById("qualifying-questions");

    if (questions) {
      questions.scrollIntoView({ behavior: "smooth", block: "center" });
    } else {
      const viewHeight = window.innerHeight;
      window.scrollTo({ top: viewHeight, behavior: "smooth" });
    }
  }
};

const HeroSection = styled.section`
  .heroExtraContentRow {
    width: 100%;
    display: none;
  }

  ${mediaQuery.tabletLandScapeDown} {
    .heroExtraContentRow {
      display: block;
    }
  }
`;

const HeroContainer = styled(Container)<{ hasImage?: boolean; pageColor?: string }>`
  position: relative;
  overflow: hidden;
  height: ${props => (props.hasImage ? "100vh" : "70vh")};
  min-height: ${props => (props.hasImage ? "100vh" : "70vh")};
  width: 100%;
  height: 100%;
  background-color: ${props => (props.hasImage ? colors.white : colors.navy)};
  align-items: ${props => (props.hasImage ? "initial" : "center")};

  div {
    color: white;
  }

  .content {
    position: relative;
    width: 100%;
    margin: 0 40px;
    ${mediaQuery.mobileDown} {
      margin: 20px;
      .scroll-down {
        bottom: 35px;
      }
    }
  }

  #hero-content {
    pointer-events: none;
    z-index: 1;
    display: grid;
    align-items: center;
    .content-wrapper {
      margin: 160px auto 0 auto;
      h1 {
        ${({ pageColor }) => pageColor && `color: ${pageColor};`}
      }
    }
  }

  #hero-links,
  .scroll-down {
    pointer-events: visible;
  }

  &.hasExtraContent {
    #hero-content {
      pointer-events: initial;
    }
    .content-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      align-items: center;
      ${mediaQuery.tabletLandScapeDown} {
        grid-template-columns: 1fr;
        .col-2 {
          display: none;
        }
      }
    }
  }

  ${mobileOSQueries.iosMobile} {
    height: 500px;
    min-height: fill-available;
  }

  ${mediaQuery.tabletDown} {
    height: 92vh;
    ${mobileOSQueries.androidMobile} {
      height: 92vh;
    }
  }
`;

const SliderWrapper = styled(AbsoluteWrapper)`
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  .swiper-horizontal > .swiper-pagination {
    position: absolute;
    z-index: 2;
    text-align: left;
    left: 9%;
    bottom: 11%;
    width: max-content;
  }
  .swiper-horizontal {
    width: 100%;
    height: 100%;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    opacity: 0.5;
    background: ${colors.white};
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
  }

  ${mediaQuery.tabletDown} {
    .swiper-horizontal > .swiper-pagination {
      left: 14%;
      bottom: 9%;
    }
  }
`;

const NavArrow = styled.img<{ right?: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 50%;
  z-index: 3;
  pointer-events: visible;
  transform: translateY(-50%);
  left: 4%;
  ${({ right }) => right && `transform: rotateZ(180deg) translateY(50%); right: 4%; left: unset;`};

  ${mediaQuery.ipadProDown} {
    left: 5%;
    ${({ right }) =>
      right && `transform: rotateZ(180deg) translateY(50%); right: 5%; left: unset;`};
  }
  ${mediaQuery.tabletDown} {
    left: 7%;
    ${({ right }) =>
      right && `transform: rotateZ(180deg) translateY(50%); right: 7%; left: unset;`};
  }

  ${mediaQuery.mobileDown} {
    display: none;
  }
`;
