exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-book-a-tour-tsx": () => import("./../../../src/pages/book-a-tour.tsx" /* webpackChunkName: "component---src-pages-book-a-tour-tsx" */),
  "component---src-pages-care-tsx": () => import("./../../../src/pages/care.tsx" /* webpackChunkName: "component---src-pages-care-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dashjkleqwpocsbs-tsx": () => import("./../../../src/pages/dashjkleqwpocsbs.tsx" /* webpackChunkName: "component---src-pages-dashjkleqwpocsbs-tsx" */),
  "component---src-pages-eventregistration-tsx": () => import("./../../../src/pages/eventregistration.tsx" /* webpackChunkName: "component---src-pages-eventregistration-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-feedback-tsx": () => import("./../../../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-preferences-tsx": () => import("./../../../src/pages/preferences.tsx" /* webpackChunkName: "component---src-pages-preferences-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-search-results-tsx": () => import("./../../../src/pages/search-results.tsx" /* webpackChunkName: "component---src-pages-search-results-tsx" */),
  "component---src-templates-contact-village-landing-template-tsx": () => import("./../../../src/templates/contactVillageLandingTemplate.tsx" /* webpackChunkName: "component---src-templates-contact-village-landing-template-tsx" */),
  "component---src-templates-event-inner-template-tsx": () => import("./../../../src/templates/eventInnerTemplate.tsx" /* webpackChunkName: "component---src-templates-event-inner-template-tsx" */),
  "component---src-templates-events-landing-template-tsx": () => import("./../../../src/templates/eventsLandingTemplate.tsx" /* webpackChunkName: "component---src-templates-events-landing-template-tsx" */),
  "component---src-templates-filtered-villages-landing-template-tsx": () => import("./../../../src/templates/filteredVillagesLandingTemplate.tsx" /* webpackChunkName: "component---src-templates-filtered-villages-landing-template-tsx" */),
  "component---src-templates-guide-template-tsx": () => import("./../../../src/templates/guideTemplate.tsx" /* webpackChunkName: "component---src-templates-guide-template-tsx" */),
  "component---src-templates-guides-landing-template-tsx": () => import("./../../../src/templates/guidesLandingTemplate.tsx" /* webpackChunkName: "component---src-templates-guides-landing-template-tsx" */),
  "component---src-templates-info-packs-landing-template-tsx": () => import("./../../../src/templates/infoPacksLandingTemplate.tsx" /* webpackChunkName: "component---src-templates-info-packs-landing-template-tsx" */),
  "component---src-templates-investor-center-template-tsx": () => import("./../../../src/templates/investorCenterTemplate.tsx" /* webpackChunkName: "component---src-templates-investor-center-template-tsx" */),
  "component---src-templates-news-landing-template-tsx": () => import("./../../../src/templates/newsLandingTemplate.tsx" /* webpackChunkName: "component---src-templates-news-landing-template-tsx" */),
  "component---src-templates-news-template-tsx": () => import("./../../../src/templates/newsTemplate.tsx" /* webpackChunkName: "component---src-templates-news-template-tsx" */),
  "component---src-templates-pages-template-tsx": () => import("./../../../src/templates/pagesTemplate.tsx" /* webpackChunkName: "component---src-templates-pages-template-tsx" */),
  "component---src-templates-region-template-tsx": () => import("./../../../src/templates/regionTemplate.tsx" /* webpackChunkName: "component---src-templates-region-template-tsx" */),
  "component---src-templates-village-contact-template-tsx": () => import("./../../../src/templates/villageContactTemplate.tsx" /* webpackChunkName: "component---src-templates-village-contact-template-tsx" */),
  "component---src-templates-village-home-option-inner-template-tsx": () => import("./../../../src/templates/villageHomeOptionInnerTemplate.tsx" /* webpackChunkName: "component---src-templates-village-home-option-inner-template-tsx" */),
  "component---src-templates-village-home-options-template-tsx": () => import("./../../../src/templates/villageHomeOptionsTemplate.tsx" /* webpackChunkName: "component---src-templates-village-home-options-template-tsx" */),
  "component---src-templates-village-template-tsx": () => import("./../../../src/templates/villageTemplate.tsx" /* webpackChunkName: "component---src-templates-village-template-tsx" */),
  "component---src-templates-villages-landing-template-tsx": () => import("./../../../src/templates/villagesLandingTemplate.tsx" /* webpackChunkName: "component---src-templates-villages-landing-template-tsx" */)
}

