import React, { memo, useContext, useState } from "react";
import styled from "styled-components";

import { Maybe, SanityInfo, SanityInfoGrid } from "@graphql-types";
import { PageWidth, Section } from "@util/standard";
import { BlocksContent, Button, Image, Link, VariedImages } from "@global";
import { colors, fontFamilies, mediaQuery } from "@util/constants";
import { BlocksContext } from "../shared/globalBlocks";
import GuidesCards from "./forms/guidesCards";
import useReorderGuidesCategory from "@util/useReorderGuidesCategory";

interface Props {
  data: SanityInfoGrid;
}

function InfoAccordionList({ data }: Props) {
  if (!data) return null;
  const { text, cta, grid } = data;

  const orderdGrid: Maybe<Maybe<SanityInfo>[]> = useReorderGuidesCategory(grid);

  return (
    <Wrapper>
      <PageWidth>
        <div className="header-text">
          {text && <BlocksContent data={text} />}
          {cta && <Link {...cta} className="button-base" />}
        </div>

        <div className="list">
          {orderdGrid?.map(item => {
            if (item == null) return null;
            return <ListItem item={item} key={item._key} />;
          })}
        </div>
      </PageWidth>
    </Wrapper>
  );
}

export default memo(InfoAccordionList);

const ListItem = ({ item }: { item: Maybe<SanityInfo> }) => {
  const [show, setShow] = useState(false);
  const context = useContext(BlocksContext);
  if (!item) return null;
  const { title, description, richDescription, image, cta, accordion, variedImages } = item;

  console.log(richDescription);

  const categoryGuides = context?.guides?.filter(guide =>
    guide?.guideCategory?.find(
      category => category?.categoryId?.current === accordion?.reference?.categoryId?.current,
    ),
  );

  const handleClick = () => {
    setShow(!show);
  };

  const CallToAction = () => {
    if (accordion?.reference) {
      return (
        <Button theme="base" onClick={handleClick}>
          {accordion?.title || title}
        </Button>
      );
    }

    if (cta) {
      return <Link {...cta} className="button-base" />;
    }

    return null;
  };

  return (
    <div>
      <div className="category">
        <div className="preview-content">
          {image && (
            <div className="image-wrapper">
              {Boolean(variedImages?.length) ? (
                <VariedImages data={variedImages} defaultImage={image} />
              ) : (
                <Image data={image} objectFit="cover" width="100%" />
              )}
            </div>
          )}
          <div>
            <h4>{title}</h4>
            {Boolean(richDescription?._rawBlockContent?.length) ? (
              <BlocksContent data={richDescription} />
            ) : (
              <p>{description}</p>
            )}
          </div>
        </div>
        <CallToAction />
      </div>
      {show && accordion?.reference && (
        <GuidesCards data={categoryGuides} category={accordion?.reference} />
      )}
    </div>
  );
};

const Wrapper = styled(Section)`
  .list {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }

  .header-text {
    margin-bottom: 40px;
  }

  .category {
    display: flex;
    column-gap: 60px;
    padding-top: 40px;
    border-top: 1px solid ${colors.navy};

    ${mediaQuery.tabletDown} {
      flex-direction: column;
    }
  }

  .preview-content {
    display: flex;
    column-gap: 20px;

    h4 {
      font-size: 32px;
      font-family: ${fontFamilies.tobias};
    }

    ${mediaQuery.tabletDown} {
      row-gap: 20px;
      flex-direction: column;
      h4 {
        font-size: 26px;
      }
    }
  }

  .image-wrapper {
    width: 371px;
    height: 227px;
    flex-shrink: 0;

    ${mediaQuery.mobileDown} {
      width: 100%;
      height: 100%;
    }
  }

  .formPreview-wrapper {
    min-width: 272px;
  }
`;
