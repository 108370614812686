import React from "react";
import { useMarketo } from "@util/hooks";
import { Container } from "@util/standard";
import { Maybe } from "@graphql-types";

interface Props {
  formID: Maybe<string> | undefined;
  munchkinID: Maybe<string> | undefined;
}

const EventForm = ({ formID, munchkinID }: Props) => {
  if (formID == null || munchkinID == null) return null;

  const formConfig = {
    baseUrl: "//info.metlifecare.co.nz",
    munchkinId: munchkinID,
    formId: formID,
  };
  useMarketo(formConfig);

  return (
    <Container display="none">
      <form id={`mktoForm_${formID}`} />
    </Container>
  );
};

export default EventForm;
