import { useStaticQuery, graphql } from "gatsby";

import { Query, SanityVillageConnection } from "@graphql-types";

interface Data extends Query {
  villages: SanityVillageConnection;
}

export default function StaticPageSlugsQuery() {
  const {
    sanityNewsLanding,
    sanityVillagesLanding,
    sanityFaqsLanding,
    sanityPoliciesPage,
    sanityEventsLanding,
    sanityGuidesLanding,
    sanityInfoPacksLanding,
    sanityContactPage,
  }: Data = useStaticQuery(graphql`
    {
      sanityNewsLanding {
        slug {
          current
        }
      }
      sanityFaqsLanding {
        slug {
          current
        }
      }
      sanityVillagesLanding {
        slug {
          current
        }
      }
      sanityPoliciesPage {
        slug {
          current
        }
      }
      sanityEventsLanding {
        slug {
          current
        }
      }
      sanityGuidesLanding {
        slug {
          current
        }
      }
      sanityInfoPacksLanding {
        slug {
          current
        }
      }
      sanityContactPage {
        slug {
          current
        }
      }
    }
  `);

  return {
    newsLanding: sanityNewsLanding,
    villagesLanding: sanityVillagesLanding,
    faqsLanding: sanityFaqsLanding,
    policiesPage: sanityPoliciesPage,
    eventsLanding: sanityEventsLanding,
    guidesLanding: sanityGuidesLanding,
    infoPacksLanding: sanityInfoPacksLanding,
    contactPage: sanityContactPage,
  };
}

export type StaticPagePaths = ReturnType<typeof StaticPageSlugsQuery>;
