import { Maybe } from "@graphql-types";
import { assets, colors, mediaQuery } from "@util/constants";
import { Container } from "@util/standard";
import styled from "styled-components";

export const Form = styled.form`
  input {
    padding: 20px 15px;
    border: none;
  }
  label {
    padding: 12px 15px;
  }
  textarea {
    border: 1px solid #262746;
    border-radius: 20px;
    height: 190px;
    padding: 20px;

    ::placeholder {
      color: ${colors.navy};
    }
  }

  input:focus {
    background-color: ${colors.cream};
  }

  textarea:focus {
    background-color: ${colors.cream};
  }

  .update {
    font-weight: 700;
  }

  input[type="submit"] {
    padding: 12px 15px;
    margin: auto 0 0 0;
    height: 50px;
    border-radius: 60px;
    color: ${colors.navy};
    cursor: pointer;
    width: fit-content;
    ${mediaQuery.tabletDown} {
      margin-left: 0px;
      margin-right: auto;
      -webkit-appearance: none;
      padding: 15px 30px;
    }
  }
`;

export const LabelInput = styled.div<{
  isDrawer?: boolean;
  isMetguide?: boolean;
  customWidth?: string;
  minWidth?: string;
}>`
  display: flex;
  flex-direction: row;
  border: 1px solid #262746;
  margin-bottom: 20px;
  border-radius: 60px;
  overflow: hidden;
  width: 100%;
  height: 50px;

  label {
    width: ${props => (props.customWidth ? props.customWidth : "160px")};
    ${({ minWidth }) => minWidth && `min-width: ${minWidth};`};
    border-right: 1px solid #262746;
  }

  input {
    width: 80%;
  }

  ${({ isMetguide }) =>
    isMetguide &&
    `
    width: 45%;
    `};

  ${({ isDrawer }) =>
    isDrawer &&
    ` 
    height: 50px;
    padding:  0px;
    width: 100%;

    label {
      padding: 12px 15px;
      width: 180px;
    }
  `}

  ${mediaQuery.tabletDown} {
    flex-direction: column;
    border: none;
    width: 100%;
    border-radius: 0px;
    margin-bottom: 12px;
    height: auto;

    input {
      border: 1px solid #262746;
      width: 100%;
      border-radius: 60px;
      height: 50px;
    }

    input[type="date"] {
      text-align: left;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url(${assets.downArrowBlue}) no-repeat;
      background-size: 12px;
      background-position: calc(100% - 20px) center;
      background-repeat: no-repeat;
      background-color: white;
      ::-webkit-date-and-time-value {
        text-align: left;
      }
    }
    label {
      border: none;
      padding: 0px;
      margin-bottom: 6px;
    }
  }
`;

export const RadioGroup = styled.div<{ isSelected?: boolean }>`
  border: 1px solid #262746;
  border-radius: 60px;
  padding: 4px 15px;
  margin-right: 15px;
  background-color: ${props => (props.isSelected ? colors.navy : colors.white)};
  width: fit-content;
  height: 42px;

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0px;
    top: 5px;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    border: 1px solid #262746;
    position: relative;
  }
  input:checked {
    background: url(${assets.whiteTick});
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: center;
    background-color: ${colors.white};
  }
  label {
    padding: 10px 11px;
    color: ${props => (props.isSelected ? colors.white : colors.navy)};
  }

  ${mediaQuery.tabletDown} {
    margin-right: auto;
    margin-left: left;
    margin-bottom: 10px;
  }
`;

export const DropdownContainer = styled.select<{
  noLabel?: boolean;
  isDrawer?: boolean;
}>`
  font-size: 16px;
  background-color: ${colors.white};
  color: ${colors.navy};
  border-radius: 6px;
  border: none;
  width: 100%;
  padding-left: 10px;
  height: 50px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(${assets.downArrowBlue}) no-repeat;
  background-size: 12px;
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  background-color: white;

  &:focus-visible {
    outline: none;
  }

  ${({ isDrawer }) => isDrawer && `width: 72%;`};

  ${({ noLabel }) =>
    noLabel &&
    `border: 1px solid #262746;
  margin-bottom: 20px;
  border-radius: 60px;
  width: 100%;
  padding: 12px 15px;

  `}

  ${mediaQuery.tabletDown} {
    height: 50px;
    padding: 0px 15px;
    border: 1px solid #262746;
    margin-bottom: 20px;
    border-radius: 60px;
    width: 100%;
  }
`;

export const SubmitWrapper = styled(Container)<{ generalContact: Maybe<boolean> }>`
  flex-direction: column;
  justify-content: flex-start;
  ${({ generalContact }) => generalContact && `justify-content: space-between`};
  ${({ generalContact }) => generalContact && `flex-direction: row-reverse`};

  ${mediaQuery.ipadProDown} {
    flex-direction: column;
    p {
      width: 94%;
    }
  }

  ${mediaQuery.mobileDown} {
    justify-content: flex-start;
  }
`;

export const StyledSubmitButton = styled.input<{
  pageColour?: string;
  overrideMargin?: string;
  enabled?: boolean;
}>`
  background: ${props => (props.pageColour ? props.pageColour : colors.cyan)};
  ${({ overrideMargin }) => overrideMargin && `margin: ${overrideMargin}!important`};
  ${({ enabled }) => !enabled && `opacity: 0.5;`};
`;

export const StyledSubmitContainer = styled(Container)`
  h3 {
    color: ${colors.navy};
    font-size: 50px;
  }
`;
