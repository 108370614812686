import React, { ReactNode, RefObject, useState } from "react";
import { Swiper, SwiperProps, SwiperRef } from "swiper/react";
import { Pagination, Controller, Navigation } from "swiper/modules";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { AbsoluteWrapper, Container } from "@util/standard";
import { assets, colors, mediaQuery } from "@util/constants";

interface Props {
  children: ReactNode;
  swiperProps?: SwiperProps;
  hideArrows?: boolean;
  swiperRef?: RefObject<SwiperRef>;
}

export default function Carousel({ children, swiperProps, hideArrows, swiperRef }: Props) {
  const [controller, setController] = useState<any>();
  const shouldHideArrows = (children as any[])?.length < 2;

  const handleNextSlide = () => {
    if (controller) controller.slideNext();
  };

  const handlePrevSlide = () => {
    if (controller) controller.slidePrev();
  };

  return (
    <Container position="relative" width="100%">
      <StyledSwiper
        ref={swiperRef}
        loop
        modules={[Controller, Pagination, Navigation]}
        onSwiper={setController}
        slidesPerView={"auto"}
        spaceBetween={0}
        allowTouchMove={!shouldHideArrows}
        pagination={{
          clickable: true,
        }}
        passiveListeners
        {...swiperProps}
      >
        {children}
      </StyledSwiper>
      {!hideArrows && (
        <NavWrapper onClick={handlePrevSlide} shouldHide={shouldHideArrows}>
          <NavArrow src={assets.circleArrowFill} />
        </NavWrapper>
      )}
      {!hideArrows && (
        <NavWrapper rightAligned onClick={handleNextSlide} shouldHide={shouldHideArrows}>
          <NavArrow src={assets.circleArrowFill} />
        </NavWrapper>
      )}
    </Container>
  );
}

const StyledSwiper = styled(Swiper)`
  width: 100%;
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    height: max-content;
    bottom: 30px;
  }
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: ${colors.white};
    opacity: 0.5;
  }
  .swiper-pagination-bullet-active {
    background-color: ${colors.white};
    opacity: 1;
  }
`;

const NavWrapper = styled(AbsoluteWrapper)<{ rightAligned?: boolean; shouldHide: boolean }>`
  height: 48px;
  width: 48px;
  top: 50%;
  left: 3%;
  z-index: 2;
  transform: translateY(-50%);
  cursor: pointer;
  ${({ shouldHide }) => shouldHide && `display: none;`}
  ${({ rightAligned }) =>
    rightAligned && `right: 3%; left: unset; transform: translateY(-50%) rotateZ(180deg);`};

  ${mediaQuery.tabletDown} {
    ${({ rightAligned }) => (rightAligned ? `right: 2%;` : `left: 2%;`)};
  }
`;

const NavArrow = styled.img`
  &:hover {
    transform: scale(1.2);
  }
  transition: all 0.2s;
  ${mediaQuery.tabletDown} {
    &:hover {
      transform: scale(1);
    }
  }
`;
